import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../components/layouts/dashboard';
import TabView from '../../components/tabview';
import AttendanceSummary from '../../components/attendance/attendance-summary';
import AttendanceHistoryTab from '../../components/attendance/attendance-history';

const AttendancePage = () => {

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [activeTab, setActiveTab] = useState(1);

  const AttendanceTabs = [
    {
      title: 'Attendance Summary',
      Content: AttendanceSummary,
    },
    {
      title: 'Attendance History',
      Content: AttendanceHistoryTab,
    },
    
  ];

  return (
    <DashboardLayout title='My Attendance'>
      <div className='flex flex-col -mt-4'>
        <div className='mt-3'>
          <TabView
            Tabs={AttendanceTabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AttendancePage;
