import React from "react";

export const InfoSquare = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Iconly/Broken/Info Square">
      <g id="Info Square">
        <path
          id="Info Square_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.7472 1.99976H8.25419C4.41019 1.99976 2.00019 4.39276 2.00019 8.25276V10.5748C1.99919 10.9968 2.34119 11.3398 2.76319 11.3398H2.76519H2.77419C3.19119 11.3398 3.53019 11.0018 3.53019 10.5838V10.5658V8.25276C3.53019 5.20976 5.21019 3.52976 8.25319 3.52976H15.7472C18.8002 3.52976 20.4702 5.20976 20.4702 8.25276V15.7528C20.4702 18.7958 18.7902 20.4668 15.7472 20.4668H8.25419C5.20219 20.4668 3.53119 18.7868 3.53119 15.7528C3.53119 15.3308 3.18819 14.9878 2.76619 14.9878C2.34319 14.9878 2.00119 15.3308 2.00119 15.7528C2.00019 19.6078 4.39219 21.9998 8.24419 21.9998H15.7472C19.6082 21.9998 22.0002 19.6078 22.0002 15.7558V8.25576C22.0002 4.39276 19.6082 1.99976 15.7472 1.99976ZM11.9947 16.5457C11.5807 16.5457 11.2447 16.2097 11.2447 15.7957V11.3757C11.2447 10.9617 11.5807 10.6257 11.9947 10.6257C12.4087 10.6257 12.7447 10.9617 12.7447 11.3757V15.7957C12.7447 16.2097 12.4087 16.5457 11.9947 16.5457ZM11.995 8.95386H12.005C12.419 8.95386 12.755 8.61786 12.755 8.20386C12.755 7.78986 12.419 7.45386 12.005 7.45386C11.591 7.45386 11.25 7.78986 11.25 8.20386C11.25 8.61786 11.581 8.95386 11.995 8.95386Z"
          fill="#130F26"
        />
      </g>
    </g>
  </svg>
);
