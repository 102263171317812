import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import Button from '../../components/button';
import { logout, useBackupLogin2Fa, useLogin2Fa} from '../../redux/user/hook';
import AuthLayout from '../../components/layouts/auth';
import { useSelector } from 'react-redux';
import FormOtpInput from '../../components/form-otp-input';

const Verify2Fa = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const { twoFaToken } = useSelector((state) => state.userSlice);

  const [otp, setOtp] = useState('');

  const [useBackupCode, setUseBackupCode] = useState(false);

  const history = useHistory();
  const { mutateAsync: login2Fa, isLoading: login2FaLoading } = useLogin2Fa();

  const { mutateAsync: backupLogin2Fa, isLoading: backupLogin2FaLoading } = useBackupLogin2Fa();

  const { token } = useParams();

  const submitForm = async () => {

    const requestData = {
        'token':token,
        'otp': otp,
    };

    if (useBackupCode) {
      backupLogin2Fa(requestData)
    } else {
      login2Fa(requestData)
    }
  };

  return (
    <AuthLayout>
        <React.Fragment>
            <form className='form'>
            <div className='w-full text-center flex flex-col pb-[25px] items-center justify-center'>
                <span className='text-[18px] font-semibold text-secondary-2'>Welcome Back&nbsp;</span>
                <span className={'text-[28px] font-extrabold  text-secondary-2'}>
                {twoFaToken?.first_name} {twoFaToken?.last_name}
                </span>
                <span className='flex justify-center text-[15px] text-color-gray'>
                    Use 2FA Backup Codes?&ensp;
                    <span 
                      className='text-[15px] font-extrabold hover:underline duration-300 cursor-pointer'
                      onClick={() => {
                        setUseBackupCode(!useBackupCode)
                      }}
                    >
                        Click here
                    </span>
                </span>
            </div>

            <div className='flex w-[100%] justify-center border bg-gray-50 p-2 rounded-md mb-4'>
                <FormOtpInput
                    value={otp}
                    valueLength={6}
                    onChange={(value) => setOtp(value)}
                    label={useBackupCode === true? `Enter 2FA Backup code`:`Enter One Time Password (OTP) generated here`}
                />
            </div>
            <div className='flex flex-row justify-between w-full mt-[20px] gap-4'>
              <Button
                text={'LOGOUT'}
                type='button'
                theme={'secondary'}
                className='flex gap-2 h-[35px] w-fit p-2 border-gray-400'
                textClass={'!text-[13px] text-gray-500'}
                onClick={() => {
                  logout()
                  // history.push('/logout')
                }}
              />
              <Button
                text={useBackupCode === true? 'VERIFY WITH BACKUP CODE': 'VERIFY OTP'}
                type='button'
                className='flex gap-2 h-[35px] w-fit p-2 border-gray-400'
                textClass={'!text-[13px] text-gray-500'}
                onClick={() => submitForm()}
                disabled={!otp && !token}
                loading={login2FaLoading || backupLogin2FaLoading}
              />
            </div>
            
            </form>
        </React.Fragment>
    </AuthLayout>
  );
};

export default Verify2Fa;
