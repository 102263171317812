import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { store } from '../..';
import { queryClient } from '../../..';
import { teams } from '../../../api/hr/team-management';
import { setAlert, setIsLoading } from '../../components/components-slice';

export function useGetTeamSummary() {
  return useQuery(['team-summary'], () => {
    return teams.getTeamSummary();
  });
}

export function useGetTeams() {
    return useQuery(
      ['teams'],
      () => {
        store.dispatch(setIsLoading(true));
        return teams.getTeams();
      },
      {
        select: (data) => {
          data?.forEach((team) => {
            team.created = moment(team.created).format('MMMM D, YYYY');
            team.computedActions = [0,1]
            team.team_manager = team.manager_setting.manager
            if (team && team.description && team.description.length >= 30) {
                team.description = team.description.substring(0, 30) + '....';
            }
          });
          return data;
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
      },
    );
}

export function useGetOnboardingTeams(limit, page, search) {
  return useQuery(
    ['onboarding-teams', { limit, page, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return teams.getTeams({
        limit,
        page,
        search,
      });
    },
    {
      select: (data) => {
        let newData = [];
        newData.push({ value: '', label: 'No Team Selected' });
        data.forEach((item) => {
          newData.push({ value: item.id, label: item.name });
        });
        return newData;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
    
  );
}

export function useAddTeam() {
    return useMutation(
      (payload) => {
        store.dispatch(setIsLoading(true));
        return teams.addTeam(payload);
      },
      {
        onSuccess: (response, variables, context) => {
          queryClient.invalidateQueries('teams');
          queryClient.invalidateQueries('team-settings');
          store.dispatch(setAlert(true, 'success', response.message));
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
      },
    );
}

export function useEditTeam() {
    return useMutation(
      (payload) => {
        store.dispatch(setIsLoading(true));
        return teams.updateTeam(payload);
      },
      {
        onSuccess: (response, variables, context) => {
          queryClient.invalidateQueries('teams');
          queryClient.invalidateQueries('team-settings');
          store.dispatch(setAlert(true, 'success', response.message));
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
      },
    );
}

export function useDeleteTeam() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return teams.deleteTeam(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('teams');
        queryClient.invalidateQueries('team-settings');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetTeam(id) {
    return useQuery(
        ['team', {id} ],
        () => {
            return teams.getTeam({id});
        },
        {
          onSettled: (data, error, variables, context) => {
              store.dispatch(setIsLoading(false));
          },
        },
    );
}

export function useGetTeamEmployees(id, limit, page, statusFilter, search) {
    return useQuery(
      ['team-employees', { id, limit, page, statusFilter, search }],
      () => {
        store.dispatch(setIsLoading(true));
        return teams.getTeamEmployees({
          id,
          limit,
          page,
          statusFilter,
          search,
        });
      },
      {
        enabled: id !== null,
        select: (data) => {
          data.employees.name = data.name;
          data.employees.id = data.id;
          if (data.employees.data) {
            data.employees.data.forEach((employee) => {
              employee.created = moment(employee.created).format('MMMM D, YYYY');
              employee.computedActions = [0];
              employee.employeeName = `${employee.employee_first_name} ${employee.employee_last_name}`;
            });
          }
          return data?.employees;
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        
      },
    );
}

export function useGetTeamSettings(id) {
    return useQuery(
      ['team-settings', { id }],
      () => {
        store.dispatch(setIsLoading(true));
        return teams.getTeamSettings({
          id
        });
      },
      {
        enabled: id !== null,
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        
      },
    );
}

export function useAddEmployeesToTeam() {
    return useMutation(
      (payload) => {
        return teams.addEmployeesToTeam(payload);
      },
      {
        onSuccess: (response, variables, context) => {
          queryClient.invalidateQueries('teams');
          store.dispatch(setAlert(true, 'success', response.message));
        },
      },
    );
}



export function useRemoveEmployeeFromTeam() {
  return useMutation(
      (payload) => {
          store.dispatch(setIsLoading(true));
          return teams.removeEmployeeFromTeam(payload);
      },
      {
          onSuccess: (response, variables, context) => {
          queryClient.invalidateQueries('team-employees');
          store.dispatch(setAlert(true, 'success', response.message));
          },
          onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
          },
      },
  );
}

export function useRemoveEmployeesFromTeam() {
    return useMutation(
        (payload) => {
            store.dispatch(setIsLoading(true));
            return teams.removeEmployeesFromTeam(payload);
        },
        {
            onSuccess: (response, variables, context) => {
            queryClient.invalidateQueries('team-employees');
            store.dispatch(setAlert(true, 'success', response.message));
            },
            onSettled: (data, error, variables, context) => {
            store.dispatch(setIsLoading(false));
            },
        },
    );
}

export function useMoveEmployeesToTeam() {
    return useMutation(
        (payload) => {
            store.dispatch(setIsLoading(true));
            return teams.moveEmployeesToTeam(payload);
        },
        {
            onSuccess: (response, variables, context) => {
            queryClient.invalidateQueries('team-employees');
            store.dispatch(setAlert(true, 'success', response.message));
            },
            onSettled: (data, error, variables, context) => {
            store.dispatch(setIsLoading(false));
            },
        },
    );
}