import React, { useEffect, useRef, useState } from 'react';
import TickSVG from '../../assets/svg/tick-primary.svg';
import { useHistory } from 'react-router-dom';
import Logo from '../logo';
import { useSelector } from 'react-redux';
import ProfileMenu from '../profile-menu';

const EmployeeHmoLayout = ({ children, step, title, company_name, logo, }) => {
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(1);
  const { user } = useSelector((state) => state.userSlice);

  const [steps, setSteps] = useState([
    {
      title: 'Add Dependants',
      subtitle: 'Add your dependants',
      completed: false,
    },
    {
      title: 'Select HMO, Plan and hospital',
      subtitle: 'Select your HMO, Plan and hospital',
      completed: false,
    },
    
  ]);

  useEffect(() => {
    if (user) {
      if (user.company_id && user.company) {
        if (user.status == 2) {
          history.push('/registration/profile')
        }
      }
    }
  }, [user])

  useEffect(() => {
    if (step) {
      setCurrentStep(step);
    }
  }, [step, currentStep]);

  return (
    <div className='flex flex-col h-screen min-h-screen max-h-screen overflow-auto'>
      <div className='flex flex-col lg:bg-secondary-2'>
        {/* Desktop Sidebar */}
        <div className='flex flex-col justify-center lg:justify-start lg:px-0'>
          <div>
            <div className='flex flex-col'>
              {/* Mobile Stepper */}
              <div className='flex lg:hidden justify-center w-full'>
                <div className='flex flex-row w-[85%] my-3'>
                  {steps.map((item, index) => (
                    <div
                      key={index}
                      className={`${
                        index + 1 !== steps.length
                          ? 'flex flex-row w-full items-center'
                          : ''
                      }`}
                    >
                      <div>
                        <div
                          className={`flex items-center justify-center w-[30px] h-[30px] rounded-[30px] ${
                            index + 1 <= currentStep
                              ? 'bg-secondary-2'
                              : 'border border-color-black'
                          }  ${
                            index + 1 === currentStep
                              ? 'opacity-100'
                              : 'opacity-40'
                          }`}
                        >
                          {index < currentStep - 1 ? (
                            <img
                              src={TickSVG}
                              alt='tick'
                              style={{ width: '16px', height: '16px' }}
                            />
                          ) : (
                            <span
                              className={`text-[16px] font-bold ${
                                index + 1 <= currentStep
                                  ? 'text-white'
                                  : 'text-color-black'
                              }`}
                            >
                              {index + 1}
                            </span>
                          )}
                        </div>
                      </div>

                      {index + 1 !== steps.length ? (
                        <div className='bg-color-border w-full h-[1px]'></div>
                      ) : null}
                    </div>
                  ))}
                </div>
              </div>
              <div className='flex flex-col lg:flex-row w-full'>
                <div className='w-full lg:w-[35%] flex flex-col lg:justify-between justify-between !max-h-screen overflow-auto ml-[5%]'>
                  <div className='flex flex-col'>
                    <div className='w-full lg:w-[78%] flex flex-col'>
                      {/* Header */}
                      <span className='header-3'>{title}</span>
                    </div>
                    {/* Desktop Stepper */}
                    <div className='hidden lg:flex flex-col justify-between w-full mt-4'>
                      <div className='flex flex-col w-[100%]'>
                        
                        <div className='mt-8 mb-5 flex flex-col'>
                          <span className='text-white text-[15px] font-extrabold'>
                            Welcome to the HMO Onboarding Portal. 
                          </span>
                          <span className='text-white text-[15px] p3 mt-1'>
                          Complete your employee HMO profiling in 3 easy steps.
                          </span>
                        </div>
                        {steps.map((item, index) => (
                          <div 
                            key={index}
                            className='flex flex-row mb-[50px]'>
                            <div className='flex items-center w-[15%]'>
                              <div
                                className={`flex items-center justify-center  w-[50px] h-[50px] rounded-[50px] ${
                                  index + 1 <= currentStep
                                    ? 'bg-white'
                                    : 'border border-color-black bg-white'
                                }  ${
                                  index + 1 == currentStep
                                    ? 'opacity-100'
                                    : 'opacity-40 '
                                }`}
                              >
                                {index < currentStep - 1 ? (
                                  <img
                                    src={TickSVG}
                                    alt='tick'
                                    style={{ width: '20px', height: '20px' }}
                                  />
                                ) : (
                                  <span
                                    className={`header-3 ${
                                      index + 1 <= currentStep
                                        ? 'text-secondary-2'
                                        : 'text-secondary-2'
                                    }`}
                                  >
                                    {index + 1}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className='flex flex-col w-[85%] justify-center text-white pl-3'>
                              <span
                                className={`text-[18px] font-bold text-white leading-[23px] ${
                                  index + 1 == currentStep
                                    ? 'text-opacity-100'
                                    : 'text-opacity-50'
                                }`}
                              >
                                {item.title}
                              </span>
                              <div className='w-[70%] mt-[4px]'>
                                <span
                                  className={`text-[14px] text-justify leading-[20px] text-white ${
                                    index + 1 === currentStep
                                      ? 'text-opacity-80'
                                      : 'text-opacity-40'
                                  } `}
                                >
                                  {item.subtitle}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      'flex items-center justify-center text-color-white gap-2  -ml-[20%]'
                    }
                  >
                    Powered By:{' '}
                    <a
                      href='https://www.ropay.africa'
                      target={'_blank'}
                      rel='noreferrer'
                      className='mt-2'
                    >
                      {<Logo width='70px' height='35px' white />}
                    </a>
                  </div>
                </div>
                <div className='w-full lg:w-[65%] h-screen'>
                  <div className='w-full bg-white h-full'>
                    {/* Page components */}
                    <div className='lg:max-h-[100vh] overflow-auto h-full'>
                      <div className='w-full flex items-center justify-center flex-col'>
                      <div className='flex justify-end w-full'>
                        <div className='mt-[20px] mr-[36px]'>
                          <ProfileMenu />
                        </div>
                      </div>
                        <div className='w-full lg:max-w-[85%] flex flex-col lg:flex-row '>
                          <div className='w-full pb-[30px] !h-full'>
                            {children}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeHmoLayout;
