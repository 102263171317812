import React from 'react';
import Info from './info';

const FormSelect = ({
  type,
  name,
  label,
  defaultValue,
  extraClass,
  selectRef,
  readOnly,
  options,
  error,
  onChange,
  errorMessage,
  infos,
  ...rest
}) => {
  const selectedThemeClass =
    type === 'default'
      ? ' w-full !pl-[15px] text-[14px] leading-[18.23px] h-[35px] border'
      : '!pl-[20px] h-[35px] border';

  return (
    <div>
      <div className='flex flex-col pb-2'>
        {label && (
          <label className='text-[12px] leading-[23px] font-normal text-color-gray -mb-2'>
            {label}
          </label>
        )}
        <select
          name={name}
          className={`shadow-sm select-input rounded-md ${selectedThemeClass} ${extraClass} my-[10px] text-color-black text-[13px]`}
          defaultValue={defaultValue}
          ref={selectRef}
          readOnly={readOnly}
          onChange={(event) => {
            onChange(event.target.value);
          }}
          {...rest}
        >
          {options.map((option, i) => (
            <option key={i} value={option.value} className='!text-color-black'>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      {error && <span>* {errorMessage}</span>}
      {infos && <Info infos={infos} />}
    </div>
  );
};

export default FormSelect;
