import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../components/layouts/dashboard';
import TabView from '../../components/tabview';
import HRPerformanceOverviewTab from '../../components/performance/performance-overview';
import PerformanceOKRTab from '../../components/performance/okr';
import PerformanceSmartGoalsTab from '../../components/performance/smart-goals';
import TechnicalAppraisalsTab from '../../components/performance/technical-appraisals';

const HRPerformancePage = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [activeTab, setActiveTab] = useState(1);
  const performanceTabs = [
    {
      title: 'Performance Appraisals',
      Content: HRPerformanceOverviewTab,
    },
    {
      title: 'OKR',
      Content: PerformanceOKRTab,
    },
    {
      title: 'SMART Goals',
      Content: PerformanceSmartGoalsTab,
    },
    {
      title: 'Technical Appraisals',
      Content: TechnicalAppraisalsTab,
    },
  ];

  return (
    <DashboardLayout title='Performance Management'>
        <div className='flex flex-col -mt-2'>
          <div>
            <TabView
              Tabs={performanceTabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>
        </div>
    </DashboardLayout>
  );
};

export default HRPerformancePage;
