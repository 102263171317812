import { Axios } from '../axios';
  
const fetchExit = async () => {
    const { data } = await Axios.get('/mobile/employee/exit');
    return data?.data;
};

const saveExitAnswers = async (payload) => {
    const { data } = await Axios.post(
      '/mobile/employee/exit/save-answer', payload
    );
    return data;
};

const saveExitStage = async (payload) => {
    const { data } = await Axios.post(
      '/mobile/employee/exit/save-stage/' + payload.id,
      payload.data,
    );
    return data;
};

const markExitComplete = async (payload) => {
    const { data } = await Axios.post(
      '/mobile/employee/exit/mark-exit-complete/' + payload.id,
      payload.data,
    );
    return data;
};

export const employeeExits = {
    fetchExit,
    saveExitAnswers,
    saveExitStage,
    markExitComplete
  };