import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../assets/svg/close.svg';
import { formValidation } from '../../../utils/functions';
import Button from '../../../components/button';
import FormInput from '../../../components/form-input';
import FormSelect from '../../../components/form-select';
import {useAddAdvancedPerformanceEvaluation,} from '../../../redux/human-resources/hook/performance';
import PerformanceEvaluationLayout from '../../../components/layouts/performance-evaluation';
import { employees as employeeAPI } from '../../../api/employees';
import { useHistory } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import FormSwitch from '../../../components/form-switch';
import WarningSVG from '../../../assets/svg/warning.svg';
import ConfirmationModal from '../../../components/modal/confirmation-modal';
import { useSelector } from 'react-redux';

function AdvancedEvaluation() {
    const {
        register,
        handleSubmit,
        formState: { isValid },
        errors,
    } = useForm({
        mode: 'onChange',
    });

    const { user } = useSelector((state) => state.userSlice);

    const history = useHistory()

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    const [showConfirmation, setShowConfirmation] = useState(false);

    const {
    mutateAsync: addPerformanceEvaluation,
    isLoading: addPerformanceEvaluationLoading,
    } = useAddAdvancedPerformanceEvaluation();


    const [type, setType] = useState('all');
    const [useOkr, setUseOkr] = useState(false);
    const [useOrganisationalAppraisal, setUseOrganisationalAppraisal] = useState(true);
    const [useSmartGoals, setUseSmartGoals] = useState(false);

    const typeOptions = [
        {
          value: 'all',
          label: 'All employees',
        },
        {
          value: 'select',
          label: 'Selected Employees',
        },
    ];

    const [employees, setEmployees] = useState([]);

    const loadOptions = (inputValue) =>
    new Promise((resolve) => {
      employeeAPI
        .getEmployees({
          limit: 10,
          statusFilter: 1,
          search: inputValue,
          page: 1,
        })
        .then((response) => {
          let data = [];

          response.data.forEach((item) => {
            data.push({
              label: `${item.first_name} ${item.last_name} | ${item.mobile}`,
              value: item.id,
            });
          });

          resolve(data);
        })
        .catch((err) => {
          resolve([]);
        });
    });
    
    const submitForm = async () => {
        let employee_ids = "";
        if (type == 'select') {
            if (employees.length === 0) {
                dispatch(setAlert(true, 'info', 'Select at least one employee'));
                return;
            }
            let employeeArrays = [];
        
            employees.forEach((item) => {
                employeeArrays.push(item.value);
            });
        
            employee_ids = employeeArrays.join();
        } else {
            employee_ids = "none"; 
        }
        
        let payload = {
            type,
            employee_ids,
            use_okr: useOkr? 1: 0,
            use_organisational_appraisal: useOrganisationalAppraisal? 1: 0,
            use_smart_goals: useSmartGoals? 1: 0,
            evaluation_start: startDate,
            evaluation_end: endDate,
        };
        addPerformanceEvaluation(payload).then((result) => {
            setShowConfirmation(true);
        });
    };

    const confirmationNext = () => {
        history.push('/performance');
    };

    return (
        <PerformanceEvaluationLayout step={2} title={'Add Evaluation Information'}>
            {showConfirmation?
            <ConfirmationModal
                isOpen={showConfirmation}
                closeModal={() => setShowConfirmation(false)}
                btnFunction={confirmationNext}
                title={'You did it, ' + user?.first_name}
                subtitle={'Performance Evalaution successfully added'}
            />
            :
            null}
            <div className='flex flex-col justify-center h-full min-h-[480px]'>
                <div className='flex flex-col overflow-auto'>
                    <form className='form'>
                        <div className='flex flex-row justify-between'>
                            <span className='header-3'>Evaluate Performance (Advanced)</span>
                        </div>
                        {/* <LoadingBar loading={performanceIsLoading} /> */}
                        <hr className='divider' />
                        <div className='flex flex-col'>
                            <div className='w-full '>
                                <FormSelect
                                    defaultValue={type}
                                    options={typeOptions}
                                    onChange={(selected) => {
                                    setType(selected);
                                    }}
                                    label='Performance Evaluation Scope'
                                />
                            </div>
                            <div>
                                {type !== 'all'?
                                <div>
                                    <label className='!text-[15px] leading-[23px] font-normal text-color-gray mb-3'>
                                    Select Employees
                                    </label>
                                    <AsyncSelect
                                        isMulti
                                        cacheOptions
                                        defaultOptions
                                        placeholder={'Type to select'}
                                        loadOptions={loadOptions}
                                        onChange={(options) => setEmployees(options)}
                                        classNamePrefix='mySelect'
                                    />
                                </div>
                                :
                                null
                                }
                                
                                <span className='text-color-gray'>Evaluation Period</span>
                                <div className='flex flex-row md:flex-col w-full justify-between'>
                                    <div className='w-full md:w-1/2'>
                                    <FormInput
                                        name='start_date'
                                        type='date'
                                        value={startDate}
                                        onInput={(e) => {
                                        setStartDate(e.target.value);
                                        }}
                                        inputRef={register(formValidation('date', true))}
                                        error={errors.start_date}
                                        errorMessage={
                                        errors.start_date && errors.start_date.message
                                        }
                                    />
                                    </div>
                                    <div className='w-full md:w-1/2 pl-2'>
                                    <FormInput
                                        name='end_date'
                                        type='date'
                                        value={endDate}
                                        onInput={(e) => {
                                        setEndDate(e.target.value);
                                        }}
                                        inputRef={register(formValidation('date', true))}
                                        error={errors.end_date}
                                        errorMessage={
                                        errors.end_date && errors.end_date.message
                                        }
                                    />
                                    </div>
                                </div>
                                <hr className='divider' />
                                <div className='w-full'>
                                    <FormSwitch
                                        label={'Evaluate Organisational Appraisals?'}
                                        name={'organisational_appraisals'}
                                        checked={useOrganisationalAppraisal}
                                        onClick={() => setUseOrganisationalAppraisal(!useOrganisationalAppraisal)}
                                    />
                                </div>
                                {useOrganisationalAppraisal?
                                <div className='flex flex-col justify-center'>
                                    <div className='flex flex-row px-4 border-secondary-4 border-[2px] rounded-lg p-2 mt-2 bg-color-warning-light text-[14px] items-center'>
                                        <div className='w-[70px] pr-2 flex flex-row items-center '>
                                            <img src={WarningSVG} alt='' srcset='' />
                                        </div>
                                        <span>
                                            Predefined Organisational Performance indicators will be assessed. Both behavioural and technical indicators will be assessed.
                                        </span>
                                    </div>
                                </div>
                                :
                                null
                                }
                                
                                <hr className='divider mt-0' />
                                <div className='w-full'>
                                    <FormSwitch
                                        label={'Evaluate Objectives and Key Results (OKR)?'}
                                        name={'evaluate_okr'}
                                        checked={useOkr}
                                        onClick={() => setUseOkr(!useOkr)}
                                    />
                                </div> 
                                {useOkr?
                                <div className='flex flex-col justify-center'>
                                    <div className='flex flex-row px-4 border-secondary-4 border-[2px] rounded-lg p-2 mt-2 bg-color-warning-light text-[14px] items-center'>
                                        <div className='w-[70px] pr-2 flex flex-row items-center '>
                                            <img src={WarningSVG} alt='' srcset='' />
                                        </div>
                                        <span>
                                            Predefined OKRs will be included in this assessment. OKRs used will be OKRs set per employee. Only OKRs that fall withtin the evaluation period will be considered.
                                        </span>
                                    </div>
                                </div>
                                :
                                null
                                }
                                <hr className='divider mt-0' />
                                <div className='w-full'>
                                    <FormSwitch
                                        label={'Evaluate SMART Goals?'}
                                        name={'smart_goals'}
                                        checked={useSmartGoals}
                                        onClick={() => setUseSmartGoals(!useSmartGoals)}
                                    />
                                </div>   
                                {useSmartGoals?
                                <div className='flex flex-col justify-center'>
                                    <div className='flex flex-row px-4 border-secondary-4 border-[2px] rounded-lg p-2 mt-2 bg-color-warning-light text-[14px] items-center'>
                                        <div className='w-[70px] pr-2 flex flex-row items-center '>
                                            <img src={WarningSVG} alt='' srcset='' />
                                        </div>
                                        <span>
                                            Predefined SMART Goals will be included in this assessment. SMART Goals set per employee will be attached to that employee. Only SMART Goals that fall withtin the evaluation period will be considered.
                                        </span>
                                    </div>
                                </div>
                                :
                                null
                                }
                            </div>
                            <div className='w-full mt-[20px] pb-4 '>
                                <Button
                                    loading={addPerformanceEvaluationLoading}
                                    disabled={!isValid}
                                    onClick={() => submitForm()}
                                    text={'COMMENCE EVALUATION'}
                                    type='button'
                                />
                                <div
                                    className='flex justify-center w-full cursor-pointer'
                                    onClick={() => history.goBack()}
                                    >
                                    <span className='p2-medium text-color-black mt-3'>Back</span>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </PerformanceEvaluationLayout>
    );
}

export default AdvancedEvaluation;
