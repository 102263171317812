import React, { useState } from 'react';

const ChatContent = () => {
    return (
        <div className='w-full flex flex-col gap-3 items-center mt-2'>
            <div className='w-full h-[40px] bg-highlight rounded-2xl '>

            </div>
            <div className='flex flex-col w-full min-h-[400px] max-h-[400px] bg-highlight rounded-2xl overflow-y-auto'>
                <div className='flex justify-start w-full'>
                    <div class="talk-bubble tri-right border btm-left-in round">
                        <div class="talktext">
                            <span className='text-[11px] text-white'>Now flipped the other way and square. Uses .border and .btm-right-in</span>
                        </div>
                    </div>
                </div>
                <div className='flex justify-end w-full'>
                    <div class="talk-bubble-right tri-right border btm-right-in round">
                        <div class="talktext">
                            <span className='text-[11px] text-white'>
                                Now flipped the other way and square. Uses .border and .btm-right-in, Now flipped the other way and square. Uses .border and .btm-right-in, Now flipped the other way and square. Uses .border and .btm-right-in
                            </span>
                        </div>
                    </div>
                </div>
                <div className='flex justify-end w-full'>
                    <div class="talk-bubble-right tri-right border btm-right-in round">
                        <div class="talktext">
                            <span className='text-[11px] text-white'>
                                Now flipped the other way and square. Uses .border and .btm-right-in, Now flipped the other way and square. Uses .border and .btm-right-in, Now flipped the other way and square. Uses .border and .btm-right-in
                            </span>
                        </div>
                    </div>
                </div>
                <div className='flex justify-end w-full'>
                    <div class="talk-bubble-right tri-right border btm-right-in round">
                        <div class="talktext">
                            <span className='text-[11px] text-white'>
                                Now flipped the other way and square. Uses .border and .btm-right-in, Now flipped the other way and square. Uses .border and .btm-right-in, Now flipped the other way and square. Uses .border and .btm-right-in
                            </span>
                        </div>
                    </div>
                </div>
                <div className='flex justify-end w-full'>
                    <div class="talk-bubble-right tri-right border btm-right-in round">
                        <div class="talktext">
                            <span className='text-[11px] text-white'>
                                Now flipped the other way and square. Uses .border and .btm-right-in, Now flipped the other way and square. Uses .border and .btm-right-in, Now flipped the other way and square. Uses .border and .btm-right-in
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full h-[60px] bg-highlight rounded-2xl'>

            </div>
        </div>
    );
};

export default ChatContent;