import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { store } from '../..';
import { queryClient } from '../../..';
import { employeeSalaryAdvance } from '../../../api/employees/salary-advance';
import { setAlert, setIsLoading } from '../../components/components-slice';

export function useGetEmployeeSalaryAdvance(limit, page, statusFilter, search) {
  return useQuery(
    ['employee-salary-advances', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeSalaryAdvance.getEmployeeSalaryAdvance({
        limit,
        page,
        statusFilter,
        search
      });
    },
    {
      select: (data) => {
        data.data.forEach((employee) => {
          employee.created = moment(employee.created).format('MMMM D, YYYY');
          employee.formatted_month = moment(employee.month).format('MMMM, YYYY');
          employee.amount = parseFloat(employee.amount).toFixed(2);
          employee.employeeName = `${employee.first_name} ${employee.last_name}`;
          employee.status = parseInt(employee.status)
          if (employee.status == 2) {
            employee.status = 0
          }
          switch (parseInt(employee.status)) {
            case 0:
              employee.computedActions = [0, 1];
              break;
            default:
              break;
          }
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useAddSalaryAdvance() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeSalaryAdvance.addSalaryAdvance(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-salary-advances');
        store.dispatch(setAlert(true, 'success', 'Added successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useEditSalaryAdvance() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeSalaryAdvance.editSalaryAdvance(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-salary-advances');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useBulkAddSalaryAdvance() {
  return useMutation(
    (payload) => {
      return employeeSalaryAdvance.bulkAddSalaryAdvance(payload);
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries('employee-salary-advances');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeleteSalaryAdvance() {
  return useMutation(
    (payload) => {
      return employeeSalaryAdvance.deleteSalaryAdvance(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('employee-salary-advances');
        store.dispatch(setAlert(true, 'success', 'Salary advance deleted successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useSingleGetEmployeeSalaryAdvances(id, limit, page) {
  return useQuery(
    ['single-employee-salary-advanced', {id, limit, page}],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeSalaryAdvance.getSingleEmployeeSalaryAdvances({id, limit, page});
    },
    {
      enabled: id !== null,
      select: (data) => {
        data.data.forEach((employee) => {
          employee.created = moment(employee.created).format('MMMM D, YYYY');
          employee.formatted_month = moment(employee.month).format('MMMM, YYYY');
          employee.amount = parseFloat(employee.amount).toFixed(2);
          employee.employeeName = `${employee.first_name} ${employee.last_name}`;
          employee.status = parseInt(employee.status)
          if (employee.status == 2) {
            employee.status = 0
          }
          switch (parseInt(employee.status)) {
            case 0:
              employee.computedActions = [0, 1];
              break;
            default:
              break;
          }
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetSalaryAdvanceApplications(limit, page, search) {
  return useQuery(
    ['reimbursement-applications', {limit, page, search}],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeSalaryAdvance.getSalaryAdvanceApplications({
        limit, 
        page, 
        search
      });
    },
    {
      select: (data) => {
        data.data.forEach((application) => {
          application.created = application.created.split('T')[0];
          application.amount = parseFloat(
            application.amount,
          ).toFixed(2);
          application.employeeName = `${application.first_name} ${application.last_name}`;
          //compute action items
          switch (application.status) {
            case 0:
              application.computedActions = [0, 2];
              break;
            case 3:
              application.computedActions = [1, 2, 3];
              break;
            default:
              break;
          }
        });

        return data;
      },

      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

//New
export function useSingleGetEmployeeSalaryAdvanceApplications(id, limit, page, search) {
  return useQuery(
    ['single-employee-reimbursement-applications', { id, limit, page, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeSalaryAdvance.getSingleEmployeeSalaryAdvanceApplications({ id, limit, page, search });
    },
    {
      select: (data) => {
        data.data.forEach((application) => {
          application.created = application.created.split('T')[0];
          application.amount = parseFloat(
            application.amount,
          ).toFixed(2);
          application.employeeName = `${application.first_name} ${application.last_name}`;
          //compute action items
          switch (application.status) {
            case 0:
              application.computedActions = [0, 2];
              break;
            case 3:
              application.computedActions = [1, 2, 3];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useDisburseSalaryAdvanceApplication() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeSalaryAdvance.disburseApplication(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('reimbursement-applications');
        queryClient.invalidateQueries('employee-reimbursements');
        queryClient.invalidateQueries('single-employee-reimbursement-applications');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useApprovePaySalaryAdvanceApplication() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeSalaryAdvance.approvePaySalaryAdvance(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('reimbursement-applications');
        queryClient.invalidateQueries('employee-reimbursements');
        queryClient.invalidateQueries('single-employee-reimbursement-applications');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useApproveSalaryAdvance() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeSalaryAdvance.approveSalaryAdvance(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('reimbursement-applications');
        queryClient.invalidateQueries('employee-reimbursements');
        queryClient.invalidateQueries('single-employee-reimbursement-applications');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeclineSalaryAdvanceApplication() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeSalaryAdvance.declineSalaryAdvanceApplication(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('reimbursement-applications');
        queryClient.invalidateQueries('employee-reimbursements');
        queryClient.invalidateQueries('single-employee-reimbursement-applications');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeclineSalaryAdvanceApplicationDisbursement() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeSalaryAdvance.declineApplicationDisbursement(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('reimbursement-applications');
        queryClient.invalidateQueries('employee-reimbursements');
        queryClient.invalidateQueries('single-employee-reimbursement-applications');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

