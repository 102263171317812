import { createSlice } from '@reduxjs/toolkit';

// Slice
const slice = createSlice({
  name: 'onboarding',
  initialState: {
    currentOnboarding: 'first-time',
    isDone: false,
    currentStep: 1,
  },
  reducers: {
    setCurrentOnboarding(state, { payload }) {
      state.currentOnboarding = payload;
    },
    setOnboardingStep(state, { payload }) {
      state.currentStep = payload;
    },
    setOnboardingDone(state, { payload }) {
      state.isDone = payload;
    },
  },
});

// Actions
export const { setCurrentOnboarding, setOnboardingStep, setOnboardingDone } =
  slice.actions;

export const updateCurrentOnboarding = (state) => (dispatch) => {
  dispatch(setCurrentOnboarding(state));
};

export const updateOnboardingStep = (state) => (dispatch) => {
  dispatch(setOnboardingStep(state));
};

export const updateOnboardingDone = (state) => (dispatch) => {
  dispatch(setOnboardingDone(state));
};

export const updateOnboardingSource = (state) => (dispatch) => {
  dispatch(setOnboardingSource(state));
};

export default slice.reducer;
