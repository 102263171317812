import React, { useState } from 'react';
import Button from '../button';
import ChatContent from './chat-content';

const ChatView = () => {

    return (
        <div className='md:w-[60%] w-full p-2 flex flex-col gap-2 items-center pt-2'>
            <div className='flex flex-col gap-1 items-center w-full'>
                <Button
                    text='START A NEW CONVERSATION'
                    type='button'
                    theme='primary'
                    className='flex gap-2 h-[42px] !min-w-full p-2 rounded-lg'
                    textClass={'!text-[13px]'}
                    leftIcon={'add'}
                />
            </div>
            <div className='w-full h-[40px] rounded-3xl'>
                <ChatContent />
            </div>

        </div>
    );
};

export default ChatView;