import { Axios } from '../axios';

const getTeams = async () => {
    const { data } = await Axios.get( `/mobile/teams`,);
    return data?.data;
};
  
const getTeam = async (payload) => {
    const { data } = await Axios.get('/mobile/teams/' + payload.id, payload);
    return data?.data;
};

const getTeamEmployees = async (payload) => {
    const { data } = await Axios.get('/mobile/teams/' + payload.id, payload);
    return data?.data;
};

export const teams = {
    getTeams,
    getTeam,
    getTeamEmployees,
};

