import React, { useEffect } from 'react';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  PAGINATION_DEFAULT,
} from '../../../utils/constants';
import Button from '../../button';
import DataTable from '../../datatable';
import { useGetBranchTeams } from '../../../redux/human-resources/hook/branches';

const BranchTeamTab = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: '',
  });
  const { id } = useParams();

  const { data: branch_teams } = useGetBranchTeams(
    id,
    pagination?.limit,
    pagination?.page,
    pagination?.search,
  );

  const tableConfig = {
    headers: ['Date Added', 'Branch Name', 'Team Name'],
    keys: ['created', 'branch_name', 'team_name'],
    mobileHeader: {
      left: {
        title: 'Date Added',
        key: 'created',
      },
      right: {
        title: 'Team Name',
        key: 'name',
      },
    },
  };

  const actionConfig = [
    {
      name: 'Remove',
      color: 'red',
      action: (id) => {
        removeTeamsFromGroup(id)
      },
    },
  ];


  return (
    <div className='flex flex-col bg-white shadow-md rounded-md'>
      <div className='flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3'>
        <div className='p3-bold header-4'>
          Teams
        </div>
      </div>
      <hr className='divider mt-2' />
      <DataTable
        data={branch_teams}
        tableConfig={tableConfig}
        actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        computedActions={true}
        searchData={true}
        noCheck={true}
      />
    </div>
  );
};

export default BranchTeamTab;
