import React, { useState } from 'react';
import Button from '../../button';
import CloseSVG from '../../../assets/svg/close.svg';
import FormOtpInput from '../../form-otp-input';

import WarningSVG from '../../../assets/svg/warning.svg';
import FormInput from '../../form-input';
import Modal from '../modal';
import { useAddCompany } from '../../../redux/company/hook';
import DOMPurify from 'dompurify';

function AddCompanyModal({ isOpen, closeModal, refresh, setEmployee }) {
  const [otp, setOtp] = useState('');
  const [code, setCode] = useState('');

  const [showOtpInput, setShowOtpInput] = useState(false);
  const [showExpiredToken, setShowExpiredToken] = useState(false);
  const { mutateAsync: addCompany, isLoading: addCompanyLoading } =
    useAddCompany();

  async function submitForm() {
    let data = {
      code: code,
    };

    //   await generateOtp(data).then((result) => {
    //     if (result?.status == 'failed') {
    //       setToken('');
    //       setShowExpiredToken(true);
    //     } else {
    //       setButtonAction('confirm_otp');
    //       setButtonText('CONFIRM OTP');
    //       setShowOtpInput(true);
    //     }
    //   });
    // } else if (buttonAction === 'confirm_otp') {
    //   let data = {
    //     phone: phone,
    //     token: token,
    //     otp: otp,
    //   };
    await addCompany(data).then((result) => {
      closeModal();
    });
  }

  return (
    <Modal
      scrollable={true}
      className='!max-w-[750px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto w-full'>
        <div className='text-center flex flex-col'>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-3'>Add Company</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='' />
              </div>
            </div>
          </div>
        </div>
        <hr className='divider' />
        <div className='px-8 pb-3 flex flex-col w-[100%]'>
          {showOtpInput ? (
            <>
              <div className='flex w-[100%] justify-center'>
                <FormOtpInput
                  value={otp}
                  valueLength={6}
                  onChange={(value) => setOtp(value)}
                  label='Enter OTP'
                />
              </div>
            </>
          ) : (
            <div className='flex flex-col w-full justify-between'>
              {showExpiredToken ? (
                <div className='flex flex-col justify-center mb-4 -mt-4'>
                  <div className='flex flex-row border-secondary-4 border-[2px] rounded-lg p-4 bg-color-warning-light'>
                    <div className='w-[70px] pr-4 flex flex-row items-center '>
                      <img src={WarningSVG} alt='' srcset='' />
                    </div>
                    <span className='text-justify text-[14px]'>
                      Token is expired. Please check your email for the updated
                      token and regenerate the OTP.
                    </span>
                  </div>
                </div>
              ) : null}

              <div className='w-full'>
                <FormInput
                  label='Company Code (Check email)'
                  name='code'
                  type='text'
                  placeholder={'Enter Company Code'}
                  value={code}
                  onInput={(e) => {
                    setCode(e.target.value);
                  }}
                />
              </div>
            </div>
          )}
          <div className='w-full mt-4'>
            <Button
              text={'Add Company'}
              type='submit'
              className={'!bg-secondary-2'}
              loading={addCompanyLoading}
              onClick={() => submitForm()}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AddCompanyModal;
