import { createSlice } from '@reduxjs/toolkit';

// Slice
const slice = createSlice({
  name: 'recruitment',
  initialState: {
    assignWorkflowData: null,
  },
  reducers: {
    setAssignWorkflow: (state, { payload }) => {
      state.assignWorkflowData = payload;
    },
  },
});

// Actions
export const { setAssignWorkflow } = slice.actions;

export default slice.reducer;
