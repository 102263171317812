import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { PAGINATION_DEFAULT } from "../../../utils/constants";
import DataTable from "../../datatable";
import {
  useGetDepartment,
  useGetDepartmentTeams,
} from "../../../redux/human-resources/hook/departments";

const DepartmentTeamTab = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: "",
  });
  const { id } = useParams();

  const { data: department_teams } = useGetDepartmentTeams(
    id,
    pagination?.limit,
    pagination?.page,
    pagination?.search
  );
  console.log(department_teams);
  const tableConfig = {
    headers: ["Date Added", "Team Name"],
    keys: ["created", "team_name"],
    mobileHeader: {
      left: {
        title: "Date Added",
        key: "created",
      },
      right: {
        title: "Team Name",
        key: "team_name",
      },
    },
  };

  return (
    <div className="flex flex-col bg-white">
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="p3-bold header-4">Teams</div>
      </div>
      <hr className="divider mt-2" />
      <DataTable
        data={department_teams}
        tableConfig={tableConfig}
        updatePagination={(data) => setPagination(data)}
        computedActions={true}
        noCheck={true}
      />
    </div>
  );
};

export default DepartmentTeamTab;
