import React from "react";

export const Change = () => (
  <svg
    width="512"
    height="512"
    viewBox="0 0 497.883 497.883"
    fill="#000000"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="M256.13,0.125c-78.9,0-150.399,37-197.199,93.1l-19.2-18.9c-3.9-4.2-9.901-5.7-15.3-3.6c-5.4,1.5-9.3,6.299-9.901,11.999   l-14.399,99c-1.626,12.188,12.431,19.135,17.1,16.8c3.051-0.434,105.377-15.007,99-14.099c5.7-0.601,10.201-4.501,12.001-9.901   c1.8-5.4,0.599-11.4-3.6-15.599l-22.8-22.8c35.7-45.601,92.199-75,154.3-75c107.1,0,195.7,87.299,196,194.399c0,0.3,0,0.3,0,0.601   c0,3.814-0.126,7.63-0.363,11.413c-0.591,9.487,7.701,17.161,17.159,15.778l30.236-4.426c7.167-1.049,12.572-7.07,12.821-14.308   c0.097-2.842,0.147-5.674,0.147-8.458C512.13,115.676,397.209,0.125,256.13,0.125z" />
      <path d="M495.03,310.725c-3.051,0.434-105.377,15.007-99,14.099c-5.7,0.601-10.201,4.501-12.001,9.901s-0.599,11.4,3.6,15.601   l24.001,24c-35.402,46.5-92.501,76.8-155.501,76.8c-107.401,0-196-87.601-196-195c0.101-1.952-0.242-6.154,0.416-14.141   c0.791-9.622-7.582-17.43-17.043-16.097l-30.231,4.255c-7.141,1.007-12.57,6.956-12.894,14.163   C0,252.662,0.17,259.392,0.13,262.424c3.3,137.701,117.4,249.701,256,249.701c79.799,0,151.901-37.601,198.701-94.9l17.699,17.699   c3.94,4.378,10.488,5.526,15.3,3.6c5.4-1.5,9.3-6.299,9.901-11.999l14.399-99C513.37,318.238,504.797,309.096,495.03,310.725z" />
    </g>
  </svg>
);
