import React, { useEffect, useState } from 'react';
import { useFetchHMODependants, useGetBeneficiary, useStoreDependant } from '../../redux/hmo/hook';
import DashboardLayout from '../../components/layouts/dashboard';
import { StatusText } from '../../components/fragments/status-text';
import LoadingBar from '../../components/loader-bar';
import EmptyTableSVG from '../../assets/svg/empty-table.svg';
import Button from '../../components/button';
import { useHistory } from 'react-router-dom';
import FormSelect from '../../components/form-select';
import FormInput from '../../components/form-input';
import { useForm } from 'react-hook-form';

const EmployeeHMOPage = () => {

  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const history = useHistory()

  const { data: hmo_beneficiary, isLoading: beneficiaryLoading } = useGetBeneficiary();
  const { data: hmo_dependants, isLoading: dependantsLoading } = useFetchHMODependants();

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [mobile, setMobile] = useState();
  const [address, setAddress] = useState();
  const [type, setType] = useState('Spouse');

  const typeOptions = [
    {
      label: 'Spouse',
      value: 'Spouse'
    },
    {
      label: 'Child',
      value: 'Child'
    }
  ]

  const [showAddDependant, setShowAddDependant] = useState(false)

  useEffect(() => {
    if ( hmo_beneficiary ) {
      if (hmo_beneficiary.status == 0 || hmo_beneficiary.status == 2 && hmo_beneficiary.max_dependants > 0 && hmo_dependants?.length < hmo_beneficiary.max_dependants) {
        history.push('/hmo/add-dependant')
      } else if (hmo_beneficiary.status == 2) {
        history.push('/hmo/select-hmo')
      }
    }
  }, [hmo_beneficiary, hmo_dependants])

  useEffect(() => {
    if ( !hmo_beneficiary && !beneficiaryLoading ) {
      history.push('/dashboard')
    }
  }, [hmo_beneficiary, beneficiaryLoading])

  const statusConfig = [
    {
      label: 'Pending',
      color: 'orange',
      value: 0,
    },
    {
      label: 'Active',
      color: 'green',
      value: 1,
    },
    {
      label: 'Pending',
      color: 'orange',
      value: 2,
    },
    {
      label: 'Awating Activatiion',
      color: 'orange',
      value: 3,
    },
  ];

  const { mutateAsync: storeDependant, isLoading: storeDependantLoading } = useStoreDependant();

  const submitForm = async () => {
    let payload = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      mobile: mobile,
      address: address,
      type: type
    }
    await storeDependant(payload).then(() => {
      setShowAddDependant(false)
    })
  }
  
  return (
    <DashboardLayout title='My HMO Information'>
      <div className='flex flex-col bg-white shadow-md'>
        <div className='flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3'>
          <div className='p3-bold header-4'>
            My HMO information
          </div>
        </div>
        <hr className='divider' />
        {beneficiaryLoading || dependantsLoading?
        <div className='flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]'>
          <LoadingBar loading={beneficiaryLoading || dependantsLoading} />
        </div>
        :
        <div className='flex md:flex-row flex-col gap-4 p-3'>
          <div className='flex flex-col md:w-1/2 gap-4 p-3 border rounded-md shadow-md'>
            {beneficiaryLoading?
            <div className='flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]'>
              <LoadingBar loading={beneficiaryLoading} />
            </div>
            :
            <>
              {hmo_beneficiary?
              <>
                <div className='flex flex-row justify-between w-full items-center mb-3'>
                  <span className='header-4 p-2 text-white rounded-[8px] bg-secondary-2 max-w-fit'>HMO Information</span>
                  <StatusText
                    code={hmo_beneficiary?.status}
                    statusConfig={statusConfig}
                    className={'py-2 rounded p1-bold px-3 p2-bold text-[15px]'}
                  />
                </div>
                <div className='grid grid-cols-2 gap-4 w-full'>
                  <div className='flex flex-col'>
                    <span className='header-5 text-color-gray'>First Name</span>
                    <span className='mt-1 text-color-black font-bold'>{hmo_beneficiary?.first_name}</span>
                  </div>
                  <div className='flex flex-col'>
                    <span className='header-5 text-color-gray'>Last name</span>
                    <span className='mt-1 text-color-black font-bold'>{hmo_beneficiary?.last_name}</span>
                  </div>
                  <div className='flex flex-col'>
                    <span className='header-5 text-color-gray'>Phone Number</span>
                    <span className='mt-1 text-color-black font-bold'>{hmo_beneficiary?.mobile}</span>
                  </div>
                  
                  <div className='flex flex-col'>
                    <span className='header-5 text-color-gray'>Email</span>
                    <span className='mt-1 text-color-black font-bold'>
                    {hmo_beneficiary?.email}
                    </span>
                  </div>
                  <div className='flex flex-col'>
                    <span className='header-5 text-color-gray'>HMO Name</span>
                    <span className='mt-1 text-color-black font-bold'>{hmo_beneficiary?.hmo_name}</span>
                  </div>
                  <div className='flex flex-col'>
                    <span className='header-5 text-color-gray'>HMO Plan</span>
                    <span className='mt-1 text-color-black font-bold'>{hmo_beneficiary?.hmo_plan}</span>
                  </div>
                  <div className='flex flex-col'>
                    <span className='header-5 text-color-gray'>Max Dependants</span>
                    <span className='mt-1 text-color-black font-bold'>{hmo_beneficiary?.max_dependants}</span>
                  </div>
                  <div className='flex flex-col'>
                    <span className='header-5 text-color-gray'>
                      Policy Number
                    </span>
                    <span className='mt-1 text-color-black font-bold'>{hmo_beneficiary?.policy_number ? hmo_beneficiary?.policy_number : 'Not Activated'}</span>
                  </div>
                </div>
                
                <div className='flex flex-col justify-start gap-3 w-full items-start mb-3'>
                  <span className='header-4 p-2 text-white rounded-[8px] bg-secondary-2 max-w-fit'>Hospitals</span>
                  {hmo_beneficiary?.hospitals && hmo_beneficiary?.hospitals.length > 0 ?
                  <div className='flex flex-col gap-3'>
                    {hmo_beneficiary?.hospitals?.map((item, index) => (
                    <div className='flex flex-col p-3 border shadow-md rounded-md gap-1' key={index}>
                      <div className='text-[15px] font-extrabold'>{item.hospital}</div>
                      <div className='flex justify-between text-color-gray'>
                        <span className='text-color-gray text-[14px]'>{item.address + item.city + item.state}</span>
                      </div>
                    </div>
                    ))}
                  </div>
                  :
                  <div className='flex flex-col p-3 border shadow-md rounded-md gap-1 h-[80px] w-full items-center justify-center'>
                    No Hospital Selected
                  </div>}
                </div>
              </>
              :
              <div className='flex flex-col items-center justify-center w-[100%]'>
                <img src={EmptyTableSVG} alt='No Data Fetched' width={'70%'} height={'100px'}/>
                <span className='header-4 p-2 text-white rounded-[8px] bg-secondary-2 max-w-fit'>No HMO information fetched</span>
              </div>
              } 
            </>
            }
          </div>
          <div className='flex flex-col md:w-1/2 p-3 border rounded-md shadow-md'>
            {dependantsLoading?
            <div className='flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]'>
              <LoadingBar loading={dependantsLoading} />
            </div>
            :
            <>
            {showAddDependant?
            <>
              <div className='text-center font-semibold'>Add Dependant</div>
              <form className='form min-h-fit border p-3 rounded-md shadow-sm' >
                <div className='flex flex-col md:flex-row w-full justify-between'>
                  <div className='w-full md:w-1/2 md:pr-2'>
                    <FormInput
                      label='First Name'
                      name='first_name'
                      type='text'
                      value={firstName}
                      onInput={(e) => {
                        setFirstName(e.target.value);
                      }}
                      error={errors.first_name}
                      errorMessage={errors.first_name && errors.first_name.message}
                    />
                  </div>
                  <div className='w-full md:w-1/2 md:pl-2'>
                    <FormInput
                      label='Last Name'
                      name='last_name'
                      type='text'
                      value={lastName}
                      onInput={(e) => {
                        setLastName(e.target.value);
                      }}
                      error={errors.last_name}
                      errorMessage={errors.last_name && errors.last_name.message}
                    />
                  </div>
                </div>
                <div className='flex flex-col md:flex-row w-full justify-between'>
                  <div className='w-full md:w-2/3 md:pr-2'>
                    <FormInput
                      label='Email'
                      name='email'
                      type='email'
                      value={email}
                      onInput={(e) => {
                        setEmail(e.target.value);
                      }}
                      error={errors.email}
                      errorMessage={errors.email && errors.email.message}
                    />
                  </div>
                  <div className='w-full md:w-1/3 md:pl-2'>
                    <FormInput
                      label='Mobile'
                      name='mobile'
                      type='text'
                      value={mobile}
                      onInput={(e) => {
                        setMobile(e.target.value);
                      }}
                      error={errors.mobile}
                      errorMessage={errors.mobile && errors.mobile.message}
                    />
                  </div>
                </div>
                <div className='flex flex-col md:flex-row w-full justify-between'>
                  <div className='w-full md:w-2/3 md:pr-2'>
                    <FormInput
                      label='Address'
                      name='address'
                      type='text'
                      value={address}
                      onInput={(e) => {
                        setAddress(e.target.value);
                      }}
                      error={errors.address}
                      errorMessage={errors.address && errors.address.message}
                    />
                  </div>
                  <div className='w-full md:w-1/3 md:pl-2'>
                    <FormSelect
                      value={type}
                      options={typeOptions}
                      onChange={(selected) => {
                        setType(selected);
                      }}
                      label='Dependant Type'
                    />
                  </div>
                </div>
                <div className='flex gap-3 md:w-full justify-end mt-2'>
                  <Button
                    text='Cancel'
                    type='button'
                    theme='secondary'
                    className='flex gap-2 h-[32px] w-fit p-2 '
                    textClass={'!text-[13px]'}
                    onClick={() => {
                      setShowAddDependant(false)
                    }}
                  />
                  <Button
                    text='Save Dependant'
                    type='button'
                    theme='primary'
                    className='flex gap-2 h-[32px] w-fit p-2 '
                    textClass={'!text-[13px]'}
                    loading={storeDependantLoading}
                    onClick={() => {
                      submitForm()
                    }}
                  />
                </div>
              </form>
            </>
            :
            <>
              {hmo_dependants && hmo_beneficiary?
              <>
                <span className='header-4 p-2 text-white rounded-[8px] bg-secondary-2 max-w-fit'>Dependants Summary</span>
                <div className='flex flex-col gap-3 mt-3'>
                  
                  <>
                  <div className='text-center font-semibold'>{hmo_dependants?.length}/{hmo_beneficiary?.max_dependants} Dependants Added</div>
                  <div className='flex flex-col gap-3'>
                    {hmo_dependants?.map((item, index) => (
                    <div className='flex flex-col p-3 border shadow-md rounded-md gap-1' key={index}>
                      <div className='text-[15px] font-extrabold'>{item.first_name} {item.last_name} <span className='text-[13px] text-color-gray'>({item.type})</span></div>
                      <div className='flex justify-between text-color-gray'>
                        <span className='text-color-gray text-[14px]'>{item.mobile}</span>
                        <span className='text-color-gray text-[14px]'>{item.email}</span>
                      </div>
                    </div>
                    ))}
                  </div>
                  </>

                  {hmo_beneficiary?.max_dependants > 0 && hmo_dependants?.length <= hmo_beneficiary?.max_dependants?
                  <div className='flex gap-2 md:w-full justify-end'>
                    <Button
                      text='Add dependant'
                      type='button'
                      theme='primary'
                      className='flex gap-2 h-[32px] w-fit p-2 '
                      textClass={'!text-[13px]'}
                      leftIcon={'add'}
                      onClick={() => {
                        setShowAddDependant(true)
                      }}
                    />
                  </div>
                  :
                  null
                  }
                </div>
              </>
              :
              <div className='flex flex-col items-center justify-center w-[100%] gap-2'>
                {!hmo_dependants && hmo_beneficiary?.max_dependants > 0?
                <div className='flex md:w-full justify-end'>
                  <Button
                    text='Add dependant'
                    type='button'
                    theme='primary'
                    className='flex gap-2 h-[32px] w-fit p-2'
                    textClass={'!text-[13px]'}
                    leftIcon={'add'}
                    onClick={() => {
                      setShowAddDependant(true)
                    }}
                  />
                </div>
                :hmo_beneficiary?.max_dependants > 0 && hmo_dependants?.length <= hmo_beneficiary?.max_dependants?
                <div className='flex md:w-full justify-end'>
                  <Button
                    text='Add dependant'
                    type='button'
                    theme='primary'
                    className='flex gap-2 h-[32px] w-fit p-2 '
                    textClass={'!text-[13px]'}
                    leftIcon={'add'}
                    onClick={() => {
                      setShowAddDependant(true)
                    }}
                  />
                </div>
                :
                null
                }
                <img src={EmptyTableSVG} alt='No Data Fetched' width={'70%'} height={'100px'}/>
                <span className='header-4 p-2 text-white rounded-[8px] bg-secondary-2 max-w-fit'>No HMO dependants fetched</span>
              </div>
              } 
            </>
            }
            </>
            }
          </div>
        </div>
        }
      </div>
    </DashboardLayout>
  );
};

export default EmployeeHMOPage;
