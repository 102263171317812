import React from 'react';
import EmployeeHMOPage from '../pages/hmo';
import ProtectedRoute from './protected';
import SelectHMO from '../pages/employee-hmo-onboarding/select-hmo';
import AddDependants from '../pages/employee-hmo-onboarding/add-dependants';

export const HMORoutes = [
  <ProtectedRoute exact path='/hmo' component={EmployeeHMOPage} />,
  <ProtectedRoute exact path='/hmo/select-hmo' component={SelectHMO} />,
  <ProtectedRoute exact path='/hmo/add-dependant' component={AddDependants} />,
];