import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Button from "../../components/button";
import FormInput from "../../components/form-input";
import FormSelect from "../../components/form-select";
import EmployeeRegistrationLayout from "../../components/layouts/employee-registration";
import { formValidation } from "../../utils/functions";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { countriesAndState, AllCountries } from "../../constants/countries";
import Logo from "../../components/logo";
import { setEmployee } from "../../redux/employee/reducer";
import { store } from "../../redux";

function CompleteProfileInformationPage() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const { employee } = useSelector((state) => state.employeeSlice);
  const { company } = useSelector((state) => state.companySlice);
  const { user } = useSelector((state) => state.userSlice);

  const [dob, setDob] = useState();
  const [empAddress, setEmpAddress] = useState();
  const [empCity, setEmpCity] = useState();
  const [gender, setGender] = useState("Male");
  const [maritalStatus, setMaritalStatus] = useState("single");
  const [country, setCountry] = useState("Nigeria");
  const [state, setState] = useState("Lagos");
  const [stateOptions, setStateOptions] = useState([]);
  const [employeeId, setEmployeeId] = useState();
  const [employmentDate, setEmploymentDate] = useState();
  const [nokName, setNokName] = useState();
  const [nokMobile, setNokMobile] = useState();
  const [nokEmail, setNokEmail] = useState();
  const [nokRelationship, setNokRelationship] = useState();
  const [nokAddress, setNokAddress] = useState();

  useEffect(() => {
    if (employee) {
      setEmployeeId(employee.id);
      setEmploymentDate(employee.date_joined);
      setDob(employee.dob);
      setEmpCity(employee.city);
      setMaritalStatus(employee.marital_status);
      setCountry(employee.countr);
      setMaritalStatus(employee.marital_status);
      setEmpAddress(employee.street_address);
      setNokName(employee.next_of_kin_name);
      setNokMobile(employee.next_of_kin_mobile);
      setNokEmail(employee.next_of_kin_email);
      setNokRelationship(employee.next_of_kin_relationship);
      setNokAddress(employee.next_of_kin_address);
    }
  }, [employee]);

  const history = useHistory();

  const {
    register,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });

  const [loading, setLoading] = useState(false);

  const genderOptions = [
    {
      label: "Male",
      value: "Male",
    },
    {
      label: "Female",
      value: "Female",
    },
  ];

  const maritalOptions = [
    {
      label: "Single",
      value: "single",
    },
    {
      label: "Married",
      value: "married",
    },
  ];

  useEffect(() => {
    if (country) {
      let getStates = countriesAndState.find((a) => a.name === country);

      if (getStates) {
        let computedStates = [];

        getStates.states.map((item, index) => {
          computedStates.push({ label: item, value: item });
        });

        setStateOptions(computedStates);
      }
    }
  }, [country]);

  const submitForm = async () => {
    setLoading(true);
    let payload = {
      ...employee,
      employeeId: employeeId,
      first_name: employee?.first_name,
      middle_name: employee?.middle_name,
      last_name: employee?.last_name,
      email: employee?.email,
      mobile: employee?.mobile,
      staff_number: employee?.staff_number,
      job_position: employee?.job_position,
      street_address: empAddress,
      city: empCity,
      country,
      state,
      dob: dob,
      gender,
      date_joined: employmentDate,
      marital_status: maritalStatus,
      next_of_kin_name: nokName,
      next_of_kin_mobile: nokMobile,
      next_of_kin_email: nokEmail,
      next_of_kin_relationship: nokRelationship,
      next_of_kin_address: nokAddress,
    };
    store.dispatch(setEmployee(payload));
    history.push("/registration/employment-information", payload);
    setLoading(false);
  };

  return (
    <EmployeeRegistrationLayout
      step="1"
      company_name={company?.company_name}
      logo={company?.logo}
    >
      <div className="flex flex-col justify-center w-full md:pt-5 min-h-fit overflow-y-auto gap-4">
        <div className="flex md:min-h-fit flex-col w-full md:max-w-[800px] px-5 md:px-0">
          <form className="form min-h-fit">
            <div className="w-full text-center flex flex-col pb-[25px] pt-2">
              <span className="header-2-regular text-secondary-2">
                Profile Information
              </span>
              <span className="header-5-regular text-secondary-2">
                View your personal information and enter your basic information
              </span>
            </div>
            <hr className="divider -mt-2" />
            <>
              <div className="md:hidden flex flex-col md:w-1/2 w-full md:pr-2">
                <span className="header-3 text-color-gray">Company Name</span>
                <span className="header-4 mt-1 text-color-black font-bold">
                  {company?.company_name}
                </span>
                <hr className="divider" />
              </div>
              <div className="flex flex-col min-h-fit overflow-auto mb-[10px]">
                <div className="p-2 rounded-md border bg-secondary-2-extralight">
                  <div className="header-4 mb-2 text-color-gray">
                    Pre-filled information
                  </div>
                  <div className="flex flex-row w-full justify-between">
                    <div className="flex flex-col md:w-1/2 w-full md:pr-2">
                      <span className="header-5 text-color-gray">
                        First Name
                      </span>
                      <span className="text-[13px] mt-1 text-color-black font-medium">
                        {employee?.first_name}
                      </span>
                    </div>
                    <div className="flex flex-col md:w-1/2 w-full md:pr-2">
                      <span className="text-[13px] header-5 text-color-gray">
                        Last name
                      </span>
                      <span className="text-[13px] mt-1 text-color-black font-medium">
                        {employee?.last_name}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-row w-full justify-between mt-3 gap-3">
                    <div className="flex flex-col md:w-1/2 w-full md:pr-2">
                      <span className="header-5 text-color-gray">Email</span>
                      <span className="text-[13px] mt-1 text-color-black font-medium">
                        {employee?.email}
                      </span>
                    </div>
                    <div className="flex flex-col md:w-1/2 w-full md:pr-2">
                      <span className="header-5 text-color-gray">
                        Phone number
                      </span>
                      <span className="text-[13px] mt-1 text-color-black font-medium">
                        {employee?.mobile}
                      </span>
                    </div>
                  </div>
                </div>
                <hr className="divider" />
                <div className="p-2 rounded-md border bg-secondary-2-extralight">
                  <div className="header-4 mb-2 text-color-gray">
                    Basic personal information
                  </div>
                  <div className="flex md:flex-row flex-col w-full justify-between">
                    <div className="w-full md:w-1/2 md:pr-2">
                      <FormInput
                        label="Date Of Birth"
                        name="dob"
                        type="date"
                        value={dob}
                        onInput={(e) => setDob(e.target.value)}
                        inputRef={register(formValidation("text", true))}
                        error={errors.dob}
                        errorMessage={errors.dob && errors.dob.message}
                      />
                    </div>
                    <div className="w-full md:w-1/2 md:pl-2">
                      <FormSelect
                        value={gender}
                        options={genderOptions}
                        onChange={(selected) => {
                          setGender(selected);
                        }}
                        label="Gender"
                      />
                    </div>
                  </div>
                  <div className="flex md:flex-row flex-col w-full justify-between">
                    <div className="w-full md:w-1/2 md:pr-2">
                      <FormSelect
                        value={maritalStatus}
                        options={maritalOptions}
                        onChange={(selected) => {
                          setMaritalStatus(selected);
                        }}
                        label="Marital Status"
                      />
                    </div>
                    <div className="w-full md:w-1/2 md:pl-2">
                      <FormSelect
                        value={country}
                        options={AllCountries}
                        onChange={(selected) => {
                          setCountry(selected);
                        }}
                        label="Country"
                      />
                    </div>
                  </div>
                  <div className="flex md:flex-row flex-col w-full justify-between">
                    <div className="w-full md:w-1/2 md:pr-2">
                      {country == "Nigeria" ? (
                        <FormSelect
                          value={state}
                          options={stateOptions}
                          onChange={(selected) => {
                            setState(selected);
                          }}
                          label="State"
                        />
                      ) : (
                        <FormInput
                          label="State"
                          name="state"
                          type="text"
                          placeholder={"Enter state"}
                          value={state}
                          onInput={(e) => setState(e.target.value)}
                          error={errors.state}
                          errorMessage={errors.state && errors.state.message}
                        />
                      )}
                    </div>
                    <div className="w-full md:w-1/2 md:pl-2">
                      <FormInput
                        label="City"
                        name="city"
                        type="text"
                        value={empCity}
                        placeholder={"Enter city"}
                        readOnly={loading}
                        onInput={(e) => setEmpCity(e.target.value)}
                        inputRef={register(formValidation("text", true))}
                        error={errors.city}
                        errorMessage={errors.city && errors.city.message}
                      />
                    </div>
                  </div>
                  <FormInput
                    label="Street Address"
                    name="street_address"
                    type="text"
                    value={empAddress}
                    placeholder={"Enter street address"}
                    readOnly={loading}
                    onInput={(e) => setEmpAddress(e.target.value)}
                    inputRef={register(formValidation("text", true))}
                    error={errors.street_address}
                    errorMessage={
                      errors.street_address && errors.street_address.message
                    }
                  />
                </div>
                <hr className="divider mb-2 mt-2" />
                <div className="p-2 rounded-md border bg-secondary-2-extralight">
                  <div className="header-4 mb-2 text-color-gray">
                    Next Of Kin Information - (Optional)
                  </div>
                  <FormInput
                    label="Next Of Kin Name - (Optional)"
                    name="next_of_kin_name"
                    type="text"
                    placeholder={"Enter Full Name"}
                    value={nokName}
                    onInput={(e) => setNokName(e.target.value)}
                    inputRef={register(formValidation("text", false))}
                    readOnly={loading}
                    error={errors.next_of_kin_name}
                    errorMessage={
                      errors.next_of_kin_name && errors.next_of_kin_name.message
                    }
                  />
                  <div className="flex flex-col md:flex-row justify-between">
                    <div className="w-full md:w-1/2 md:pr-2">
                      <FormInput
                        label="Next Of Kin Mobile - (Optional)"
                        name="next_of_kin_mobile"
                        type="text"
                        value={nokMobile}
                        placeholder={"Enter Next Of Kin Mobile"}
                        onInput={(e) => setNokMobile(e.target.value)}
                        inputRef={register(formValidation("text", false))}
                        readOnly={loading}
                        error={errors.next_of_kin_mobile}
                        errorMessage={
                          errors.next_of_kin_mobile &&
                          errors.next_of_kin_mobile.message
                        }
                      />
                    </div>
                    <div className="w-full md:w-1/2 md:pl-2">
                      <FormInput
                        label="Next Of Kin Email - (Optional)"
                        name="next_of_kin_email"
                        type="text"
                        placeholder={"Enter Next Of Kin Email"}
                        value={nokEmail}
                        onInput={(e) => setNokEmail(e.target.value)}
                        inputRef={register(formValidation("text", false))}
                        readOnly={loading}
                        error={errors.next_of_kin_email}
                        errorMessage={
                          errors.next_of_kin_email &&
                          errors.next_of_kin_email.message
                        }
                      />
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row justify-between">
                    <div className="w-full md:w-1/2 md:pr-2">
                      <FormInput
                        label="Relationship - (Optional)"
                        name="next_of_kin_relationship"
                        type="text"
                        placeholder={"What is your relationship?"}
                        value={nokRelationship}
                        onInput={(e) => setNokRelationship(e.target.value)}
                        inputRef={register(formValidation("text", false))}
                        readOnly={loading}
                        error={errors.next_of_kin_relationship}
                        errorMessage={
                          errors.next_of_kin_relationship &&
                          errors.next_of_kin_relationship.message
                        }
                      />
                    </div>
                    <div className="w-full md:w-1/2 md:pl-2">
                      <FormInput
                        label="Next Of Kin Address - (Optional)"
                        name="next_of_kin_address"
                        type="text"
                        placeholder={"Enter Address of Next Of Kin"}
                        value={nokAddress}
                        onInput={(e) => setNokAddress(e.target.value)}
                        inputRef={register(formValidation("text", false))}
                        readOnly={loading}
                        error={errors.next_of_kin_address}
                        errorMessage={
                          errors.next_of_kin_address &&
                          errors.next_of_kin_address.message
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-end w-full mt-[20px]">
                  {user.status != 2 ? (
                    <div
                      className="flex justify-end w-full cursor-pointer hover:underline"
                      onClick={() => history.push("/dashboard")}
                    >
                      <span className="p2-medium text-color-black">
                        Back To Dashboard
                      </span>
                    </div>
                  ) : null}
                  <Button
                    text="Save and Continue"
                    className={"!bg-secondary-2 w-[200px] h-[42px]"}
                    type="button"
                    loading={loading}
                    onClick={() => submitForm()}
                  />
                </div>
              </div>
            </>
          </form>
          {user.status != 2 ? (
            <div
              className="flex justify-end w-full cursor-pointer hover:underline"
              onClick={() => history.push("/dashboard")}
            >
              <span className="p2-medium text-color-black mt-2">
                Back To Dashboard
              </span>
            </div>
          ) : null}
        </div>
        <div
          className={
            "md:hidden flex items-center justify-center text-color-black gap-2"
          }
        >
          Powered By:{" "}
          <a href="https://www.ropay.africa" target={"_blank"} rel="noreferrer">
            <Logo width="80px" height="40px" blue />
          </a>
        </div>
      </div>
    </EmployeeRegistrationLayout>
  );
}

export default CompleteProfileInformationPage;
