import React, { useCallback, useEffect, useState } from 'react';
import Button from '../../components/button';
import DashboardLayout from '../../components/layouts/dashboard';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { useGetAttendanceSchedules } from '../../redux/human-resources/hook/attendance';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import LoadingBar from '../../components/loader-bar';
import EmptyTableSVG from '../../assets/svg/empty-table.svg';

const localizer = momentLocalizer(moment)

// Custom styles
const eventStyleGetter = (event, start, end, isSelected, ) => {
  const backgroundColor = event.color;
  const style = {
    backgroundColor: backgroundColor,
    borderRadius: '8px',
    opacity: 0.8,
    color: 'white',
    border: '0px',
    display: 'block',
    padding: '2px 5px',
    fontSize: '13px',
    fontWeight: 'bolder',
    fontFamily: 'sans-serif',
  };
  return {
    style: style,
  };
};

const calendarStyle = {
  height: 500,
  fontFamily: 'sans-serif',
  color: '#333',
  fontSize: '15px',
  borderRadius: 8,
};

const CalendarPage = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const firstdate = moment().startOf('month').format('YYYY-MM-DD');
  const lastdate = moment().endOf('month').format("YYYY-MM-DD");

  const [range, setRange] = useState({
    start: firstdate,
    end: lastdate,
  });

  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'))

  const {data:attendance_schedule, isLoading} = useGetAttendanceSchedules(
    startDate,
    endDate
  )
  const [view, setView] = useState('month');

  const onRangeChange = (range) => {
    
    if (range.start) {
      setStartDate(moment(range.start).format('YYYY-MM-DD'))
      setEndDate(moment(range.end).format('YYYY-MM-DD'))
      setView('month');
    } else if (range.length > 1) {
      setStartDate(moment(range[0]).format('YYYY-MM-DD'))
      setEndDate(moment(range[range.length - 1]).format('YYYY-MM-DD'))
      setView('week');
    } else if (range.length == 1) {
      setStartDate(moment(range[0]).format('YYYY-MM-DD'))
      setEndDate(moment(range[0]).format('YYYY-MM-DD'))
      setView('day');
    }
  }

  const handleNavigate = (newDate, view, action) => {
    const newRange = {
      start: moment(newDate).startOf(view),
      end: moment(newDate).endOf(view),
    };
    setRange(newRange);
  };

  return (
    <DashboardLayout title='My calendar'>

      <div className='flex flex-col'>
        <div className='flex flex-col bg-white mt-[10px] rounded shadow-md'>
          <div className='flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3'>
            <div className='p3-bold header-4'>
              Attendance Calendar
            </div>
            {/* <div className='flex flex-col md:flex-row items-start gap-[10px] md:items-center'>
              <div className='md:w-fit w-[100%]'>
                <Button
                  text='New Event'
                  type='button'
                  theme='primary'
                  className='flex gap-2 h-[35px] w-fit p-2 '
                  textClass={'!text-[13px]'}
                  leftIcon={'add'}
                />
              </div>
            </div> */}
          </div>
          <hr className='divider mt-2 mb-2' />
          {
            isLoading?
            <LoadingBar loading={isLoading} />
            :
            <div className='p-4'>
              {attendance_schedule?
              <Calendar
                localizer={localizer}
                style={calendarStyle}
                events={attendance_schedule}
                onRangeChange={onRangeChange}
                startAccessor="start"
                onNavigate={handleNavigate}
                endAccessor="end"
                defaultDate={range.start}
                view={view}
                views={['month', 'week', 'day']}
                eventPropGetter={eventStyleGetter}
              />
              :
              <div className='flex items-center justify-center min-w-full mt-4'>
                <img
                  src={EmptyTableSVG}
                  alt='No Data Fetched'
                  width={'40%'}
                  height={'400px'}
                />
              </div>
              }
              
            </div>
          }
        </div>
      </div>
    </DashboardLayout>
  );
};

export default CalendarPage;
