import { useMutation, useQuery } from "@tanstack/react-query";
import { store } from "../..";
import { queryClient } from "../../..";
import { employees } from "../../../api";
import { setAlert, setIsLoading } from "../../components/components-slice";
import { setEmployee } from "../reducer";
import moment from "moment";
import { setUser } from "../../user/user-slice";
import { setCompany } from "../../company/reducer";
import { updateUser } from "../../user/hook";

export function useGetEmployeeToSelect(limit, page, statusFilter, search) {
  return useQuery(
    ["employee-select", { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return employees.getEmployees({ limit, page, statusFilter, search });
    },
    {
      select: (data) => {
        data.data.forEach((employee, index) => {
          employee.employeeName = `${employee.first_name} ${employee.last_name}`;
          if (employee.status != 1) {
            data.data.splice(index, 1);
          }
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    }
  );
}

export function useFetchRegistration() {
  return useQuery(
    ["employee-registration"],
    () => {
      store.dispatch(setIsLoading(true));
      return employees.fetchRegistration();
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setEmployee(response.data));
        store.dispatch(
          setAlert(true, "success", "Employee details fetched successfully")
        );
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useGetEmployee() {
  return useQuery(
    ["employee"],
    () => {
      store.dispatch(setIsLoading(true));
      return employees.fetchEmployeeInformation();
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setEmployee(response.data));
        updateUser({ reload: false });
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useRegistrationUploadProfilePicture() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.employeeUploadProfilePicture(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, "success", response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useUpdateInvitedEmployees() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.updateInvitedEmployee(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setUser(response.data));
        store.dispatch(setCompany(response.data.company));
        store.dispatch(setAlert(true, "success", response.message));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}
