import React, { useState } from 'react';
import { useEffect } from 'react';
import CloseSVG from '../../../assets/svg/close.svg';
import {
  useGetAdvancedPayrollCheck,
  useRunAdvancedPayroll,
} from '../../../redux/payroll/hook';
import Button from '../../button';
import WarningSVG from '../../../assets/svg/warning.svg';
// import RunAdvancedPayroll from './run-advanced-payroll';
import { useHistory } from 'react-router-dom';
import LoadingBar from '../../loader-bar';
import Modal from '../modal';

function AdvancedPayrollBreakdown({
  isOpen,
  closeModal,
  source = 'default',
  sourceData,
  payloadData,
}) {
  const [data, setData] = useState(null);
  const {
    refetch: getAdvancePayrollCheck,
    data: advancedPayrollCheck,
    isLoading: advancedIsLoading,
  } = useGetAdvancedPayrollCheck(sourceData);

  const { mutateAsync: runAdvancedPayroll, isLoading: loading } =
    useRunAdvancedPayroll();

  const history = useHistory();

  function onAdvancedPayrollSuccess(ref) {
    history.push(`/payroll/advanced/preview/${ref}`);
    closeModal();
  }

  const submitForm = async () => {
    await runAdvancedPayroll(payloadData).then((response) => {
      onAdvancedPayrollSuccess(response.data.ref);
    });
  };

  useEffect(() => {
    if (isOpen && source === 'default') {
      getAdvancePayrollCheck();
    }
  }, [isOpen, source]);

  useEffect(() => {
    if (advancedPayrollCheck) {
      setData(advancedPayrollCheck);
    }
  }, [advancedPayrollCheck]);

  return (
    <Modal
      scrollable={true}
      className='!max-w-[700px] selection: h-full !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col item overflow-auto'>
        <div className='pl-8 pr-8 pt-4'>
          <div className='flex flex-row justify-between'>
            <span className='header-3'>Preview Advanced Payroll</span>
            <div className='cursor-pointer' onClick={() => closeModal()}>
              <img src={CloseSVG} alt='' />
            </div>
          </div>
        </div>
        <hr className='divider' />
        <div className='flex flex-col mx-14'>
          {data ? (
            <div className='flex flex-col'>
              <div className='flex flex-col items-center -mt-1'>
                <span className='stat-card-header pb-1'>PAYROLL MONTH</span>
                <span className='p4-medium text-color-black'>
                  {payloadData?.month}
                </span>
              </div>
              <hr className='divider' />
              <div className='flex flex-col items-center -mt-1'>
                <span className='stat-card-header pb-1'>
                  ACTIVE EMPLOYEES CHARGED
                </span>
                <span className='p4-medium  text-color-black'>
                  {data?.total_salaries}
                </span>
              </div>
              <hr className='divider' />
              <div className='flex flex-col items-center -mt-1'>
                <span className='stat-card-header pb-1'>FEES PER EMPLOYEE</span>
                <span className='p4-medium  text-color-black'>
                  {(
                    data?.total_payroll_fee / data?.total_salaries
                  ).toLocaleString('en-NG', {
                    style: 'currency',
                    currency: 'NGN',
                  })}
                </span>
              </div>
              <hr className='divider' />
              <div className='flex flex-col items-center -mt-1'>
                <span className='stat-card-header pb-1'>TOTAL FEES</span>
                <span className='p4-medium  text-color-black'>
                  {data?.total_payroll_fee?.toLocaleString('en-NG', {
                    style: 'currency',
                    currency: 'NGN',
                  })}
                </span>
              </div>
            </div>
          ) : (
            <LoadingBar
              loading={
                advancedIsLoading 
              }
            />
          )}
          <hr className='divider' />
          <div className='flex mb-3'>
            <div className='flex flex-row px-4 border-secondary-4 border-[1.2px] rounded-lg p-3 mt-2 bg-color-warning-light w-[100%]'>
              <div className='w-[70px] pr-2 flex flex-row items-center '>
                <img src={WarningSVG} alt='' srcset='' />
              </div>

              <div className='p4-regular'>
                <span className='p4-bold'>What Does this Fee Cover?</span>

                <li>Payroll Computation, payment and Payslip to Employees</li>
                <li>
                  Pension Computation
                  {data?.tax_pension_total_fees
                    ? ', Payment and Filling'
                    : null}
                </li>
                <li>
                  Tax Computation
                  {data?.tax_pension_total_fees
                    ? ', Payment and Filling'
                    : null}
                </li>
                {data?.other_payments_total_fees ? (
                  <li>Other Statutory Computation, Payment and Filing</li>
                ) : null}
              </div>
            </div>
          </div>

          <div className='w-full mb-[20px]'>
            <Button
              text='Continue'
              type='submit'
              loading={loading}
              onClick={() => submitForm()}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AdvancedPayrollBreakdown;
