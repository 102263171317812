import React, { useState } from "react";
import { useForm } from "react-hook-form";
import CloseSVG from "../../../assets/svg/close.svg";
import { useAddReimbursement } from "../../../redux/employee/hook/reimbursements";
import { formValidation } from "../../../utils/functions";
import Button from "../../button";
import FormInput from "../../form-input";
import Modal from "../modal";
// import FormFileInputWithOnchange from '../../form-file-input-with-onchange';
import DOMPurify from "dompurify";
import FormFileInputWithOnchange from "../../form-file-input-with-onchange";

function AddReImbursement({ isOpen, closeModal, title, buttonText }) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });

  const { mutateAsync: addReimbursement, isLoading: addReimbloading } =
    useAddReimbursement();

  const [file, setFile] = useState();

  function handleChange(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  const submitForm = async (data) => {
    const formData = new FormData();
    const expenses_amount = DOMPurify.sanitize(data?.expenses_amount);
    const expenses_description = DOMPurify.sanitize(data?.expenses_description);

    formData.append("file", data?.file[0]);
    formData.append("expenses_amount", expenses_amount);
    formData.append("expenses_description", expenses_description);

    await addReimbursement(formData).then(() => {
      closeModal();
    });
  };

  return (
    <Modal
      scrollable={true}
      className="!max-w-[700px] !rounded-none"
      isOpen={isOpen}
      centered={true}
    >
      <div className="flex flex-col overflow-auto ">
        <form className="form pb-4" onSubmit={handleSubmit(submitForm)}>
          <div className="pl-4 pr-4 pt-4">
            <div className="flex flex-row justify-between">
              <span className="header-3">{title}</span>
              <div className="cursor-pointer" onClick={() => closeModal()}>
                <img src={CloseSVG} alt="" />
              </div>
            </div>
          </div>
          <hr className="divider" />
          <div className="flex flex-col mx-8 ">
            <div>
              <FormInput
                label="Expenses Description"
                name="expenses_description"
                placeholder="Enter expense purpose"
                type="text"
                inputRef={register(formValidation("text", true))}
                readOnly={addReimbloading}
                error={errors.expenses_description}
                errorMessage={
                  errors.expenses_description &&
                  errors.expenses_description.message
                }
              />
              <FormInput
                label="Expenses Amount"
                name="expenses_amount"
                type="number"
                inputRef={register(formValidation("number", true))}
                readOnly={addReimbloading}
                error={errors.expenses_amount}
                errorMessage={
                  errors.expenses_amount && errors.expenses_amount.message
                }
              />
              <div className="flex flex-row gap-2 w-full justify-center">
                <FormFileInputWithOnchange
                  label={"Attach support document (Max. 2mb)"}
                  multiSource={false}
                  inputRef={register(formValidation("file", true))}
                  accept=".jpg,.jpeg,.png,.pdf"
                  name={"file"}
                  error={errors.file}
                  onChange={handleChange}
                  errorMessage={errors.file && errors.file.message}
                />
                {file ? (
                  <div className="flex items-center justify-center mt-4 border p-2 h-[65px] w-[65px] rounded duration-500">
                    <img
                      src={file}
                      alt={"profile_pix"}
                      className="object-contain max-w-[55px] max-h-[55px]"
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="w-full mt-[10px]">
              <Button
                text={buttonText}
                type="submit"
                loading={addReimbloading}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddReImbursement;
