import { store } from '..';
import { setAlert, setIsLoading } from '../components/components-slice';
import { updateUser } from '../user/hook';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from '../..';
import { hmo } from '../../api/hmo/beneficiaries';


export function useGetBeneficiary() {
  return useQuery(
    ['hmo-beneficiary'],
    () => {
      store.dispatch(setIsLoading(true));
      return hmo.getHMOBeneficiary();
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'Your HMO information is fetched successfully.'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetHMOs() {
  return useQuery(
    ['hmos', {}],
    () => {
      store.dispatch(setIsLoading(true));
      return hmo.getHMOs();
    },
    {
      select: (data) => {
        let newData = [];
        newData.push({ value: '', label: 'No HMO Selected', hmo_name: '' });
        data.forEach((item) => {
          newData.push({ value: item._id, label: item.name, hmo_name: item.name });
        });
        return newData;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetOnboardingHMOPlans(hmo_id) {
  return useQuery(
    ['onboarding-hmo-plans',  hmo_id ],
    () => {
      return hmo.getHMOPlans(hmo_id);
    },
    {
      enabled: hmo_id !== null,
      select: (data) => {
        let newData = [];
        newData.push({ value: '', label: 'No HMO Plan Selected', plan_name: '' });
        data.forEach((item) => {
          newData.push({ value: item.id, label: item.name, plan_name: item.name });
        });
        return newData;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetHMOPlans(hmo_id) {
  return useQuery(
    ['hmo-plans',  hmo_id ],
    () => {
      store.dispatch(setIsLoading(true));
      return hmo.getHMOPlans(hmo_id);
    },
    {
      enabled: hmo_id !== null,
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetHMOPlan(id) {
  return useQuery(
    ['hmo-plan', { id }],
    () => {
      store.dispatch(setIsLoading(true));
      return hmo.getHMOPlan(id);
    },
    {
      enabled: id !== null,
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useStoreDetails() {
  return useMutation(
    (payload) => {
      return hmo.storeDetails(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('hmo-beneficiary');
        store.dispatch(setAlert(true, 'success', 'HMO Details Saved Successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useStoreDetailsShort() {
  return useMutation(
    (payload) => {
      return hmo.storeShort(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('hmo-beneficiary');
        store.dispatch(setAlert(true, 'success', 'HMO Details Saved Successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useFetchHMODependants(limit, page, statusFilter, search) {
  return useQuery(
    ['hmo-dependants', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return hmo.fetchDependants({ limit, page, statusFilter, search });
    },
    {
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetHMODependant(id) {
  return useQuery(
    ['hmo-dependant', { id }],
    () => {
      store.dispatch(setIsLoading(true));
      return hmo.fetchDependant(id);
    },
    {
      enabled: id !== null,
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'Your HMO information is fetched successfully.'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useStoreDependant() {
  return useMutation(
    (payload) => {
      return hmo.storeDependant(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('hmo-dependants');
        queryClient.invalidateQueries('hmo-dependant');
        store.dispatch(setAlert(true, 'success', 'Dependant Added Successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetHMOSettings() {
    return useQuery(
      ['hmo-settings'],
      () => {
        return hmo.getHMOSettings();
      },
      {
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
      },
    );
}
