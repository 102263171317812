import DOMPurify from "dompurify";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CloseSVG from "../../../../assets/svg/close.svg";
import { formValidation } from "../../../../utils/functions";
import Button from "../../../button";
import FormInput from "../../../form-input";
import { useAddAttendance } from "../../../../redux/human-resources/hook/attendance";
import Modal from "../../modal";
import moment from "moment";
import { useSelector } from "react-redux";
import { store } from "../../../../redux";
import { setUser } from "../../../../redux/user/user-slice";

function AddAttendance({ isOpen, closeModal }) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });

  const [long, setLong] = useState();
  const [lat, setLat] = useState();
  const { user } = useSelector((state) => state.userSlice);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setLat(position.coords.latitude);
      setLong(position.coords.longitude);
    });
  }, []);

  const { mutateAsync: addAttendance, isLoading: addAttendanceloading } =
    useAddAttendance();

  const submitForm = async (data) => {
    let todayDate = data?.attendance_date;
    let payload = {
      long: long ? long.toString() : "Not Set",
      lat: lat ? lat.toString() : "Not Set",
      date: todayDate,
    };
    await addAttendance(payload).then(() => {
      closeModal();
    });
  };

  return (
    <Modal
      scrollable={true}
      className="!max-w-[500px] !rounded-none"
      isOpen={isOpen}
      centered={true}
    >
      <div className="flex flex-col pb-4 overflow-auto">
        <form className="form" onSubmit={handleSubmit(submitForm)}>
          <div className="pl-4 pr-4 pt-4">
            <div className="flex flex-row justify-between">
              <span className="header-3">Clock In</span>
              <div className="cursor-pointer" onClick={() => closeModal()}>
                <img src={CloseSVG} alt="" />
              </div>
            </div>
          </div>
          <hr className="divider" />
          <div className="flex flex-col mx-6">
            <div>
              <div className="w-full">
                <FormInput
                  label="Clock In Date"
                  name="attendance_date"
                  type="date"
                  max={moment().format("YYYY-MM-DD")}
                  defaultValue={moment().format("YYYY-MM-DD")}
                  inputRef={register(formValidation("date", true))}
                  error={errors.attendance_date}
                  errorMessage={
                    errors.attendance_date && errors.attendance_date.message
                  }
                />
              </div>
            </div>
            <div className="w-full mt-[20px]">
              <Button
                text={"Clock In"}
                className={"!bg-green-800"}
                type="submit"
                loading={addAttendanceloading}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddAttendance;
