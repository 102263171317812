import React from 'react';
import { useSelector } from 'react-redux';

function LoadingBar({loading, sidebar=false}) {
  const { section } = useSelector((state) => state.sectionSlice);
  return (
    <>
      {loading ? (
      <div className='loader'>
        <div className='loaderBar bg-hr-primary-1'>
        </div>
      </div>
        ) : null}
    </>
  );
}
export default LoadingBar;
