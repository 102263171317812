import result from 'autoprefixer/data/prefixes';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useEffect } from 'react';
import CollapsingIcon from './collapsing-icon';

const FormDatalist = ({
  id,
  label,
  className,
  value,
  error,
  defaultText='',
  errorMessage,
  placeholder='Type to select',
  theme,
  setEmployee,
  inputRef,
  onSelect,
  data = [],
  noOfResults = 10,
  ...rest
}) => {
  const [search, setSearch] = useState('');
  const [text, setText] = useState(defaultText);
  const [showResults, setShowResult] = useState(false);
  const [results, setResults] = useState([]);

  function handleItemSelect(item) {
    onSelect(item.value);
    setText(item.label);
    setShowResult(false);
  }

  useEffect(() => {
    if (value) { 
      setText(value)
    }
 
  }, [value])

  useEffect(() => {
    if (defaultText !== '') {
      setText(defaultText)
    }
  }, [defaultText]);

  useEffect(() => {
    if (search !== '') {
      let newResult = data.filter((item) =>
        item.label.toLowerCase().includes(search.toLowerCase()),
      );

      //Trim results
      newResult = newResult.slice(0, noOfResults);

      setResults(newResult);
      setShowResult(true);
    }
  }, [search]);

  return (
    <>
      <div className='pb-[15px] flex flex-col min-h-fit'>
        <div className='flex w-full flex-col'>
          {label && (
            <label className='text-[13px] leading-[23px] font-normal text-color-gray -mb-2'>
              {label}
            </label>
          )}
          <div>
            <div className='flex items-center justify-center min-h-fit' id='datalist'>
              <input
                placeholder={placeholder}
                className='!max-h-[35px] !font-normal text-[13px]'
                id='datalist-input'
                type='text'
                autoComplete='off'
                ref={inputRef}
                onInput={(event) => {
                  setSearch(event.target.value);
                  setText(event.target.value);
                }}
                value={text}
                {...rest}
              />
              <i id='datalist-icon' className='flex items-center mb-2'>
                <CollapsingIcon />
              </i>
            </div>
            {/* Result List */}
            {results && results.length > 0 ? (
              <ul
                className={classNames(
                  'datalist-items px-0 mt-1 rounded-sm border border-secondary-2 bg-white w-full ',
                  {
                    hidden: results.length === 0 || !showResults,
                  },
                )}
              >
                {results.map((item, index) => (
                  <li
                    key={index}
                    className={`flex flex-row justify-between py-2 cursor-pointer
                      hover:bg-secondary-2
                      px-2 rounded-sm hover:text-white text-[14px]`}
                    onClick={() => handleItemSelect(item)}
                  >
                    <div className='flex flex-row items-center text-inherit'>
                      <span className='text-inherit'>{item.label}</span>
                    </div>

                    {/* <div className='text-inherit'>
                      <span className='text-inherit'>{item.mobile}</span>
                    </div> */}
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
        </div>
        {error && <span className='text-error'>* {errorMessage}</span>}
      </div>
    </>
  );
};

export default FormDatalist;
