import DOMPurify from 'dompurify';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../../assets/svg/close.svg';
import { formValidation } from '../../../../utils/functions';
import Button from '../../../button';
import FormInput from '../../../form-input';
import { useAddPerformanceSmartGoals, useEditPerformanceSmartGoals } from '../../../../redux/human-resources/hook/performance';
import FormSelectBranch from '../../../employee/form-select-branch';
import FormSelectDepartment from '../../../employee/form-select-department';
import FormSelectEmployee from '../../../employee/form-select-employee';
import FormSelectTeam from '../../../employee/form-select-team';
import { store } from '../../../../redux';
import { setAlert } from '../../../../redux/components/components-slice';
import Modal from '../../modal';
import AddSmallSVG from '../../../../assets/svg/add-icon-small.svg';
import FormSelect from '../../../form-select';

function AddPerformanceSmartGoals({
  isOpen,
  closeModal,
  smartGoalData,
  title,
  buttonText,
}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const [source, setSource] = useState('employee');
  const [sourceId, setSourceId] = useState(null);
  const [sourceName, setSourceName] = useState(null);

  const sourceOptions = [
    { label: 'Employee', value: 'employee' },
    { label: 'Team', value: 'team' },
    { label: 'Department', value: 'department' },
    { label: 'Branch', value: 'branch' },
  ];

  const [employee, setEmployee] = useState();

  const [team, setTeam] = useState();

  const [department, setDepartment] = useState();

  const [branch, setBranch] = useState();

  const [smartGoals, setSmartGoals] = useState([
    {
      smart_goal: '',
      weight: '',
      start_date: '',
      end_date: '',
    },
  ]);

  const [smartGoalName, setSmartGoalName] = useState(null);
  const [description, setDescription] = useState(null);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [smartGoalId, setSmartGoalId] = useState(null);

  useEffect(() => {
    if (smartGoalData) {
      setSmartGoalId(smartGoalData.id);
      setStartDate(smartGoalData?.start_date)
      setEndDate(smartGoalData?.end_date)
      setSmartGoalName(smartGoalData.smart_goal_title);
      setSmartGoals(smartGoalData.goals);
      setSourceName(smartGoalData.source_name)
      setDescription(smartGoalData.description)
      setSourceId(smartGoalData.source_id)
      setSource(smartGoalData.source)
      setPerformanceType('edit');
    } else {
      setPerformanceType('add');
    }
  }, [smartGoalData]);

  const [weightError, setWeightError] = useState('Total goals weight must be equal to (100)')

  const { mutateAsync: addSmartGoal, isLoading: addSmartGoalloading } = useAddPerformanceSmartGoals();
  const { mutateAsync: editSmartGoal, isLoading: editSmartGoalloading } = useEditPerformanceSmartGoals();
  const [preformanceType, setPerformanceType] = useState('add');

  const addGoal = () => {
    setSmartGoals([
      ...smartGoals,
      ...[
        {
          smart_goal: '',
          weight: '',
          start_date: '',
          end_date: '',
        },
      ],
    ]);
  };

  const removeGoal = (index) => {
    let data = JSON.parse(JSON.stringify(smartGoals));
    if (index > -1) {
      data.splice(index, 1); // 2nd parameter means remove one item only
      setSmartGoals(data);
    }
  };

  const submitForm = async (data) => {
    const smart_goal_title = DOMPurify.sanitize(smartGoalName);
    const source_name = DOMPurify.sanitize(sourceName);
    const start_date = DOMPurify.sanitize(startDate);
    const end_date = DOMPurify.sanitize(endDate);

    let smartGoal = [];
    if (smartGoals.length > 0) {
        smartGoals?.forEach((item) => {
        if (item.smart_goal && item.weight) {
          let newSmartGoal = {
            smart_goal: item.smart_goal,
            weight: item.weight,
            start_date,
            end_date,
          };
          smartGoal.push(newSmartGoal);
        }
      });
      let payload = {
        smart_goal_title,
        description,
        source,
        source_id: sourceId,
        source_name,
        max_grade: 100,
        start_date,
        end_date,
        smart_goals: smartGoal,
      };
      if (smartGoal.length > 0) {
        
        if (preformanceType === 'add') {
          await addSmartGoal(payload).then(() => {
            closeModal();
          });
        } else {
          let editPayload = {
            id: smartGoalId,
            data: payload,
          };
          await editSmartGoal(editPayload).then(() => {
            closeModal();
          });
        }
      } else {
        store.dispatch(
          setAlert(
            true,
            'info',
            'There must be at least one Smart Goal with Name and Grade',
          ),
        );
      }
    } else {
      store.dispatch(
        setAlert(
          true,
          'info',
          'There must be at least one Smart Goal',
        ),
      );
    }
  };
  
  return (
    <Modal
      scrollable={true}
      className='!max-w-[600px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <form className='form pb-6' onSubmit={handleSubmit(submitForm)}>
          <div className='pl-6 pr-6 pt-4'>
            <div className='flex flex-row justify-between'>
              <span className='header-3'>{title}</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='' />
              </div>
            </div>
          </div>
          <hr className='divider mb-3 mt-2' />
          <div className='flex flex-col max-h-fit mx-8'>
          <div className='flex flex-row md:flex-col w-full justify-between'>
              <div className='w-full md:w-1/3 pr-2'>
                <FormSelect
                  readOnly={true}
                  defaultValue={source}
                  options={sourceOptions}
                  onChange={(selected) => {
                    setSource(selected);
                    setSourceId(null);
                  }}
                  label='SMART Goals For:'
                />
              </div>
              <div className='w-full md:w-2/3 pl-2'>
                {!source || source === 'company' ? null : source ===
                  'employee' ? (
                  <FormSelectEmployee
                    label={'Employee'}
                    name='employee'
                    placeholder='Select Employee'
                    value={employee}
                    readOnly={true}
                    empName={sourceName}
                    setSourceName={setSourceName}
                    setEmployee={setSourceId}
                  />
                ) : source === 'team' ? (
                  <FormSelectTeam
                    label={'Team'}
                    name='team'
                    placeholder='Select Team'
                    value={team}
                    readOnly={true}
                    teamName={sourceName}
                    setSourceName={setSourceName}
                    setTeam={setSourceId}
                  />
                ) : source === 'department' ? (
                  <FormSelectDepartment
                    label={'Department'}
                    name='department'
                    readOnly={true}
                    placeholder='Select Department'
                    value={department}
                    departmentName={sourceName}
                    setSourceName={setSourceName}
                    setDepartment={setSourceId}
                  />
                ) : (
                  <FormSelectBranch
                    label={'Branch'}
                    name='branch'
                    readOnly={true}
                    placeholder='Select Branch'
                    value={sourceId}
                    branchName={sourceName}
                    setSourceName={setSourceName}
                    setBranch={setSourceId}
                  />
                )}
              </div>
            </div>
            <span className='text-[13px] font-normal text-color-gray -mt-2 '>SMART Goals title</span>
            <div className='w-full p-2 border rounded-md text-[13px] mb-2'>
              {smartGoalName}
            </div>
            <span className='text-[13px] font-normal text-color-gray mt-2 '>Description</span>
            <div className='w-full p-2 border rounded-md text-[13px] mb-2'>
              {description}
            </div>
            <span className='text-color-gray -mb-2 text-[13px]'>Period Covered</span>
            <div className='flex flex-row md:flex-col w-full justify-between'>
                <div className='w-full md:w-1/2'>
                  <FormInput
                    name='start_date'
                    type='date'
                    value={startDate}
                    readOnly
                    className='!h-[30px] text-[13px] w-[100%] '
                  />
                </div>
                <div className='w-full md:w-1/2 pl-2'>
                  <FormInput
                    name='end_date'
                    type='date'
                    value={endDate}
                    readOnly
                    className='!h-[30px] text-[13px] w-[100%] '
                  />
                </div>
            </div>
            <hr className='divider mb-3 mt-2' />
            <div className='flex flex-col gap-3'>
              {smartGoals && smartGoals?.map((item, index) => (
                <div className='flex flex-col hover:shadow-2xl shadow-green-500 p-2 rounded-xl border-1 bg-gray-50 overflow-clip'>
                  <div className='flex justify-between'>
                    <div className='text-[13px] italic text-[#44AF69] font-semibold'>
                        SMART Goal {index + 1}
                    </div>
                  </div>
                  <div
                    className='flex flex-row items-center justify-between first-line:-mt-3 pr-2 text-[13px]'
                    key={index}
                  >
                    <div className='flex flex-col w-full'>
                      <span className='text-[13px] leading-[23px] font-normal text-color-gray'>Goal to be achieved: <span className='font-semibold text-[12px]'>Weight({item.weight})</span></span>
                      <div className='w-full p-2 border rounded-md text-[13px] mb-2 bg-white text-justify'>
                        {item.smart_goal}
                      </div>
                    </div>
                    
                  </div>
                  <div className='text-[13px] italic text-gray-400 -mb-2'>
                    Timeline
                  </div>
                  <div className='flex flex-row md:flex-col w-full justify-between'>
                    <div className='w-full md:w-1/2'>
                      <FormInput
                        name='start_date'
                        type='date'
                        value={item.start_date}
                        readOnly
                        className='!h-[30px] text-[13px] w-[100%] '
                      />
                    </div>
                    <div className='w-full md:w-1/2 pl-2'>
                      <FormInput
                        name='end_date'
                        type='date'
                        value={item.end_date}
                        readOnly
                        className='!h-[30px] text-[13px] w-[100%] '
                      />
                    </div>
                  </div>
                  <hr className='divider mt-1 mb-1' />
                </div>
              ))}
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddPerformanceSmartGoals;
