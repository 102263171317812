import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

export function useCheckOnboarding() {
  const location = useLocation();
  const { user, twoFaToken } = useSelector((state) => state.userSlice);
  const history = useHistory();

  if (twoFaToken) {
    if (location.pathname.split("/")[1] !== 'verify-2fa') {
      return history.push(`/verify-2fa/${twoFaToken?.token}`);
    }
  } else if (user) {
    return history.push('/dashboard');
  } else {
    if (location.pathname.split("/")[1] === 'verify-2fa') {
      return history.push('/');
    } 
  }

}
