import { Axios } from '../axios';

const getEmployeeOvertimes = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';

  const { data } = await Axios.get(
    `/mobile/overtimes?limit=${payload.limit}&page=${payload.page}${filter}${search}`,
  );

  return data?.data;
};

const addOvertime = async (payload) => {
  const { data } = await Axios.post('/mobile/overtimes', payload, 
  {
    timeout: 0
  });
  return data;
};

const editOvertime = async (payload) => {
  const { data } = await Axios.put('/mobile/overtimes/'+payload.id, payload.body);
  return data;
};

const deleteOvertime = async (payload) => {
  const { data } = await Axios.delete('/mobile/overtimes/'+payload);
  return data;
};


export const employeeOvertime = {
    getEmployeeOvertimes,
    addOvertime,
    editOvertime,
    deleteOvertime
};
