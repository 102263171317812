import React, { useState } from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import Info from './info';
import ChooseFileSVG from '../assets/svg/choose-file.svg';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

const FormFileInput = ({
  id,
  name,
  label,
  multiSource = false,
  placeholder,
  accept,
  value,
  inputRef,
  onChange,
  onKeyDown,
  readOnly,
  error,
  errorMessage,
  infos,
  ...rest
}) => {
  const [fileName, setFileName] = useState('')
  const { section } = useSelector((state) => state.sectionSlice);
  return (
    <>
      {multiSource ? (
        <div className={'mt-10 max-w-[366px] w-full h-[170px] bg-secondary-2-extralight flex justify-center items-center flex-col'}
        >
          <div className=''>
            <UncontrolledDropdown group className='flex flex-row h-[45px]'>
              {/* <FileButton color='primary'>Choose File</FileButton> */}
              <div className='file-select-wrapper w-[95%]'>
                <button className={'min-w-fit py-2 rounded-md flex flex-row justify-center px-3 items-center h-full !bg-hr-primary-1 cursor-pointer'}
                >
                  <div className='cursor-pointer'>
                    <img src={ChooseFileSVG} alt='' />
                  </div>
                  <span className='ml-2 text-color-white cursor-pointer text-[14px] min-w-fit'>Choose File</span>
                </button>
                <input
                  type='file'
                  accept={accept}
                  ref={inputRef}
                  onChange={(e) => {
                    setFileName(e.target.value)
                  }}
                  onKeyDown={onKeyDown}
                  readOnly={readOnly ? true : false}
                  value={value}
                  {...rest}
                  name='file'
                />
              </div>
            </UncontrolledDropdown>
          </div>

          <span className='max-w-[100%] flex flex-wrap p4-medium mt-2'> {fileName?fileName.split("\\")[2]:'or drop file here'}</span>

          {error && <span className='text-red-600'>* {errorMessage}</span>}
          {infos && <Info infos={infos} />}
        </div>
      ) : (
        <div className='pb-[20px]'>
          <div className='flex flex-col'>
            {label && (
              <label className='text-[12px] leading-[23px] font-normal text-color-gray'>
                {label}
              </label>
            )}
            <input
              type='file'
              accept={accept}
              ref={inputRef}
              className={'hr-file-input bg-color-off-white rounded-md h-[65px] p-[20px] border-[0.5px] border-color-gray mt-[10px] text-hr-secondary-1'}
              onChange={(e) => {
                setFileName(e.target.value)
              }}
              onKeyDown={onKeyDown}
              readOnly={readOnly ? true : false}
              value={value}
              {...rest}
              name='file'
            />
              
          </div>

          {error && <span className='text-red-600'>* {errorMessage}</span>}
          {infos && <Info infos={infos} />}
        </div>
      )}
    </>
  );
};

export default FormFileInput;
