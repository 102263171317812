import React, { useState } from "react";
import { useSelector } from "react-redux";
import FormSelect from "./form-select";
import { NavLink, useHistory } from "react-router-dom";
import { useEffect } from "react";
import {
  useGetUserCompanies,
  useSwitchCurrentCompany,
} from "../redux/company/hook";
import LoadingBar from "./loader-bar";
import LogoSVG from "../assets/svg/ropay-logo-white.svg";
import HomeSVG from "../assets/svg/sidebar/home-white.svg";
import CalendarSVG from "../assets/svg/sidebar/calendar-sidebar.svg";
import EmployeeSVG from "../assets/svg/sidebar/employees-white.svg";
import LogoutSVG from "../assets/svg/sidebar/logout-white.svg";
import ChatSVG from "../assets/svg/sidebar/chat.svg";
import Button from "./button";
import GoogleImage from "../assets/img/google-play-badge.png";
import AppleImage from "../assets/svg/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";
import AddCompanyModal from "./modal/add-company/add-company";

const Sidebar = ({ switchLoading }) => {
  const { user } = useSelector((state) => state.userSlice);
  const { company } = useSelector((state) => state.companySlice);
  const [showAddCompany, setShowAddCompany] = useState(false);
  console.log(company);

  const [sidebarItems, setSidebarItems] = useState([
    {
      name: "Dashboard",
      icon: HomeSVG,
      route: "/dashboard",
      active: true,
    },
    {
      name: "My Profile",
      icon: EmployeeSVG,
      route: "/profile",
    },
    {
      name: "Logout",
      icon: LogoutSVG,
      route: "/logout",
    },
  ]);

  useEffect(() => {
    if (user) {
      if (user.company_id) {
        if (
          user?.company?.hr_subscription &&
          user.company.hr_subscription == 1
        ) {
          setSidebarItems([
            {
              name: "Dashboard",
              icon: HomeSVG,
              route: "/dashboard",
              active: true,
            },
            // {
            //   name: 'Ro-chat',
            //   icon: ChatSVG,
            //   route: '/ro-chat',
            // },
            {
              name: "My Calendar",
              icon: CalendarSVG,
              route: "/calendar",
            },
            {
              name: "My Profile",
              icon: EmployeeSVG,
              route: "/profile",
            },
            {
              name: "Logout",
              icon: LogoutSVG,
              route: "/logout",
            },
          ]);
        }
      }
    }
  }, [user]);

  const [companyOptions, setCompanyOptions] = useState([]);

  const { mutateAsync: switchCurrentCompany } = useSwitchCurrentCompany();

  const { data: companies } = useGetUserCompanies();

  const history = useHistory();

  useEffect(() => {
    if (companies) {
      let computeCompanyOptions = [];
      companies.forEach((company) => {
        computeCompanyOptions.push({
          label: company?.company_name,
          value: company?.company_id,
        });
      });
      setCompanyOptions(computeCompanyOptions);
    }
  }, [companies]);

  const [loggedInCompany, setLoggedInCompany] = useState(user?.company_id);

  return (
    <div className="flex flex-col w-full h-full relative overflow-auto select-none bg-secondary-2">
      {showAddCompany ? (
        <AddCompanyModal
          isOpen={showAddCompany}
          closeModal={() => setShowAddCompany(false)}
        />
      ) : null}
      <div
        className="flex px-[15px] pt-[30px] cursor-pointer"
        onClick={() => {
          history.push("/");
        }}
      >
        <img src={LogoSVG} alt="" width={"55%"} />
      </div>
      <hr className="!bg-white min-h-[3px] mb-0" />
      <div className="flex flex-col px-[15px]">
        {companyOptions && companyOptions.length > 0 ? (
          <FormSelect
            value={loggedInCompany}
            options={companyOptions}
            onChange={(selected) => {
              switchCurrentCompany(selected);
            }}
            type="default"
            extraClass={
              "!text-white font-bold bg-white bg-opacity-25 !h-[35px] !text-[14px]"
            }
          />
        ) : (
          <LoadingBar loading={true} />
        )}
      </div>
      <hr className="!bg-white min-h-[3px] -mt-2" />
      <div className="flex flex-col justify-between h-full mb-3 gap-2 ">
        <div className="flex flex-col">
          {switchLoading ? (
            <div className="flex flex-col min-w-full min-h-[100%] text-center justify-center">
              <LoadingBar loading={switchLoading} sidebar={true} />
            </div>
          ) : (
            <>
              {sidebarItems?.map((item, index) => (
                <NavLink
                  to={item.route}
                  className="text-color-white font-normal text-[13px] leading-[20px] flex pl-[20px] h-[42px] items-center hover:text-color-white hover:font-semibold hover:bg-white hover:bg-opacity-5"
                  activeClassName={
                    "font-bold text-[15px] text-color-white bg-white bg-opacity-10 border-l-4 border-l-white"
                  }
                >
                  <img
                    src={item.icon}
                    alt={item.name}
                    className={"pr-[15px]"}
                    width={"30px"}
                    height={"35px"}
                  />
                  {item.name}
                </NavLink>
              ))}
            </>
          )}
        </div>
        <div className="flex flex-col gap-2 w-full px-3 items-start">
          <span className="text-white font-semibold ">
            Download mobile app on:
          </span>
          <a
            href="https://play.google.com/store/apps/details?id=com.ropay.app"
            className="mb-1 w-full flex justify-start"
            target="_blank"
          >
            <img width="220" src={GoogleImage} alt="android" />
          </a>
          <a
            href="https://apps.apple.com/ng/app/ropay-mobile/id1673798586"
            className="mb-1 w-full flex justify-start"
            target="_blank"
          >
            <img width="220" src={AppleImage} alt="ios" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
