import classNames from "classnames";
import React from "react";
import { useEffect } from "react";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import TableActionIconSVG from "../../assets/svg/table-action-icon.svg";
import EmailSVG from "../../assets/svg/email-widget-icon.svg";
import RecruitmentSVG from "../../assets/svg/recruit-logo.svg";
import WorkflowSVG from "../../assets/svg/workflow-widget-icon.svg";
import PerformanceSVG from "../../assets/svg/performance-widget-icon.svg";
import PromotionSVG from "../../assets/svg/promotion.svg";
import EmployeeLeaveSVG from "../../assets/svg/employee-leave.svg";
import ResignationSVG from "../../assets/svg/resignation.svg";
import TeamSVG from "../../assets/svg/team-widget-icon.svg";
import OnboardingSVG from "../../assets/svg/onboarding.svg";
import TrainingSVG from "../../assets/svg/trainings.svg";
import TrainersSVG from "../../assets/svg/trainers.svg";
import DepartmentSVG from "../../assets/svg/department-widget-icon.svg";
import BranchSVG from "../../assets/svg/branch-widget-icon.svg";
import TerminationSVG from "../../assets/svg/termination.svg";
import QuestionSVG from "../../assets/svg/question.svg";
import { StatusText } from "../fragments/status-text";
import {
  employeeStatusConfig,
  exitStatusConfig,
  leaveStatusConfig,
  onboardingEmployeeStatusConfig,
  onboardingStatusConfig,
  TrainerStatusConfig,
  TrainingStatusConfig,
} from "../../utils/constants";
import { Initials } from "../../utils/functions";
import CopyToClipboard from "react-copy-to-clipboard";
import { useDispatch } from "react-redux";
import CopySVG from "../../assets/svg/copy.svg";
import { setAlert } from "../../redux/components/components-slice";

const TableCard = ({ data, type, computedActions, actionConfig }) => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const dispatch = useDispatch();

  const ActionItem = ({ item, id, sentData }) => {
    return (
      <div
        className="py-2 hover:bg-secondary-2-extralight px-3 pb-1 cursor-pointer"
        onClick={() => {
          item.action(id);
        }}
      >
        <span
          className={classNames(
            {
              "text-color-green-medium": item.color === "green",
            },
            {
              "text-color-orange": item.color === "orange",
            },
            {
              "text-color-error": item.color === "red",
            }
          )}
        >
          {item.name}
        </span>
      </div>
    );
  };

  const Action = ({ data }) => {
    return (
      <>
        {data && computedActions ? (
          <div className="flex justify-end">
            <div className="!max-w-[45px]">
              <UncontrolledDropdown direction="auto">
                <DropdownToggle className="bg-transparent border-0 w-full">
                  <img
                    src={TableActionIconSVG}
                    alt=""
                    className="!min-w-[35px]"
                  />
                </DropdownToggle>

                <DropdownMenu
                  container="body"
                  className="!w-[150px] !p-0 !text-[14px]"
                  style={{ zIndex: 1090 }}
                  width="50px"
                >
                  <div className="flex flex-col">
                    {actionConfig?.map((item, index) => (
                      <>
                        {computedActions ? (
                          <>
                            {data.computedActions ? (
                              <>
                                {data.computedActions.includes(index) ? (
                                  <ActionItem item={item} id={data.id} />
                                ) : null}
                              </>
                            ) : null}
                          </>
                        ) : (
                          <ActionItem item={item} id={data.id} />
                        )}
                      </>
                    ))}
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
        ) : null}
      </>
    );
  };

  return (
    <div className="flex flex-row flex-wrap-reverse justify-between rounded-md mx-2 p-3 w-full shadow-md hover:shadow-2xl border hover:bg-green-50 duration-500 hover:scale-110 mt-1">
      {type === "email" ? (
        <div className=" flex flex-col justify-between !min-w-full gap-2 !min-h-[180px]">
          <div className="flex flex-row items-center justify-between !min-w-full ">
            <div>
              <img src={EmailSVG} alt="email_icon" />
            </div>
            <Action data={data} />
          </div>
          <div className="flex flex-col justify-between">
            <div className="flex gap-3 header-4 text-left mb-2 items-center">
              <span className="header-5 text-gray-400">Subject:</span>
              <span className="">{data?.subject}</span>
            </div>
            <hr className="divider mt-2" />
            <div className="flex flex-row items-center justify-between mb-2">
              <div className="flex flex-col">
                <span className="header-5 text-gray-400">Salutation</span>
                <span className="text-[14px]">{data?.salutation}</span>
              </div>
              <div className="flex flex-col">
                <span className="header-5 text-gray-400">Name Type</span>
                <span className="text-[14px]">
                  {data?.name_type == "first_name"
                    ? "First Name"
                    : data?.name_type == "last_name"
                    ? "Last Name"
                    : "Full Name"}
                </span>
              </div>
            </div>
            <span className="header-5 text-gray-400 ">Body</span>
            <td
              className="border-1 p-3 rounded !max-h-[200px] overflow-auto !min-h-[200px] !text-[13px] shadow-sm"
              dangerouslySetInnerHTML={{ __html: data?.body }}
            />
            <div className="flex flex-row items-center justify-between mt-3">
              <div className="flex flex-col">
                <span className="header-5 text-gray-400">Closing Remark</span>
                <span className="text-[13px]">{data?.closing_remark}</span>
              </div>
            </div>
          </div>
        </div>
      ) : type === "recruitment" ? (
        <div className=" flex flex-col justify-between !min-w-full !max-w-full gap-2 !min-h-[120px]">
          <div className="flex flex-row items-center justify-between w-full -mr-8">
            <div>
              <img src={RecruitmentSVG} alt="email_icon" />
            </div>
            <Action data={data} />
          </div>
          <div className="flex flex-col justify-between">
            <div className="header-4 text-left">{data?.role}</div>
          </div>
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-col">
              <span className="header-5 text-gray-400">Salary Range</span>
              <span className="text-[15px]">
                {data?.min_salary} - {data?.max_salary}
              </span>
            </div>
            <div className="flex flex-col">
              <span className="header-5 text-gray-400">Closing Date</span>
              <span className="text-[15px]">{data?.application_end}</span>
            </div>
          </div>
          <div className="flex flex-row p3 items-center justify-start">
            <CopyToClipboard
              text={data?.application_url}
              onCopy={() => {
                dispatch(
                  setAlert(
                    true,
                    "success",
                    "Application link copied successfully"
                  )
                );
              }}
            >
              <button className="flex flex-row mt-2 items-center text-center">
                <span className="text-color-black pt-2 text-[14px] text-hr-primary-1">
                  Copy application link to clipboard{" "}
                </span>
                <img
                  src={CopySVG}
                  width={15}
                  className="ml-2 mt-2"
                  alt="copy"
                />
              </button>
            </CopyToClipboard>
          </div>
        </div>
      ) : type === "promotion" ? (
        <div className=" flex flex-col justify-between !min-w-full gap-2 !min-h-[120px]">
          <div className="flex flex-row items-center justify-between w-full -mr-8">
            <div>
              <img src={PromotionSVG} alt="email_icon" />
            </div>
            <Action data={data} />
          </div>
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row justify-between gap-3 items-center">
              <div className="header-5 text-left">
                {data?.first_name} {data?.last_name}{" "}
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-between mt-2">
            <div className="flex flex-col w-1/2">
              <span className="header-5 text-gray-400">Promotion From</span>
              <span className="text-[13px]">{data?.promotion_from}</span>
            </div>
            <div className="flex flex-col w-1/2">
              <span className="header-5 text-gray-400">Promotion To</span>
              <span className="text-[13px]">{data?.promotion_to}</span>
            </div>
          </div>
          <div className="flex flex-row justify-between mt-2">
            <div className="flex flex-col w-1/2">
              <span className="header-5 text-gray-400">Mobile</span>
              <span className="text-[13px]">{data?.mobile}</span>
            </div>
            <div className="flex flex-col w-1/2">
              <span className="header-5 text-gray-400">Effective Date</span>
              <span className="text-[13px]">
                {data?.formatted_effective_date}
              </span>
            </div>
          </div>
          <div className="flex flex-row items-center gap-2 mt-2">
            <div>
              <img src={EmailSVG} alt="email_icon" />
            </div>
            <div className="flex flex-col w-1/2">
              <span className="text-[13px]">{data?.email}</span>
            </div>
          </div>
        </div>
      ) : type === "training" ? (
        <div className=" flex flex-col justify-between !min-w-full gap-2 !min-h-[120px]">
          <div className="flex flex-row items-center justify-between w-full -mr-8">
            <div>
              <img src={TrainingSVG} alt="email_icon" />
            </div>
            <Action data={data} />
          </div>
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row justify-between gap-3 items-center">
              <div className="flex flex-col justify-between">
                <div className="header-4 text-left">{data?.training_name}</div>
                <span className="text-[15px]">{data?.trainer_name}</span>
              </div>
              <StatusText
                code={data?.status}
                statusConfig={TrainingStatusConfig}
              />
            </div>
          </div>
          <div className="flex flex-row items-center justify-between mt-2">
            <div className="flex flex-col">
              <span className="header-5 text-gray-400">Training Period</span>
              <span className="text-[14px]">{data?.period}</span>
            </div>
          </div>
        </div>
      ) : type === "trainer" ? (
        <div className=" flex flex-col justify-between !min-w-full gap-2 !min-h-[120px]">
          <div className="flex flex-row items-center justify-between w-full -mr-8">
            <div>
              <img src={TrainersSVG} alt="email_icon" />
            </div>
            <Action data={data} />
          </div>
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row justify-between gap-3 items-center">
              <div className="flex flex-col justify-between">
                <div className="header-4 text-left">{data?.trainer_name}</div>
                <span className="text-[15px]">{data?.training_type}</span>
              </div>
              <StatusText
                code={data?.status}
                statusConfig={TrainerStatusConfig}
              />
            </div>
          </div>
          <div className="flex flex-row justify-between mt-2">
            <div className="flex flex-col w-1/2">
              <span className="header-5 text-gray-400">Mobile</span>
              <span className="text-[13px]">{data?.mobile}</span>
            </div>
            <div className="flex flex-col w-1/2">
              <span className="header-5 text-gray-400">Date Created</span>
              <span className="text-[13px]">{data?.created}</span>
            </div>
          </div>

          <div className="flex flex-row items-center gap-2 mt-2">
            <div>
              <img src={EmailSVG} alt="email_icon" />
            </div>
            <div className="flex flex-col w-1/2">
              <span className="text-[13px]">{data?.email}</span>
            </div>
          </div>
        </div>
      ) : type === "performance" ? (
        <div className=" flex flex-col justify-between !min-w-full gap-3 !min-h-[120px]">
          <div className="flex flex-row items-center justify-between w-full -mr-8">
            <div>
              <img src={PerformanceSVG} alt="email_icon" />
            </div>
            <Action data={data} />
          </div>
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-col justify-between items-start">
              <div className="header-4 text-left">
                {data?.first_name} {data?.last_name}{" "}
              </div>
              <div className="flex flex-col">
                <span className="text-[15px]">{data?.reviewer_comment}</span>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-col">
              <span className="text-[14px] font-bold text-gray-400">
                Period Covered
              </span>
              <span className="text-[13px]">{data?.period}</span>
            </div>
          </div>
        </div>
      ) : type === "leave" ? (
        <div className=" flex flex-col justify-between !min-w-full gap-2 !min-h-[120px]">
          <div className="flex flex-row items-center justify-between w-full -mr-8">
            <div className="flex gap-3 items-center">
              <img src={EmployeeLeaveSVG} alt="email_icon" />
              <div className="flex flex-col justify-center">
                <div className="header-5 text-start">
                  {data?.first_name} {data?.last_name}{" "}
                </div>
                <div className="flex flex-col mb-2">
                  <span className="text-[13px]">{data?.leave_type}</span>
                </div>
              </div>
            </div>
            <Action data={data} />
          </div>
          <div className="flex flex-col justify-start items-start w-full">
            <div className="flex flex-row justify-between gap-2 items-center p-2 border rounded-md bg-secondary-2-extralight w-full">
              <div className="flex flex-col header-4 text-left">
                <span className="text-[11px] ">Final Status</span>
                <StatusText
                  code={data?.final_status}
                  statusConfig={leaveStatusConfig}
                />
              </div>
              <div className="flex flex-col header-4 text-left">
                <span className="text-[11px] ">Team Status</span>
                <StatusText
                  code={data?.team_status}
                  statusConfig={leaveStatusConfig}
                />
              </div>
              <div className="flex flex-col header-4 text-left">
                <span className="text-[11px] ">Department Status</span>
                <StatusText
                  code={data?.department_status}
                  statusConfig={leaveStatusConfig}
                />
              </div>
            </div>
          </div>
          <hr className="divider my-0" />
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-col">
              <span className="header-6 text-gray-400">Period Covered</span>
              <span className="text-[13px]">{data?.period}</span>
            </div>
          </div>
        </div>
      ) : type === "employee-onboarding" ? (
        <div className=" flex flex-col justify-between !min-w-full gap-3 !min-h-[120px]">
          <div className="flex flex-row items-center justify-between w-full -mr-8">
            <div>
              <img src={OnboardingSVG} alt="email_icon" />
            </div>
            <Action data={data} />
          </div>
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-col justify-between items-start">
              <div className="flex flex-row items-center justify-between">
                <div className="flex flex-row justify-between gap-3 items-start">
                  <div className=" flex flex-col text-left">
                    <span className="header-5 text-left">
                      {data?.first_name} {data?.last_name}{" "}
                    </span>
                    <span className="text-[15px] text-left">
                      {data?.mobile}
                    </span>
                  </div>
                  <StatusText
                    code={data?.employee_status}
                    statusConfig={onboardingEmployeeStatusConfig}
                  />
                </div>
              </div>
              <div className="flex flex-row items-center gap-2 mt-2">
                <div>
                  <img src={EmailSVG} alt="email_icon" />
                </div>

                <div className="flex flex-col w-1/2">
                  <span className="text-[15px]">{data?.email}</span>
                </div>
              </div>
              <hr className="divider mt-2" />
              <div className="flex flex-row items-center justify-between">
                <div className="flex flex-col ">
                  <span className="header-5 text-gray-400">Review Status</span>
                  <div>
                    <StatusText
                      code={data?.status}
                      statusConfig={onboardingStatusConfig}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : type === "team" ? (
        <div className=" flex flex-col !min-w-full gap-2 !min-h-[120px]">
          <div className="flex flex-row items-center justify-between w-full -mr-8">
            <div>
              <img src={TeamSVG} alt="team_icon" />
            </div>
            <Action data={data} />
          </div>
          <div className="flex flex-col justify-between">
            <div className="header-4 text-left">{data?.name}</div>
            <span className="text-[15px]">{data?.description}</span>
          </div>
          <div className="flex flex-row items-start justify-between min-h-[70px]">
            <div className="flex flex-col">
              <span className="header-6 md:header-5 text-gray-400">
                Employees
              </span>
              {data?.employees && data?.employee_count > 0 ? (
                <>
                  {data?.employee_count <= 5 ? (
                    <div className="flex flex-row items-center justify-between w-full mt-2">
                      {data?.employees.map((item, index) => (
                        <div
                          className={classNames(
                            "text-[12px] md:text-[18px] flex font-bold justify-center items-center h-[30px] md:h-[45px] w-[30px] md:w-[45px] rounded-[45px] header-3 border-secondary-2 bg-green-200 relative shadow-md",
                            {
                              "-ml-3 md:-ml-4": index !== 0,
                            }
                          )}
                        >
                          {item?.profile_photo_url ? (
                            <div>
                              <img
                                src={item?.profile_photo_url}
                                alt={`employee_${index + 1}`}
                                className="object-contain !max-w-[35px] !max-h-[35px] rounded-[35px]"
                              />
                            </div>
                          ) : (
                            <>{Initials(item?.first_name, item?.last_name)}</>
                          )}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="flex flex-row items-center justify-between w-full  mt-2 ">
                      {data?.employees.slice(0, 5).map((item, index) => (
                        <div
                          className={classNames(
                            "text-[12px] md:text-[18px] flex font-bold justify-center items-center h-[30px] md:h-[45px] w-[30px] md:w-[45px] rounded-[45px] border-[3px] header-3 border-secondary-2 bg-green-200 relative shadow-md",
                            {
                              "-ml-3 md:-ml-4": index !== 0,
                            }
                          )}
                        >
                          {item?.profile_photo_url ? (
                            <div>
                              <img
                                src={item?.profile_photo_url}
                                alt={`employee_${index + 1}`}
                                className="object-contain  !max-w-[35px] !max-h-[35px] rounded-[35px]"
                              />
                            </div>
                          ) : (
                            <>{Initials(item?.first_name, item?.last_name)}</>
                          )}
                        </div>
                      ))}
                      <span className="font-semibold text-[15px] ml-1">
                        + {data?.employee_count - 5}
                      </span>
                    </div>
                  )}
                </>
              ) : (
                <span className="text-[15px]">
                  {data?.employee_count} Employees
                </span>
              )}
            </div>
            <div className="flex flex-col justify-center items-end">
              {data.manager_setting.manager_id &&
                data.employees[
                  data.employees.findIndex(
                    (item) => item.id === data.manager_setting.manager_id
                  )
                ] && (
                  <>
                    <div className="flex justify-center flex-col items-center">
                      <span className="header-6 md:header-5 text-gray-400">
                        Team Lead
                      </span>
                      <div className="text-[12px] md:text-[18px] flex font-bold justify-center items-center h-[30px] md:h-[45px] w-[30px] md:w-[45px] rounded-[45px] border-[3px] header-3 border-secondary-2 bg-green-200 relative mt-2 hover:scale-150 hover:m-2 duration-500">
                        {data.employees[
                          data.employees.findIndex(
                            (item) =>
                              item.id === data.manager_setting.manager_id
                          )
                        ]?.profile_photo_url ? (
                          <div>
                            <img
                              src={
                                data.employees[
                                  data.employees.findIndex(
                                    (item) =>
                                      item.id ===
                                      data.manager_setting.manager_id
                                  )
                                ]?.profile_photo_url
                              }
                              alt={`lead_pix`}
                              className="object-contain !max-w-[35px] !max-h-[35px] rounded-[35px]"
                            />
                          </div>
                        ) : (
                          <>
                            {Initials(
                              data.employees[
                                data.employees.findIndex(
                                  (item) =>
                                    item.id === data.manager_setting.manager_id
                                )
                              ]?.first_name,
                              data.employees[
                                data.employees.findIndex(
                                  (item) =>
                                    item.id === data.manager_setting.manager_id
                                )
                              ]?.last_name
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
            </div>
          </div>
        </div>
      ) : type === "department" ? (
        <div className=" flex flex-col !min-w-full gap-2 !min-h-[120px]">
          <div className="flex flex-row items-center justify-between w-full -mr-8">
            <div>
              <img src={DepartmentSVG} alt="team_icon" />
            </div>
            <Action data={data} />
          </div>
          <div className="flex flex-col justify-between">
            <div className="header-4 text-left">{data?.name}</div>
            <span className="text-[13px]">{data?.description}</span>
          </div>
          <div className="flex flex-row items-start justify-between min-h-[70px]">
            <div className="flex flex-col">
              <span className="header-5 text-gray-400">Employees</span>
              {data?.employees && data?.employee_count > 0 ? (
                <>
                  {data?.employee_count <= 5 ? (
                    <div className="flex flex-row items-center justify-between w-full mt-2">
                      {data?.employees.map((item, index) => (
                        <div
                          className={classNames(
                            "text-[18px] flex font-bold justify-center items-center h-[45px] w-[45px] rounded-[45px] border-[3px] header-3 border-secondary-2 bg-green-200 relative shadow-md",
                            {
                              "-ml-3": index !== 0,
                            }
                          )}
                        >
                          {item?.profile_photo_url ? (
                            <div>
                              <img
                                src={item?.profile_photo_url}
                                alt={`employee_${index + 1}`}
                                className="object-contain !max-w-[35px] !max-h-[35px] rounded-[35px]"
                              />
                            </div>
                          ) : (
                            <>{Initials(item?.first_name, item?.last_name)}</>
                          )}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="flex flex-row items-center justify-between w-full  mt-2 ">
                      {data?.employees.slice(0, 5).map((item, index) => (
                        <div
                          className={classNames(
                            "text-[18px] flex font-bold justify-center items-center h-[45px] w-[45px] rounded-[45px] border-[3px] header-3 border-secondary-2 bg-green-200 relative shadow-md",
                            {
                              "-ml-4": index !== 0,
                            }
                          )}
                        >
                          {item?.profile_photo_url ? (
                            <div>
                              <img
                                src={item?.profile_photo_url}
                                alt={`employee_${index + 1}`}
                                className="object-contain !max-w-[35px] !max-h-[35px] rounded-[35px]"
                              />
                            </div>
                          ) : (
                            <>{Initials(item?.first_name, item?.last_name)}</>
                          )}
                        </div>
                      ))}
                      <span className="font-semibold text-[15px] ml-1">
                        + {data?.employee_count - 5}
                      </span>
                    </div>
                  )}
                </>
              ) : (
                <span className="text-[15px]">
                  {data?.employee_count} Employees
                </span>
              )}
            </div>
            <div className="flex flex-col justify-center items-end">
              {data.manager_setting.manager_id &&
                data.employees[
                  data.employees.findIndex(
                    (item) => item.id === data.manager_setting.manager_id
                  )
                ] && (
                  <>
                    <span className="header-5 text-gray-400">Manager</span>
                    <div className="text-[18px] flex font-bold justify-center items-center h-[45px] w-[45px] rounded-[45px] border-[3px] header-3 border-secondary-2 bg-green-200 relative mt-2 hover:scale-150 hover:m-2 duration-500">
                      {data.employees[
                        data.employees.findIndex(
                          (item) => item.id === data.manager_setting.manager_id
                        )
                      ]?.profile_photo_url ? (
                        <div>
                          <img
                            src={
                              data.employees[
                                data.employees.findIndex(
                                  (item) =>
                                    item.id === data.manager_setting.manager_id
                                )
                              ]?.profile_photo_url
                            }
                            alt={`lead_pix`}
                            className="object-contain !max-w-[35px] !max-h-[35px] rounded-[35px]"
                          />
                        </div>
                      ) : (
                        <>
                          {Initials(
                            data.employees[
                              data.employees.findIndex(
                                (item) =>
                                  item.id === data.manager_setting.manager_id
                              )
                            ]?.first_name,
                            data.employees[
                              data.employees.findIndex(
                                (item) =>
                                  item.id === data.manager_setting.manager_id
                              )
                            ]?.last_name
                          )}
                        </>
                      )}
                    </div>
                  </>
                )}
            </div>
          </div>
        </div>
      ) : type === "branch" ? (
        <div className=" flex flex-col !min-w-full gap-2 !min-h-[120px]">
          <div className="flex flex-row items-center justify-between !min-w-full -mr-8">
            <div>
              <img src={BranchSVG} alt="team_icon" />
            </div>
            <Action data={data} />
          </div>
          <div className="flex flex-col justify-between">
            <div className="header-4 text-left">{data?.name}</div>
            <span className="text-[15px]">{data?.description}</span>
          </div>
          <div className="flex flex-row items-start justify-between min-h-[70px]">
            <div className="flex flex-col">
              <span className="header-5 text-gray-400">Employees</span>
              {data?.employees && data?.employee_count > 0 ? (
                <>
                  {data?.employee_count <= 5 ? (
                    <div className="flex flex-row items-center justify-between w-full mt-2">
                      {data?.employees.map((item, index) => (
                        <div
                          className={classNames(
                            "text-[18px] flex font-bold justify-center items-center h-[45px] w-[45px] rounded-[45px] border-[3px] header-3 border-secondary-2 bg-green-200 relative shadow-md",
                            {
                              "-ml-3": index !== 0,
                            }
                          )}
                        >
                          {item?.profile_photo_url ? (
                            <div>
                              <img
                                src={item?.profile_photo_url}
                                alt={`employee_${index + 1}`}
                                className="object-contain !max-w-[35px] !max-h-[35px] rounded-[35px]"
                              />
                            </div>
                          ) : (
                            <>{Initials(item?.first_name, item?.last_name)}</>
                          )}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="flex flex-row items-center justify-between w-full  mt-2 ">
                      {data?.employees.slice(0, 5).map((item, index) => (
                        <div
                          className={classNames(
                            "text-[18px] flex font-bold justify-center items-center h-[45px] w-[45px] rounded-[45px] border-[3px] header-3 border-secondary-2 bg-green-200 relative shadow-md",
                            {
                              "-ml-4": index !== 0,
                            }
                          )}
                        >
                          {item?.profile_photo_url ? (
                            <div>
                              <img
                                src={item?.profile_photo_url}
                                alt={`employee_${index + 1}`}
                                className="object-contain !max-w-[35px] !max-h-[35px] rounded-[35px]"
                              />
                            </div>
                          ) : (
                            <>{Initials(item?.first_name, item?.last_name)}</>
                          )}
                        </div>
                      ))}
                      <span className="font-semibold text-[15px] ml-1">
                        + {data?.employee_count - 5}
                      </span>
                    </div>
                  )}
                </>
              ) : (
                <span className="text-[15px]">
                  {data?.employee_count} Employees
                </span>
              )}
            </div>
            <div className="flex flex-col justify-center items-end">
              {data.manager_setting.manager_id &&
                data.employees[
                  data.employees.findIndex(
                    (item) => item.id === data.manager_setting.manager_id
                  )
                ] && (
                  <>
                    <span className="header-5 text-gray-400">Manager</span>
                    <div className="text-[18px] flex font-bold justify-center items-center h-[45px] w-[45px] rounded-[45px] border-[3px] header-3 border-secondary-2 bg-green-200 relative mt-2 hover:scale-150 hover:m-2 duration-500">
                      {data.employees[
                        data.employees.findIndex(
                          (item) => item.id === data.manager_setting.manager_id
                        )
                      ]?.profile_photo_url ? (
                        <div>
                          <img
                            src={
                              data.employees[
                                data.employees.findIndex(
                                  (item) =>
                                    item.id === data.manager_setting.manager_id
                                )
                              ]?.profile_photo_url
                            }
                            alt={`lead_pix`}
                            className="object-contain !max-w-[35px] !max-h-[35px] rounded-[35px]"
                          />
                        </div>
                      ) : (
                        <>
                          {Initials(
                            data.employees[
                              data.employees.findIndex(
                                (item) =>
                                  item.id === data.manager_setting.manager_id
                              )
                            ]?.first_name,
                            data.employees[
                              data.employees.findIndex(
                                (item) =>
                                  item.id === data.manager_setting.manager_id
                              )
                            ]?.last_name
                          )}
                        </>
                      )}
                    </div>
                  </>
                )}
            </div>
          </div>
        </div>
      ) : type === "employee-exit" ? (
        <div className=" flex flex-col justify-between !min-w-full gap-3 !min-h-[120px]">
          <div className="flex flex-row items-center justify-between w-full -mr-8">
            <div>
              {data?.type == "resignation" ? (
                <img src={ResignationSVG} alt="email_icon" />
              ) : (
                <img src={TerminationSVG} alt="email_icon" />
              )}
            </div>
            <Action data={data} />
          </div>
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-col justify-between items-start">
              <div className="flex flex-row items-center justify-between">
                <div className="flex flex-row justify-between gap-3 items-center">
                  <div className="header-5 text-left">
                    {data?.first_name} {data?.last_name}{" "}
                  </div>
                  <StatusText
                    code={data?.status}
                    statusConfig={exitStatusConfig}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-between mt-2">
            <div className="flex flex-col w-1/2">
              <span className="header-5 text-gray-400">Notice Date</span>
              <span className="text-[15px]">{data?.new_notice_date}</span>
            </div>
            <div className="flex flex-col w-1/2">
              <span className="header-5 text-gray-400">Resignation date</span>
              <span className="text-[15px]">{data?.new_left_date}</span>
            </div>
          </div>
        </div>
      ) : type === "leave-type" ? (
        <div className=" flex flex-col justify-between !min-w-full gap-2 !min-h-[120px]">
          <div className="flex flex-row items-center justify-between w-full -mr-8">
            <div>
              <img src={EmployeeLeaveSVG} alt="email_icon" />
            </div>
            <Action data={data} />
          </div>
          <div className="flex flex-col justify-between">
            <div className="header-4 text-left">{data?.name}</div>
            <span className="text-start text-[15px]">{data?.description}</span>
          </div>
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-col">
              <span className="header-5 text-gray-400">Annual Days</span>
              <span>{data?.max_annual_days}</span>
            </div>
            <div className="flex flex-col">
              <span className="header-5 text-gray-400">Application Days</span>
              <span>{data?.max_appplication_days}</span>
            </div>
          </div>
        </div>
      ) : type === "workflow" ? (
        <div className=" flex flex-col justify-between !min-w-full gap-2 !min-h-[120px]">
          <div className="flex flex-row items-center justify-between w-full -mr-8">
            <div>
              <img src={WorkflowSVG} alt="email_icon" />
            </div>
            <Action data={data} />
          </div>
          <div className="flex flex-col justify-between">
            <div className="header-4 text-left">{data?.name}</div>
            <span className="text-start text-[15px]">{data?.description}</span>
          </div>
        </div>
      ) : type === "employee" ? (
        <div className=" flex flex-col justify-between !min-w-full gap-2 !min-h-[120px]">
          <div className="flex flex-row items-center justify-between w-full -mr-8">
            <div className="text-[18px] flex font-bold justify-center items-center h-[70px] w-[70px] rounded-[70px] border-[3px] header-3 border-secondary-2 bg-green-200 relative hover:scale-150 hover:m-2 duration-500">
              {data?.profile_photo_url ? (
                <div>
                  <img
                    src={data?.profile_photo_url}
                    alt="email_icon"
                    className="object-fit min-w-[60px] min-h-[60px] max-w-[60px] max-h-[60px] rounded-[60px]"
                  />
                </div>
              ) : (
                <>{Initials(data?.first_name, data?.last_name)}</>
              )}
            </div>
            <Action data={data} />
          </div>
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row justify-between gap-3 items-center">
              <div className="header-5 text-left">
                {data?.first_name} {data?.last_name}{" "}
              </div>
              <StatusText
                code={data?.status}
                statusConfig={employeeStatusConfig}
              />
            </div>
          </div>

          <div className="flex flex-row justify-between mt-2">
            <div className="flex flex-col w-1/2">
              <span className="header-5 text-gray-400">Mobile</span>
              <span className="text-[13px]">{data?.mobile}</span>
            </div>
            <div className="flex flex-col w-1/2">
              <span className="header-5 text-gray-400">Date Joined</span>
              <span className="text-[13px]">
                {data?.date_joined ? data.date_joined : "-"}
              </span>
            </div>
          </div>
          <div className="flex flex-row items-center gap-2 mt-2">
            <div>
              <img src={EmailSVG} alt="email_icon" />
            </div>

            <div className="flex flex-col w-1/2">
              <span className="text-[13px]">{data?.email}</span>
            </div>
          </div>
        </div>
      ) : type === "exit-questions" ? (
        <div className=" flex flex-col justify-between !min-w-full gap-2 !min-h-[120px]">
          <div className="flex flex-row items-center justify-between w-full -mr-8">
            <div>
              <img src={QuestionSVG} alt="email_icon" />
            </div>
            <Action data={data} />
          </div>
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row justify-between gap-3 items-center">
              <div className="header-5 text-left">{data?.question} </div>
              <StatusText
                code={data?.status}
                statusConfig={employeeStatusConfig}
              />
            </div>
          </div>

          <div className="flex flex-row justify-between mt-2">
            <div className="flex flex-col w-1/2">
              <span className="header-5 text-gray-400">Question Type</span>
              <span className="text-[15px]">
                {data?.question_type == "multiple+choice"
                  ? "Multiple Choice"
                  : "Open Ended"}
              </span>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default TableCard;
