import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { logout } from '../redux/user/hook';

const Logout = () => {
  const history = useHistory();

  useEffect(() => {
    logout();
    history.push('/');
  }, []);

  return <></>;
};

export default Logout;
