import { createSlice } from '@reduxjs/toolkit';

// Slice
const slice = createSlice({
  name: 'company',
  initialState: {
    company: null,
  },
  reducers: {
    setCompany: (state, { payload }) => {
      state.company = payload;
    },
  },
});

// Actions
export const { setCompany } = slice.actions;

export default slice.reducer;
