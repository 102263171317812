import React from "react";

export const Checkmark = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.7714 7.37691L10.4354 14.6098L7.22855 11.447C6.71872 10.9445 5.8922 10.9445 5.38237 11.447C4.87254 11.9496 4.87254 12.7643 5.38237 13.2668L9.51148 17.3387C9.75683 17.5788 10.0874 17.7143 10.4354 17.7143C10.78 17.7143 11.1123 17.5788 11.3577 17.3387L19.6176 9.19671C20.1275 8.69417 20.1275 7.87946 19.6176 7.37691C19.1078 6.87436 18.2813 6.87436 17.7714 7.37691"
      fill="#130F26"
    />
  </svg>
);
