import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Button from "../../components/button";
import EmployeeRegistrationLayout from "../../components/layouts/employee-registration";
import { useUpdateInvitedEmployees } from "../../redux/employee/hook";
import Logo from "../../components/logo";
import { useSelector } from "react-redux";

function VerifySingleEmployeeInformationPage() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const { mutateAsync: updateInvitedEmployee, isLoading: loading } =
    useUpdateInvitedEmployees();
  const history = useHistory();

  const { employee } = useSelector((state) => state.employeeSlice);
  const { company } = useSelector((state) => state.companySlice);

  const { token } = useParams();

  const submitForm = async () => {
    let newData = {
      employeeId: employee.id,
      data: {
        token: token,
        dob: employee.dob,
        gender: employee.gender,
        marital_status: employee.marital_status,
        street_address: employee.street_address,
        state: employee.state,
        city: employee.city,
        country: employee.country,
        account_number: employee.account_number,
        account_name: employee.account_name,
        bank: employee.bank,
        tax_number: employee.tax_number,
        pfa_name: employee.pfa_name,
        rsa_pin: employee.rsa_pin,
        next_of_kin_name: employee.next_of_kin_name,
        next_of_kin_mobile: employee.next_of_kin_mobile,
        next_of_kin_email: employee.next_of_kin_email,
        next_of_kin_relationship: employee.next_of_kin_relationship,
        next_of_kin_address: employee.next_of_kin_address,
      },
    };

    await updateInvitedEmployee(newData).then((result) => {
      history.push("/dashboard");
    });
  };

  return (
    <EmployeeRegistrationLayout
      step="5"
      company_name={company?.company_name}
      logo={company?.logo}
    >
      <div className="flex flex-col justify-center items-center w-full md:pt-2 h-fit gap-4">
        <div className="flex md:min-h-max flex-col w-[600px] max-w-[600px]">
          <div className="w-full text-center flex flex-col pb-[25px] md:pt-[20px] pt-2">
            <span className="header-2-regular text-secondary-2">
              Verify and Submit
            </span>
            <span className="header-5-regular text-secondary-2">
              Confirm your information before submitting
            </span>
          </div>
          <hr className="divider -mt-2" />
          <div className="flex flex-col">
            <div className="flex flex-col mt-1">
              <span className="header-4 mb-2">Profile Information</span>
              {/* <hr className='divider mt-2' /> */}
              <div className="grid md:grid-cols-2  grid-cols-1 gap-4 w-full">
                <div className="flex flex-col">
                  <span className="header-5 text-color-gray">First Name</span>
                  <span className="mt-1 text-color-black text-[14px]">
                    {employee?.first_name}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="header-5 text-color-gray">Last name</span>
                  <span className="mt-1 text-color-black text-[14px]">
                    {employee?.last_name}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="header-5 text-color-gray">Phone Number</span>
                  <span className="mt-1 text-color-black text-[14px]">
                    {employee?.mobile}
                  </span>
                </div>

                <div className="flex flex-col">
                  <span className="header-5 text-color-gray">Email</span>
                  <span className="mt-1 text-color-black text-[14px]">
                    {employee?.email}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="header-5 text-color-gray">
                    Date of birth
                  </span>
                  <span className="mt-1 text-color-black text-[14px]">
                    {employee?.dob}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="header-5 text-color-gray">Gender</span>
                  <span className="mt-1 text-color-black text-[14px]">
                    {employee?.gender}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="header-5 text-color-gray">Country</span>
                  <span className="mt-1 text-color-black text-[14px]">
                    {employee?.country}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="header-5 text-color-gray">State</span>
                  <span className="mt-1 text-color-black text-[14px]">
                    {employee?.state}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="header-5 text-color-gray">City</span>
                  <span className="mt-1 text-color-black text-[14px]">
                    {employee?.city}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="header-5 text-color-gray">
                    Street Address
                  </span>
                  <span className="mt-1 text-color-black text-[14px]">
                    {employee?.street_address}
                  </span>
                </div>
              </div>
              <hr className="divider mb-3" />
              <div className="flex flex-col">
                <span className="header-4 mb-2">Employment information</span>
                {/* <hr className='divider mt-2' /> */}
                <div className="flex flex-col">
                  <span className="header-5 text-color-gray">Staff Number</span>
                  <span className="mt-1 text-color-black text-[14px]">
                    {employee?.staff_number}
                  </span>
                </div>
                <div className="grid md:grid-cols-2  grid-cols-1 gap-4 w-full mt-3">
                  <div className="flex flex-col">
                    <span className="header-5 text-color-gray">
                      Date Of Employment
                    </span>
                    <span className="mt-1 text-color-black text-[14px]">
                      {moment(employee?.date_joined).format("MMMM DD, YYYY")}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="header-5 text-color-gray">
                      Job Position
                    </span>
                    <span className="mt-1 text-color-black text-[14px]">
                      {employee?.job_position
                        ? employee?.job_position
                        : "Not Set"}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="header-5 text-color-gray">
                      Pension Fund Administrator
                    </span>
                    <span className="mt-1 text-color-black text-[14px]">
                      {employee?.pfa_name ? employee.pfa_name : "Not Set"}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="header-5 text-color-gray">
                      Pension R.S.A PIN
                    </span>
                    <span className="mt-1 text-color-black text-[14px]">
                      {employee?.rsa_pin ? employee.rsa_pin : "Not Set"}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="header-5 text-color-gray">Tax ID</span>
                    <span className="mt-1 text-color-black text-[14px]">
                      {employee?.tax_number ? employee.tax_number : "Not Set"}
                    </span>
                  </div>
                </div>
                <hr className="divider mb-3" />
                <span className="header-4 mb-2">Salary information</span>
                {/* <hr className='divider mt-2' /> */}
                <div className="grid md:grid-cols-2  grid-cols-1 gap-4 w-full">
                  <div className="flex flex-col">
                    <span className="header-5 text-color-gray">Bank Name</span>
                    <span className="mt-1 text-color-black text-[14px]">
                      {employee?.bank}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="header-5 text-color-gray">
                      Account Number
                    </span>
                    <span className="mt-1 text-color-black text-[14px]">
                      {employee?.account_number}
                    </span>
                  </div>
                </div>
                <div className="grid md:grid-cols-1  grid-cols-1 w-full mt-4">
                  <div className="flex flex-col">
                    <span className="header-5 text-color-gray">
                      Account Name - (Auto generated)
                    </span>
                    <span className="mt-1 text-color-black text-[14px]">
                      {employee?.account_name}
                    </span>
                  </div>
                </div>
                <hr className="divider" />
                <div className="header-4 mb-2">
                  Employee Next Of Kin Information
                </div>
                <div className="grid md:grid-cols-2  grid-cols-1 gap-4 w-full mt-3">
                  <div className="flex flex-col">
                    <span className="header-5 text-color-gray">
                      Next Of Kin Name
                    </span>
                    <span className="mt-1 text-color-black text-[14px]">
                      {employee.next_of_kin_name
                        ? employee.next_of_kin_name
                        : "Not Set"}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="header-5 text-color-gray">
                      Next Of Kin Mobile
                    </span>
                    <span className="mt-1 text-color-black text-[14px]">
                      {employee.next_of_kin_mobile
                        ? employee.next_of_kin_mobile
                        : "Not Set"}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="header-5 text-color-gray">
                      Next Of Kin Email
                    </span>
                    <span className="mt-1 text-color-black text-[14px]">
                      {employee.next_of_kin_email
                        ? employee.next_of_kin_email
                        : "Not Set"}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="header-5 text-color-gray">
                      Next Of Kin Relationship
                    </span>
                    <span className="mt-1 text-color-black text-[14px]">
                      {employee.next_of_kin_relationship
                        ? employee.next_of_kin_relationship
                        : "Not Set"}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="header-5 text-color-gray">
                      Next Of Kin Address
                    </span>
                    <span className="mt-1 text-color-black text-[14px]">
                      {employee.next_of_kin_address
                        ? employee.next_of_kin_address
                        : "Not Set"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-end items-center w-full mt-[20px] gap-4">
            <div
              className="flex justify-end cursor-pointer hover:underline"
              onClick={() => history.goBack()}
            >
              <span className="p2-medium text-color-black">Go back</span>
            </div>
            <Button
              text="Confirm and Submit"
              type="submit"
              className={"!bg-secondary-2 w-[200px] h-[42px]"}
              loading={loading}
              onClick={() => submitForm()}
            />
          </div>
        </div>
        <div
          className={
            "md:hidden flex items-center justify-center text-color-black gap-2"
          }
        >
          Powered By:{" "}
          <a href="https://www.ropay.africa" target={"_blank"} rel="noreferrer">
            <Logo width="80px" height="40px" blue />
          </a>
        </div>
      </div>
    </EmployeeRegistrationLayout>
  );
}

export default VerifySingleEmployeeInformationPage;
