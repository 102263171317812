import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../components/layouts/dashboard';
import { useGetemployeeTrainings } from '../../redux/employee/hook/training';
import { PAGINATION_DEFAULT, trainingStatusConfig } from '../../utils/constants';
import DataTable from '../../components/datatable';
import TrainedEmployee from '../../components/modal/hr-section/employee-training/trained-employee';

const TrainingPage = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  // const { user } = useSelector((state) => state.userSlice);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    satusFilter: PAGINATION_DEFAULT.statusFilter,
    search: '',
  });
  const { data: trainings } = useGetemployeeTrainings(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search,
  );

  const [showTrainedEmployee, setShowTrainedEmployee] = useState(false);
  const [trainedEmployeeData, setTrainedEmployeeData] = useState(null);

  const tableConfig = {
    headers: [
      'Training Name',
      'Trainer',
      'Training Period',
      'Training Location',
    ],
    keys: ['training_name', 'trainer_name','period', 'training_location'],
    mobileHeader: {
      left: {
        title: 'Training',
        key: 'trainer_name',
      },
      right: {
        title: 'Period',
        key: 'period',
      },
    },
  };

  const actionConfig = [
    {
      name: 'View',
      color: 'green',
      action: (id) => {
        let index = trainings.data.findIndex((item) => item.id === id);
        setShowTrainedEmployee(true)
        setTrainedEmployeeData(trainings.data[index]);
      }
    },
  ];

  return (
    <DashboardLayout title='Employee Training Management'>
      <div className='flex flex-col bg-white shadow-md rounded'>
        {showTrainedEmployee ? (
          <TrainedEmployee
              isOpen={showTrainedEmployee}
              trainedEmployeeData={trainedEmployeeData}
              closeModal={() => setShowTrainedEmployee(false)}
          />
        ) : null}
        <div className='flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3'>
          <div className='p3-bold header-4'>
            My Trainings
          </div>
        </div>
        <hr className='divider mt-2 mb-2' />
        <DataTable
          data={trainings}
          tableConfig={tableConfig}
          statusConfig={trainingStatusConfig}
          actionConfig={actionConfig}
          updatePagination={(data) => setPagination(data)}
          filterByStatus={true}
          computedActions={true}
          tableType={'table'}
          layoutSwitch={false}
          searchData={true}
          noCheck={true}
        />
      </div>
    </DashboardLayout>
  );
};

export default TrainingPage;
