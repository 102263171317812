import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DashboardLayout from "../../components/layouts/dashboard";
import PayslipSVG from "../../assets/svg/payslip.svg";
import EmployeeAwaitingApprovalSVG from "../../assets/svg/employee-awaiting-approval.svg";
import EmployeeSuspendedSVG from "../../assets/svg/employee-suspension.svg";
import AttendanceSVG from "../../assets/svg/attendance.svg";
import SalaryAdvanceSVG from "../../assets/svg/salary-advance.svg";
import ReimbursementsSVG from "../../assets/svg/reimbursements.svg";
import PerformanceSVG from "../../assets/svg/performance.svg";
import LeaveSVG from "../../assets/svg/employee-leave.svg";
import ExitSVG from "../../assets/svg/employee-suspension.svg";
import HmoSVG from "../../assets/svg/hmo.svg";
import BranchSVG from "../../assets/svg/branch-widget-icon.svg";
import DepartmentSVG from "../../assets/svg/department-widget-icon.svg";
import TeamSVG from "../../assets/svg/team-widget-icon.svg";
import OnboardingSVG from "../../assets/svg/employee-awaiting-approval.svg";
import TrainingSVG from "../../assets/svg/trainings.svg";
import OvertimeSVG from "../../assets/svg/overtime.svg";
import { Card } from "../../components/card";
import Button from "../../components/button";
import AddAttendance from "../../components/modal/hr-section/attendance/add-attendance";
import { useClockOut } from "../../redux/human-resources/hook/attendance";
import { store } from "../../redux";
import { setUser } from "../../redux/user/user-slice";
import { updateUser } from "../../redux/user/hook";

const Dashboard = () => {
  useEffect(() => {
    updateUser({ reload: false });
    window.scroll(0, 0);
  }, []);

  // const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userSlice);

  const [dashboardCards, setDashboardCards] = useState([]);
  const [clockedIn, setClockedIn] = useState(true);

  useEffect(() => {
    if (user) {
      if (user.company_id && user.company) {
        let company = user.company;
        if (company?.attendance?.clockout_date || !company?.attendance) {
          setClockedIn(false);
        }
        if (user.status == 1) {
          let data = [];
          if (company.onboarding == 1) {
            data.push({
              title: "ONBOARDING",
              path: `/onboarding`,
              icon: OnboardingSVG,
            });
          }
          let newData = [
            {
              title: "PAYSLIP",
              path: `/payslip`,
              icon: PayslipSVG,
            },
            {
              title: "SALARY ADVANCE",
              path: `/salary-advance`,
              icon: SalaryAdvanceSVG,
            },
            {
              title: "EXPENSE CLAIM",
              path: `/reimbursements`,
              icon: ReimbursementsSVG,
            },
            {
              title: "OVERTIME",
              path: `/overtimes`,
              icon: OvertimeSVG,
            },
          ];
          newData.forEach((item) => {
            data.push(item);
          });
          if (company.hmo_beneficiary == 1) {
            newData = [
              {
                title: "EMPLOYEE BENEFITS",
                path: `/hmo`,
                icon: HmoSVG,
              },
            ];
            newData.forEach((item) => {
              data.push(item);
            });
          }
          if (company.hr_subscription == 1) {
            newData = [
              {
                title: "ATTENDANCE",
                path: `/attendance`,
                icon: AttendanceSVG,
              },
              {
                title: "LEAVE",
                path: `/leave`,
                icon: LeaveSVG,
              },
              {
                title: "MY TEAMS",
                path: `/team`,
                icon: TeamSVG,
              },
              {
                title: "MY DEPARTMENTS",
                path: `/department`,
                icon: DepartmentSVG,
              },
              {
                title: "MY BRANCHES",
                path: `/branch`,
                icon: BranchSVG,
              },
              {
                title: "MY PERFORMANCE",
                path: `/performance`,
                icon: PerformanceSVG,
              },
              {
                title: "TRAINING",
                path: `/training`,
                icon: TrainingSVG,
              },
            ];
            newData.forEach((item) => {
              data.push(item);
            });
          }
          if (company.exit == 1) {
            newData = [
              {
                title: "EXIT",
                path: `/employee-exit/questions`,
                icon: ExitSVG,
              },
            ];
            newData.forEach((item) => {
              data.push(item);
            });
          }
          setDashboardCards(data);
        }
      } else {
        setDashboardCards([
          {
            title: "ADD COMPANY",
            path: `/add-company`,
            icon: OnboardingSVG,
          },
        ]);
      }
    }
  }, [user]);

  const [showAddAttendance, setshowAddAttendance] = useState(false);

  const { mutateAsync: clockOut, isLoading: clockOutloading } = useClockOut();

  const [long, setLong] = useState();
  const [lat, setLat] = useState();

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setLat(position.coords.latitude);
      setLong(position.coords.longitude);
    });
  }, []);

  const clockInOut = async () => {
    if (clockedIn) {
      let payload = {
        id: user?.company?.attendance?.id,
        data: {
          long: long ? long.toString() : "Not Set",
          lat: lat ? lat.toString() : "Not Set",
        },
      };
      await clockOut(payload).then((result) => {
        let newArray = JSON.parse(JSON.stringify(user));
        newArray.company.attendance = result;
        store.dispatch(setUser(newArray));
      });
    } else {
      setshowAddAttendance(true);
    }
  };

  return (
    <DashboardLayout title="Dashboard">
      <div className="flex flex-col h-full justify-start -mt-2">
        {showAddAttendance ? (
          <AddAttendance
            isOpen={showAddAttendance}
            closeModal={() => setshowAddAttendance(false)}
            title="Employee Clock In"
            buttonText="CLOCK IN"
          />
        ) : null}
        <div className="flex flex-col md:flex-row justify-between items-center gap-3">
          <div className="flex flex-col">
            <span className="header-3"> Welcome {user?.first_name},</span>
            <span className="text-[14px] text-gray-500">
              What do you want to do today?
            </span>
          </div>
          <div className="flex justify-end w-full md:w-[220px] md:mt-0 h-[35px] ">
            {user?.company && user?.status == 1 && !user.company.on_leave ? (
              <Button
                text={!clockedIn ? "Clock In" : "Clock out"}
                textClass={"text-[16px]"}
                className={!clockedIn ? "!bg-green-800" : "!bg-red-500"}
                type="button"
                loading={clockOutloading}
                onClick={() => clockInOut()}
              />
            ) : user?.company && user.company.on_leave ? (
              <span
                className={
                  "flex items-center justify-center height-[42px] text-[14px] text-semibold text-color-green-medium px-2 bg-color-green-light rounded border-[1px] border-color-green-medium animate-pulse duration-300"
                }
              >
                You are on leave
              </span>
            ) : null}
          </div>
        </div>
        {user?.status === 1 && user.company_id ? (
          <div
            id="dashboard-overview"
            className="grid md:grid-cols-4 lg:grid-cols-4 grid-cols-2 gap-4 w-full content-center justify-items-center justify-self-center mt-8"
          >
            {dashboardCards.map((item) => (
              <Card
                title={item.title}
                path={item.path}
                subtitle={item.value}
                icon={item.icon}
              />
            ))}
          </div>
        ) : user?.status === 4 && user.company_id ? (
          <div className="flex flex-col items-center justify-center mt-8 h-[300px] border rounded font-extrabold text-orange-400 gap-2">
            <img
              src={EmployeeAwaitingApprovalSVG}
              alt=""
              width={"80px"}
              height={"80px"}
            />
            Awaiting Activation
          </div>
        ) : user?.status === 5 && user.company_id ? (
          <div className="flex flex-col items-center justify-center mt-8 h-[300px] border rounded font-extrabold text-orange-400 gap-2">
            <img
              src={EmployeeAwaitingApprovalSVG}
              alt=""
              width={"80px"}
              height={"80px"}
            />
            Pre-registered
          </div>
        ) : user?.status === 3 && user.company_id ? (
          <div className="flex flex-col items-center justify-center mt-8 h-[300px] border rounded font-extrabold text-red-600 gap-2 w-full">
            <img
              src={EmployeeSuspendedSVG}
              alt=""
              width={"80px"}
              height={"80px"}
            />
            <span className="font-extrabold text-red-700 w-full text-center">
              Your employment record has been removed by this company.{" "}
            </span>
            <span className="font-extrabold text-red-700 w-full text-center">
              Please contact your company for more information.{" "}
            </span>
          </div>
        ) : (
          <div className="grid md:grid-cols-1 lg:grid-cols-1 grid-cols-1 gap-4 w-full content-center justify-items-center justify-self-center mt-8 min-h-[200px] min-w-full p-8">
            {dashboardCards.map((item) => (
              <Card
                title={item.title}
                path={item.path}
                subtitle={item.value}
                icon={item.icon}
              />
            ))}
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;
