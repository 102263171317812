import React from 'react';
import Loader from './loader';
import Icon from '../assets/svg';

const Button = ({
  className,
  textClass,
  text,
  theme = 'primary',
  leftIcon,
  rightIcon,
  loading,
  disabled,
  onClick,
  ...rest
}) => {

  let btnClass =
    theme === 'secondary'
      ? '!bg-color-white rounded-md border-secondary-2 border-[1.5px] flex items-center justify-center hover:opacity-80 duration-500'
      : theme === 'third'
      ? '!bg-[#44AF69] rounded-[5px] h-[55px] flex justify-center items-center hover:opacity-80 duration-500'
      : '!bg-secondary-2 rounded-[5px] h-[55px] flex justify-center items-center hover:opacity-80 duration-500';
  return (
    <button
      className={`select-none w-full shadow-md ${btnClass} ${className} ${
        loading && 'loading'
      }`}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {loading ? (
        <Loader color={className === 'btn_secondary' && 'white'} />
      ) : (
        <>
          {leftIcon && (
            <span className='left_icon'>
              <Icon name={leftIcon} />
            </span>
          )}
          <span
            className={`${
              theme === 'secondary' ?
               'text-secondary-2' : 
               'text-white'
            } !uppercase text-[13px] leading-[23.4px] font-bold ${textClass}`}
          >
            {text}
          </span>
          {rightIcon && (
            <span className='right_icon'>
              <Icon name={rightIcon} />
            </span>
          )}
        </>
      )}
    </button>
  );
};

export default Button;
