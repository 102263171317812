import classNames from 'classnames';
import React from 'react';

export const StatusText = ({ code, statusConfig, className }) => {
  return (
    <>
      {code !== undefined ? (
        <span
          className={classNames(
            className,
            'text-[10px]',
            {
              'text-color-green-medium py-1 px-2 bg-color-green-light rounded border-[1px] border-color-green-medium':
                statusConfig[code]?.color === 'green',
            },
            {
              'text-color-orange py-1 px-2 bg-color-orange-light rounded border-[1px] border-color-orange':
                statusConfig[code]?.color === 'orange',
            },
            {
              'text-color-error py-1 px-2 bg-color-error-light rounded border-[1px] border-color-error':
                statusConfig[code]?.color === 'red',
            },
          )}
        >
          {statusConfig[code]?.label ? statusConfig[code]?.label : 'None'}
        </span>
      ) : null}
    </>
  );
};
