import { user } from "../../api";
import { store } from "..";
import {
  clearToken,
  clearTwoFaToken,
  clearUser,
  setToken,
  setTwoFaToken,
  setUser,
} from "./user-slice";
import { Axios } from "../../api/axios";
import { queryClient } from "../..";
import { setAlert, setIsLoading } from "../components/components-slice";
import { setCompany } from "../company/reducer";
import { useMutation } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";
import history from "../../routes/history";
import { setEmployee } from "../employee/reducer";

export function useLogin() {
  const history = useHistory();
  return useMutation(
    (payload) => {
      return user.login(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        // console.log(response)
        if (response?.data?.token) {
          store.dispatch(setTwoFaToken(response?.data));
        } else {
          console.log(response.data);
          store.dispatch(setToken(response.data.authorization.token));
          store.dispatch(setUser(response.data));
          store.dispatch(setCompany(response.data.company));
          store.dispatch(setEmployee(response.data.company.employee));
          store.dispatch(
            setAlert(true, "success", "You have successfully logged in")
          );
          history.push("/dashboard");
        }
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useSignUp() {
  return useMutation(
    (payload) => {
      return user.register(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setToken(response.data.authorization.token));
        store.dispatch(setUser(response.data));
        store.dispatch(setCompany(response.data.company));
        store.dispatch(
          setAlert(true, "success", "You have successfully signed up")
        );
        history.push("/dashboard");
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useRecoveryPassword() {
  return useMutation(
    (payload) => {
      return user.passwordRecover(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, "success", response.message));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useVerifyEmail() {
  return useMutation(
    (payload) => {
      return user.verifyEmail(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, "success", response.message));
      },
    }
  );
}

export function usePhoneVerification() {
  return useMutation(
    (payload) => {
      return user.phoneVerification(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries("company-verification");
        store.dispatch(setAlert(true, "success", response.message));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useBVNVerification() {
  return useMutation(
    (payload) => {
      return user.bvnVerification(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries("company-verification");
        store.dispatch(setAlert(true, "success", response.message));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useResendEmailVerification() {
  return useMutation(
    (payload) => {
      return user.resendEmailVerification(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, "success", response.message));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useResetPassword() {
  return useMutation(
    (payload) => {
      return user.passwordReset(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, "success", response.message));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useSetPassword() {
  return useMutation(
    (payload) => {
      return user.adminPasswordSet(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, "success", response.message));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useEnable2Fa() {
  return useMutation(
    (payload) => {
      return user.enable2Fa(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(
          setAlert(
            true,
            "success",
            "Two-factor Authentication initiated successfully."
          )
        );
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useActivate2Fa() {
  return useMutation(
    (payload) => {
      return user.activate2fa(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        updateUser({ reload: false });
        store.dispatch(
          setAlert(
            true,
            "success",
            "Two-factor Authentication Activated successfully."
          )
        );
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useDisable2Fa() {
  return useMutation(
    (payload) => {
      return user.disable2Fa(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        updateUser({ reload: false });
        store.dispatch(
          setAlert(
            true,
            "success",
            "Two-factor Authentication disabled successfully."
          )
        );
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useLogin2Fa() {
  const history = useHistory();
  return useMutation(
    (payload) => {
      return user.login2Fa(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        // console.log(response)
        store.dispatch(setToken(response.data.authorization.token));
        store.dispatch(setUser(response.data));
        store.dispatch(setCompany(response.data.company));
        store.dispatch(clearTwoFaToken());
        store.dispatch(
          setAlert(true, "success", "You have successfully logged in")
        );
        history.push("/dashboard");
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useBackupLogin2Fa() {
  const history = useHistory();
  return useMutation(
    (payload) => {
      return user.backupLogin2Fa(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        // console.log(response)
        store.dispatch(setToken(response.data.authorization.token));
        store.dispatch(setUser(response.data));
        store.dispatch(setCompany(response.data.company));
        store.dispatch(clearTwoFaToken());
        store.dispatch(
          setAlert(true, "success", "You have successfully logged in")
        );
        history.push("/dashboard");
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function usePasswordUpdate() {
  return useMutation(
    (payload) => {
      return user.updatePassword(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setToken(response.data.authorization.token));
        store.dispatch(setAlert(true, "success", response.message));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export const updateUser = ({ reload = false, route = "" }) => {
  user.profile().then((response) => {
    let user = JSON.parse(JSON.stringify(response.data));

    store.dispatch(setUser(user));
    store.dispatch(setCompany(user.company));

    setTimeout(() => {
      if (reload) {
        window.location.reload(true);
      }
      if (route) {
        window.location.replace(route);
      }
    }, 2000);
  });
};

export const updateUserPassword = async (payload) => {
  // store.dispatch(setIsLoading(true));
  user
    .updatePassword(payload)
    .then((response) => {
      store.dispatch(setCompany(response.data?.company));
      store.dispatch(setUser(response.data));
    })
    .finally(() => {
      // store.dispatch(setIsLoading(false));
    });
};

export const logout = async () => {
  store.dispatch(clearUser());
  store.dispatch(clearToken());
  store.dispatch(clearTwoFaToken());
  queryClient.invalidateQueries();
  delete Axios.defaults.headers.common["Authorization"];
  store.dispatch(setAlert(true, "warning", "You have successfully logged out"));
  history.push("/");
};
