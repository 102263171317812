import React from "react";

export const TimeCircle = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Iconly/Broken/Time Circle">
      <g id="Time Circle">
        <path
          id="Time Circle_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.99976 11.9998C1.99976 6.48476 6.48576 1.99976 11.9998 1.99976C17.5138 1.99976 21.9998 6.48476 21.9998 11.9998C21.9998 17.5138 17.5138 21.9998 11.9998 21.9998C8.87276 21.9998 5.98276 20.5768 4.07276 18.0978C3.82776 17.7788 3.88776 17.3218 4.20576 17.0778C4.52376 16.8318 4.97876 16.8928 5.22476 17.2098C6.85776 19.3298 9.32676 20.5458 11.9998 20.5458C16.7118 20.5458 20.5458 16.7128 20.5458 11.9998C20.5458 7.28776 16.7118 3.45376 11.9998 3.45376C7.28776 3.45376 3.45476 7.28776 3.45476 11.9998C3.45476 12.4378 3.48676 12.8728 3.55176 13.2988C3.61176 13.6948 3.33976 14.0658 2.94276 14.1268C2.54376 14.1928 2.17476 13.9158 2.11476 13.5188C2.03876 13.0208 1.99976 12.5098 1.99976 11.9998ZM10.9339 7.83716C10.9339 7.43516 11.2599 7.10916 11.6609 7.10916C12.0619 7.10916 12.3879 7.43516 12.3879 7.83716V12.2832L15.8119 14.3252C16.1569 14.5312 16.2699 14.9772 16.0639 15.3222C15.9289 15.5492 15.6869 15.6762 15.4399 15.6762C15.3129 15.6762 15.1839 15.6432 15.0679 15.5742L11.2879 13.3202C11.0689 13.1882 10.9339 12.9512 10.9339 12.6952V7.83716Z"
          fill="#130F26"
        />
      </g>
    </g>
  </svg>
);
