import React from 'react';
import ProtectedRoute from './protected';
import ExitQuestions from '../pages/employee-exit/exit-questions';
import ExitVerified from '../pages/employee-exit/exit';

export const EmployeeExitRoutes = [
  <ProtectedRoute
    exact
    path='/employee-exit/questions'
    component={ExitQuestions}
  />,
  <ProtectedRoute
    exact
    path='/employee-exit/stages'
    component={ExitVerified}
  />,
  
  
];
