import React, { useEffect, useState } from 'react';
import Sidebar from '../sidebar';
import { OffcanvasBody, Offcanvas } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import ProfileMenu from '../profile-menu';
import MenuSVG from '../../assets/svg/menu.svg';
import { toggleSidebar } from '../../redux/components/components-slice';
import { useHistory, useLocation } from 'react-router-dom';
import CollapsingIcon from '../collapsing-icon';

const DashboardLayout = ({ children, title }) => {
  const dispatch = useDispatch();
  const { isSidebarOpen } = useSelector((state) => state.componentsSlice);
  const { user } = useSelector((state) => state.userSlice);

  const sidebarWidth = '255px';

  const location = useLocation();
  const history = useHistory()

  useEffect(() => {
    if (user) {
      if (user.company_id && user.company) {
        if (user.status == 2) {
          history.push('/registration/profile')
        }
      }
    }
  }, [user])

  const handleSidebarToggle = () => {
    dispatch(toggleSidebar());
  };

  return (
    <>
      <>
        <div>
          <Offcanvas
            toggle={() => handleSidebarToggle()}
            isOpen={isSidebarOpen}
            style={{ width: sidebarWidth }}
          >
            <OffcanvasBody className='!p-0'>
              <Sidebar />
            </OffcanvasBody>
          </Offcanvas>
        </div>
        <div className='flex flex-row max-h-screen'>
          <div
            className='hidden lg:flex md:flex !min-w-[255px] h-screen inset-0'
            // style={{ width: sidebarWidth }}
          >
            <Sidebar switchLoading={false} />
          </div>
          <div className='w-full flex flex-col bg-secondary-2-light overflow-auto'>
            <div className='!opacity-100'>
              <div className='ml-0 bg-white h-[65px] w-full flex items-center justify-between pl-4 md:pl:0 md:px-[30px] md:border-l-2 border-secondary-2-light '>
                <div className='flex flex-row items-center'>
                  <button onClick={() => handleSidebarToggle()}>
                    <img src={MenuSVG} alt='menu' className='flex lg:hidden' />
                  </button>
                  {location.pathname !== '/dashboard'
                  ?
                  <button className='hidden md:block' onClick={() => history.goBack()}>
                    <CollapsingIcon
                      defaultPosition='right'
                      isopen={true}
                      dashboard={true}
                    />
                  </button>
                  : null}
                  <span className={'hidden md:block header-4 text-secondary-2 pl-3'}>
                    {title}
                  </span>
                </div>

                <div className='flex flex-row -mr-3 items-center justify-center'>
                  <div className='hidden md:flex'>
                    <ProfileMenu />
                  </div>
                </div>
              </div>
              {/* Page Component */}
              <div className='w-full h-full p-8'>
                {children}
              </div>
            </div>
            
          </div>
        </div>
      </>
    </>
  );
};

export default DashboardLayout;
