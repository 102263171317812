import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import FormInput from '../../components/form-input';
import Button from '../../components/button';
import { formValidation } from '../../utils/functions';
import AuthLayout from '../../components/layouts/auth';
import { useSignUp } from '../../redux/user/hook';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import GoogleImage from'../../assets/img/google-play-badge.png';
import AppleImage from'../../assets/svg/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';

const RegisterPage = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });
  
  const [Password, setPassword] = useState('');
  const [terms, setTerms] = useState();
  const [confirmPassword, setConfirmPassword] = useState('');
  const { mutate: signUp, isLoading: loading } = useSignUp();
  const { webToken } = useSelector((state) => state.userSlice);

  const submitForm = async (data) => {
    const first_name = DOMPurify.sanitize(data?.first_name);
    const last_name = DOMPurify.sanitize(data?.last_name);
    const mobile = DOMPurify.sanitize(data?.mobile);
    const email = DOMPurify.sanitize(data?.email);
    const password = DOMPurify.sanitize(data?.password);
    const password_confirmation = DOMPurify.sanitize(data?.confirm_password);
    const web_token = webToken? webToken : 'no_token';

    const requestData = {
      first_name,
      last_name,
      email,
      password,
      mobile,
      terms: terms? 'on': '',
      password_confirmation,
      web_token,
      source: 'web',
      device: 'Web',

    };

    signUp(requestData);
  };

  return (
    <AuthLayout>
      <React.Fragment>
        <form className='form' onSubmit={handleSubmit(submitForm)}>
          <div className='w-full text-center flex flex-col pb-[10px]'>
            <span className={'header-3-regular md:header-2 text-secondary-2'}>
              Create your Ropay account
            </span>
            <span
              className={'header-4 text-secondary-2 pb-1'}
            >
              Employee self service portal
            </span>
          </div>
          <div className='flex flex-col gap-2 p-2 border bg-secondary-2-extralight rounded-md'>
            <div className='text-secondary-2 font-semibold flex justify-center w-full '>Register via mobile app</div>
            <div className='flex md:flex-row flex-col gap-2 w-full px-3'>
              <a href="https://play.google.com/store/apps/details?id=com.ropay.app" className='mb-1' target='_blank'>
                  <img width="220" src={GoogleImage} alt="android"/>
              </a>
              <a href="https://apps.apple.com/ng/app/ropay-mobile/id1673798586" className='mt-[11px]' target='_blank'>
                  <img width="220" src={AppleImage} alt="ios"/>
              </a>
            </div>
          </div>
          <div className='flex justify-center items-center flex-row mt-[10px] font-bold text-[15px] mb-[10px]'>
            <div className='bg-color-border w-[100%] h-[1px] mr-[6px]'></div>
            <p className='!text-[18px]'>or</p>
            <div className='bg-color-border w-[100%] h-[1px] ml-[6px]'></div>
          </div>
          <FormInput
            label='First name'
            name='first_name'
            type='text'
            placeholder={'Enter your First Name'}
            inputRef={register(formValidation('text', true))}
            readOnly={loading}
            error={errors.first_name}
            errorMessage={errors.first_name && errors.first_name.message}
          />
          <FormInput
            label='Last name'
            name='last_name'
            type='text'
            placeholder={'Enter your Last Name'}
            inputRef={register(formValidation('text', true))}
            readOnly={loading}
            error={errors.last_name}
            errorMessage={errors.last_name && errors.last_name.message}
          />

          <FormInput
            label='Phone number'
            name='mobile'
            type='text'
            placeholder={'Enter your mobile number'}
            inputRef={register(formValidation('text', true))}
            readOnly={loading}
            error={errors.mobile}
            errorMessage={errors.mobile && errors.mobile.message}
          />
          <FormInput
            label='Email address'
            name='email'
            type='email'
            placeholder={'Enter your Email'}
            inputRef={register(formValidation('email', true))}
            readOnly={loading}
            error={errors.email}
            errorMessage={errors.email && errors.email.message}
          />
          <FormInput
            label='Password'
            name='password'
            type='password'
            placeholder={'Enter your Password'}
            inputRef={register(formValidation('password', true))}
            readOnly={loading}
            error={errors.password}
            errorMessage={errors.password && errors.password.message}
            onInput={(e) => setPassword(e.target.value)}
            infos={[
              'Password should contain minimum of 8 characters, 1 caps letter and 1 number',
            ]}
          />

          <FormInput
            label='Confirm Password'
            name='confirm_password'
            type='password'
            placeholder={'Confirm your password'}
            inputRef={register(formValidation('password', true))}
            onInput={(e) => setConfirmPassword(e.target.value)}
            readOnly={loading}
            error={confirmPassword !== Password && confirmPassword.trim()}
            errorMessage={'Password does not match'}
          />

          <div className='d_flex remember_section'>
            <div className='flex flex-row'>
              <FormInput
                label=''
                name='agreement'
                type='checkbox'
                inputRef={register(formValidation('checkbox', true))}
                readOnly={loading}
                onInput={(e) => {
                  setTerms(e.target.checked)
                }}
                error={errors.agreement}
                errorMessage={errors.agreement && errors.agreement.message}
                CustomLabel={
                  <span className={'p4-medium text-secondary-2 mt-1 pl-3'}>
                    I agree with{' '}
                    <a href='https://www.ropay.africa/terms-of-service' target='_blank' rel='noreferrer'
                      className='cursor-pointer hover:underline p4-bold'
                    >
                      terms & conditions
                    </a>
                  </span>
                }
              />
            </div>
          </div>

          <Button
            text='Sign up'
            type='submit'
            loading={loading}
            disabled={!isValid && !terms}
          />
        </form>
        <span className='flex justify-center items-center text-[14px] text-color-gray mt-4'>
          Already have an account?&ensp;
          <Link to='/'>
            <span className='text-[15px] font-extrabold cursor-pointer hover:underline'>
              Sign In
            </span>
          </Link>
        </span>
      </React.Fragment>
    </AuthLayout>
  );
};

export default RegisterPage;
