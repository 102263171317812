import React, { useEffect, useState } from 'react';
import FormTextArea from '../../../form-textarea';
import Button from '../../../button';
import { useForm } from 'react-hook-form';
import FormInput from '../../../form-input';
import moment from 'moment';
import AddSmallSVG from '../../../../assets/svg/add-icon-small.svg';
import { useCommentOnTraining } from '../../../../redux/employee/hook/training';

export const TrainingRemarks = ({employeeData}) => {

    const {
        formState: { isValid },
        errors,
        } = useForm({
        mode: 'onChange',
    });

    const [addRemark, setAddRemark] = useState(false);
    const [remark, setRemark] = useState()
    const [trainingDate, setTrainingDate] = useState(moment(employeeData?.training_start).format("YYYY-MM-DD"))
    const { mutateAsync: commentOnTraining, commentOnTrainingLoading } = useCommentOnTraining();

    const submitForm = async () => {
        let payload = {
            id: employeeData?.training_id,
            data: {
                remark: remark,
                training_date: trainingDate
            }
            
        }
        await commentOnTraining(payload).then(()=>{
            setRemark('')
            setAddRemark(false)
        })  
    }

    return (
        <div className={`min-h-[120px] flex flex-col  gap-2 justify-start bg-white rounded-[5px] border border-[#dbdce3] shadow-sm`}>
            <div className='flex flex-row justify-between items-center bg-blue-100 p-3 font-semibold text-[14px] text-color-black'>
                Training remarks
            </div>
            {addRemark?
            <form className='form p-8 pt-2'>
                <div className='flex flex-col justify-between gap-3'>
                    <div className='w-full'>
                        <FormInput
                            label='Comment For?'
                            name='training_date'
                            type='date'
                            min={moment(employeeData?.training_start).format("YYYY-MM-DD")}
                            value={trainingDate}
                            onInput={(e) => {
                                setTrainingDate(e.target.value)
                            }}
                            max={moment(employeeData?.training_end).format("YYYY-MM-DD")}
                        />
                    </div>
                    <div className='w-full'>
                        <FormTextArea
                            label='Comment'
                            name='remark'
                            value={remark}
                            placeholder={'Enter comment here'}
                            onInput={(e) => {
                                setRemark(e.target.value)
                            }}
                            type='text'
                            className=' text-[14px]'
                        />
                    </div>
                </div>
                <div className='flex md:flex-row justify-end gap-4'>
                    <Button
                        text='Cancel' 
                        type='button' 
                        onClick={() => {
                            setRemark('')
                            setAddRemark(false)
                        }}
                        textClass={'!text-[13px]'}
                        className={'h-[35px] w-[100px]'}
                        theme={'secondary'} 
                    />
                    <Button
                        text={'Add'}
                        type='button' 
                        onClick={() => { submitForm() }}
                        textClass={'!text-[13px]'}
                        className={'h-[35px] w-[100px]'}
                        loading={commentOnTrainingLoading} 
                    />
                </div>
            </form>:
            <>
            {employeeData?.remarks && employeeData?.remarks.length > 0?
            <div className='flex flex-col pt-3 !max-h-[500px] overflow-y-auto'>
                <div className='flex flex-row justify-end items-center gap-1 text-right pr-8 italic text-hr-primary-1 text-[14px] mb-2'>
                    <img src={AddSmallSVG} alt={'add_note'} className={'h-[15px] w-[15px]'} />
                    <span 
                        className='italic text-secondary-2 text-[13px] hover:underline hover:text-[14px] duration-300 cursor-pointer font-bold' 
                        onClick={()=> {
                            setRemark('')
                            setAddRemark(true)
                        }}
                    >
                        Add remark
                    </span>
                </div>
                {employeeData?.remarks?.map((item, index) => (
                    <div>
                        <div className='flex md:flex-row  items-end pl-8 pr-8 pb-3 justify-between gap-3'>
                            <div className='flex flex-col text-gray-500 p-3 border rounded-md w-full shadow-sm hover:bg-gray-50 duration-300'>
                                <div className='flex items-center header-5 text-gray-700 pb-1'>Day {item.training_day} - &nbsp; <span className='text-[11px] text-gray-500'>({moment(item.training_date).format('MMMM DD, YYYY')})</span></div>
                                <div className='text-[12px] text-gray-600'>{item.remark}</div>
                            </div>
                            
                        </div>
                    </div>
                ))}
            </div>
            :
            <div className='text-center w-[100%] p-3 italic text-secondary-2 text-[14px]'>
                <span 
                className='text-center w-[100%] p-3 italic text-secondary-2 text-[14px] hover:underline hover:text-[16px] duration-300 cursor-pointer font-semibold' 
                onClick={()=> {
                    setRemark('')
                    setAddRemark(true)
                }}
                >
                    Add remark
                </span>
            </div>
            }
            </>}
            
        </div>
    );
};