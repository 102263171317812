import React, { useState } from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import "react-big-calendar/lib/css/react-big-calendar.css"
import { useSelector } from 'react-redux';
import SignInPage from './pages/auth/sign-in';
import RegisterPage from './pages/auth/register';
import Alert from './components/alert';
import { useEffect } from 'react';
import { AdminRoutes } from './routes/admin';
import { isLoading, setIsLoading, updateModalStatus, } from './redux/components/components-slice';
import { useDispatch } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import PageNotFound from './pages/errors/page-not-found';
import ForgotPasswordPage from './pages/auth/forgot';
import ResetPassword from './pages/auth/reset-password';
import CheckEmailPassword from './pages/auth/check-email';
// import SetPassword from './pages/auth/set-password';
import GuestRoute from './routes/guest';
import VerifyEmail from './pages/auth/verify-email';
import NotFoundRoute from './routes/not-found';
import classNames from 'classnames';
import OnboardingProvider from './components/provider/onboarding';
import history from './routes/history';
import { getPushNotificationToken } from './utils/push-notifications/index';
import Verify2Fa from './pages/auth/verify-token';

const App = () => {
  const { alert, isModalOpen } = useSelector((state) => state.componentsSlice);
  const loading = useSelector(isLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    getPushNotificationToken();

    //stop any interrupted loader on reload
    if (loading) {
      dispatch(setIsLoading(false));
    }
    if (isModalOpen) {
      dispatch(updateModalStatus(false));
    }
  }, []);

  return (
    <OnboardingProvider>
      <React.Fragment>
        <div className={classNames({ 'blur-sm': isModalOpen })}>
          <Router history={history}>
            <Switch>
              <GuestRoute exact path='/' component={SignInPage} />
              <GuestRoute exact path='/register' component={RegisterPage} />
              <GuestRoute exact path='/forgot' component={ForgotPasswordPage} />
              <GuestRoute
                exact
                path='/check-email'
                component={CheckEmailPassword}
              />
              <GuestRoute
                exact
                path='/reset-password/:token'
                component={ResetPassword}
              />

              <GuestRoute
                exact
                path='/verify-email/:token'
                component={VerifyEmail}
              />
              <GuestRoute
                exact
                path='/verify-2fa/:token'
                component={Verify2Fa}
              />
              {AdminRoutes()}
              {/* 
            <AdminRoutes /> */}
              <NotFoundRoute path='*' component={PageNotFound} />
            </Switch>
          </Router>
        </div>

        {/* Other components */}
        {alert?.show && (
          <Alert
            type={alert?.type}
            message={alert?.message}
          />
        )}
      </React.Fragment>
    </OnboardingProvider>
  );
};

export default App;
