import { Axios } from '../axios';

const evaluatePerformance = async (payload) => {
  const { data } = await Axios.post('/mobile/performance-evaluations', payload);
  return data;
};

const advancedEvaluatePerformance = async (payload) => {
  const { data } = await Axios.post('/mobile/performance-evaluations/advanced', payload);
  return data;
};

const getPerformances = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  let source = payload.source ? '&source=' + payload.source : "&source=''";
  let source_id = payload.source_id ? '&source_id=' + payload.source_id : null;
  const { data } = await Axios.get(
    `/mobile/performances?limit=${payload.limit}&page=${payload.page}${filter}${search}${source_id}${source}`,
  );
  return data?.data;
};

const getPerformancesBySource = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  let source = payload.source ? '&source=' + payload.source : "&source=''";
  let source_id = payload.source_id ? '&source_id=' + payload.source_id : null;
  const { data } = await Axios.get(
    `/mobile/performances/by-source?limit=${payload.limit}&page=${payload.page}${filter}${search}${source_id}${source}`,
  );
  return data?.data;
};

const getPerformanceSummary = async () => {
  const { data } = await Axios.post('/mobile/performances/summary');
  return data?.data;
};

const gradeBasicPerformance = async (payload) => {
  const { data } = await Axios.post(
    '/mobile/performance-evaluations/grade-basic/' + payload.id,
    payload.data,
  );
  return data;
};

const updateTrainingRequirements = async (payload) => {
  const { data } = await Axios.post(
    '/mobile/performance-evaluations/update-training-requirement/' + payload.id,
    payload.data,
  );
  return data;
};

const updateReviewerComment = async (payload) => {
  const { data } = await Axios.post(
    '/mobile/performance-evaluations/update-reviewer-comment/' + payload.id,
    payload.data,
  );
  return data;
};

const getPerformanceWorkflows = async (payload) => {

  let search = payload.search ? '&search=' + payload.search : '';
  const { data } = await Axios.get(
    `/mobile/performances/workflows?limit=${payload.limit}&page=${payload.page}${search}`,
  );
  return data?.data;
};

const getPerformanceWorkflow = async (payload) => {
  const { data } = await Axios.get(
    '/performance-evaluation/workflows/' + payload,
  );
  return data?.data;
};

const addPerformanceWorkflow = async (payload) => {
  const { data } = await Axios.post(
    '/mobile/performance-evaluation/workflows',
    payload,
  );
  return data;
};

const updatePerformanceWorkflow = async (payload) => {
  const { data } = await Axios.put(
    '/mobile/performance-evaluation/workflows/' + payload.id,
    payload.data,
  );
  return data;
};

const getPerformance = async (payload) => {
  const { data } = await Axios.get('/mobile/performance/' + payload);
  return data?.data;
};

const getPerformanceBySource = async (payload) => {
  const { data } = await Axios.get('/mobile/performance/by-source/' + payload);
  return data?.data;
};

const getPerformancesByEmployee = async (payload) => {
  let start_date = payload.start_date != -1 ? '&start_date=' + payload.start_date : '';
  let end_date = payload.end_date != -1 ? '&end_date=' + payload.end_date : '';
  let search = payload.search ? '&search=' + payload.search : '';
  const { data } = await Axios.get(`/mobile/performance-evaluations/by-employee/${payload.employee_id}?limit=${payload.limit}&page=${payload.page}${search}${start_date}${end_date}`,);
  return data?.data;
};

const deletePerformance = async (payload) => {
  const { data } = await Axios.delete(`/mobile/performance-evaluations/${payload}`);
  return data;
};

//OKRS
const getPerformanceOkrs = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let source = payload.source ? '&source=' + payload.source : "&source=''";
  let source_id = payload.source_id ? '&source_id=' + payload.source_id : null;
  const { data } = await Axios.get(
    `/mobile/performance-evaluation-okrs?limit=${payload.limit}&page=${payload.page}${search}${source}${source_id}`,
  );
  return data?.data;
};

const addPerformanceOKR = async (payload) => {
  const { data } = await Axios.post(
    '/mobile/performance-okrs',
    payload,
  );
  return data;
};

const updatePerformanceOKR = async (payload) => {
  const { data } = await Axios.put(
    '/mobile/performance-okrs/' + payload.id,
    payload.data,
  );
  return data;
};

const deletePerformanceOKR = async (payload) => {
  const { data } = await Axios.delete(`/mobile/performance-okrs/${payload}`);
  return data;
};

//Smart Goals
const getPerformanceSmartGoals = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let source = payload.source ? '&source=' + payload.source : "&source=''";
  let source_id = payload.source_id ? '&source_id=' + payload.source_id : null;
  const { data } = await Axios.get(
    `/mobile/performance-evaluation-smart-goals?limit=${payload.limit}&page=${payload.page}${search}${source}${source_id}`,
  );
  return data?.data;
};

const addPerformanceSmartGoals = async (payload) => {
  const { data } = await Axios.post(
    '/mobile/performance-evaluation-smart-goals',
    payload,
  );
  return data;
};

const updatePerformanceSmartGoals = async (payload) => {
  const { data } = await Axios.put(
    '/mobile/performance-evaluation-smart-goals/' + payload.id,
    payload.data,
  );
  return data;
};

const gradeSmartGoals = async (payload) => {
  const { data } = await Axios.post(
    '/mobile/performance/evaluate/employee-grade-smart-goal',
    payload,
  );
  return data;
};

const gradeOkrs = async (payload) => {
  const { data } = await Axios.post(
    '/mobile/performance/evaluate/employee-grade-okr',
    payload,
  );
  return data;
};

const gradeBalancedScoreCard = async (payload) => {
  const { data } = await Axios.post(
    '/mobile/performance/evaluate/employee-grade-balanced-scorecard',
    payload,
  );
  return data;
};

const teamLeadGradeBalancedScoreCard = async (payload) => {
  const { data } = await Axios.post(
    '/mobile/performance/evaluate/team-lead-grade-balanced-scorecard',
    payload,
  );
  return data;
};



//Technical Appraisals
const getTechnicalAppraisals = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let source = payload.source ? '&source=' + payload.source : "&source=''";
  let source_id = payload.source_id ? '&source_id=' + payload.source_id : null;
  const { data } = await Axios.get(
    `/mobile/technical-appraisals?limit=${payload.limit}&page=${payload.page}${search}${source}${source_id}`,
  );
  return data?.data;
};

const addTechnicalAppraisal = async (payload) => {
  const { data } = await Axios.post(
    '/mobile/technical-appraisals',
    payload,
  );
  return data;
};

const updateTechnicalAppraisal = async (payload) => {
  const { data } = await Axios.put(
    '/mobile/technical-appraisals/' + payload.id,
    payload.data,
  );
  return data;
};

const gradeTechnicalAppraisal = async (payload) => {
  const { data } = await Axios.post(
    '/mobile/performance/evaluate/employee-grade-technical-appraisal',
    payload,
  );
  return data;
};

const teamLeadGradeTechnicalAppraisal = async (payload) => {
  const { data } = await Axios.post(
    '/mobile/performance/evaluate/team-lead-grade-technical-appraisal',
    payload,
  );
  return data;
};

const gradeOrganisationalAssessments = async (payload) => {
  const { data } = await Axios.post(
    '/mobile/performance/evaluate/employee-grade-organisational-appraisals',
    payload,
  );
  return data;
};

const teamLeadGradeSmartGoals = async (payload) => {
  const { data } = await Axios.post(
    '/mobile/performance/evaluate/team-lead-grade-smart-goal',
    payload,
  );
  return data;
};

const teamLeadGradeOkrs = async (payload) => {
  const { data } = await Axios.post(
    '/mobile/performance/evaluate/team-lead-grade-okr',
    payload,
  );
  return data;
};

const teamLeadGradeOrganisationalAssessments = async (payload) => {
  const { data } = await Axios.post(
    '/mobile/performance/evaluate/team-lead-grade-organisational-appraisals',
    payload,
  );
  return data;
};

export const performances = {
  evaluatePerformance,
  advancedEvaluatePerformance,
  getPerformances,
  getPerformancesBySource,
  getPerformanceSummary,
  gradeBasicPerformance,
  getPerformance,
  getPerformanceBySource,
  deletePerformance,
  getPerformancesByEmployee,
  getPerformanceWorkflows,
  getPerformanceWorkflow,
  addPerformanceWorkflow,
  updatePerformanceWorkflow,

  updateTrainingRequirements,
  updateReviewerComment,

  getPerformanceOkrs,
  addPerformanceOKR,
  updatePerformanceOKR,
  deletePerformanceOKR,

  getPerformanceSmartGoals,
  addPerformanceSmartGoals,
  updatePerformanceSmartGoals,


  gradeSmartGoals,
  gradeOkrs,
  gradeOrganisationalAssessments,
  teamLeadGradeSmartGoals,
  teamLeadGradeOkrs,
  teamLeadGradeOrganisationalAssessments,

  getTechnicalAppraisals,
  addTechnicalAppraisal,
  updateTechnicalAppraisal,
  gradeTechnicalAppraisal,
  teamLeadGradeTechnicalAppraisal,

  gradeBalancedScoreCard,
  teamLeadGradeBalancedScoreCard
};
