import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { store } from '../..';
import { queryClient } from '../../..';
import { branches } from '../../../api/hr/branches';
import { setAlert, setIsLoading } from '../../components/components-slice';

export function useGetBranches() {
    return useQuery(
      ['branches'],
      () => {
        store.dispatch(setIsLoading(true));
        return branches.getBranches();
      },
      {
        select: (data) => {
          data.forEach((branch) => {
            branch.created = moment(branch.created).format('MMMM D, YYYY');
            branch.computedActions = [0,1,2]
            branch.branch_manager = branch.manager_setting.manager
            if (branch && branch.description && branch.description.length >= 30) {
              branch.description = branch.description.substring(0, 30) + '....';
              
            }
          });
          return data;
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
      },
    );
}

export function useGetOnboardingBranches() {
  return useQuery(
    ['onboarding-branches'],
    () => {
      store.dispatch(setIsLoading(true));
      return branches.getBranches();
    },
    {
      select: (data) => {
        let newData = [];
        newData.push({ value: '', label: 'No Branch Selected' });
        data.forEach((item) => {
          newData.push({ value: item.id, label: item.name });
        });
        return newData;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
    
  );
}

export function useGetBranch(id) {
    return useQuery(
        ['branch', id],
        () => {
            return branches.getBranch( id );
        },
        {
          enabled: id !== null,
          onSettled: (data, error, variables, context) => {
              store.dispatch(setIsLoading(false));
          },
        },
    );
}

//Branch Employees
export function useGetBranchEmployees(id, limit, page, statusFilter, search) {
    return useQuery(
      ['branch-employees', { id, limit, page, statusFilter, search }],
      () => {
        store.dispatch(setIsLoading(true));
        return branches.getBranchEmployees({
          id,
          limit,
          page,
          statusFilter,
          search,
        });
      },
      {
        
        enabled: id !== null,
        select: (data) => {
          data.employees.name = data.name;
          data.employees.id = data.id;
          if (data.employees.data) {
            data.employees.data.forEach((employee) => {
              employee.created = moment(employee.created).format('MMMM D, YYYY');
              employee.computedActions = [0]
              employee.employeeName = `${employee.employee_first_name} ${employee.employee_last_name}`;
            });
          }
          return data?.employees;
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        
      },
    );
}

//Branch Teams
export function useGetBranchTeams(id, limit, page, search) {
  return useQuery(
    ['branch-teams', { id, limit, page, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return branches.getBranchTeams({
        id,
        limit,
        page,
        search,
      });
    },
    {
      enabled: id !== null,
      select: (data) => {
        data.data.forEach((item) => {
          item.created = moment(item.created).format('MMMM D, YYYY')
          item.computedActions = [0]
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      
    },
  );
}

//Branch Departments
export function useGetBranchDepartments(id, limit, page, search) {
    return useQuery(
      ['branch-departments', { id, limit, page, search }],
      () => {
        store.dispatch(setIsLoading(true));
        return branches.getBranchDepartments({
          id,
          limit,
          page,
          search,
        });
      },
      {
        
        enabled: id !== null,
        select: (data) => {
          data.data.forEach((item) => {
            item.created = moment(item.created).format('MMMM D, YYYY');
            item.computedActions = [0]
          });
          return data;
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        
      },
    );
}

export function useGetBranchWalletHistory(id, limit, page, statusFilter, search, start_date, end_date,  type ) {
  return useQuery(
    ['branch-wallet-history', { id, limit, page, statusFilter, search, start_date, end_date,  type }],
    () => {
      store.dispatch(setIsLoading(true));
      return branches.getBranchWalletHistory({
        id,
        limit,
        page,
        statusFilter, 
        search, 
        start_date,
        end_date,
        type
      });
    },

    {
      select(data) {
        data?.data?.forEach((data) => {
          data.created = data.created.split('.')[0].replace('T', ' ')
          if (data.type === 'wallet') {
            data.status = !data.status || data.status == 0 ? 1: data.status
            data.credit = parseFloat(data.amount).toFixed(2);
            data.debit = ''
            data.name = "Wallet Deposit"
            data.fees = ''
          } else {
            data.status = !data.status? 0: data.status
            data.credit = ''
            data.debit = parseFloat(data.amount).toFixed(2);
          }
          switch (data.status) {
            case 0:
              data.computedActions = [0];
              break;
            case 2:
              data.computedActions = [1];
              break;
            default:
              break;
          }
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRetryTransaction() {
  return useMutation(
    (payload) => {
      return branches.retryTransaction(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('branch-wallet-history');
        store.dispatch(setAlert(true, 'success', 'Transaction retried successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useCheckTransactionStatus() {
  return useMutation(
    (payload) => {
      return branches.checkTransactionStatus(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('branch-wallet-history');
        store.dispatch(setAlert(true, 'success', 'Transaction Status Updated Successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useCheckBulkTransactionStatus() {
  return useMutation(
    () => {
      return branches.refreshTransactionStatus();
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('branch-payroll-history');
        store.dispatch(setAlert(true, 'success', 'All payment statuses refreshed successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}
