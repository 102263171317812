import React, { useState, useEffect } from 'react';
import CloseSVG from '../../../../assets/svg/close.svg';
import Modal from '../../modal';

const ViewFile = ({ stage, isOpen, closeModal }) => {
  const [pdf, setPdf] = useState('');

  useEffect(() => {
    if (stage) {
      setPdf(stage.file_url);
    }
  }, [stage]);

  return (
    <Modal
      scrollable={true}
      className='!min-w-[70%] !rounded-none !min-h-screen'
      isOpen={isOpen}
      centered={true}
    >
      <div className='pl-8 pr-8 pt-3'>
        <div className='flex flex-row justify-between'>
          <span className='header-3'>{stage?.filename}</span>
          <div className='cursor-pointer' onClick={() => closeModal()}>
            <img src={CloseSVG} alt='' />
          </div>
        </div>
      </div>
      <hr className='divider mt-2' />
      <div className='flex flex-col items-center justify-center pb-4 overflow-auto header-4 !min-h-screen'>
        {pdf ? (
          <object data={pdf} type='application/pdf' width='100%' height='2000'>
            <p>
              <a href={pdf}>Download file</a>
            </p>
          </object>
        ) : (
          <div>No PDF Specified</div>
        )}
      </div>
    </Modal>
  );
};

export default ViewFile;
