import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { store } from '../../../redux';
import { useDeclineAdvancedPayroll, useGetAdvancedPayroll, useRecalculateAdvancedPayroll, useSendPayslip } from '../../../redux/payroll/hook';
import { setAdvancedPayroll } from '../../../redux/payroll/reducer';
import { PAGINATION_DEFAULT } from '../../../utils/constants';
import DataTable from '../../datatable';
import Button from '../../button';
import RunAdvancedPayroll from '../../modal/payroll/run-advanced-payroll';
import { useGetBranch } from '../../../redux/human-resources/hook/branches';
import { useSelector } from 'react-redux';
// import { useSelector } from 'react-redux';

export const branchPayrollTab = () => {
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: '',
  });
  const {user} = useSelector((state) => state.userSlice);
  const {id} = useParams()

  const { data: advancedPayroll } = useGetAdvancedPayroll(
    id,
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search,
  );

  const { data: branch } = useGetBranch(id);

  const { mutateAsync: rerunAdvancedPayroll } = useRecalculateAdvancedPayroll();
  const { mutateAsync: declinePayment } = useDeclineAdvancedPayroll();

  const { mutateAsync: sendPayslip } = useSendPayslip();
  const [showAdvancedPayroll, setShowAdvancedPayroll] = useState(false);

  const history = useHistory();

  async function Continue(id, type) {
    let index = advancedPayroll.data.findIndex((item) => item.id === id);
    if (index !== -1) {
      if (type === 'preview') {
        history.push(`/payroll/advanced/preview/${advancedPayroll.data[index].ref}`);
      } else if (type === 'view') {
        history.push(`/payroll/advanced/view/${advancedPayroll.data[index].id}`);
      } else if (type === 'recalculate') {
        let index = advancedPayroll.data.findIndex((item) => item.id === id);
        await rerunAdvancedPayroll(advancedPayroll.data[index].ref).then(()=> {
          history.push(`/payroll/advanced/preview/${advancedPayroll.data[index].ref}`, )
        });
      } else if (type === 'approve') {
        store.dispatch(setAdvancedPayroll(advancedPayroll.data[index]))
        history.push(`/payroll/advanced/preview/${advancedPayroll.data[index].ref}`, 'approve');
      } else if (type === 'send_payslip') {
        let index = advancedPayroll.data.findIndex((item) => item.id === id);
        sendPayslip({ref: advancedPayroll.data[index].ref})
      } else if (type === 'decline') {
        let index = advancedPayroll.data.findIndex((item) => item.id === id);
        declinePayment(advancedPayroll.data[index].ref)
      }
    }
  }

  const statusConfig = [
    {
      label: 'In Progress',
      color: 'orange',
      value: 0,
    },
    {
      label: 'Successful',
      color: 'green',
      value: 1,
    },
    {
      label: 'Awaiting Approval',
      color: 'orange',
      value: 2,
    },
    {
      label: 'Declined',
      color: 'red',
      value: 3,
    },
  ];

  const tableConfig = {
    headers: ['Date Created', 'Description', 'Ref. Number',  'Month','% Paid', '% Initiated', 'Source', 'Total Amount', 'Status'],
    keys: ['created', 'desc', 'ref', 'month','percentage_paid','initiated_percentage', 'source', 'total_amount', 'status'],
    mobileHeader: {
      left: {
        title: 'Date Created',
        key: 'created',
      },
      right: {
        title: 'Description',
        key: 'desc',
      },
    },
  };

  const actionConfig = [
    {
      name: 'Continue',
      color: 'green',
      action: (id) => {
        Continue(id, 'preview');
      },
    },
    {
      name: 'View',
      color: 'green',
      action: (id) => {
        Continue(id, 'view');
      },
    },
    {
      name: 'Send Payslips',
      color: 'green',
      action: (id) => {
        Continue(id, 'send_payslip');
      }
    },
    {
      name: 'Recalculate',
      color: 'orange',
      action: (id) => {
        Continue(id, 'recalculate')
      },
    },
    {
      name: 'Approve',
      color: 'green',
      action: (id) => {
        Continue(id, 'approve')
      },
    },
    {
      name: 'Decline',
      color: 'red',
      action: (id) => {
        Continue(id, 'decline')
      },
    },
  ];

  return (
    <div className='flex flex-col gap-4 -mt-3'>
      {showAdvancedPayroll?
      <RunAdvancedPayroll
        isOpen={showAdvancedPayroll}
        source='default'
        sourceData={id}
        closeModal={() => setShowAdvancedPayroll(false)}
      />
      :
      null
      }
      <div className='flex flex-col'>
        <div className=' bg-gray-50 border shadow-sm rounded-md text-[12px] p-2 text-gray-600'>
          <span className='font-bold text-[12px]'>Payroll Computation - </span>Compute and pay your active onboarded employees (with salary uploaded), generate and send
          Payslip and make other statutory Compliance Payments (fully automated). You can also make part salary payments.<br />
          <span className='font-bold text-[12px] '>Note:</span> Running advanced payroll from this page will compute for all active employees with salaries uploaded.
        </div>
      </div>
      <div className='flex flex-col bg-white shadow-sm rounded'>
        <div className='flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3'>
          <div className='p3-bold header-4'>
            Payroll Summary
          </div>
          {branch?.manager_setting?.manager_id === user?.employee_id && branch?.manager_setting.can_write_payroll === 1?
          <Button
            text='Run Payroll'
            type='button'
            theme='primary'
            className='flex gap-2 h-[35px] w-fit p-2 '
            textClass={'!text-[13px]'}
            leftIcon={'purchase'}
            onClick={() => {
              setShowAdvancedPayroll(true);
            }}
          />
          :
          null
          }
        </div>
        <hr className='divider mt-2 mb-2' />
        <DataTable
          data={advancedPayroll}
          tableConfig={tableConfig}
          noCheck={true}
          statusConfig={statusConfig}
          actionConfig={actionConfig}
          updatePagination={(data) => setPagination(data)}
          filterByStatus={true}
          searchData={true}
          computedActions={true}
        />
      </div>
    </div>
  );
};
