import { Axios } from '../axios';

//For Mobile
const fetchRegistration = async (payload) => {
  const { data } = await Axios.get('/mobile/companies/employee-register', payload);
  return data;
};

const fetchEmployeeInformation = async (payload) => {
  const { data } = await Axios.get('/mobile/companies/employee-infromation', payload);
  return data?.data;
};

const employeeUploadProfilePicture = async (payload) => {
  const { data } = await Axios.post(
    '/mobile/employee/upload-profile-photo', payload
  );
  return data;
};

const updateInvitedEmployee = async (payload) => {
  const { data } = await Axios.post(
    '/mobile/companies/save-employee-details',
    payload.data,
  );
  return data;
};

export const employees = {
  fetchRegistration,
  fetchEmployeeInformation,
  employeeUploadProfilePicture,
  updateInvitedEmployee
};

