import { useMutation, useQuery } from '@tanstack/react-query';
import { store } from '../..';
import { queryClient } from '../../..';
import { setAlert, setIsLoading } from '../../components/components-slice';
import moment from 'moment';
import { employeeExits } from '../../../api/employees/exit';

export function useFetchExit() {
  return useQuery(
    ['employee-exit'],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeExits.fetchExit();
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'Your exit details fetched successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useEmployeeMarkExitStageComplete() {
  return useMutation(
      (payload) => {
          store.dispatch(setIsLoading(true));
          return employeeExits.saveExitStage(payload);
      },
      {
          onSuccess: (response, variables, context) => {
            store.dispatch(setAlert(true, 'success', 'Updated successfully'));
          },
          onSettled: (data, error, variables, context) => {
              store.dispatch(setIsLoading(false));
          },
      },
  );
}

export function useEmployeeMarkExitComplete() {
  return useMutation(
      (payload) => {
          store.dispatch(setIsLoading(true));
          return employeeExits.markExitComplete(payload);
      },
      {
        onSuccess: (response, variables, context) => {
          store.dispatch(setAlert(true, 'success', 'Updated successfully'));
        },
        onSettled: (data, error, variables, context) => {
            store.dispatch(setIsLoading(false));
        },
      },
  );
}

export function useSaveAnswer() {
  return useMutation(
      (payload) => {
          store.dispatch(setIsLoading(true));
          return employeeExits.saveExitAnswers(payload);
      },
      {
          onSuccess: (response, variables, context) => {
            store.dispatch(setAlert(true, 'success', 'Submitted successfully'));
          },
          onSettled: (data, error, variables, context) => {
              store.dispatch(setIsLoading(false));
          },
      },
  );
}