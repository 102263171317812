import React, { useEffect } from 'react';
import DataTable from '../../components/datatable';
import { useState } from 'react';
import {
  leaveStatusConfig,
  PAGINATION_DEFAULT,
} from '../../utils/constants';
import { useApproveLeave, useDeclineLeave, useGetLeaves } from '../../redux/human-resources/hook/leave';
import AddLeave from '../../components/modal/leave/add-leave';
import Button from '../../components/button';
import DashboardLayout from '../../components/layouts/dashboard';
import FormSelect from '../../components/form-select';
import { useSelector } from 'react-redux';
import ViewLeave from '../../components/modal/leave/view-leave';

const HRLeavePage = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const { user } = useSelector((state) => state.userSlice);

  const [source, setSource] = useState('employee');
  const [sourceId, setSourceId] = useState(user?.employee_id);
  const [sourceName, setSourceName] = useState(null);

  const [teams, setTeams] = useState([{ label: 'Select Team', value: null }]);
  const [departments, setDepartments] = useState([{ label: 'Select Department', value: null }]);
  const [branches, setBranches] = useState([{ label: 'Select Branch', value: null }]);

  
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: '',
    
  });

  const { data: leaves } = useGetLeaves(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search,
    source,
    sourceId
  );

  const [leaveData, setLeaveData] = useState(null);
  const [title, setTitle] = useState(null);
  const [buttonText, setButtonText] = useState(null);

  const [tableType, setTableType] = useState('leave');

  const { mutateAsync: approveLeave, } = useApproveLeave();
  const { mutateAsync: declineLeave, } = useDeclineLeave();

  const [sourceOptions, setSourceOptions] = useState([
    { label: 'My Appraisals', value: 'employee' },
  ]);

  useEffect(() => {
    if (source === 'employee' && user) {
      setSourceId(user.employee_id)
      let company = user.company
      let data = [{ label: 'My Leaves', value: 'employee' },]
      if (company.is_team_lead) {
        data.push({ label: 'My Team Leaves', value: 'team' })
      }
      if (company.is_department_lead) {
        data.push({ label: 'My Department Leaves', value: 'department' })
      }
      if ((company.is_branch_lead)) {
        data.push({ label: 'My Branch Leaves', value: 'branch' })
      }
      setSourceOptions(data)
    }
  }, [source, user])

  useEffect(() => {
    if (user) {
      let company = user.company
      if (company.teams && company.teams.length > 0) {
        let teamData = [{ label: 'Select Team', value: null }]
        company.teams.forEach(item => {
          teamData.push({ label: item.name, value: item.id })
        });
        setTeams(teamData)
      }
      if (company.departments && company.departments.length > 0) {
        let departmentData = [{ label: 'Select Department', value: null }]
        company.departments.forEach(item => {
          departmentData.push({ label: item.name, value: item.id })
        });
        setDepartments(departmentData)
      }
      if (company.branches && company.branches.length > 0) {
        let branchData = [{ label: 'Select Branch', value: null }]
        company.branches.forEach(item => {
          branchData.push({ label: item.name, value: item.id })
        });
        setBranches(branchData)
      }
    }
  }, [user])

  const switchFunction = () => {
    if (tableType === 'leave') {
      setTableType('table')
    } else {
      setTableType('leave')
    }
  }

  const [showAddLeave, setShowAddLeave] = useState(false);
  const [showViewLeave, setShowViewLeave] = useState(false);

  const tableConfig = {
    headers: [
      'Date',
      'Employee Name',
      'Mobile',
      'Type',
      'Period',
      'Days',
      'Status',
    ],
    keys: ['date_created', 'employeeName', 'mobile', 'leave_type','period', 'number_of_days', 'status'],
    mobileHeader: {
      left: {
        title: 'Employee Name',
        key: 'employeeName',
      },
      right: {
        title: 'Period',
        key: 'leave_period',
      },
    },
  };

  const actionConfig = [
    {
      name: 'View',
      color: 'green',
      action: (id) => {
        let index = leaves.data.findIndex((item) => item.id === id);
        setTitle('View Leave')
        setButtonText('View Leave')
        setLeaveData(leaves.data[index])
        setShowViewLeave(true)
      },
    },
    {
      name: 'Approve',
      color: 'green',
      action: (id) => {
        let index = leaves.data.findIndex((item) => item.id === id);
        approveLeave({
          source,
          leave_id: leaves.data[index].id
        })
      },
    },
    {
      name: 'Decline',
      color: 'red',
      action: (id) => {
        let index = leaves.data.findIndex((item) => item.id === id);
        declineLeave({
          source,
          leave_id: leaves.data[index].id
        })
      },
    },
  ];

  return (
    <DashboardLayout title='Leave Management'>
      <div className='flex flex-col bg-white shadow-md rounded-md'>
        {showViewLeave ?
          <ViewLeave
            isOpen={showViewLeave}
            closeModal={() => setShowViewLeave(false)}
            leaveData={leaveData}
            title={title}
            buttonText={buttonText}
          />
        : null}
        {showAddLeave ?
          <AddLeave
            isOpen={showAddLeave}
            closeModal={() => setShowAddLeave(false)}
            leaveData={leaveData}
            title={title}
            buttonText={buttonText}
          />
        : null}
        <div className='flex flex-col md:flex-row justify-between items-center px-4 gap-[10px]'>
          <div className='p3-bold header-4'>
            Leave Applications
          </div>
          <div className='flex flex-row md:flex-col w-full justify-end gap-4 md:w-1/2'>
            <div className='w-full md:w-1/2 pr-2'>
              <FormSelect
                value={source}
                options={sourceOptions}
                onChange={(selected) => {
                  setSource(selected);
                  if (selected === 'employee') {
                    setSourceId(user?.employee_id)
                  } else {
                    setSourceId(null);
                  }
                }}
              />
            </div>
            {source === 'employee' ? (
              null
            ) : source === 'team' && teams && teams.length > 1 ? (
              <div className='w-full md:w-1/2 pl-2'>
                <FormSelect
                  value={sourceId}
                  options={teams}
                  onChange={(selected) => {
                    if (selected === 'Select Team') {
                      setSourceId(null);
                    } else {
                      setSourceId(selected);
                    }
                  }}
                />
              </div>
            ) : source === 'department' && departments && departments.length > 1 ? (
              <div className='w-full md:w-1/2 pl-2'>
                <FormSelect
                  value={sourceId}
                  options={departments}
                  onChange={(selected) => {
                    if (selected === 'Select Department') {
                      setSourceId(null);
                    } else {
                      setSourceId(selected);
                    }
                  }}
                />
              </div>
            ) :  source === 'branch' && branches && branches.length > 1 ? (
              <div className='w-full md:w-1/2 pl-2'>
                <FormSelect
                  value={sourceId}
                  options={branches}
                  onChange={(selected) => {
                    if (selected === 'Select Branch') {
                      setSourceId(null);
                    } else {
                      setSourceId(selected);
                    }
                  }}
                />
              </div>
            )
            :
            null}
          </div>
          <div className='flex flex-col md:flex-row items-start gap-[10px] md:items-center'>
            <div className='md:w-fit w-[100%]'>
              <Button
                text='Apply for leave'
                type='submit'
                theme='primary'
                className='flex gap-2 h-[35px] w-fit p-2 '
                textClass={'!text-[13px]'}
                leftIcon={'add'}
                onClick={() => {
                  setLeaveData(null)
                  setTitle('Add Employee Leave')
                  setButtonText('Add Leave')
                  setShowAddLeave(true)
                }}
              />
            </div>
          </div>
        </div>
        <hr className='divider -mt-1' />
        <DataTable
          data={leaves}
          tableConfig={tableConfig}
          statusConfig={leaveStatusConfig}
          actionConfig={actionConfig}
          updatePagination={(data) => setPagination(data)}
          filterByStatus={true}
          computedActions={sourceId !== user?.employee_id ? true : false }
          tableType={tableType}
          switchFunction={switchFunction}
          layoutSwitch={true}
          searchData={true}
          noCheck={true}
        />
      </div>
    </DashboardLayout>
  );
};

export default HRLeavePage;
