import classNames from 'classnames';
import React, { useState } from 'react';
import CollapsingIcon from '../collapsing-icon';
import { useEffect } from 'react';
import { useGetDepartments } from '../../redux/human-resources/hook/departments';

const FormSelectDepartment = ({
  id,
  label,
  className,
  readOnly=false,
  value,
  departmentName,
  error,
  errorMessage,
  theme,
  setDepartment,
  setSourceName,
  inputRef,
  ...rest
}) => {

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  
  const [text, setText] = useState('');
  const [search, setSearch] = useState('');
  const [showResults, setShowResult] = useState(false);

  const params = {
    limit: 10,
    page: 1,
    statusFilter: -1,
  };

  const { data: departments, status } = useGetDepartments(
    params.limit,
    params.page,
    search,
  );
  useEffect(() => {
    if (departmentName) {
      setText(departmentName);
    }
  }, [departmentName]);

  useEffect(() => {
    if (status === 'success' && search.trim()) {
      setShowResult(true);
    }
  }, [status, search]);

  function handleItemSelect(item) {
    setText(item.name);
    setSourceName(item.name);
    setDepartment(item.id);
    setShowResult(false);
  }

  return (
    <>
      <div className='pb-[20px] flex flex-col'>
        <div className='flex w-full flex-col'>
          {label && (
            <label className='text-[13px] leading-[23px] font-normal text-color-gray -mb-2'>
              {label}
            </label>
          )}
          <div className={showResults?'pb-[42px] flex flex-col !min-h-fit':'flex flex-col !min-h-fit shadow-sm'}>
            <div className='flex flex-col items-center' id='datalist'>
              <input
                id='datalist-input'
                type='text'
                autoComplete='off'
                ref={inputRef}
                readOnly={readOnly}
                onChange={(event) => {
                  setText(event.target.value);
                  setSearch(event.target.value);
                }}
                className={'!max-h-[35px] text-[13px]'}
                value={text}
                {...rest}
              />
              <i id='datalist-icon' className='icon iconfont'>
                <CollapsingIcon />
              </i>
              {/* Result List */}
              {departments && departments.data ? (
                <ul
                  className={classNames(
                    'datalist-items px-0 mt-2 rounded-sm border border-secondary-2 !z-[10000] bg-white',
                    {
                      hidden: departments.data.length === 0 || !showResults,
                    },
                  )}
                >
                  {departments.data.map((item) => (
                    <li
                      id={'12'}
                      className={`flex flex-row justify-between py-2 cursor-pointer text-[14px]
                       hover:bg-secondary-2
                        px-3 rounded-sm hover:text-white`}
                      onClick={() => handleItemSelect(item)}
                    >
                      <div className='flex flex-row items-center text-inherit'>
                        <span className='text-inherit'>{item.name}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : null}
            </div>
          </div>
        </div>
        {error && <span className='text-error'>* {errorMessage}</span>}
      </div>
    </>
  );
};

export default FormSelectDepartment;
