import React, { useEffect } from 'react';
import DataTable from '../datatable';
import { useState } from 'react';
import { PAGINATION_DEFAULT, } from '../../utils/constants';
import { useGetPerformancesBySource } from '../../redux/human-resources/hook/performance';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import FormSelect from '../form-select';

const HRPerformanceOverviewTab = () => {
  
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const { user } = useSelector((state) => state.userSlice);
  const history = useHistory();

  const [source, setSource] = useState('employee');
  const [sourceId, setSourceId] = useState(user?.employee_id);

  const [teams, setTeams] = useState([{ label: 'Select Team', value: null }]);
  const [departments, setDepartments] = useState([{ label: 'Select Department', value: null }]);
  const [branches, setBranches] = useState([{ label: 'Select Branch', value: null }]);

  const [tableType, setTableType] = useState('table');
  
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: '',
  });

  const { data: performances } = useGetPerformancesBySource(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search,
    source,
    sourceId
  );
  
  const [sourceOptions, setSourceOptions] = useState([
    { label: 'My Appraisals', value: 'employee' },
    { label: 'My Assigned Appraisals', value: 'assigned' },
  ]);

  useEffect(() => {
    if (source === 'employee' && user) {
      setSourceId(user.employee_id)
      let company = user.company
      let data = [
        { label: 'My Appraisals', value: 'employee' },
        { label: 'My Assigned Appraisals', value: 'assigned' }
      ]
      if (company.is_team_lead) {
        data.push({ label: 'My Team Appraisals', value: 'team' })
      }
      if (company.is_department_lead) {
        data.push({ label: 'My Department Appraisals', value: 'department' })
      }
      if ((company.is_branch_lead)) {
        data.push({ label: 'My Branch Appraisals', value: 'branch' })
      }
      setSourceOptions(data)
    }
  }, [source, user])

  useEffect(() => {
    if (user) {
      let company = user.company
      if (company.teams && company.teams.length > 0) {
        let teamData = [{ label: 'Select Team', value: null }]
        company.teams.forEach(item => {
          teamData.push({ label: item.name, value: item.id })
        });
        setTeams(teamData)
      }
      if (company.departments && company.departments.length > 0) {
        let departmentData = [{ label: 'Select Department', value: null }]
        company.departments.forEach(item => {
          departmentData.push({ label: item.name, value: item.id })
        });
        setDepartments(departmentData)
      }
      if (company.branches && company.branches.length > 0) {
        let branchData = [{ label: 'Select Branch', value: null }]
        company.branches.forEach(item => {
          branchData.push({ label: item.name, value: item.id })
        });
        setBranches(branchData)
      }
    }
  }, [user])

  const tableConfig = {
    headers: [
      'Date Created',
      'Employee Name',
      'Mobile',
      'Period Covered',
    ],
    keys: ['created', 'employeeName', 'mobile', 'period'],
    mobileHeader: {
      left: {
        title: 'Employee Name',
        key: 'employeeName',
      },
      right: {
        title: 'Period Covered',
        key: 'period',
      },
    },
  };

  const actionConfig = [
    {
      name: 'View',
      color: 'green',
      action: (id) => {
        let index = performances.data.findIndex(
          (item) => item.id === id,
        );
        if (index !== -1) {
          if (source === 'employee') {
            history.push('/performance/view/' + performances.data[index].id)
          } else {
            history.push(`/performance/team-lead/view/${performances.data[index].id}?source=${source}&source_id=${sourceId}`)
          }
        }
      },
    },
  ];

  return (
    <div className='flex flex-col bg-white -mt-2'>
      <div className='flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] mt-1'>
        <div className='p3-bold header-4'>
          Performance Appraisals
        </div>
        <div className='flex flex-row md:flex-col w-full justify-end gap-4 md:w-1/2'>
          <div className='w-full md:w-1/2 pr-2'>
            <FormSelect
              value={source}
              options={sourceOptions}
              onChange={(selected) => {
                setSource(selected);
                if (selected === 'employee') {
                  setSourceId(user?.employee_id)
                } else if (selected === 'assigned') {
                  setSourceId(-1);
                } else {
                  setSourceId(null);
                }
              }}
            />
          </div>
          {source === 'employee' ? (
            null
          ) : source === 'team' && teams && teams.length > 1 ? (
            <div className='w-full md:w-1/2 pl-2'>
              <FormSelect
                value={sourceId}
                options={teams}
                onChange={(selected) => {
                  if (selected === 'Select Team') {
                    setSourceId(null);
                  } else {
                    setSourceId(selected);
                  }
                }}
              />
            </div>
          ) : source === 'department' && departments && departments.length > 1 ? (
            <div className='w-full md:w-1/2 pl-2'>
              <FormSelect
                value={sourceId}
                options={departments}
                onChange={(selected) => {
                  if (selected === 'Select Department') {
                    setSourceId(null);
                  } else {
                    setSourceId(selected);
                  }
                }}
              />
            </div>
          ) :  source === 'branch' && branches && branches.length > 1 ? (
            <div className='w-full md:w-1/2 pl-2'>
              <FormSelect
                value={sourceId}
                options={branches}
                onChange={(selected) => {
                  setSourceId(selected);
                }}
              />
            </div>
          )
          :
          null}
        </div>
      </div>
      <hr className='divider mt-0 mb-2' />
      <DataTable
        data={performances}
        tableConfig={tableConfig}
        actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        computedActions={true}
        tableType={tableType}
      />
    </div>
  );
};

export default HRPerformanceOverviewTab;
