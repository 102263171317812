import React, { useEffect, useState } from 'react';
import CloseSVG from '../../../../assets/svg/close.svg';
import Button from '../../../button';
import Modal from '../../modal';
import { TrainingRemarks } from './training-remarks';
import moment from 'moment';
import FormFileInputWithOnchange from '../../../form-file-input-with-onchange';
import { useUploadTrainingCertificate } from '../../../../redux/employee/hook/training';
import { useForm } from 'react-hook-form';
import { formValidation } from '../../../../utils/functions';
import FormInput from '../../../form-input';
import DOMPurify from 'dompurify';

function TrainedEmployee({
  isOpen,
  closeModal,
  trainedEmployeeData,
}) {
    const {
        register,
        handleSubmit,
        formState: { isValid },
        errors,
    } = useForm({
        mode: 'onChange',
    });
    const { mutateAsync: uploadTrainingCertificate, isLoading } = useUploadTrainingCertificate();

    const [file, setFile] = useState();
    const [uploadCertificate, setUploadCertificate] = useState(false);
    function handleChange(e) {
        setFile(URL.createObjectURL(e.target.files[0]));
    }

    const submitForm = async (data) => {
        let certificate_name = DOMPurify(data?.certificate_name)
        let certificate_number = DOMPurify(data?.certificate_number);
        let description = DOMPurify(data?.description);
        const formData = new FormData();
        formData.append('file', data?.file[0]);
        formData.append('training_id', trainedEmployeeData?.training_id);
        formData.append('certificate_name', certificate_name);
        formData.append('certificate_number', certificate_number);
        formData.append('description', description);
        
        await uploadTrainingCertificate(formData).then(() => {
          closeModal()
        });
    }

    return (
        <Modal
            scrollable={true}
            className='!min-w-[90%]'
            isOpen={isOpen}
            centered={true}
        >
            <div className='flex flex-col overflow-auto'>
                <div className='pl-8 pr-8 pt-8'>
                    <div className='flex md:flex-row justify-between gap-4 items-center'>
                    <span className='md:header-3 header-4'> {trainedEmployeeData?.training_name} information</span>
                    <div className='cursor-pointer' onClick={() => closeModal()}>
                        <img src={CloseSVG} alt='' />
                    </div>
                    </div>
                </div>
                <hr className='divider' />
                {trainedEmployeeData ? 
                <div className='flex md:flex-row flex-col gap-4 px-3 pb-4'>
                    <div className=' flex flex-col gap-4 w-full md:w-[50%]'>
                        <div className=' flex flex-col gap-4 w-full border shadow-sm rounded mb-2'>
                            <div className='flex flex-row justify-between items-center bg-blue-100 p-3'>
                                <div className='text-[14px] text-color-black flex flex-col font-bold'>
                                    Training Information
                                </div>
                            </div>
                            <div className='p-3 pt-0'>
                                <div className='grid grid-cols-2 gap-4 w-full'>
                                    <div className='flex flex-col col-span-2'>
                                        <span className='header-5 text-color-gray'>Employee Name</span>
                                        <span className=' text-[14px] mt-1 text-color-black font-bold'>
                                            {trainedEmployeeData.first_name} {trainedEmployeeData.last_name}
                                        </span>
                                    </div>
                                    <div className='flex flex-col'>
                                        <span className='header-5 text-color-gray'>
                                        Email Address
                                        </span>
                                        <span className=' text-[14px] mt-1 text-color-black font-bold'>
                                        {trainedEmployeeData.email ? trainedEmployeeData.email : 'Not Set'}
                                        </span>
                                    </div>
                                    <div className='flex flex-col'>
                                        <span className='header-5 text-color-gray'>
                                        Phone number{' '}
                                        </span>
                                        <span className=' text-[14px] mt-1 text-color-black font-bold'>
                                        {trainedEmployeeData.mobile ? trainedEmployeeData.mobile : 'Not Set'}
                                        </span>
                                    </div>
                                </div>
                                <hr className='divider mt-2' />
                                <div className='grid grid-cols-2 gap-4 w-full mt-3'>
                                    <div className='flex flex-col'>
                                        <span className='header-5 text-color-gray'>Training Name</span>
                                        <span className=' text-[14px] mt-1 text-color-black font-bold'>
                                        {trainedEmployeeData.training_name ? trainedEmployeeData.training_name : 'Not Set'}
                                        </span>
                                    </div>
                                    <div className='flex flex-col'>
                                        <span className='header-5 text-color-gray'>Trainer Name</span>
                                        <span className=' text-[14px] mt-1 text-color-black font-bold'>
                                        {trainedEmployeeData.trainer_name ? trainedEmployeeData.trainer_name : 'Not Set'}
                                        </span>
                                    </div>
                                </div>
                                <hr className='divider mt-2' />
                                <div className='grid md:grid-cols-1 grid-cols-1 gap-4 w-full mt-3'>
                                    <div className='flex flex-row items-center gap-8'>
                                        <span className='header-5 text-color-gray'>Training Duration</span>
                                        <span className=' text-[14px] mt-1 text-color-black font-bold'>
                                            {moment(trainedEmployeeData.training_start).format('MMMM DD, YYYY')} <span className='font-light italic'>to</span> {moment(trainedEmployeeData.training_end).format('MMMM DD, YYYY')}
                                        </span>
                                    </div>
                                </div>
                                <hr className='divider mt-2' />
                                <div className='grid md:grid-cols-1 grid-cols-1 gap-4 w-full mt-3'>
                                    <div className='flex flex-row items-center gap-8'>
                                        <span className='header-5 text-color-gray'>Training Location</span>
                                        <span className=' text-[14px] mt-1 text-color-black font-bold'>
                                            {trainedEmployeeData.training_location ? trainedEmployeeData.training_location : 'Not Set'}
                                        </span>
                                    </div>
                                </div>
                                <hr className='divider mt-2' />
                                <div className='grid md:grid-cols-1 grid-cols-1 gap-4 w-full mt-3'>
                                    <div className='flex flex-row items-center gap-8'>
                                        <span className='header-5 text-color-gray'>Training Type</span>
                                        <span className=' text-[14px] mt-1 text-color-black font-bold'>
                                            {trainedEmployeeData.training_type ? trainedEmployeeData.training_type : 'Not Set'}
                                        </span>
                                    </div>
                                </div>
                                <hr className='divider mt-2' />
                                <div className='grid md:grid-cols-1 grid-cols-1 gap-4 w-full mt-3'>
                                    <div className='flex flex-row items-center gap-8'>
                                        {trainedEmployeeData?.certificate?.file_url?
                                        <>
                                            <span className='header-5 text-color-gray'>Certificate Earned:</span>
                                            <div className='flex flex-row gap-3 items-center'>
                                                <a className='italic text-hr-primary-1 text-[14px]' href={trainedEmployeeData?.certificate?.file_url} target='_blank' rel="noreferrer"> Download</a>
                                            </div>
                                        </>
                                        :
                                        <div className='flex flex-col justify-center items-center w-full'>
                                            {uploadCertificate?
                                            <form className='form flex flex-col justify-center items-center ' onSubmit={handleSubmit(submitForm)}>
                                                <div className='header-3 mb-3'> Upload Training Certificate</div>
                                                <div className='w-full'>
                                                    <FormInput
                                                        label='Certificate Name'
                                                        name='certificate_name'
                                                        type='text'
                                                        inputRef={register(formValidation('text', true))}
                                                        error={errors.certificate_name}
                                                        errorMessage={
                                                        errors.certificate_name && errors.certificate_name.message
                                                        }
                                                    />
                                                </div>
                                                <div className='w-full'>
                                                    <FormInput
                                                        label='Certificate Number'
                                                        name='certificate_number'
                                                        type='text'
                                                        inputRef={register(formValidation('text', true))}
                                                        error={errors.certificate_number}
                                                        errorMessage={
                                                        errors.certificate_number && errors.certificate_number.message
                                                        }
                                                    />
                                                </div>
                                                <div className='w-full'>
                                                    <FormInput
                                                        label='Brief Description - (Optional)'
                                                        name='description'
                                                        type='text'
                                                        inputRef={register(formValidation('text', true))}
                                                        error={errors.description}
                                                        errorMessage={
                                                        errors.description && errors.description.message
                                                        }
                                                    />
                                                </div>
                                                <div className='flex flex-col justify-center items-center'>
                                                    <span className='header-5 text-left'>
                                                        (Max. size 3MB)
                                                    </span>
                                                    <div className='flex flex-row gap-2'>
                                                        <FormFileInputWithOnchange
                                                            multiSource={false}
                                                            inputRef={register(formValidation('file', true))}
                                                            accept='.jpg,.jpeg,.png,.pdf,.docx,.gif'
                                                            name={'file'}
                                                            error={errors.file}
                                                            onChange={handleChange}
                                                            errorMessage={errors.file && errors.file.message}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='flex justify-end w-full mt-[5px] gap-3'>
                                                    <Button
                                                        text='Cancel'
                                                        type='button'
                                                        onClick={() => {setUploadCertificate(false)}}
                                                        theme='secondary'
                                                        className='flex gap-2 h-[35px] w-fit p-2 '
                                                        textClass={'!text-[13px]'}
                                                    />
                                                    <Button
                                                        text='Upload Certificate'
                                                        type='submit'
                                                        theme='primary'
                                                        className='flex gap-2 h-[35px] w-fit p-2 '
                                                        textClass={'!text-[13px]'}
                                                        loading={isLoading}
                                                    />
                                                </div>
                                            </form>
                                            :
                                            <div className='flex justify-end w-full mt-[5px]'>
                                                <Button
                                                    text='Click to upload training certificate'
                                                    type='button'
                                                    onClick={() => {setUploadCertificate(true)}}
                                                    theme='primary'
                                                    className='flex gap-2 h-[35px] w-fit p-2 '
                                                    textClass={'!text-[13px]'}
                                                />
                                            </div>
                                            }
                                            
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col gap-4 w-full md:w-[50%]'>
                        <div className='w-[100%] h-fit'>
                            <TrainingRemarks employeeData={trainedEmployeeData} />
                        </div> 
                    </div>
                </div>
                :
                null
                }
            </div>
        </Modal>
    );
}

export default TrainedEmployee;
