import React, { useState } from 'react';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Button from '../../components/button';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import EmptyTableSVG from '../../assets/svg/empty-table.svg';
import Logo from '../../components/logo';
import { ExitEmployeeStatusConfig, onboardingEmployeeStatusConfig } from '../../utils/constants';
import { StatusText } from '../../components/fragments/status-text';
import { setAlert } from '../../redux/components/components-slice';
import SingleEmployeeExitLayout from '../../components/layouts/employee-exit';
import { Initials } from '../../utils/functions';
import { useEmployeeMarkExitComplete, useEmployeeMarkExitStageComplete, useFetchExit } from '../../redux/employee/hook/exit';
import LoadingBar from '../../components/loader-bar';

function ExitVerified() {

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const dispatch = useDispatch();

  const {token} = useParams();
  const [exitData, setExitData] = useState({})

  const {data: employeeExit, isLoading: exitLoading} = useFetchExit();

  const history = useHistory();
  
  useEffect(() => {
    if (employeeExit) {
      if (employeeExit.status !== 1) {
        setExitData(employeeExit)
      } else {
        dispatch(setAlert(true, 'info', 'You can no longer alter your onboarding. It has been marked as reviewed. Contact your admin'));
      }
      setStageData(employeeExit.workflow?.sub_workflow[currentIndex-1])
    }
  }, [employeeExit,])

  useEffect(() => {
    if (exitData) {
      setStageData(employeeExit?.workflow?.sub_workflow[currentIndex-1]) 
    }
  }, [exitData,])

  const [currentIndex, setCurrentIndex] = useState(1);

  useEffect(() => {
    if (currentIndex) {
      setStageData(exitData.workflow?.sub_workflow[currentIndex-1])
    }
  }, [currentIndex])

  const { mutateAsync: markStage, isLoading: markStageloading } = useEmployeeMarkExitStageComplete();
  const { mutateAsync: markExit, isLoading: markExitloading } = useEmployeeMarkExitComplete();

  const [stageData, setStageData] = useState()

  const markEmployeeStage = async () => {
    let payload = {
      id: stageData?.id,
      data: {
        employee_id: exitData?.employee_id,
        exit_id: exitData?.id,
        token: token,
      }
      
    }
    await markStage(payload).then((result) => {
      
      let newArray = 
      JSON.parse(JSON.stringify(exitData));

      newArray.workflow.sub_workflow[currentIndex-1].employee_status = result.data.employee_status
      setExitData(newArray)
    });
  }

  const markemployeeExit = async() => {
    let payload = {
      id: exitData?.id,
      data: {
        employee_id: exitData?.employee_id,
        exit_id: exitData?.id,
        token: token,
      }
    }
    await markExit(payload).then((result) => {
      let newArray = JSON.parse(JSON.stringify(exitData));
      newArray.employee_status = result.data.employee_status
      setExitData(newArray)
    });
  }

  return (
    <SingleEmployeeExitLayout step='2' company_name={exitData?.company_name} logo={exitData?.logo} >
      <div className='flex flex-col gap-4 w-full md:pt-2 !min-h-screen overflow-auto'>
        <div className='flex flex-row items-center justify-start mt-2'>
          <Button
            text='Back To Dashboard'
            type='submit'
            className='!h-[35px] px-3 !bg-hr-primary-1 w-fit'
            textClass='!text-[12px] !text-white'
            onClick={() => history.push('/dashboard')}
          />
        </div>
        {exitLoading?
        <div className='mt-5'>
          <LoadingBar loading={exitLoading} />
        </div>
        : exitData && exitData.status == 0?
        <div>
          {exitData && exitData.status == 0?
          <div className='flex flex-col gap-4'>
            <div className='flex flex-row items-center box-shadow h-[104px] w-full mt-2 border border-gray-300 rounded p-4'>
              <div className='flex flex-row md:px-[55px] p-2 justify-between items-center w-full '>
                <div className='flex flex-row items-center'>
                  <div className='flex font-bold justify-center items-center h-[58px] w-[58px] rounded-[58px] border-[3px] header-3 border-secondary-2 bg-green-200'>
                    {Initials(exitData?.first_name, exitData?.last_name)}
                  </div>
                  <div className='flex flex-col pl-4 h-[48px] content-center justify-center'>
                    <span className='p1-bold'>
                      {exitData?.first_name} {exitData?.last_name}
                    </span>
                    <span className='p4 text-color-gray'>{exitData?.email}</span>
                  </div>
                </div>
                {exitData?.employee_status == 1?
                <div className='flex flex-col gap-1 items-center'>
                  <StatusText
                    statusConfig={onboardingEmployeeStatusConfig}
                    code={exitData?.employee_status}
                    className={'py-2 rounded p3-bold px-3 text-[15px]'}
                  />
                  <span className='text-[13px] text-color-gray'>{exitData?.status == 1? 'Reviewed By Admin': 'Not Reviewed By Admin'}</span>
                </div>
                :
                <div className='flex flex-row items-center justify-end'>
                  <Button
                    text='Mark Exit Complete'
                    type='submit'
                    className='!max-h-[35px] px-3 !bg-hr-primary-1'
                    textClass='!text-[13px] !text-white'
                    onClick={() => markemployeeExit()}
                    loading={markExitloading}
                  />
                </div>
                }
                
              </div>
            </div>
            <div className=' flex flex-col text-justify'>
              <span className='header-4'>Instruction: </span>
              <div className='text-[14px] text-gray-500 text-justify'>Complete exit task and mark stage as completed. Subject to review by your Human Resource manager.</div>
            </div>
            { exitData.workflow?.sub_workflow && exitData.workflow?.sub_workflow.length > 0 ?
            <div className='flex flex-row items-center justify-center gap-1 border p-2 border-gray-400 rounded bg-gray-100'>
              {exitData.workflow?.sub_workflow.map((item, index) => (
                <div 
                  className={classNames(
                    'flex onboarding-stage w-[150px] min-h-[30px] border-2 border-hr-primary-1 text-hr-primary-1 items-center justify-center cursor-pointer rounded-tr-full rounded-br-full duration-500 text-[13px] md:text-[15px] text-center',
                    {
                        'bg-hr-primary-1 text-white hover:text-hr-primary-1': index === currentIndex-1 || item.employee_status == 1,
                        'hover:bg-gray-50': index !== currentIndex-1,
                    },
                  )}
                  onClick={() => {
                    setCurrentIndex(index+1)
                  }}
                >
                  Stage {index+1}
                </div>
              ))}
            </div>
            :
            null
            }
            { stageData ?
            <div className={'flex flex-col w-full border border-hr-primary-1 min-h-[350px] rounded p-4 pt-2 items-center gap-2'}>
              <div className='flex flex-col items-center'>
                <div className='text-[30px] font-bold text-hr-primary-1 '>Stage {currentIndex} - {stageData.name}</div>
                <div className='text-[16px] text-gray-400 -mt-2'>{stageData.description}</div>
              </div>
              <>
                <div className='flex flex-col justify-center items-center max-w-[400px] min-w-[70%]'>
                  <>
                    <div className='flex flex-col justify-between items-center'>
                      <div className={'flex flex-col items-center justify-center w-[480px] h-[220px] border border-gray-300 rounded p-4 gap-4 mt-2'}>
                        <div className='text-color-black'>
                          <span className='text-[17px] font-bold text-gray-500'>Task: </span> 
                          <span className='text-[13px] '>{stageData.task}</span>
                        </div>
                        {stageData?.employee_status == 1 || stageData?.status == 1?
                        <div className='flex flex-row items-center'>
                          <StatusText
                            statusConfig={ExitEmployeeStatusConfig}
                            code={stageData?.employee_status}
                            className={'py-2 rounded p1-bold px-3 p2-bold text-[15px]'}
                          />
                        </div>
                        :
                        <div className='flex flex-row items-center justify-center w-full'>
                          <Button 
                            text={'Mark Stage Completed'}
                            type='button'
                            className=' flex !h-[42px] px-3 w-fit !bg-hr-primary-1'
                            textClass='!text-[13px]'
                            onClick={() => markEmployeeStage()}
                            loading={markStageloading}
                          />
                        </div>
                        }
                      </div>
                    </div>
                  </>
                  
                </div>
              </>
            </div>
            :
            <div className='flex flex-col items-center justify-center w-[100%] -mt-4 !max-h-[200px] !min-h-[200px]'>
              <span className='header-4 mt-3'>No data was fetched</span>
            </div>
            }
          </div>
          : !exitData ?
          <div className='flex flex-col items-center justify-center w-[100%] -mt-4 !min-h-[200px]'>
            <img src={EmptyTableSVG} alt='no_data_fetched' width={'80%'} height={'200px'}/>
            <span className='header-4 mt-3'>You Are not verified</span>
          </div>
          :
          <div className='flex flex-col items-center justify-center w-[100%] -mt-4 !min-h-[200px]'>
            <img src={EmptyTableSVG} alt='no_data_fetched' width={'80%'} height={'200px'}/>
            <span className='header-4 mt-3'>Exit has already been reviewed. You can no longer update.</span>
          </div>
          }
        </div>
        :
        <div className='flex flex-col items-center justify-center w-[100%] -mt-4 !min-h-[200px]'>
          <img src={EmptyTableSVG} alt='no_data_fetched' width={'80%'} height={'200px'}/>
          <span className='header-4 mt-3'>No Exit Data Fetched</span>
        </div>
        }
        <div className={'md:hidden flex items-center justify-center text-color-black gap-2 mb-4'}>
          Powered By: <a href='https://www.ropay.africa' target={'_blank'} rel="noreferrer">{<Logo width='80px' height='40px' blue />}</a>
        </div>
      </div>
    </SingleEmployeeExitLayout>
  );
}

export default ExitVerified;
