import React, { useEffect } from 'react';
import DataTable from '../../components/datatable';
import { useState } from 'react';
import { PAGINATION_DEFAULT, overtimeApplicationStatusConfig } from '../../utils/constants';
import Button from '../../components/button';
import DashboardLayout from '../../components/layouts/dashboard';
import AddOvertime from '../../components/modal/employees/add-overtime';
import { useGetEmployeeOvertimes } from '../../redux/employee/hook/overtime';

const OvertimePage = () => {

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: '',
  });

  const { data: employeeOvertimes } = useGetEmployeeOvertimes(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search,
  );

  const [showAddOvertime, setShowAddOvertime] = useState(false);
  const [overtimeData, setOvertimeData] = useState();
  const [title, setTitle] = useState(null);
  const [buttonText, setButtonText] = useState(null);

  const tableConfig = {
    headers: [
      'Employee Name',
      'Mobile',
      'Purpose',
      'Hours Applied',
      'Amount',
      'Status'
    ],
    keys: [
      'employeeName',
      'mobile',
      'description',
      'overtime_hours',
      'formatted_amount',
      'status'
    ],
    mobileHeader: {
      left: {
        title: 'Employee Name',
        key: 'employeeName',
      },
      right: {
        title: 'Status',
        key: 'status',
      },
    },
  };


  const actionConfig = [
    {
      name: 'View',
      color: 'green',
      action: (id) => {
        let index = employeeOvertimes.data.findIndex((item) => item.id === id);
        setButtonText('Update')
        setTitle('View Overtime')
        setOvertimeData(employeeOvertimes.data[index])
        setShowAddOvertime(true)
      },
    },
  ];

  return (
    <DashboardLayout title='My Overtime'>
      <div className='flex flex-col bg-white shadow-md rounded-md'>
        {showAddOvertime?
        <AddOvertime
          isOpen={showAddOvertime}
          closeModal={() => {
            setShowAddOvertime(false)
          }}
          overtimeData={overtimeData}
          title={title}
          buttonText={buttonText}
        />
        :
        null
        }
        <div className='flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3'>
          <div className='p3-bold header-4'>
            Overtimes
          </div>
          <div className='flex flex-col md:flex-row items-start gap-[10px] md:items-center'>
            <div className='md:w-fit w-[100%]'>
              <Button
              text='Apply for Overtime'
              type='submit'
              theme='primary'
              className='flex gap-2 h-[35px] w-fit p-2 '
              textClass={'!text-[13px]'}
              leftIcon={'add'}
              onClick={() => {
                setButtonText('Apply')
                setTitle('Apply For Overtime')
                setOvertimeData('')
                setShowAddOvertime(true)
              }}
              />
            </div>
          </div>
        </div>
        <hr className='divider mt-2 mb-2' />
        <DataTable
          data={employeeOvertimes}
          tableConfig={tableConfig}
          actionConfig={actionConfig}
          updatePagination={(data) => setPagination(data)}
          searchData={true}
          statusConfig={overtimeApplicationStatusConfig}
          computedActions={true}
          noCheck={true}
        />
      </div>
    </DashboardLayout>
  );
};

export default OvertimePage;
