import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { store } from '../..';
import { queryClient } from '../../..';
import { payroll } from '../../../api/payroll/payroll';
import { setAlert, setIsLoading } from '../../components/components-slice';

export function useGetAdvancedPayroll(id, limit, page, statusFilter, search) {
  return useQuery(
    ['advanced-payrolls', { id, limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return payroll.getAdvancedPayrolls({ id, limit, page, statusFilter, search });
    },
    {
      enabled: id !== null,
      select: (data) => {
        data?.data?.forEach((payroll) => {
          let month = moment(payroll.month).format('MMMM, YYYY');
          payroll.month = month;
          payroll.created = moment(payroll.created).format('MMMM DD, YYYY');
          payroll.status = payroll.status?parseFloat(payroll.status): 0;
          payroll.total_amount = payroll.total_amount? payroll.total_amount.toLocaleString() : 0

          switch (payroll.status) {
            case 0:
              payroll.computedActions = [0, 2, 3, 6];
              break;
            case 1:
              payroll.computedActions = [1, 2, 6];
              break;
            case 2:
              payroll.computedActions = [2, 4, 5, 6];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetPayslip(
  limit,
  page,
  statusFilter,
) {
  return useQuery(
    [
      'payroll-history',
      { limit, page, statusFilter },
    ],
    () => {
      store.dispatch(setIsLoading(true));
      return payroll.getPayslip({
        limit,
        page,
        statusFilter,
      });
    },
    // 'company',
    //     'employee',
    //     'vendor',
    //     'basic_salary',
    //     'trans_cost',
    //     'total_pay',
    //     'cra',
    //     'housing_allowance',
    //     'transport_allowance',
    //     'leave_allowance',
    //     'lunch_allowance',
    //     'benefit_in_kind',
    //     'other_allowance',
    //     'utility_allowance',
    //     'bonus',
    //     'gross_pay',
    //     'month',
    //     'pension',
    //     'employer_pension',
    //     'salary_advance',
    //     'tax',
    //     'nhf',
    //     'net_pay',
    //     'processed_amount',
    //     'nsitf',
    //     'itf',
    //     'nhis',
    //     'employer_nhis',
    //     'payment_date',
    //     'reimburse',
    //     'employee_first_name',
    //     'employee_last_name',
    //     'employee_email',
    //     'employee_phone',
    //     'description',
    //     'status',
    //     'ref',
    //     'group_id',
    //     'bank_name',
    //     'bank_code',
    //     'bank_nip',
    //     'employee_name',
    //     'vendor_name',
    //     'account_number',
    //     'account_name',
    //     'year',
    //     'okay',
    //     'type',
    //     'processed',
    //     'processed_date'
    {
      select: (data) => {
        data.data.forEach((transaction) => {
          transaction.month = moment(transaction.month).format('MMMM, YYYY')
          transaction.status = parseFloat(transaction.processed);
          transaction.formatted_net_pay = parseFloat(transaction.net_pay).toLocaleString();
          transaction.bank_info = transaction.bank_name + " | " + transaction.account_number;
          transaction.created = transaction.created.split('.')[0].replace('T', ' ');
          transaction.employeeName = transaction.employee_first_name + ' ' + transaction.employee_last_name;
          transaction.computedActions = [0, 1];
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useRefAdvancedPayroll(
  refId,
  limit,
  page,
  statusFilter,
  search,
) {
  return useQuery(
    ['advanced-payroll-by-ref', { refId, limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return payroll.fetchAdvancedPayrollByRef({
        refId,
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      select: (data) => {
        data.data.forEach((item) => {
          if (!item.status) {
            item.status = 0
          }
          item.other_accum_allowance =
            parseFloat(item.basic_salary) +
            parseFloat(item.benefit_in_kind) +
            parseFloat(item.bonus) +
            parseFloat(item.housing_allowance) +
            parseFloat(item.transport_allowance) +
            parseFloat(item.leave_allowance) +
            parseFloat(item.lunch_allowance) +
            parseFloat(item.other_allowance) +
            parseFloat(item.utility_allowance);
          item.bank_info = `${item.bank_name}`;
          item.trans_cost = item.trans_cost ? parseFloat(item.trans_cost) : 0;
          item.total = (
            item.trans_cost + parseFloat(item.net_pay)
          ).toLocaleString();

          item.status = parseInt(item.status);
          item.basic_salary = parseFloat(item.basic_salary).toFixed(2);

          item.employeeName = `${item.employee_first_name} ${item.employee_last_name}`;
          item.other_accum_allowance = parseFloat(
            item.other_accum_allowance,
          ).toFixed(2);
          item.basic_salary = parseFloat(item.basic_salary).toFixed(2);
          item.benefit_in_kind = parseFloat(item.benefit_in_kind).toFixed(2);
          item.bonus = parseFloat(item.bonus).toFixed(2);
          item.housing_allowance = parseFloat(item.housing_allowance).toFixed(
            2,
          );
          item.transport_allowance = parseFloat(
            item.transport_allowance,
          ).toFixed(2);
          item.leave_allowance = parseFloat(item.leave_allowance).toFixed(2);
          item.lunch_allowance = parseFloat(item.lunch_allowance).toFixed(2);
          item.other_allowance = parseFloat(item.other_allowance).toFixed(2);
          item.utility_allowance = parseFloat(item.utility_allowance).toFixed(
            2,
          );
          item.gross_pay = parseFloat(item.gross_pay).toFixed(2);
          item.pension = parseFloat(item.pension).toFixed(2);
          item.employer_pension = parseFloat(item.employer_pension).toFixed(2);
          item.salary_advance = parseFloat(item.salary_advance).toFixed(2);
          item.tax = parseFloat(item.tax).toFixed(2);
          item.nhf = parseFloat(item.nhf).toFixed(2);
          item.nsitf = parseFloat(item.nsitf).toFixed(2);
          item.itf = parseFloat(item.itf).toFixed(2);
          item.nhis = parseFloat(item.nhis).toFixed(2);
          item.employer_nhis = parseFloat(item.employer_nhis).toFixed(2);
          item.reimburse = parseFloat(item.reimburse).toFixed(2);
          item.net_pay = parseFloat(item.net_pay).toFixed(2);

          switch (item.status) {
            case 0:
              item.computedActions = [0, 1, 2];
              break;
            case 1:
              item.computedActions = [1, 2];
              break;
            case 2:
              item.computedActions = [1, 2, 3];
              break;
            default:
              break;
          }
        });
        data.total_net_pay = data.total_net_pay.toLocaleString();
        data.total_trans_cost = data.total_trans_cost.toLocaleString();
        data.total_payable = data.total_payable.toLocaleString();
        
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useRecalculateAdvancedPayroll() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.recalculateAdvancedPayroll(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('advanced-payrolls');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetAdvancedPayrollCheck(id) {
  return useQuery(
    ['advanced-payroll-check', id],
    () => {
      store.dispatch(setIsLoading(true));
      return payroll.advancedPayrollCheck(id);
    },
    {
      enabled: id !== null,
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetAdvancedPayrollCheckGroup(payload) {
  return useQuery(
    ['advanced-payroll-check-group', { payload }],
    () => {
      store.dispatch(setIsLoading(true));
      return payroll.advancedPayrollCheckGroup(payload);
    },
    {
      enabled: false,
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetAdvancedPayrollCheckSelective() {
  return useMutation((payload) => {
    return payroll.advancedPayrollCheckSelective(payload);
  });
}

export function useRunAdvancedPayroll() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.runAdvancedPayroll(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('advanced-payrolls');
        store.dispatch(setAlert(true, 'success', 'Payroll run successfully. Proceed to make payment.'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useSendPayslip() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.sendBulkPayslip(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('advanced-payrolls');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useSendSinglePayslip() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.sendSinglePayslip(payload);
    },
    {
      onSuccess: (response, variables, context) => {

        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useSendSelectPayslip() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.sendSelectPayslip(payload);
    },
    {
      onSuccess: (response, variables, context) => {

        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRunAdvancedPayrollSelective() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.runAdvancedPayrollSelective(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('advanced-payrolls');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useFetchAdvancedPayrollById(
  id,
  limit,
  page,
  statusFilter,
  search,
) {
  return useQuery(
    ['advanced-payroll-by-id', { id, limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return payroll.fetchAdvancedPayrollById({
        id,
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((item) => {
          if (!item.status) {
            item.status = 0
          }
          item.other_accum_allowance =
            parseFloat(item.basic_salary) +
            parseFloat(item.benefit_in_kind) +
            parseFloat(item.bonus) +
            parseFloat(item.housing_allowance) +
            parseFloat(item.transport_allowance) +
            parseFloat(item.leave_allowance) +
            parseFloat(item.lunch_allowance) +
            parseFloat(item.other_allowance) +
            parseFloat(item.utility_allowance);
          item.bank_info = `${item.bank_name}`;
          item.trans_cost = item.trans_cost ? parseFloat(item.trans_cost) : 0;
          item.total = (
            item.trans_cost + parseFloat(item.net_pay)
          ).toLocaleString();

          item.status = parseInt(item.status);
          item.basic_salary = parseFloat(item.basic_salary).toFixed(2);

          item.employeeName = `${item.employee_first_name} ${item.employee_last_name}`;
          item.other_accum_allowance = parseFloat(
            item.other_accum_allowance,
          ).toFixed(2);
          item.basic_salary = parseFloat(item.basic_salary).toFixed(2);
          item.benefit_in_kind = parseFloat(item.benefit_in_kind).toFixed(2);
          item.bonus = parseFloat(item.bonus).toFixed(2);
          item.housing_allowance = parseFloat(item.housing_allowance).toFixed(
            2,
          );
          item.transport_allowance = parseFloat(
            item.transport_allowance,
          ).toFixed(2);
          item.leave_allowance = parseFloat(item.leave_allowance).toFixed(2);
          item.lunch_allowance = parseFloat(item.lunch_allowance).toFixed(2);
          item.other_allowance = parseFloat(item.other_allowance).toFixed(2);
          item.utility_allowance = parseFloat(item.utility_allowance).toFixed(
            2,
          );
          item.gross_pay = parseFloat(item.gross_pay).toFixed(2);
          item.pension = parseFloat(item.pension).toFixed(2);
          item.employer_pension = parseFloat(item.employer_pension).toFixed(2);
          item.salary_advance = parseFloat(item.salary_advance).toFixed(2);
          item.tax = parseFloat(item.tax).toFixed(2);
          item.nhf = parseFloat(item.nhf).toFixed(2);
          item.nsitf = parseFloat(item.nsitf).toFixed(2);
          item.itf = parseFloat(item.itf).toFixed(2);
          item.nhis = parseFloat(item.nhis).toFixed(2);
          item.employer_nhis = parseFloat(item.employer_nhis).toFixed(2);
          item.reimburse = parseFloat(item.reimburse).toFixed(2);
          item.net_pay = parseFloat(item.net_pay).toFixed(2);

          switch (item.status) {
            case 0:
              item.computedActions = [0, 1, 2];
              break;
            case 1:
              item.computedActions = [1, 2];
              break;
            case 2:
              item.computedActions = [1, 2, 3];
              break;
            default:
              break;
          }
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useDisburseAdvancedPayroll() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.disburseAdvancedPayroll(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('advanced-payrolls');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useInitiateAdvancedPayroll() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.initiateAdvancedPayroll(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('advanced-payrolls');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useInitiateBasicPayroll() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.initiateBasicPayroll(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('basic-payrolls');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRevertPayroll() {
  return useMutation(
    () => {
      store.dispatch(setIsLoading(true));
      return {
        status: 'success',
        message: 'No Payroll With This Reference Number',
      };
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useCheckBulkTransactionStatus() {
  return useMutation(
    () => {
      return payroll.refreshTransactionStatus();
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('payroll-history');
        store.dispatch(setAlert(true, 'success', 'All Statuses Refreshed Successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeclineAdvancedPayroll() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.declineAdvancedPayroll(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('advanced-payrolls');
        store.dispatch(setAlert(true, 'success', 'Payroll declined successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

