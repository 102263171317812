import React, { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  useGetTeam,
  useGetTeamEmployees,
} from '../../redux/human-resources/hook/teams';
import {
  employeeStatusConfig,
  PAGINATION_DEFAULT,
} from '../../utils/constants';
import DataTable from '../../components/datatable';
import DashboardLayout from '../../components/layouts/dashboard';
import { Initials } from '../../utils/functions';

const ViewTeamPage = () => {

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: '',
  });
  const { id } = useParams();
  const { data: team } = useGetTeam(id);

  const { data: team_employees } = useGetTeamEmployees(
    id,
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search,
  );

  const tableConfig = {
    headers: ['Date Added', 'Employee Name', 'Email', 'Mobile'],
    keys: ['created', 'employeeName', 'employee_email', 'employee_mobile'],
    mobileHeader: {
      left: {
        title: 'Employee Name',
        key: 'employeeName',
      },
      right: {
        title: 'Mobile',
        key: 'employee_mobile',
      },
    },
  };

  return (
    <DashboardLayout title='View Team'>
      <div className='flex flex-row items-center box-shadow h-[104px]'>
        <div className='flex flex-row px-[55px] justify-between  w-full'>
          <div className='flex flex-row items-center'>
            <div className='flex font-bold justify-center items-center h-[58px] w-[58px] rounded-[58px] border-[3px] header-3 border-secondary-2 bg-[#FFC0CB]'>
              {team?.name ? Initials(team?.name): null}
            </div>
            <div className='flex flex-col pl-4 items-start justify-center'>
              <span className='header-4'>{team?.name}</span>
              <span className='p4'>{team?.description}</span>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col bg-white mt-4 shadow-md rounded-md'>
        <div className='flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3'>
          <div className='p3-bold header-4'>Team Employees List</div>
        </div>
        <hr className='divider mt-2' />
        <DataTable
          data={team_employees}
          tableConfig={tableConfig}
          statusConfig={employeeStatusConfig}
          updatePagination={(data) => setPagination(data)}
          noCheck={true}
          searchData={true}
        />
      </div>
    </DashboardLayout>
  );
};

export default ViewTeamPage;
