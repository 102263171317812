import React, { useEffect } from 'react';
import DataTable from '../datatable';
import { useState } from 'react';
import {
  attendanceStatusConfig,
  PAGINATION_DEFAULT,
} from '../../utils/constants';
import { useHistory } from 'react-router-dom';
import Button from '../button';
import AddAttendance from '../modal/hr-section/attendance/add-attendance';
import { useGetAttendances } from '../../redux/human-resources/hook/attendance';
import ViewAttendance from '../modal/hr-section/attendance/view-attendance';

const AttendanceHistoryTab = () => {

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: '',
    start_date: '',
    end_date: '',
  });

  const { data: attendances } = useGetAttendances(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search,
    pagination?.start_date,
    pagination?.end_date,
  );

  const history = useHistory();

  const tableConfig = {
    headers: [
      'Date',
      'Employee',
      'Mobile',
      'Clock In',
      'Clock Out',
      'Status',
    ],
    keys: ['attendance_date', 'employeeName', 'mobile', 'clockin_time','clockout_time','status'],
    mobileHeader: {
      left: {
        title: 'Employee Name',
        key: 'employeeName',
      },
      right: {
        title: 'Status',
        key: 'status',
      },
    },
  };

  const [showAddAttendance, setshowAddAttendance] = useState(false);
  const [attendanceData, setAttendanceData] = useState()
  const [showViewAttendance, setShowViewAttendance] = useState(false);

  const actionConfig = [
    {
      name: 'View Attendance',
      color: 'orange',
      action: (id) => {
        let index = attendances.data.findIndex((item) => item.id === id);
        setAttendanceData(attendances.data[index])
        setShowViewAttendance(true)
      },
    },
  ];

  return (
    <div className='flex flex-col bg-white shadow-md rounded-md'>
      {showViewAttendance ?
        <ViewAttendance
          isOpen={showViewAttendance}
          closeModal={() => setShowViewAttendance(false)}
          attendance={attendanceData}
        />
      : null}
      {showAddAttendance ?
        <AddAttendance
          isOpen={showAddAttendance}
          closeModal={() => setshowAddAttendance(false)}
          attendanceData={attendanceData}
          title='Add Attendance'
          buttonText='SAVE ATTENDANCE'
        />
      : null}
      <div className='flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3'>
        <div className='p3-bold header-4'>
          Attendance History
        </div>
      </div>
      <hr className='divider mt-2 mb-2' />
      <DataTable
        data={attendances}
        tableConfig={tableConfig}
        statusConfig={attendanceStatusConfig}
        actionConfig={actionConfig}
        filterByDate={true}
        updatePagination={(data) => setPagination(data)}
        filterByStatus={true}
        computedActions={true}
        searchData={true}
      />
    </div>
  );
};

export default AttendanceHistoryTab;
