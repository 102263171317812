import { Axios } from './axios';

const login = async (payload) => {
  const { data } = await Axios.post('/mobile/login', payload);
  return data;
};

const register = async (payload) => {
  const { data } = await Axios.post('/mobile/register', payload,
  {
    timeout: 0
  });
  return data;
};

const passwordRecover = async (payload) => {
  const { data } = await Axios.post('/mobile/recover-password', payload);
  return data;
};

const passwordReset = async (payload) => {
  const { data } = await Axios.post('/mobile/reset-password-email', payload);
  return data;
};

const adminPasswordSet = async (payload) => {
  const { data } = await Axios.post('/mobile/set-password', payload);
  return data;
};

const updatePassword = async (payload) => {
  const { data } = await Axios.post('/mobile/change-password', payload);
  return data;
};

const profile = async () => {
  const { data } = await Axios.get('/mobile/me');
  return data;
};

const verifyEmail = async (payload) => {
  const { data } = await Axios.post('/mobile/verify-email', payload);
  return data;
};

const phoneVerification = async (payload) => {
  const { data } = await Axios.post('/mobile/phone-verification', payload);
  return data;
};

const bvnVerification = async (payload) => {
  const { data } = await Axios.post('/mobile/bvn-verification', payload);
  return data;
};

const enable2Fa = async (payload) => {
  const { data } = await Axios.post('/mobile/enable-two-fa', payload);
  return data;
};

const activate2fa = async (payload) => {
  const { data } = await Axios.post('/mobile/activate-two-fa', payload);
  return data;
};

const disable2Fa = async (payload) => {
  const { data } = await Axios.get('/mobile/disable-two-fa', payload);
  return data;
};

const login2Fa = async (payload) => {
  const { data } = await Axios.post('/mobile/two-fa-login', payload);
  return data;
};

const backupLogin2Fa = async (payload) => {
  const { data } = await Axios.post('/mobile/backup-login', payload);
  return data;
};

export const user = {
  login,
  register,
  profile,
  passwordRecover,
  passwordReset,
  updatePassword,
  adminPasswordSet,
  verifyEmail,
  phoneVerification,
  bvnVerification,
  enable2Fa,
  activate2fa,
  disable2Fa,
  login2Fa,
  backupLogin2Fa
};
