import { Axios } from '../axios';

const getAdvancedPayrolls = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';

  const { data } = await Axios.get(
    `/mobile/branch/payrolls/${payload.id}?limit=${payload.limit}&page=${payload.page}${filter}${search}`,
  );
  return data?.data;
};

const getPayslip = async (payload) => {
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';

  const { data } = await Axios.get(
    `/mobile/payslips?limit=${payload.limit}&page=${payload.page}${filter}`,
  );

  return data?.data;
};

const advancedPayrollCheck = async (payload) => {
  const { data } = await Axios.get('/mobile/branch/payroll/check-branch/'+ payload,
  {
    timeout: 0
  });
  return data?.data;
};

const runAdvancedPayroll = async (payload) => {
  const { data } = await Axios.post('/mobile/branch/payroll/run', payload,
  {
    timeout: 0
  });
  return data;
};

const fetchAdvancedPayrollByRef = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';

  const { data } = await Axios.get(`/mobile/branch/payroll/fetch-by-ref/${payload.refId}?limit=${payload.limit}&page=${payload.page}${filter}${search}`);
  if (data.data) {
    data.data.salaries.total_net_pay = data?.data.total_amount
    data.data.salaries.total_payable = data?.data.total_payable
    data.data.salaries.total_trans_cost = data?.data.total_fees
    data.data.salaries.source = data?.data.source
    data.data.salaries.source_id = data?.data.source_id

  }
  return data?.data.salaries;
};

const fetchAdvancedPayrollById = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';

  const { data } = await Axios.get(`/mobile/branch/show-payroll/${payload.id}?limit=${payload.limit}&page=${payload.page}${filter}${search}`);
  if (data.data) {
    data.data.salaries.total_net_pay = data?.data.total_amount
    data.data.salaries.total_payable = data?.data.total_payable
    data.data.salaries.total_trans_cost = data?.data.total_fees
  }
  return data?.data.salaries;
};


const initiateAdvancedPayroll = async (payload) => {
  const { data } = await Axios.post(`/mobile/branch/payroll/initiate-disbursement`, payload);
  return data;
};

const disburseAdvancedPayroll = async (payload) => {
  const { data } = await Axios.post('/mobile/branch/payroll/disburse', payload,
  {
    timeout: 0
  });
  return data;
};

const sendBulkPayslip = async (payload) => {
  const { data } = await Axios.post('/mobile/branch/payroll/generate-payslip-bulk', payload,
  {
    timeout: 0
  });
  return data;
};

const sendSinglePayslip = async (payload) => {
  const { data } = await Axios.post('/mobile/branch/payroll/generate-payslip-single', payload,
  {
    timeout: 0
  });
  return data;
};

const sendSelectPayslip = async (payload) => {
  const { data } = await Axios.post('/mobile/branch/payroll/generate-payslip-select', payload,
  {
    timeout: 0
  });
  return data;
};



export const payroll = {
  getAdvancedPayrolls,
  getPayslip,
  advancedPayrollCheck,
  runAdvancedPayroll,
  fetchAdvancedPayrollByRef,
  disburseAdvancedPayroll,
  initiateAdvancedPayroll,
  sendBulkPayslip,
  sendSinglePayslip,
  sendSelectPayslip,
  fetchAdvancedPayrollById
  
};
