import { useMutation, useQuery } from '@tanstack/react-query';
import { store } from '../..';
import { queryClient } from '../../..';
import { setAlert, setIsLoading } from '../../components/components-slice';
import { setEmployeeOnboarding} from '../reducer';
import moment from 'moment';
import { employeeOnboardings } from '../../../api/employees/onboarding';


export function useFetchOnboarding() {
  return useQuery(
    ['employee-onboarding'],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeOnboardings.fetchOnboarding();
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'Your onboarding details fetched successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useEmployeeMarkOnboardingStageComplete() {
  return useMutation(
      (payload) => {
          store.dispatch(setIsLoading(true));
          return employeeOnboardings.employeeMarkStageComplete(payload);
      },
      {
          onSuccess: (response, variables, context) => {
            store.dispatch(setAlert(true, 'success', 'Updated successfully'));
          },
          onSettled: (data, error, variables, context) => {
              store.dispatch(setIsLoading(false));
          },
      },
  );
}

export function useEmployeeMarkOnboardingComplete() {
  return useMutation(
      (payload) => {
          store.dispatch(setIsLoading(true));
          return employeeOnboardings.employeeMarkOnboardingComplete(payload);
      },
      {
        onSuccess: (response, variables, context) => {
          store.dispatch(setAlert(true, 'success', 'Updated successfully'));
        },
        onSettled: (data, error, variables, context) => {
            store.dispatch(setIsLoading(false));
        },
      },
  );
}

export function useSaveAnswer() {
  return useMutation(
      (payload) => {
          store.dispatch(setIsLoading(true));
          return employeeOnboardings.employeeSaveAnswer(payload);
      },
      {
          onSuccess: (response, variables, context) => {
            store.dispatch(setAlert(true, 'success', 'Updated successfully'));
          },
          onSettled: (data, error, variables, context) => {
              store.dispatch(setIsLoading(false));
          },
      },
  );
}

export function useUploadFile() {
  return useMutation(
      (payload) => {
          store.dispatch(setIsLoading(true));
          return employeeOnboardings.uploadRequestedFile(payload);
      },
      {
          onSuccess: (response, variables, context) => {
            store.dispatch(setAlert(true, 'success', 'File uploaded successfully'));
          },
          onSettled: (data, error, variables, context) => {
              store.dispatch(setIsLoading(false));
          },
      },
  );
}

export function useClearOnboardingData() {
  return useMutation(
      (payload) => {
          store.dispatch(setIsLoading(true));
          return;
      },
      {
          onSuccess: (response, variables, context) => {
            store.dispatch(setEmployeeOnboarding(null));
            store.dispatch(setAlert(true, 'success', 'Session Expired'));
          },
          onSettled: (data, error, variables, context) => {
              store.dispatch(setIsLoading(false));
          },
      },
  );
}

