import DOMPurify from 'dompurify';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../../assets/svg/close.svg';
import FormInput from '../../../form-input';
import { useAddPerformanceSmartGoals, useEditPerformanceSmartGoals } from '../../../../redux/human-resources/hook/performance';
import FormSelectBranch from '../../../employee/form-select-branch';
import FormSelectDepartment from '../../../employee/form-select-department';
import FormSelectEmployee from '../../../employee/form-select-employee';
import FormSelectTeam from '../../../employee/form-select-team';
import { store } from '../../../../redux';
import { setAlert } from '../../../../redux/components/components-slice';
import Modal from '../../modal';
import FormSelect from '../../../form-select';

function AddTechnicalAppraisals({
  isOpen,
  closeModal,
  technicalAppraisalData,
  title,
  buttonText,
}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const [source, setSource] = useState('employee');
  const [sourceId, setSourceId] = useState(null);
  const [sourceName, setSourceName] = useState(null);

  const sourceOptions = [
    { label: 'Employee', value: 'employee' },
    { label: 'Team', value: 'team' },
    { label: 'Department', value: 'department' },
    { label: 'Branch', value: 'branch' },
  ];

  const [employee, setEmployee] = useState();

  const [team, setTeam] = useState();

  const [department, setDepartment] = useState();

  const [branch, setBranch] = useState();

  const [appraisals, setAppraisals] = useState([
    {
      appraisal: '',
      weight: '',
    },
  ]);

  const [technicalAppraisalName, setTechnicalAppraisalName] = useState(null);

  useEffect(() => {
    if (technicalAppraisalData) {
        setTechnicalAppraisalName(technicalAppraisalData.technical_appraisal_title);
        setAppraisals(technicalAppraisalData.appraisals);
        setSourceName(technicalAppraisalData.source_name)
        setSourceId(technicalAppraisalData.source_id)
        setSource(technicalAppraisalData.source)
        setPerformanceType('edit');
    } else {
        setPerformanceType('add');
    }
  }, [technicalAppraisalData]);

  const [weightError, setWeightError] = useState('Total goals weight must be equal to (100)')
  const [preformanceType, setPerformanceType] = useState('add');

  const addGoal = () => {
    setAppraisals([
      ...appraisals,
      ...[
        {
          appraisal: '',
          weight: '',
        },
      ],
    ]);
  };
  
  return (
    <Modal
      scrollable={true}
      className='!max-w-[600px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <form className='form pb-6'>
          <div className='pl-6 pr-6 pt-4'>
            <div className='flex flex-row justify-between'>
              <span className='header-3'>{title}</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='' />
              </div>
            </div>
          </div>
          <hr className='divider mb-3 mt-2' />
          <div className='flex flex-col max-h-fit mx-14'>
          <div className='flex flex-row md:flex-col w-full justify-between'>
              <div className='w-full md:w-1/2 pr-2'>
                <FormSelect
                  readOnly={true}
                  defaultValue={source}
                  options={sourceOptions}
                  onChange={(selected) => {
                    setSource(selected);
                    setSourceId(null);
                  }}
                  label='SMART Goals For:'
                />
              </div>
              <div className='w-full md:w-1/2 pl-2'>
                {!source || source === 'company' ? null : source ===
                  'employee' ? (
                  <FormSelectEmployee
                    label={'Employee'}
                    name='employee'
                    placeholder='Select Employee'
                    value={sourceId}
                    empName={sourceName}
                    readOnly={true}
                    setSourceName={setSourceName}
                    setEmployee={setSourceId}
                  />
                ) : source === 'team' ? (
                  <FormSelectTeam
                    label={'Team'}
                    name='team'
                    placeholder='Select Team'
                    value={sourceId}
                    teamName={sourceName}
                    readOnly={true}
                    setSourceName={setSourceName}
                    setTeam={setSourceId}
                  />
                ) : source === 'department' ? (
                  <FormSelectDepartment
                    label={'Department'}
                    name='department'
                    placeholder='Select Department'
                    value={sourceId}
                    readOnly={true}
                    departmentName={sourceName}
                    setSourceName={setSourceName}
                    setDepartment={setSourceId}
                  />
                ) : (
                  <FormSelectBranch
                    label={'Branch'}
                    name='branch'
                    placeholder='Select Branch'
                    value={branch}
                    readOnly={true}
                    branchName={sourceName}
                    setSourceName={setSourceName}
                    setBranch={setSourceId}
                  />
                )}
              </div>
            </div>
            <FormInput
              label='Technical Appraisal title'
              Placeholder='Enter Technical Appraisal title'
              name='name'
              type='text'
              value={technicalAppraisalName}
              readOnly
              className='!h-[30px] text-[13px] w-[100%] '
            />
            <div className='flex flex-col gap-3'>
              <div className='flex justify-end w-full text-[14px] text-red-600 font-bold italic'>{weightError? weightError: null}</div>
              {appraisals &&
              appraisals?.map((item, index) => (
                <div className='flex flex-col hover:shadow-2xl shadow-green-500 p-2 px-4 rounded-xl border-1 bg-secondary-2-extralight overflow-clip'>
                  <div className='flex justify-between'>
                    <div className='text-[13px] italic text-[#44AF69] font-semibold'>
                        Appraisal {index + 1}
                    </div>
                  </div>
                  <div
                    className='flex flex-row items-center justify-between first-line:-mt-3 pr-2 text-[13px]'
                    key={index}
                  >
                    <div className='flex flex-row items-center !w-[100%]'>
                      <FormInput
                        Placeholder='Enter SMART Goal'
                        name={index + '_goal_name'}
                        type='text'
                        value={item.appraisal}
                        readOnly
                        className='!h-[30px] text-[13px] w-[100%] border-1 border-gray-300 md:min-w-[300px]'
                      />
                    </div>
                    <div className='flex flex-row justify-end items-center'>
                      <FormInput
                        type='number'
                        Placeholder='Weight'
                        value={item.weight}
                        readOnly
                        className='!h-[30px] text-[13px] w-[100%] border-1 border-gray-300 min-w-[100px]'
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddTechnicalAppraisals;
