import React, { useState } from "react";
import classNames from "classnames";
import Button from "./button";
import { useHistory } from "react-router-dom";
import AddCompanyModal from "./modal/add-company/add-company";

export const Card = ({ title, icon, path }) => {
  const [showAddCompany, setShowAddCompany] = useState(false);
  const history = useHistory();
  return (
    <div
      className={classNames(
        `md:h-[200px] md:w-[200px] w-[160px] p-3 flex flex-col justify-center items-center border rounded-md hover:bg-highlight hover:shadow-md`,
        {
          "bg-green-100 hover:bg-green-200": title === "ONBOARDING",
          "bg-red-100 hover:bg-red-200": title === "EXIT",
        }
      )}
    >
      {showAddCompany ? (
        <AddCompanyModal
          isOpen={showAddCompany}
          closeModal={() => setShowAddCompany(false)}
        />
      ) : null}
      <div className="flex flex-col items-center md:justify-between justify-between gap-4 !min-w-full ">
        <div
          className={classNames("flex justify-center items-center min-w-[40%]")}
        >
          <img src={icon} alt="" width={"80px"} height={"80px"} />
        </div>
        <div className="flex flex-col items-center justify-around gap-2">
          <span className="font-bold text-[15px] leading-[12px] text-color-gray flex flex-col text-center">
            <div className="md:w-fit w-[100%]" id="second-step">
              <Button
                text={title}
                type="button"
                theme="secondary"
                className="flex gap-2 h-[35px] md:w-[150px] w-[130px] p-2"
                textClass={"!text-[12px]"}
                onClick={() => {
                  if (path == "/add-company") {
                    setShowAddCompany(true);
                  } else {
                    history.push(path);
                  }
                }}
              />
            </div>
          </span>
        </div>
      </div>
    </div>
  );
};
