import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { store } from '../..';
import { queryClient } from '../../..';
import { setAlert, setIsLoading } from '../../components/components-slice';
import { employeeOvertime } from '../../../api/employees/overtime';
import { formatNumber } from '../../../utils/functions';

export function useGetEmployeeOvertimes(limit, page, statusFilter, search) {
  return useQuery(
    ['employee-overtimes', { limit, page, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeOvertime.getEmployeeOvertimes({
        limit,
        page,
        search,
      });
    },
    {
      select: (data) => {
        data.data.forEach((employee) => {
          employee.formatted_amount = formatNumber(parseFloat(
            employee.amount,
          ));
          employee.employeeName = `${employee.first_name} ${employee.last_name}`;

          if (employee.type == 0 || employee.type == 1) {
            employee.typeLabel = 'One Time';
            employee.formatted_month = moment(employee.month).format('MMMM, YYYY');
          } else if (employee.type === 2) {
            employee.typeLabel = 'Recurring';
            employee.month = '-';
            employee.formatted_month = '-';
          }
          //compute action items
          switch (employee.status) {
            case 0:
              employee.computedActions = [0, 1, 4];
              break;
            case 3:
              employee.computedActions = [2, 3, 4];
              break;
            default:
              break;
          }
        });

        return data;
      },

      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useAddOvertime() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeOvertime.addOvertime(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-overtimes');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeleteOvertime() {
  return useMutation(
    (payload) => {
      return employeeOvertime.deleteOvertime(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('employee-overtimes');
        store.dispatch(setAlert(true, 'success', 'Overtime deleted successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useEditOvertime() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeOvertime.editOvertime(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-overtimes');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}





