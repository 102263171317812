import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import CloseSVG from '../../../assets/svg/close.svg';
import { formValidation } from '../../../utils/functions';
import Button from '../../button';
import FormInput from '../../form-input';
import FormSelect from '../../form-select';
import {
  useAddLeave,
  useGetApplicationLeaveTypes,
} from '../../../redux/human-resources/hook/leave';
import Modal from '../modal';
import moment from 'moment';

function AddLeave({
  isOpen,
  closeModal,
}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const { data: leave_types, isLoading: leaveTypeLoading } =
  useGetApplicationLeaveTypes(
    50,
    1,
    '',
  );

  const { mutateAsync: addLeave, isLoading: addLeaveloading } = useAddLeave();

  useEffect(() => {
    if (leave_types) {
      setLeaveTypeOptions(leave_types);
    }
  }, [leave_types]);

  const [leaveType, setLeaveType] = useState();

  const [leaveTypeOptions, setLeaveTypeOptions] = useState();

  const history = useHistory();

  const submitForm = async (data) => {
    const start_date = DOMPurify.sanitize(data?.start_date);
    const end_date = DOMPurify.sanitize(data?.end_date);
    const purpose = DOMPurify.sanitize(data?.purpose);

    let payload = {
      leave_type: leaveType,
      start_date: start_date,
      end_date: end_date,
      purpose,
    };
    addLeave(payload).then(() => {
      closeModal();
    });
  };

  return (
    <>
      <Modal
        scrollable={true}
        className='!max-w-[600px] !rounded-none'
        isOpen={isOpen}
        centered={true}
      >
        <div className='flex flex-col overflow-auto'>
          <form className='form' onSubmit={handleSubmit(submitForm)}>
            <div className='pl-8 pr-8 pt-8'>
              <div className='flex flex-row justify-between'>
                <span className='header-3'>Apply for Leave</span>
                <div className='cursor-pointer' onClick={() => closeModal()}>
                  <img src={CloseSVG} alt='' />
                </div>
              </div>
            </div>
            <hr className='divider' />
            <div className='flex flex-col mx-14'>
              <div>
                <div className='flex flex-row md:flex-col w-full justify-between'>
                  {leave_types && leaveTypeOptions? (
                    <div className='w-full'>
                      <FormSelect
                        value={leaveType}
                        options={leaveTypeOptions}
                        onChange={(selected) => {
                          setLeaveType(selected);
                        }}
                        label='Leave Type'
                      />
                    </div>
                  ) : null}
                </div>
                <div className='flex flex-row md:flex-col w-full justify-between'>
                  <div className='w-full md:pr-2 md:w-1/2'>
                    <FormInput
                      label='Start Date'
                      name='start_date'
                      type='date'
                      min={moment().format("YYYY-MM-DD")}
                      inputRef={register(formValidation('date', true))}
                      error={errors.start_date}
                      errorMessage={
                        errors.start_date && errors.start_date.message
                      }
                    />
                  </div>
                  <div className='w-full md:pl-2 md:w-1/2'>
                    <FormInput
                      label='End Date'
                      name='end_date'
                      type='date'
                      inputRef={register(formValidation('date', true))}
                      error={errors.end_date}
                      errorMessage={errors.end_date && errors.end_date.message}
                    />
                  </div>
                </div>
                <FormInput
                  label='Purpose'
                  name='purpose'
                  type='text'
                  inputRef={register(formValidation('text', true))}
                  error={errors.purpose}
                  errorMessage={errors.purpose && errors.purpose.message}
                />
              </div>
              {leave_types?
              <div className='w-full mt-[20px] pb-4 '>
                <Button
                  text={'Apply'}
                  type='submit'
                  loading={addLeaveloading}
                />
              </div>
              :
              <div className=' bg-gray-100 border border-gray-300 text-[14px] p-3 rounded text-gray-600 mb-4 mt-2'>
                <span className='font-semibold'>Note:</span> You cannot apply for leave, as no leave type has been added by your admin. Contact your company admin for further information
              </div>
              }
              
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default AddLeave;
