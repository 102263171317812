import React from 'react';
import ProtectedRoute from './protected';
import HRPerformancePage from '../pages/performance-management';
import SelectEvaluationOptions from '../pages/performance-management/performance-process/select-option';
import BasicEvaluation from '../pages/performance-management/performance-process/basic-evaluation';
import AdvancedEvaluation from '../pages/performance-management/performance-process/advanced-evaluation';
import ViewPerformanceEvaluation from '../pages/performance-management/view-performance-evaluation';
import TeamLeadViewPerformanceEvaluation from '../pages/performance-management/view-performance-evaluation-team-lead';

export const PerformanceRoutes = [
  <ProtectedRoute exact path='/performance' component={HRPerformancePage} />,
  <ProtectedRoute exact path='/performance/select-option' component={SelectEvaluationOptions} />,
  <ProtectedRoute exact path='/performance/evaluate/basic' component={BasicEvaluation} />,
  <ProtectedRoute exact path='/performance/evaluate/advanced' component={AdvancedEvaluation} />,
  <ProtectedRoute exact path='/performance/view/:id' component={ViewPerformanceEvaluation} />,
  <ProtectedRoute exact path='/performance/team-lead/view/:id' component={TeamLeadViewPerformanceEvaluation} />,
];