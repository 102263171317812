import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../assets/svg/close.svg';
import { formValidation } from '../../../utils/functions';
import Button from '../../button';
import FormInput from '../../form-input';
import Modal from '../modal';
import DOMPurify from 'dompurify';
import { useAddOvertime } from '../../../redux/employee/hook/overtime';

function AddOvertime({
  isOpen,
  closeModal,
  title,
  buttonText,
}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const { mutateAsync: addOvertime, isLoading: addOvertimeLoading } =
    useAddOvertime();

  const [overtime_hours, setOvertimeHours] = useState(1);
  const [amount, setAmount] = useState(0);
  const [overtime_rate_per_hour, setOvertimeRatePerHour] = useState(500);

  useEffect(() => {
    if (overtime_hours && overtime_rate_per_hour) {
      setAmount(overtime_hours * overtime_rate_per_hour)
    } else {
      setAmount(0)
    }

  }, [overtime_hours, overtime_rate_per_hour])

  const submitForm = async (data) => {
    let payload = {
      amount: parseFloat(DOMPurify.sanitize(amount)),
      overtime_hours,
      overtime_rate_per_hour,
      description: DOMPurify.sanitize(data?.description),
    }
    await addOvertime(payload).then(() => {
      closeModal();
    });
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[600px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto '>
        <form className='form pb-4' onSubmit={handleSubmit(submitForm)}>
          <div className='pl-4 pr-4 pt-4'>
            <div className='flex flex-row justify-between'>
              <span className='header-3'>{title}</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='' />
              </div>
            </div>
          </div>
          <hr className='divider mb-2' />
          <div className='flex flex-col mx-4'>
            <div>
              <FormInput
                label='Description'
                name='description'
                placeholder='Enter description'
                type='text'
                inputRef={register(formValidation('text', true))}
                readOnly={addOvertimeLoading}
                error={errors.description}
                errorMessage={
                  errors.description &&
                  errors.description.message
                }
              />
              <div className='flex flex-row md:flex-col w-full justify-between items-center'>
                <div className='w-full md:w-1/2 pr-2'>
                  <FormInput
                    label='Overtime Hours'
                    name='overtime_hours'
                    type='number'
                    inputRef={register(formValidation('number', true))}
                    readOnly={addOvertimeLoading}
                    error={errors.overtime_hours}
                    onInput={(e) => {
                      setOvertimeHours(parseInt(e.target.value));
                    }}
                    errorMessage={errors.overtime_hours && errors.overtime_hours.message}
                  />
                </div>
                <div className='flex flex-col w-full md:w-1/2 pl-2'>
                  <FormInput
                    label='Overtime Rate Per Hour - (Read Only)'
                    name='overtime_rate_per_hour'
                    type='number'
                    inputRef={register(formValidation('number', true))}
                    readOnly={true}
                    value={overtime_rate_per_hour}
                  />
                </div>
              </div>
              <div className='flex flex-row md:flex-col w-full justify-between items-center'>
                <div className='w-full md:w-1/2'>
                  <FormInput
                    label='Amount'
                    name='amount'
                    type='number'
                    inputRef={register(formValidation('number', true))}
                    readOnly={true}
                    value={amount}
                  />
                </div>
              </div>
            </div>
            <div className='w-full mt-[10px]'>
              <Button
                text={buttonText}
                type='submit'
                loading={addOvertimeLoading}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddOvertime;
