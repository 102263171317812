import React, { useEffect } from "react";
import DataTable from "../../components/datatable";
import { useState } from "react";
import {
  PAGINATION_DEFAULT,
  salaryAdvanceApplicationStatusConfig,
} from "../../utils/constants";
import AddSalaryAdvance from "../../components/modal/employees/add-salary-advance";
import {
  useDeleteSalaryAdvance,
  useGetEmployeeSalaryAdvance,
} from "../../redux/employee/hook/salary-advance";
import Button from "../../components/button";
import DashboardLayout from "../../components/layouts/dashboard";

const SalaryAdvancePage = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const { data: employeeAdvance } = useGetEmployeeSalaryAdvance(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const { mutateAsync: deleteSalaryAdvance } = useDeleteSalaryAdvance();

  const [saladData, setSaladData] = useState();
  const [title, setTitle] = useState(null);
  const [buttonText, setButtonText] = useState("Add Salary Advance");
  const [showAddSalaryAdvance, setShowAddSalaryAdvance] = useState(false);

  const [showDoYouWant, setShowDoYouWant] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const tableConfig = {
    headers: [
      "Employee Name",
      "Mobile",
      "Purpose",
      "Month",
      "Amount",
      "Status",
    ],
    keys: [
      "employeeName",
      "mobile",
      "purpose",
      "formatted_month",
      "amount",
      "status",
    ],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "Amount",
        key: "amount",
      },
    },
  };

  const actionConfig = [
    {
      name: "View/Update",
      color: "orange",
      action: (id) => {
        let index = employeeAdvance.data.findIndex((item) => item.id === id);
        setButtonText("Update");
        setTitle("Update Salary Advance/Loan");
        setSaladData(employeeAdvance.data[index]);
        setShowAddSalaryAdvance(true);
      },
    },
  ];

  return (
    <DashboardLayout title="My Salary Advance/Loans">
      <div className="flex flex-col bg-white shadow-md rounded-md">
        {showAddSalaryAdvance ? (
          <AddSalaryAdvance
            isOpen={showAddSalaryAdvance}
            closeModal={() => setShowAddSalaryAdvance(false)}
            saladData={saladData}
            title={title}
            buttonText={buttonText}
          />
        ) : null}
        <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
          <div className="p3-bold header-4">
            Salary Advance/Loan Applications
          </div>
          <div className="flex flex-col md:flex-row items-start gap-[10px] md:items-center">
            <div className="md:w-fit w-[100%]">
              <Button
                text="Apply for salary advance/Loan"
                type="submit"
                theme="primary"
                className="flex gap-2 h-[35px] w-fit p-2 "
                textClass={"!text-[13px]"}
                leftIcon={"add"}
                onClick={() => {
                  setButtonText("Apply");
                  setTitle("Apply for salary advance/Loan");
                  setShowAddSalaryAdvance(true);
                }}
              />
            </div>
          </div>
        </div>
        <hr className="divider mt-2 mb-2" />
        <DataTable
          data={employeeAdvance}
          tableConfig={tableConfig}
          actionConfig={actionConfig}
          statusConfig={salaryAdvanceApplicationStatusConfig}
          updatePagination={(data) => setPagination(data)}
          searchData={true}
          computedActions={true}
          noCheck={true}
        />
      </div>
    </DashboardLayout>
  );
};

export default SalaryAdvancePage;
