import React, { useEffect, useState } from "react";
import DashboardLayout from "../../components/layouts/dashboard";
import { useSelector } from "react-redux";
import moment from "moment";
import { useGetEmployee } from "../../redux/employee/hook";
import { Initials } from "../../utils/functions";
import LoadingBar from "../../components/loader-bar";
import EmptyTableSVG from "../../assets/svg/empty-table.svg";
import Button from "../../components/button";
import ChangePassword from "../../components/modal/settings/change-password";
import EnableTwoFactorAuthentication from "../../components/modal/settings/enable-2fa";
import { updateUser, useDisable2Fa } from "../../redux/user/hook";

const ProfilePage = () => {
  useEffect(() => {
    updateUser({ reload: false });
    window.scroll(0, 0);
  }, []);

  const { user } = useSelector((state) => state.userSlice);
  const { company } = useSelector((state) => state.companySlice);

  const { mutateAsync: disableTwoFa, isLoading: disableTwoFaLoading } =
    useDisable2Fa();

  const [showChangePassword, setShowChangePassword] = useState(null);
  const [showEnable2Fa, setShowEnable2Fa] = useState(null);

  const { data: employeeData, isLoading: employeeDataLoading } =
    useGetEmployee();

  return (
    <DashboardLayout title="My Profile">
      <div className="flex flex-col">
        {showChangePassword ? (
          <ChangePassword
            isOpen={showChangePassword}
            closeModal={() => setShowChangePassword(false)}
          />
        ) : null}
        {showEnable2Fa ? (
          <EnableTwoFactorAuthentication
            isOpen={showEnable2Fa}
            closeModal={() => setShowEnable2Fa(false)}
          />
        ) : null}
        <div className="flex flex-col bg-white shadow-md rounded-md">
          <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
            <div className="p3-bold header-4">My Profile</div>
            <div className="flex flex-col md:flex-row items-center gap-[10px] md:items-center justify-center">
              <div className="md:w-fit w-[100%]">
                <Button
                  text="Change Password"
                  type="button"
                  theme="primary"
                  className="flex gap-2 h-[35px] w-fit p-2 "
                  textClass={"!text-[13px]"}
                  onClick={() => {
                    setShowChangePassword(true);
                  }}
                />
              </div>
              {user.enable_two_fa === 1 ? (
                <div className="md:w-fit w-[100%]">
                  <Button
                    text="DISABLE TWO-FACTOR AUTHENTICATION"
                    type="button"
                    theme="primary"
                    className="flex gap-2 h-[32px] w-fit p-2 !bg-red-700"
                    textClass={"!text-[12px]"}
                    loading={disableTwoFaLoading}
                    onClick={() => {
                      disableTwoFa();
                    }}
                  />
                </div>
              ) : (
                <div className="md:w-fit w-[100%]">
                  <Button
                    text="ENABLE TWO-FACTOR AUTHENTICATION"
                    type="button"
                    theme="secondary"
                    className="flex gap-2 h-[32px] w-fit p-2"
                    textClass={"!text-[12px]"}
                    onClick={() => {
                      setShowEnable2Fa(true);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <hr className="divider mt-2 -mb-2" />
          {user?.company_id ? (
            <div className="p-4">
              {employeeDataLoading ? (
                <LoadingBar loading={employeeDataLoading} />
              ) : (
                <>
                  {employeeData ? (
                    <div className="flex flex-col gap-2">
                      <div className="flex flex-row items-center gap-3">
                        <div className="text-[25px] flex justify-center items-center h-[60px] w-[60px] border-[3px] header-3 rounded-[60px] relative hover:scale-110 hover:m-2 hover:opacity-25 duration-500 text-black font-bold">
                          {employeeData?.logo ? (
                            <div>
                              <img
                                src={employeeData?.logo}
                                alt="email_icon"
                                className="object-fit min-w-[52px] min-h-[52px] max-w-[52px] max-h-[52px]"
                              />
                            </div>
                          ) : (
                            <span className="text-black font-bold text-[25px]">
                              {Initials(employeeData?.company_name)}
                            </span>
                          )}
                        </div>

                        <button className="lg:flex header-4 header-3 text-[25px] text-black">
                          {employeeData?.company_name}
                        </button>
                      </div>
                      <div className="flex md:flex-row flex-col mt-1 gap-4">
                        <div className="flex flex-col md:w-1/2 gap-4">
                          <div className="flex flex-col p-3 border rounded-md shadow-md">
                            <span className="header-3 mb-3">
                              Personal Information
                            </span>
                            <div className="grid md:grid-cols-2  grid-cols-1 gap-4 w-full">
                              <div className="flex flex-col">
                                <span className="header-5 text-color-gray">
                                  First Name
                                </span>
                                <span className="mt-1 text-color-black font-bold">
                                  {employeeData?.first_name}
                                </span>
                              </div>
                              <div className="flex flex-col">
                                <span className="header-5 text-color-gray">
                                  Last name
                                </span>
                                <span className="mt-1 text-color-black font-bold">
                                  {employeeData?.last_name}
                                </span>
                              </div>
                              <div className="flex flex-col">
                                <span className="header-5 text-color-gray">
                                  Phone Number
                                </span>
                                <span className="mt-1 text-color-black font-bold">
                                  {employeeData?.mobile}
                                </span>
                              </div>

                              <div className="flex flex-col">
                                <span className="header-5 text-color-gray">
                                  Email
                                </span>
                                <span className="mt-1 text-color-black font-bold">
                                  {employeeData?.email}
                                </span>
                              </div>
                              <div className="flex flex-col">
                                <span className="header-5 text-color-gray">
                                  Date of birth
                                </span>
                                <span className="mt-1 text-color-black font-bold">
                                  {moment(employeeData?.dob).format(
                                    "MMMM DD, YYYY"
                                  )}
                                </span>
                              </div>
                              <div className="flex flex-col">
                                <span className="header-5 text-color-gray">
                                  Gender
                                </span>
                                <span className="mt-1 text-color-black font-bold">
                                  {employeeData?.gender}
                                </span>
                              </div>
                              <div className="flex flex-col">
                                <span className="header-5 text-color-gray">
                                  Country
                                </span>
                                <span className="mt-1 text-color-black font-bold">
                                  {employeeData?.country}
                                </span>
                              </div>
                              <div className="flex flex-col">
                                <span className="header-5 text-color-gray">
                                  State
                                </span>
                                <span className="mt-1 text-color-black font-bold">
                                  {employeeData?.state}
                                </span>
                              </div>
                              <div className="flex flex-col">
                                <span className="header-5 text-color-gray">
                                  City
                                </span>
                                <span className="mt-1 text-color-black font-bold">
                                  {employeeData?.city}
                                </span>
                              </div>
                              <div className="flex flex-col">
                                <span className="header-5 text-color-gray">
                                  Street Address
                                </span>
                                <span className="mt-1 text-color-black font-bold">
                                  {employeeData?.street_address}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col p-3 border rounded-md shadow-md">
                            <span className="header-3 mb-3">
                              Salary Payment information
                            </span>
                            {/* <hr className='divider mt-2' /> */}
                            <div className="grid md:grid-cols-2  grid-cols-1 gap-4 w-full">
                              <div className="flex flex-col">
                                <span className="header-5 text-color-gray">
                                  Bank Name
                                </span>
                                <span className="mt-1 text-color-black font-bold">
                                  {employeeData?.bank}
                                </span>
                              </div>
                              <div className="flex flex-col">
                                <span className="header-5 text-color-gray">
                                  Account Number
                                </span>
                                <span className="mt-1 text-color-black font-bold">
                                  {employeeData?.account_number}
                                </span>
                              </div>
                            </div>
                            <div className="grid md:grid-cols-1  grid-cols-1 w-full mt-4">
                              <div className="flex flex-col">
                                <span className="header-5 text-color-gray">
                                  Account Name
                                </span>
                                <span className="mt-1 text-color-black font-semibold">
                                  {employeeData?.account_name}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col md:w-1/2 gap-4">
                          <div className="flex flex-col p-3 border rounded-md shadow-md">
                            <span className="header-3 mb-3">
                              Employment information
                            </span>
                            {/* <hr className='divider mt-2' /> */}
                            <div className="flex flex-col">
                              <span className="header-5 text-color-gray">
                                Staff Number
                              </span>
                              <span className="mt-1 text-color-black font-bold">
                                {employeeData?.staff_number}
                              </span>
                            </div>
                            <div className="grid md:grid-cols-2  grid-cols-1 gap-4 w-full mt-3">
                              <div className="flex flex-col">
                                <span className="header-5 text-color-gray">
                                  Date Of Employment
                                </span>
                                <span className="mt-1 text-color-black font-bold">
                                  {moment(employeeData?.date_joined).format(
                                    "MMMM DD, YYYY"
                                  )}
                                </span>
                              </div>
                              <div className="flex flex-col">
                                <span className="header-5 text-color-gray">
                                  Job Position
                                </span>
                                <span className="mt-1 text-color-black font-bold">
                                  {employeeData?.job_position
                                    ? employeeData?.job_position
                                    : "Not Set"}
                                </span>
                              </div>
                              <div className="flex flex-col">
                                <span className="header-5 text-color-gray">
                                  Pension Fund Administrator
                                </span>
                                <span className="mt-1 text-color-black font-bold">
                                  {employeeData?.pfa_name
                                    ? employeeData.pfa_name
                                    : "Not Set"}
                                </span>
                              </div>
                              <div className="flex flex-col">
                                <span className="header-5 text-color-gray">
                                  Pension R.S.A PIN
                                </span>
                                <span className="mt-1 text-color-black font-bold">
                                  {employeeData?.rsa_pin
                                    ? employeeData.rsa_pin
                                    : "Not Set"}
                                </span>
                              </div>
                              <div className="flex flex-col">
                                <span className="header-5 text-color-gray">
                                  Tax ID
                                </span>
                                <span className="mt-1 text-color-black font-bold">
                                  {employeeData?.tax_number
                                    ? employeeData.tax_number
                                    : "Not Set"}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col p-3 border rounded-md shadow-md">
                            <span className="header-3 mb-3">Compensation</span>
                            {company?.compensation?
                            <div className="grid md:grid-cols-2  grid-cols-1 gap-4 w-full">
                              <div className="flex flex-col">
                                <span className="text-[13px] font-medium text-color-gray">
                                  Basic Salary
                                </span>
                                <span className="mt-1 text-color-black font-semibold text-[14px]">
                                  {company?.compensation?.basic_salary.toLocaleString(
                                    "en-NG",
                                    {
                                      style: "currency",
                                      currency: "NGN",
                                    }
                                  )}
                                </span>
                              </div>
                              <div className="flex flex-col">
                                <span className="text-[13px] font-medium text-color-gray">
                                  Housing Allowance
                                </span>
                                <span className="mt-1 text-color-black font-semibold text-[14px]">
                                  {company?.compensation?.housing_allowance.toLocaleString(
                                    "en-NG",
                                    {
                                      style: "currency",
                                      currency: "NGN",
                                    }
                                  )}
                                </span>
                              </div>
                              <div className="flex flex-col">
                                <span className="text-[13px] font-medium text-color-gray">
                                  Transportation Allowance
                                </span>
                                <span className="mt-1 text-color-black font-semibold text-[14px]">
                                  {company?.compensation?.transportation_allowance.toLocaleString(
                                    "en-NG",
                                    {
                                      style: "currency",
                                      currency: "NGN",
                                    }
                                  )}
                                </span>
                              </div>
                              <div className="flex flex-col">
                                <span className="text-[13px] font-medium text-color-gray">
                                  Other Allowances
                                </span>
                                <span className="mt-1 text-color-black font-semibold text-[14px]">
                                  {company?.compensation?.other_allowance.toLocaleString(
                                    "en-NG",
                                    {
                                      style: "currency",
                                      currency: "NGN",
                                    }
                                  )}
                                </span>
                              </div>
                            </div>
                            :
                            <div className="flex items-center justify-center font-semibold">
                              No compensation added yet
                            </div>}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center justify-center min-w-full mt-4">
                      <img
                        src={EmptyTableSVG}
                        alt="No Data Fetched"
                        width={"40%"}
                        height={"400px"}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          ) : (
            <div className="p-4">
              <div className="flex flex-col gap-4">
                <div className="flex md:flex-row flex-col mt-1 gap-4">
                  <div className="flex flex-col md:w-2/3 gap-4">
                    <div className="flex flex-col p-3 border rounded-md shadow-md">
                      <span className="header-4 mb-3 p-2 px-4 text-white rounded-[8px] bg-secondary-2 max-w-fit">
                        Personal Information
                      </span>
                      <div className="grid md:grid-cols-2  grid-cols-1 gap-4 w-full">
                        <div className="flex flex-col">
                          <span className="header-5 text-color-gray">
                            First Name
                          </span>
                          <span className="mt-1 text-color-black font-bold">
                            {user?.first_name}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="header-5 text-color-gray">
                            Last name
                          </span>
                          <span className="mt-1 text-color-black font-bold">
                            {user?.last_name}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="header-5 text-color-gray">
                            Phone Number
                          </span>
                          <span className="mt-1 text-color-black font-bold">
                            {user?.mobile}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="header-5 text-color-gray">
                            Email
                          </span>
                          <span className="mt-1 text-color-black font-bold">
                            {user?.email}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ProfilePage;
