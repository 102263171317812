import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../components/layouts/dashboard';
import { useParams } from 'react-router-dom';
import PerformanceSVG from '../../assets/svg/performance-widget-icon.svg';
import { StatusText } from '../../components/fragments/status-text';
import { performanceSatusConfig } from '../../utils/constants';
import { useDispatch } from 'react-redux';
import {
    useGetPerformanceBySource, 
    useTeamLeadGradeBalancedScorecard, 
    useTeamLeadGradeOkrs, 
    useTeamLeadGradeOrganisationalAssessments, 
    useTeamLeadGradeSmartGoals, 
    useTeamLeadGradeTechnicalAppraisals 
} from '../../redux/human-resources/hook/performance';
import Button from '../../components/button';
import CollapsingIcon from '../../components/collapsing-icon';
import { Collapse } from 'reactstrap';
import classNames from 'classnames';
import moment from 'moment';
import FormInput from '../../components/form-input';
import LoadingBar from '../../components/loader-bar';
import { setAlert } from '../../redux/components/components-slice';
import { useRouteQuery } from '../../hooks/useRouteQuery';
import FormTextArea from '../../components/form-textarea';
import CustomerGrowthSVG from '../../assets/svg/employee-growth.svg';
import FInancialGrowthSVG from '../../assets/svg/financial-growth.svg';
import BusinessProcessesSVG from '../../assets/svg/business-processes.svg';
import LeaningGrowthSVG from '../../assets/svg/learning-growth.svg';

const TeamLeadViewPerformanceEvaluation = () => {
    useEffect(() => {
        window.scroll(0, 0);
    }, []);
    const { id } = useParams();
    const [showOkr, setShowOkr] = useState(false);
    const [showSmartGoals, setShowSmartGoals] = useState(false);
    const [showTechnicalAppraisals, setShowTechnicalAppraisals] = useState(false);
    const [showBalancedScorecards, setShowBalancedScorecards] = useState(false);
    const [showOganisationalAssessment, setShowOganisationalAssessment] = useState(true);

    const dispatch = useDispatch();

    const [performanceData, setPerformanceData] = useState(null)
    const { data: performance, isLoading } = useGetPerformanceBySource(id);

    const [trainingRequirement, setTrainingRequirement] = useState('')
    const [reviewerComment, setReviewerComment] = useState();
    const [employeeComment, setEmployeeComment] = useState();

    const [source, setSource] = useState();
    const [sourceId, setSourceId] = useState();

    const query = useRouteQuery();

    useEffect(() => {
        if (query) {
            setSource(query.get('source'));
            setSourceId(query.get('source_id'));
        }
    }, [query]);

    const {
        mutateAsync: gradeSmartGoals,
        isLoading: gradeSmartGoalLoading,
    } = useTeamLeadGradeSmartGoals();

    const {
        mutateAsync: gradePerformanceIndicator,
        isLoading: gradeIndicatorLoading,
    } = useTeamLeadGradeOrganisationalAssessments();

    const {
        mutateAsync: gradePerformanceOkr,
        isLoading: gradeOkrLoading,
    } = useTeamLeadGradeOkrs();

    const {
        mutateAsync: gradeBalancedScorecard,
        isLoading: gradeBalancedScorecardLoadng,
        } = useTeamLeadGradeBalancedScorecard();
    
    const {
        mutateAsync: gradeTechnicalAppraisals,
        isLoading: gradeTechnicalAppraisalsLoading,
    } = useTeamLeadGradeTechnicalAppraisals();

    useEffect(() => {
        if (performance) {
            setTrainingRequirement(performance.training_requirements)
            setReviewerComment(performance.reviewer_comment)
            setEmployeeComment(performance.employee_comment)
            setPerformanceData(performance)
        }
    }, [performance])

    const gradeOrganisationalPerformance = async () => {
        let payload = {
            source: source,
            source_id: sourceId,
            performance_id: parseInt(id),
            indicators: performanceData?.organisational_assessments
        }
        await gradePerformanceIndicator(payload)
    }

    const gradeSmartGoalsFn = async () => {
        let payload = {
            performance_id: parseInt(id),
            team_lead_comment: performanceData?.smart_goal?.team_lead_comment,
            goal_id: performanceData?.smart_goal?.id,
            goals_list: performanceData?.smart_goal?.goals,
            source: source,
            source_id: sourceId,
        }
        await gradeSmartGoals(payload)
    }

    const gradeTechnicalAppraisalsFn = async () => {
        let payload = {
            performance_id: parseInt(id),
            team_lead_comment: performanceData?.technical_appraisal?.team_lead_comment,
            technical_appraisal_id: performanceData?.technical_appraisal?.id,
            technical_appraisal_list: performanceData?.technical_appraisal?.appraisals,
            source: source,
            source_id: sourceId,
        }
        await gradeTechnicalAppraisals(payload)
    }

    const gradeOkrFn = async () => {
        let payload = {
            performance_id: parseInt(id),
            team_lead_comment: performanceData?.okr?.team_lead_comment,
            okr_id: performanceData?.okr.id,
            okr_objectives: performanceData?.okr.objectives,
            source: source,
            source_id: sourceId,
        }
        await gradePerformanceOkr(payload)
    }

    const gradeBalancedScorecardFn = async () => {
        let payload = {
            source: source,
            source_id: sourceId,
            performance_id: id,
            balanced_scorecard_id: performanceData?.balanced_scorecard.id,
            quadrants: [
                ...performanceData?.balanced_scorecard.quadrants.finance.data,
                ...performanceData?.balanced_scorecard.quadrants.customer.data,
                ...performanceData?.balanced_scorecard.quadrants.business_process.data,
                ...performanceData?.balanced_scorecard.quadrants.learning_growth.data,
            ]
        }
        await gradeBalancedScorecard(payload)
    }

    return (
        <DashboardLayout title='Manager Performance Appraisal Review Page'>
            <div className='flex flex-col -mt-2'>
                {isLoading?
                <LoadingBar loading={isLoading} />
                :
                <>
                    <div className='flex flex-row items-center box-shadow h-[104px]'>
                        <div className='flex flex-row md:px-[55px] justify-between  w-full'>
                        <div className='flex flex-row items-center'>
                            <div className='flex font-bold justify-center items-center md:h-[58px] md:w-[58px] md:rounded-[58px] h-[45px] w-[45px] rounded-[45px] border-[3px] header-3 border-secondary-2 '>
                                <img src={PerformanceSVG} alt={'performance'} className={'md:h-[45px] md:w-[45px] h-[45px] w-[45px]'} />
                            </div>
                            <div className='flex flex-col pl-4 h-[48px] content-center justify-center'>
                                <span className='p1-bold'>
                                    {performanceData?.first_name + ' ' + performanceData?.last_name}
                                </span>
                                <span className='p4 text-color-gray'>{performanceData?.mobile}</span>
                            </div>
                        </div>
                        <div className='flex flex-row items-center'>
                            <StatusText
                            statusConfig={performanceSatusConfig}
                            code={performanceData?.review_status}
                            className={'py-2 rounded p1-bold px-3 p2-bold text-[13px]'}
                            />
                        </div>
                        </div>
                    </div>
                    <div className='flex flex-col bg-white mt-4'>
                        <div className='flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] p-2 pt-3'>
                            <div className='p3-bold header-4'>
                                {performanceData?.first_name}'s Performance Review 
                            </div>
                        </div>
                        <hr className='divider mt-2 mb-2' />
                        <div className='flex flex-col gap-3 pr-8 pl-8'>
                            {performanceData?.type === 'basic'?
                            <>
                                <div className='flex flex-col p-4 border gap-3 rounded-md'>
                                    <div className='grid md:grid-cols-2  grid-cols-2 gap-4 w-full mb-2'>
                                        <div className='flex gap-2 col-span-1 items-center'>
                                            <div className='font-bold text-[15px]'>
                                                Review Period
                                            </div>
                                            <div className='text-[14px]'>
                                                {moment(performanceData?.evaluation_start).format('MMMM D, YYYY')} - {moment(performanceData?.evaluation_end).format('MMMM D, YYYY')}
                                            </div>
                                        </div>
                                    </div>
                                    {performanceData?.results?.map((item, index) => (
                                        <div className='flex flex-col p-4 py-2 border rounded shadow-sm hover:bg-gray-50 duration-500'>
                                            <div className='grid md:grid-cols-3 grid-cols-3 gap-4 w-full'>
                                                <div className='flex gap-2 col-span-1 items-center'>
                                                    <div className='font-bold text-[15px]'>
                                                        Result {index+1}:
                                                    </div>
                                                    <div className='text-[14px]'>
                                                        {item.name}
                                                    </div>
                                                </div>
                                                <div className='flex gap-2 items-center'>
                                                    <div className='font-bold text-[13px]'>
                                                        HR Grade:
                                                    </div>
                                                    <div className='flex items-center text-[13px] text-hr-secondary-1 font-extrabold gap-1'>
                                                        <span>{item.grade}</span>/ {item.max_grade}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                            :
                            <div className='flex flex-col p-4 border gap-3 rounded-md'>
                                <div className='grid md:grid-cols-2  grid-cols-1 gap-4 w-full mb-2 p-2 bg-gray-100 rounded-md border'>
                                    <div className='flex gap-2 col-span-1 items-center'>
                                        <div className='font-bold text-[15px]'>
                                            Review Period
                                        </div>
                                        <div className='text-[14px]'>
                                            ({moment(performanceData?.evaluation_start).format('MMMM D, YYYY')} - {moment(performanceData?.evaluation_end).format('MMMM D, YYYY')})
                                        </div>
                                    </div>
                                </div>
                                {performanceData?.organisational_assessments && performanceData?.organisational_assessments.length > 0  ?
                                <>
                                    <div
                                        className={classNames('flex flex-row justify-between items-center text-[15px] font-bold cursor-pointer hover:bg-[#eff2fe] hover:rounded-md hover:pl-2 hover:pr-2 duration-300', {
                                            'pt-3 pb-3': showOganisationalAssessment,
                                        })}
                                        onClick={() => {
                                            setShowOganisationalAssessment(!showOganisationalAssessment);
                                        }}
                                    >
                                        <span>General Organisational Assessment (Behavioural and Technical)</span>
                                        <div className='pr-4'>
                                            <CollapsingIcon defaultPosition='left' isOpen={showOganisationalAssessment} />
                                        </div>
                                    </div>
                                    <div
                                        className={classNames('flex flex-col', {
                                            hidden: !showOganisationalAssessment,
                                        })}
                                    >
                                        <Collapse isOpen={showOganisationalAssessment}>
                                            <div className='flex flex-col p-4 border gap-3 rounded-md shadow-sm'>
                                                {performanceData?.organisational_assessments?.length > 0 && performanceData?.organisational_assessments?.map((item, index) => (
                                                    <div className='flex flex-col p-4 border rounded shadow-sm bg-highlight duration-500 gap-2 pb-0 pt-2'>
                                                        <div className='grid md:grid-cols-3 grid-cols-1 gap-4 w-full'>
                                                            <div className='flex gap-2 col-span-1 items-center'>
                                                                <div className='font-bold text-[15px]'>
                                                                    {item.indicator}
                                                                </div>
                                                            </div>  
                                                            <div className='flex gap-2 col-span-1 items-center'>
                                                                <div className='font-bold text-[15px]'>
                                                                    Indicator Type:
                                                                </div>
                                                                <div className='text-[14px]'>
                                                                    {item.type}
                                                                </div>
                                                            </div> 
                                                        </div>
                                                        <div className='grid md:grid-cols-3 grid-cols-1 w-full gap-2'>
                                                            {performanceData?.review_status === 1?
                                                            <>
                                                                <div className='flex gap-2 items-center'>
                                                                    <div className='font-bold text-[13px]'>
                                                                        HR Grade:
                                                                    </div>
                                                                    <div className='text-[13px] text-hr-secondary-1 font-extrabold'>
                                                                        {item.hr_grade}/{item.max_grade}
                                                                    </div>
                                                                </div>
                                                                <div className='flex gap-2 items-center'>
                                                                    <div className='font-bold text-[13px]'>
                                                                        Employee Grade:
                                                                    </div>
                                                                    <div className='text-[13px] text-hr-secondary-1 font-extrabold'>
                                                                        {item.employee_grade}/{item.max_grade}
                                                                    </div>
                                                                </div>
                                                                <div className='flex gap-2 items-center'>
                                                                    <div className='font-bold text-[13px]'>
                                                                        Team Lead Grade:
                                                                    </div>
                                                                    <div className='text-[13px] text-hr-secondary-1 font-extrabold'>
                                                                        {item.team_lead_grade}/{item.max_grade}
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            <div className='flex gap-2 items-center'>
                                                                <div className='font-bold text-[13px]'>
                                                                    Team Lead Grade:
                                                                </div>
                                                                <div className='flex items-center text-[13px] text-hr-secondary-1 font-extrabold gap-1'>
                                                                    <FormInput 
                                                                        name='team_lead_grade'
                                                                        type='number'
                                                                        value={item.team_lead_grade}
                                                                        className='!h-[30px] !w-[80px]'
                                                                        onChange={(e) => {
                                                                            if (e.target.value <= item.max_grade) {
                                                                                let newArray = JSON.parse(JSON.stringify(performanceData));
                                                                                newArray.organisational_assessments[index].team_lead_grade = e.target.value
                                                                                setPerformanceData(newArray);
                                                                            } else {
                                                                                let newArray = JSON.parse(JSON.stringify(performanceData));
                                                                                newArray.organisational_assessments[index].team_lead_grade = 0
                                                                                setPerformanceData(newArray);
                                                                                dispatch(setAlert(true, 'info', 'Grade cannot be greater than total weight')); 
                                                                            }
                                                                        }}
                                                                    />/{item.max_grade}
                                                                </div>
                                                            </div>
                                                            }
                                                        </div>
                                                    </div>
                                                ))}
                                                {performanceData?.review_status !== 1?
                                                <>
                                                {performanceData?.organisational_assessments?.length > 0 ? 
                                                <div className='flex justify-end w-full mt-[10px]'>
                                                    <Button
                                                        className={'!w-fit !h-[35px] p-2'}
                                                        loading={gradeIndicatorLoading}
                                                        textClass={'!text-[13px]'}
                                                        onClick={() => gradeOrganisationalPerformance()}
                                                        text={'SUBMIT GRADES'}
                                                        type='button'
                                                    />
                                                </div>
                                                :
                                                null
                                                }
                                                </>
                                                :null}
                                            </div>
                                        </Collapse>
                                    </div>
                                    
                                </>
                                :
                                null
                                }
                                <hr className='divider -mt-2 -mb-3' />
                                {performanceData?.okr ?
                                <>
                                    <div
                                        className={classNames('flex flex-row justify-between items-center text-[15px] font-bold cursor-pointer hover:bg-[#eff2fe] hover:rounded-md hover:pl-2 hover:pr-2 duration-300', {
                                            'pt-3 pb-3': showOkr,
                                        })}
                                        onClick={() => {
                                        setShowOkr(!showOkr);
                                        }}
                                    >
                                        <span>Objectives and Key Results (OKRs)</span>
                                        <div className='pr-4'>
                                            <CollapsingIcon defaultPosition='left' isOpen={showOkr} />
                                        </div>
                                    </div>
                                    <div
                                        className={classNames('flex flex-col', {
                                            hidden: !showOkr,
                                        })}
                                    >
                                        <Collapse isOpen={showOkr}>
                                            <div className='flex flex-col p-4 border gap-3'>
                                                <div className='grid md:grid-cols-3  grid-cols-1 gap-4 w-full mb-2  p-2 bg-gray-100 rounded-md border'>
                                                    <div className='flex gap-2 col-span-1 items-center'>
                                                        <div className='font-extrabold text-[16px]'>
                                                            OKR Title:
                                                        </div>
                                                        <div className='text-[15px]'>
                                                            {performanceData?.okr?.okr_title}
                                                        </div>
                                                    </div>
                                                    <div className='flex gap-2 col-span-2 items-center'>
                                                        <div className='font-bold text-[15px]'>
                                                            OKR Period:
                                                        </div>
                                                        <div className='text-[14px]'>
                                                            {moment(performanceData?.okr?.start_date).format('MMMM D, YYYY')} - {moment(performanceData?.okr?.end_date).format('MMMM D, YYYY')}
                                                        </div>
                                                    </div>
                                                </div>
                                                {performanceData?.okr?.objectives?.map((item, index) => (
                                                    <div className='flex flex-col p-4 border rounded shadow-sm hover:bg-gray-50 duration-500'>
                                                        <div className='grid md:grid-cols-3 grid-cols-1 gap-4 w-full'>
                                                            <div className='flex gap-2 col-span-1 items-center'>
                                                                <div className='font-bold text-[15px]'>
                                                                    Objective {index+1}:
                                                                </div>
                                                                <div className='text-[14px]'>
                                                                    {item.objective}
                                                                </div>
                                                            </div>
                                                            <div className='flex gap-2 col-span-2 items-center'>
                                                                <div className='font-bold text-[15px]'>
                                                                    Completion Period:
                                                                </div>
                                                                <div className='text-[14px]'>
                                                                    {moment(item.start_date).format('MMMM D, YYYY')} - {moment(item.end_date).format('MMMM D, YYYY')}
                                                                </div>
                                                            </div>
                                                            <div className='flex gap-4 -mt-2 items-center'>
                                                                <div className='font-bold text-[15px]'>
                                                                    Max Grade:
                                                                </div>
                                                                <div className='text-[14px] text-hr-secondary-1 font-extrabold'>
                                                                    {item.max_grade}
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                        <div className='flex flex-col mt-2 gap-2 '>
                                                            {item.key_results?.map((subItem, subIndex) => (
                                                                <div className='flex flex-col p-2 px-4 pb-1 md:gap-0 gap-3 rounded bg-highlight '>
                                                                    <div className='grid md:grid-cols-2 grid-cols-1 gap-3 w-full justify-items-stretch'>
                                                                        <div className='flex gap-2 items-center rounded-md w-fit px-3'>
                                                                            <div className='font-bold text-[15px] !text-secondary-2'>
                                                                                Key Result {subIndex+1}:
                                                                            </div>
                                                                            <div className='text-[14px] !text-secondary-2'>
                                                                                {subItem.key_result}
                                                                            </div>
                                                                        </div>
                                                                        <div className='flex gap-2 items-center'>
                                                                            <div className='font-bold text-[15px]'>
                                                                                Completion Period:
                                                                            </div>
                                                                            <div className='text-[14px]'>
                                                                                ({moment(subItem.start_date).format('MMMM D, YYYY')} - {moment(subItem.end_date).format('MMMM D, YYYY')})
                                                                            </div>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                    <div className='grid md:grid-cols-2 grid-cols-1 md:gap-0 gap-3 w-full '>
                                                                        {performanceData?.review_status === 1?
                                                                        <>
                                                                            <div className='flex gap-2 items-center'>
                                                                                <div className='font-bold text-[13px]'>
                                                                                    Employee Grade:
                                                                                </div>
                                                                                <div className='text-[13px] font-extrabold'>
                                                                                    {subItem.employee_grade} / {subItem.weight}
                                                                                </div>
                                                                            </div>
                                                                            <div className='flex gap-2 items-center'>
                                                                                <div className='font-bold text-[13px]'>
                                                                                    Team Lead Grade:
                                                                                </div>
                                                                                <div className='text-[13px] font-extrabold'>
                                                                                    {subItem.team_lead_grade} / {subItem.weight}
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <div className='flex gap-2 items-center w-full'>
                                                                            <div className='font-bold text-[13px] mb-1'>
                                                                                Team Lead Grade:
                                                                            </div>
                                                                            <FormInput 
                                                                                name='team_lead_grade'
                                                                                type='number'
                                                                                value={subItem.team_lead_grade}
                                                                                className='!h-[30px] !w-[80px]'
                                                                                onChange={(e) => {
                                                                                    if (e.target.value <= item.max_grade) {
                                                                                        let newArray = JSON.parse(JSON.stringify(performanceData));
                                                                                        newArray.okr.objectives[index].key_results[subIndex].team_lead_grade = e.target.value
                                                                                        setPerformanceData(newArray);
                                                                                    } else {
                                                                                        let newArray = JSON.parse(JSON.stringify(performanceData));
                                                                                        newArray.organisational_assessments[index].team_lead_grade = 0
                                                                                        setPerformanceData(newArray);
                                                                                        dispatch(setAlert(true, 'info', 'Grade cannot be greater than total weight')); 
                                                                                    }
                                                                                    
                                                                                }}
                                                                            />
                                                                            <span className='mb-1 text-[13px]'> / {subItem.weight}</span>
                                                                            
                                                                        </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ))}
                                                {performanceData?.review_status !== 1?
                                                <div className='flex flex-col items-start gap-3 md:items-start justify-end w-full'>
                                                    <div className='flex w-[100%]'>
                                                        <FormTextArea
                                                            label={'Team Lead Comment - (Optional)'}
                                                            type='text'
                                                            name='description'
                                                            className={'md:min-w-[600px] text-[13px]'}
                                                            readOnly={performanceData?.review_status === 1? true : false}
                                                            placeholder={'Enter evaluation comment'}
                                                            value={performanceData?.okr?.team_lead_comment}
                                                            onChange={(e) => {
                                                                let newArray = JSON.parse(JSON.stringify(performanceData));
                                                                newArray.okr.team_lead_comment = e.target.value
                                                                setPerformanceData(newArray);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='flex w-full justify-end'>
                                                        <Button
                                                            text={'Submit'}
                                                            type='button'
                                                            theme='primary'
                                                            className='flex gap-2 h-[35px] w-fit px-3'
                                                            loading={gradeOkrLoading}
                                                            textClass={'!text-[12px]'}
                                                            onClick={() => {
                                                                gradeOkrFn()
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                :
                                                <div className='flex flex-col gap-2'>
                                                    <div className='flex flex-col p-4 py-2 border rounded w-full shadow-sm bg-highlight'>
                                                        <span className='text-color-gray text-[13px]'>
                                                            Employee Comments:
                                                        </span>
                                                        <div className='flex text-black text-[13px] h-fit'>
                                                            {performanceData?.okr?.employee_comment}
                                                        </div>
                                                    </div>
                                                    <div className='flex flex-col p-4 py-2 border rounded w-full shadow-sm bg-highlight'>
                                                        <span className='text-color-gray text-[13px]'>
                                                            Team Lead Comments:
                                                        </span>
                                                        <div className='flex text-black text-[13px] h-fit'>
                                                            {performanceData?.okr?.team_lead_comment}
                                                        </div>
                                                    </div>
                                                </div>}
                                            </div>
                                        </Collapse>
                                    </div>
                                    <hr className='divider -mt-3 -mb-3' />
                                </>
                                :
                                null}
                                
                                {performanceData?.smart_goal ?
                                <>
                                    <div
                                        className={classNames('flex flex-row justify-between items-center text-[15px] font-bold cursor-pointer hover:bg-[#eff2fe] hover:rounded-md hover:pl-2 hover:pr-2 duration-300', {
                                            'pt-3 pb-3': showSmartGoals,
                                        })}
                                        onClick={() => {
                                            setShowSmartGoals(!showSmartGoals);
                                        }}
                                    >
                                        <span>SMART Goals assessed</span>
                                        <div className='pr-4'>
                                            <CollapsingIcon defaultPosition='left' isOpen={showSmartGoals} />
                                        </div>
                                    </div>
                                    <div
                                        className={classNames('flex flex-col', {
                                            hidden: !showSmartGoals,
                                        })}
                                    >
                                        <Collapse isOpen={showSmartGoals}>
                                            <div className='flex flex-col p-4 py-3 border gap-2'>
                                                <div className='grid md:grid-cols-2 grid-cols-1 gap-2 w-full'>
                                                    <div className='flex gap-2 col-span-1 items-center'>
                                                        <div className='font-extrabold text-[14px]'>
                                                            SMART Goal Title:
                                                        </div>
                                                        <div className='text-[13px]'>
                                                            {performanceData?.smart_goal?.smart_goal_title}
                                                        </div>
                                                    </div>
                                                    <div className='flex gap-2 col-span-1 items-center'>
                                                        <div className='font-bold text-[14px]'>
                                                            SMART Goal Period:
                                                        </div>
                                                        <div className='text-[13px]'>
                                                            {moment(performanceData?.smart_goal?.start_date).format('MMMM D, YYYY')} - {moment(performanceData?.smart_goal?.end_date).format('MMMM D, YYYY')}
                                                        </div>
                                                    </div>
                                                </div>
                                                {performanceData?.smart_goal?.goals?.map((item, index) => (
                                                    <div className='flex flex-col p-4 py-2 border rounded shadow-sm bg-highlight duration-500'>
                                                        <div className='grid md:grid-cols-3 grid-cols-1 gap-4 w-full'>
                                                            <div className='flex gap-2 col-span-1 items-center'>
                                                                <div className='font-bold text-[14px]'>
                                                                    Goal {index+1}:
                                                                </div>
                                                                <div className='text-[13px]'>
                                                                    {item.smart_goal}
                                                                </div>
                                                            </div>
                                                            <div className='flex gap-2 col-span-2 items-center'>
                                                                <div className='font-bold text-[14px]'>
                                                                    Completion Period:
                                                                </div>
                                                                <div className='text-[13px]'>
                                                                    {moment(item.start_date).format('MMMM D, YYYY')} - {moment(item.end_date).format('MMMM D, YYYY')}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='grid md:grid-cols-3 grid-cols-1 w-full gap-2 mt-1'>
                                                            {performanceData?.review_status === 1?
                                                            <>
                                                                <div className='flex gap-2 items-center'>
                                                                    <div className='font-bold text-[13px]'>
                                                                        Employee Grade:
                                                                    </div>
                                                                    <div className='text-[13px] text-hr-secondary-1 font-extrabold'>
                                                                        {item.employee_grade}/{item.weight}
                                                                    </div>
                                                                </div>
                                                                <div className='flex gap-2 items-center'>
                                                                    <div className='font-bold text-[13px]'>
                                                                        HR Grade:
                                                                    </div>
                                                                    <div className='text-[13px] text-hr-secondary-1 font-extrabold'>
                                                                        {item.hr_grade}/{item.weight}
                                                                    </div>
                                                                </div>
                                                                <div className='flex gap-2 items-center'>
                                                                    <div className='font-bold text-[13px]'>
                                                                        Team Lead Grade:
                                                                    </div>
                                                                    <div className='text-[13px] text-hr-secondary-1 font-extrabold'>
                                                                        {item.team_lead_grade}/{item.weight}
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            <div className='flex gap-2 items-center'>
                                                                <div className='font-bold text-[13px]'>
                                                                    Team Lead Grade:
                                                                </div>
                                                                <div className='flex items-center text-[13px] text-hr-secondary-1 font-extrabold gap-1'>
                                                                    <FormInput 
                                                                        name='team_lead_grade'
                                                                        type='number'
                                                                        value={item.team_lead_grade}
                                                                        className='!h-[30px] !w-[80px]'
                                                                        onChange={(e) => {
                                                                            if (e.target.value <= item.weight) {
                                                                                let newArray = JSON.parse(JSON.stringify(performanceData));
                                                                                newArray.smart_goal.goals[index].team_lead_grade = e.target.value
                                                                                setPerformanceData(newArray);
                                                                            } else {
                                                                                let newArray = JSON.parse(JSON.stringify(performanceData));
                                                                                newArray.smart_goal.goals[index].team_lead_grade = 0
                                                                                setPerformanceData(newArray);
                                                                                dispatch(setAlert(true, 'info', 'Grade cannot be greater than total weight')); 
                                                                            } 
                                                                        }}
                                                                    />/{item.weight}
                                                                </div>
                                                            </div>
                                                            }
                                                        </div>
                                                    </div>
                                                ))}
                                                {performanceData?.review_status !== 1?
                                                <div className='flex flex-col items-start gap-3 md:items-start justify-end w-full'>
                                                    <div className='flex w-[100%]'>
                                                        <FormTextArea
                                                            label={'Team Lead Comment - (Optional)'}
                                                            type='text'
                                                            name='description'
                                                            className={'md:min-w-[600px] text-[13px]'}
                                                            readOnly={performanceData?.review_status === 1? true : false}
                                                            placeholder={'Enter evaluation comment'}
                                                            value={performanceData?.smart_goal?.team_lead_comment}
                                                            onChange={(e) => {
                                                                let newArray = JSON.parse(JSON.stringify(performanceData));
                                                                newArray.smart_goal.team_lead_comment = e.target.value
                                                                setPerformanceData(newArray);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='flex w-full justify-end'>
                                                        <Button
                                                            text={'Submit'}
                                                            type='button'
                                                            theme='primary'
                                                            className='flex gap-2 h-[35px] w-fit px-3'
                                                            loading={gradeSmartGoalLoading}
                                                            textClass={'!text-[12px]'}
                                                            onClick={() => {gradeSmartGoalsFn()}}
                                                        />
                                                    </div>
                                                </div>
                                                :
                                                <div className='flex flex-col gap-2'>
                                                    <div className='flex flex-col p-4 py-2 border rounded w-full shadow-sm mt-2 bg-highlight'>
                                                        <span className='text-color-gray text-[13px]'>
                                                            HR Comments:
                                                        </span>
                                                        <div className='flex text-black text-[13px] h-fit'>
                                                            {performanceData?.smart_goal?.hr_comment}
                                                        </div>
                                                    </div>
                                                    <div className='flex flex-col p-4 py-2 border rounded w-full shadow-sm bg-highlight'>
                                                        <span className='text-color-gray text-[13px]'>
                                                            Employee Comments:
                                                        </span>
                                                        <div className='flex text-black text-[13px] h-fit'>
                                                            {performanceData?.smart_goal?.employee_comment}
                                                        </div>
                                                    </div>
                                                    <div className='flex flex-col p-4 py-2 border rounded w-full shadow-sm bg-highlight'>
                                                        <span className='text-color-gray text-[13px]'>
                                                            Team Lead Comments:
                                                        </span>
                                                        <div className='flex text-black text-[13px] h-fit'>
                                                            {performanceData?.smart_goal?.team_lead_comment}
                                                        </div>
                                                    </div>
                                                </div>}
                                            </div>
                                        </Collapse>
                                    </div>
                                    <hr className='divider -mt-3 mb-2' />
                                </>
                                :
                                null}
                                {performanceData?.technical_appraisal ?
                                <>
                                    <div
                                        className={classNames('flex flex-row justify-between items-center text-[15px] font-bold cursor-pointer hover:bg-[#eff2fe] hover:rounded-md hover:pl-2 hover:pr-2 duration-300', {
                                            'pt-3 pb-3': showTechnicalAppraisals,
                                        })}
                                        onClick={() => {
                                            setShowTechnicalAppraisals(!showTechnicalAppraisals);
                                        }}
                                    >
                                        <span>Technical Appraisals assessed</span>
                                        <div className='pr-4'>
                                            <CollapsingIcon defaultPosition='left' isOpen={showTechnicalAppraisals} />
                                        </div>
                                    </div>
                                    <div
                                        className={classNames('flex flex-col', {
                                            hidden: !showTechnicalAppraisals,
                                        })}
                                    >
                                        <Collapse isOpen={showTechnicalAppraisals}>
                                            <div className='flex flex-col p-4 border rounded-md shadow-sm gap-3'>
                                                <div className='grid md:grid-cols-1  grid-cols-1 gap-4 w-full -mb-2'>
                                                    <div className='flex gap-2 col-span-1 items-center'>
                                                        <div className='font-extrabold text-[16px]'>
                                                            Appraisal Title:
                                                        </div>
                                                        <div className='text-[15px]'>
                                                            {performanceData?.technical_appraisal?.technical_appraisal_title}
                                                        </div>
                                                    </div>
                                                </div>
                                                {performanceData?.technical_appraisal?.appraisals?.map((item, index) => (
                                                    <div className='flex flex-col p-2 border rounded-md shadow-sm bg-highlight duration-500'>
                                                        <div className='grid md:grid-cols-2 grid-cols-1 gap-4 w-full'>
                                                            <div className='flex gap-2 col-span-1 items-center'>
                                                                <div className='font-bold text-[15px]'>
                                                                    Appraisal {index+1}:
                                                                </div>
                                                                <div className='text-[14px]'>
                                                                    {item.appraisal}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        {performanceData?.review_status === 1?
                                                        <div className='grid md:grid-cols-3 grid-cols-1 w-full mt-3'>
                                                            <div className='flex gap-2 items-center'>
                                                                <div className='font-bold text-[13px]'>
                                                                    Team Lead Grade:
                                                                </div>
                                                                <div className='text-[13px] text-hr-secondary-1 font-extrabold'>
                                                                    {item.team_lead_grade}/{item.weight}
                                                                </div>
                                                            </div>
                                                            <div className='flex gap-2 items-center'>
                                                                <div className='font-bold text-[13px]'>
                                                                    Employee Grade:
                                                                </div>
                                                                <div className='text-[13px] text-hr-secondary-1 font-extrabold'>
                                                                    {item.employee_grade}/{item.weight}
                                                                </div>
                                                            </div>
                                                            <div className='flex gap-2 items-center'>
                                                                <div className='font-bold text-[13px]'>
                                                                    HR Grade:
                                                                </div>
                                                                <div className='text-[13px] text-hr-secondary-1 font-extrabold'>
                                                                    {item.hr_grade}/{item.weight}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className='flex gap-2 items-center -mb-2'>
                                                            <div className='font-bold text-[13px]'>
                                                                Team Lead Grade:
                                                            </div>
                                                            <div className='flex items-center text-[13px] text-hr-secondary-1 font-extrabold gap-1'>
                                                                <FormInput 
                                                                    name='hr_grade'
                                                                    type='number'
                                                                    value={item.team_lead_grade}
                                                                    className='!h-[30px] !w-[80px]'
                                                                    onChange={(e) => {
                                                                        if (e.target.value <= item.weight) {
                                                                            let newArray = JSON.parse(JSON.stringify(performanceData));
                                                                            newArray.technical_appraisal.appraisals[index].team_lead_grade = e.target.value
                                                                            setPerformanceData(newArray);
                                                                        } else {
                                                                            let newArray = JSON.parse(JSON.stringify(performanceData));
                                                                            newArray.technical_appraisal.appraisals[index].team_lead_grade = 0
                                                                            setPerformanceData(newArray);
                                                                            dispatch(setAlert(true, 'info', 'Grade cannot be greater than total weight')); 
                                                                        }
                                                                        
                                                                    }}
                                                                />/{item.weight}
                                                                
                                                            </div>
                                                        </div>
                                                        }
                                                    </div>
                                                ))}
                                                {performanceData?.review_status !== 1?
                                                <div className='flex flex-col items-start gap-3 md:items-start justify-end w-full'>
                                                    <div className='flex w-[100%]'>
                                                        <FormTextArea
                                                            label={'Team Lead Comment - (Optional)'}
                                                            type='text'
                                                            name='description'
                                                            className={'md:min-w-[600px] text-[13px]'}
                                                            readOnly={performanceData?.review_status === 1? true : false}
                                                            placeholder={'Enter evaluation comment'}
                                                            value={performanceData?.technical_appraisal?.team_lead_comment}
                                                            onChange={(e) => {
                                                                let newArray = JSON.parse(JSON.stringify(performanceData));
                                                                newArray.technical_appraisal.team_lead_comment = e.target.value
                                                                setPerformanceData(newArray);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='flex w-full justify-end'>
                                                        <Button
                                                            text={'Submit'}
                                                            type='button'
                                                            theme='primary'
                                                            className='flex gap-2 h-[35px] w-fit px-3'
                                                            loading={gradeTechnicalAppraisalsLoading}
                                                            textClass={'!text-[13px]'}
                                                            onClick={() => gradeTechnicalAppraisalsFn()}
                                                        />
                                                    </div>
                                                </div>
                                                :
                                                <div className='flex flex-col gap-2'>
                                                    <div className='flex flex-col p-4 py-2 border rounded w-full shadow-sm mt-2 bg-highlight'>
                                                        <span className='text-color-gray text-[13px]'>
                                                            HR Comments:
                                                        </span>
                                                        <div className='flex text-black text-[13px] h-fit'>
                                                            {performanceData?.technical_appraisal?.hr_comment}
                                                        </div>
                                                    </div>
                                                    <div className='flex flex-col p-4 py-2 border rounded w-full shadow-sm bg-highlight'>
                                                        <span className='text-color-gray text-[13px]'>
                                                            Employee Comments:
                                                        </span>
                                                        <div className='flex text-black text-[13px] h-fit'>
                                                            {performanceData?.technical_appraisal?.employee_comment}
                                                        </div>
                                                    </div>
                                                    <div className='flex flex-col p-4 py-2 border rounded w-full shadow-sm bg-highlight'>
                                                        <span className='text-color-gray text-[13px]'>
                                                            Team Lead Comments:
                                                        </span>
                                                        <div className='flex text-black text-[13px] h-fit'>
                                                            {performanceData?.technical_appraisal?.team_lead_comment}
                                                        </div>
                                                    </div>
                                                </div>}
                                            </div>
                                        </Collapse>
                                    </div>
                                    <hr className='divider -mt-3 mb-2' />
                                </>
                                :
                                null}
                                {performanceData?.balanced_scorecard ?
                                <>
                                    <div
                                        className={classNames('flex flex-row justify-between items-center text-[15px] font-bold cursor-pointer hover:bg-[#eff2fe] hover:rounded-md hover:pl-2 hover:pr-2 duration-300 select-none', {
                                            'pt-2 pb-2': showBalancedScorecards,
                                        })}
                                        onClick={() => {
                                            setShowBalancedScorecards(!showBalancedScorecards);
                                        }}
                                    >
                                        <div className='flex flex-col'>
                                            <span>Balanced scorecards assessed</span>
                                            <span className='text-gray-400 text-[13px]'>{performanceData.review_status === 1 ?`Final score: (${performanceData?.balanced_scorecard.total_score}/100)` : 'Not reviewed'}</span>
                                        </div>
                                        
                                        <div className='pr-4'>
                                            <CollapsingIcon defaultPosition='left' isOpen={showBalancedScorecards} />
                                        </div>
                                    </div>
                                    {performanceData.review_status !== 1 ?
                                    <div
                                        className={classNames('flex flex-col -mt-2', {
                                            hidden: !showBalancedScorecards,
                                        })}
                                    >
                                        <Collapse isOpen={showBalancedScorecards}>
                                            <div className='flex flex-col p-3 border rounded-md shadow-sm gap-3'>
                                                <div className='grid md:grid-cols-1 grid-cols-1 gap-4 w-full -mb-2'>
                                                    <div className='flex gap-2 col-span-1 items-center'>
                                                        <div className='font-extrabold text-[15px]'>
                                                            Balanced Scorecard Title:
                                                        </div>
                                                        <div className='text-[13px]'>
                                                            {performanceData?.balanced_scorecard.title}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'grid md:grid-cols-2 grid-cols-1 w-full gap-3'}>
                                                    <div className='w-full border rounded overflow-x-hidden shadow-sm'>
                                                        <div className='flex gap-2 bg-hr-primary-1 px-2 h-[50px] items-center justify-start font-extrabold text-white'>
                                                            <img src={FInancialGrowthSVG} alt='' height={'20px'} width={'20px'} />
                                                            <span className='text-white'>Financial</span> 
                                                            <span className='text-white text-[13px]'>({performanceData?.balanced_scorecard.total_finance_score}/{performanceData?.balanced_scorecard.finance_weight}%)</span>
                                                        </div>
                                                        <div className='w-full h-[350px] overflow-x-hidden p-2'>
                                                            {performanceData?.balanced_scorecard?.quadrants?.finance?.data.length > 0 ?
                                                            <div className=' flex flex-col gap-2'> 
                                                                {performanceData?.balanced_scorecard?.quadrants?.finance.data.map((item, index) => (
                                                                <div className='flex flex-col p-2 gap-1 border rounded-md shadow-sm bg-secondary-2-extralight duration-500' key={index}>
                                                                    <div className='grid grid-cols-1 gap-2 w-full'>
                                                                        <div className='flex gap-2 col-span-1 items-center'>
                                                                            <div className='font-bold text-[13px]'>
                                                                                Objective {index+1}:
                                                                            </div>
                                                                            <div className='text-[12px]'>
                                                                                {item.title}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex gap-2 items-center w-full mt-1'>
                                                                        <div className='font-bold text-[12px]'>
                                                                            Team Lead Grade:
                                                                        </div>
                                                                        <div className='flex items-center text-[13px] text-hr-secondary-1 font-extrabold gap-1'>
                                                                            <FormInput 
                                                                                name='team_lead_grade'
                                                                                type='number'
                                                                                value={item.team_lead_score}
                                                                                className='!h-[25px] !w-[80px] bg-white'
                                                                                onChange={(e) => {
                                                                                    if (e.target.value <= item.weight) {
                                                                                        let newArray = JSON.parse(JSON.stringify(performanceData));
                                                                                        newArray.balanced_scorecard.quadrants.finance.data[index].team_lead_score = e.target.value
                                                                                        setPerformanceData(newArray);
                                                                                    } else {
                                                                                        let newArray = JSON.parse(JSON.stringify(performanceData));
                                                                                        newArray.balanced_scorecard.quadrants.finance.data[index].team_lead_score = 0
                                                                                        setPerformanceData(newArray);
                                                                                        dispatch(setAlert(true, 'info', 'Grade cannot be greater than total weight')); 
                                                                                    } 
                                                                                }}
                                                                            />/{item.weight}
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex flex-col gap-2'>
                                                                        <div className='flex w-[100%]'>
                                                                            <FormTextArea
                                                                                label={'Team Lead Comment - (Optional)'}
                                                                                type='text'
                                                                                name='description'
                                                                                className={'md:min-w-[400px] text-[12px]'}
                                                                                readOnly={performanceData?.review_status === 1? true : false}
                                                                                placeholder={'Enter evaluation comment'}
                                                                                value={item.team_lead_comment}
                                                                                onChange={(e) => {
                                                                                    let newArray = JSON.parse(JSON.stringify(performanceData));
                                                                                    newArray.balanced_scorecard.quadrants.finance.data[index].team_lead_comment = e.target.value
                                                                                    setPerformanceData(newArray);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                ))}
                                                            </div>
                                                            :
                                                            <div className='p-2'>
                                                                <div className='flex flex-row w-full text-[13px] justify-center items-center h-[60px] rounded-md border bg-highlight text-black mt-8'>
                                                                No Finance Objective
                                                                </div>
                                                            </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='w-full border rounded overflow-x-hidden shadow-sm'>
                                                        <div className='flex gap-2 bg-spend-primary-1 px-2 h-[50px] items-center justify-start font-extrabold text-white'>
                                                            <img src={CustomerGrowthSVG} alt='' height={'20px'} width={'20px'} />
                                                            <span className='text-white'>Customers</span> 
                                                            <span className='text-white text-[13px]'>({performanceData?.balanced_scorecard.total_customer_score}/{performanceData?.balanced_scorecard.customer_weight}%)</span>
                                                        </div>
                                                        <div className='w-full h-[350px] overflow-x-hidden p-2'>
                                                        {performanceData?.balanced_scorecard?.quadrants?.customer.data?.length > 0 ?
                                                        <div className=' flex flex-col gap-2'> 
                                                            {performanceData?.balanced_scorecard?.quadrants?.customer.data.map((item, index) => (
                                                            <div className='flex flex-col p-2 gap-1 border rounded-md shadow-sm bg-secondary-2-extralight duration-500' key={index}>
                                                                <div className='grid grid-cols-1 gap-4 w-full'>
                                                                    <div className='flex gap-2 col-span-1 items-center'>
                                                                        <div className='font-bold text-[13px]'>
                                                                            Objective {index+1}:
                                                                        </div>
                                                                        <div className='text-[12px]'>
                                                                            {item.title}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='flex gap-2 items-center w-full mt-1'>
                                                                    <div className='font-bold text-[12px]'>
                                                                    Team Lead Grade:
                                                                    </div>
                                                                    <div className='flex items-center text-[13px] text-hr-secondary-1 font-extrabold gap-1'>
                                                                        <FormInput 
                                                                            name='team_lead_grade'
                                                                            type='number'
                                                                            value={item.team_lead_score}
                                                                            className='!h-[25px] !w-[80px] bg-white'
                                                                            onChange={(e) => {
                                                                                if (e.target.value <= item.weight) {
                                                                                    let newArray = JSON.parse(JSON.stringify(performanceData));
                                                                                    newArray.balanced_scorecard.quadrants.customer.data[index].team_lead_score = e.target.value
                                                                                    setPerformanceData(newArray);
                                                                                } else {
                                                                                    let newArray = JSON.parse(JSON.stringify(performanceData));
                                                                                    newArray.balanced_scorecard.quadrants.customer.data[index].team_lead_score = 0
                                                                                    setPerformanceData(newArray);
                                                                                    dispatch(setAlert(true, 'info', 'Grade cannot be greater than total weight')); 
                                                                                } 
                                                                            }}
                                                                        />/{item.weight}
                                                                    </div>
                                                                </div>
                                                                <div className='flex flex-col gap-2'>
                                                                    <div className='flex w-[100%]'>
                                                                        <FormTextArea
                                                                            label={'Team Lead Comment - (Optional)'}
                                                                            type='text'
                                                                            name='description'
                                                                            className={'md:min-w-[400px] text-[12px]'}
                                                                            readOnly={performanceData?.review_status === 1? true : false}
                                                                            placeholder={'Enter evaluation comment'}
                                                                            value={item.team_lead_comment}
                                                                                onChange={(e) => {
                                                                                    let newArray = JSON.parse(JSON.stringify(performanceData));
                                                                                    newArray.balanced_scorecard.quadrants.customer.data[index].team_lead_comment = e.target.value
                                                                                    setPerformanceData(newArray);
                                                                                }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            ))}
                                                        </div>
                                                        :
                                                        <div className='p-2'>
                                                            <div className='flex flex-row w-full text-[13px] justify-center items-center h-[60px] rounded-md border bg-highlight text-black mt-8'>
                                                            No Customer Objective
                                                            </div>
                                                        </div>
                                                        }
                                                        </div>
                                                    </div>
                                                    <div className='w-full border rounded overflow-x-hidden shadow-sm'>
                                                        <div className='flex gap-2 bg-secondary-2 px-2 h-[50px] items-center justify-start font-extrabold text-white'>
                                                        <img src={BusinessProcessesSVG} alt='' height={'20px'} width={'20px'} />
                                                        <span className='text-white'>Business Processes</span> 
                                                        <span className='text-white text-[13px]'>({performanceData?.balanced_scorecard.total_business_process_score}/{performanceData?.balanced_scorecard.business_process_weight}%)</span>
                                                        </div>
                                                        <div className='w-full h-[350px] overflow-x-hidden p-2'>
                                                        {performanceData?.balanced_scorecard?.quadrants?.business_process?.data.length > 0 ?
                                                        <div className=' flex flex-col gap-2'> 
                                                            {performanceData?.balanced_scorecard?.quadrants?.business_process?.data.map((item, index) => (
                                                            <div className='flex flex-col p-2 gap-1 border rounded-md shadow-sm bg-secondary-2-extralight duration-500' key={index}>
                                                                <div className='grid gap-2 w-full'>
                                                                    <div className='flex gap-2 items-center w-full'>
                                                                        <div className='font-bold text-[13px]'>
                                                                            Objective {index+1}:
                                                                        </div>
                                                                        <div className='text-[12px]'>
                                                                            {item.title}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='flex gap-2 items-center w-full mt-1'>
                                                                    <div className='font-bold text-[12px]'>
                                                                    Team Lead Grade:
                                                                    </div>
                                                                    <div className='flex items-center text-[13px] text-hr-secondary-1 font-extrabold gap-1'>
                                                                        <FormInput 
                                                                            name='team_lead_grade'
                                                                            type='number'
                                                                            value={item.team_lead_score}
                                                                            className='!h-[25px] !w-[80px] bg-white'
                                                                            onChange={(e) => {
                                                                                if (e.target.value <= item.weight) {
                                                                                    let newArray = JSON.parse(JSON.stringify(performanceData));
                                                                                    newArray.balanced_scorecard.quadrants.business_process.data[index].team_lead_score = e.target.value
                                                                                    setPerformanceData(newArray);
                                                                                } else {
                                                                                    let newArray = JSON.parse(JSON.stringify(performanceData));
                                                                                    newArray.balanced_scorecard.quadrants.business_process.data[index].team_lead_score = 0
                                                                                    setPerformanceData(newArray);
                                                                                    dispatch(setAlert(true, 'info', 'Grade cannot be greater than total weight')); 
                                                                                } 
                                                                            }}
                                                                        />/{item.weight}
                                                                    </div>
                                                                </div>
                                                                <div className='flex flex-col gap-2'>
                                                                    <div className='flex w-[100%]'>
                                                                        <FormTextArea
                                                                            label={'Team Lead Comment - (Optional)'}
                                                                            type='text'
                                                                            name='description'
                                                                            className={'md:min-w-[400px] text-[12px]'}
                                                                            readOnly={performanceData?.review_status === 1? true : false}
                                                                            placeholder={'Enter evaluation comment'}
                                                                            value={item.team_lead_comment}
                                                                            onChange={(e) => {
                                                                                let newArray = JSON.parse(JSON.stringify(performanceData));
                                                                                newArray.balanced_scorecard.quadrants.business_process.data[index].team_lead_comment = e.target.value
                                                                                setPerformanceData(newArray);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            ))}
                                                        </div>
                                                        :
                                                        <div className='p-2'>
                                                            <div className='flex flex-row w-full text-[13px] justify-center items-center h-[60px] rounded-md border bg-highlight text-black mt-8'>
                                                            No Business Processes Objective
                                                            </div>
                                                        </div>
                                                        }
                                                        </div>
                                                    </div>
                                                    <div className='w-full border rounded overflow-x-hidden shadow-sm'>
                                                        <div className='flex gap-2 bg-error px-2 h-[50px] items-center justify-start font-extrabold text-white'>
                                                            <img src={LeaningGrowthSVG} alt='' height={'20px'} width={'20px'} />
                                                            <span className='text-white'>Learning and Growth</span> 
                                                            <span className='text-white text-[13px]'>({performanceData?.balanced_scorecard.total_learning_growth_score}/{performanceData?.balanced_scorecard.learning_growth_weight}%)</span>
                                                        </div>
                                                        <div className='w-full h-[350px] overflow-x-hidden p-2'>
                                                        {performanceData?.balanced_scorecard?.quadrants?.learning_growth?.data.length > 0 ?
                                                        <div className=' flex flex-col gap-2'> 
                                                            {performanceData?.balanced_scorecard?.quadrants?.learning_growth?.data.map((item, index) => (
                                                            <div className='flex flex-col p-2 gap-1 border rounded-md shadow-sm bg-secondary-2-extralight duration-500' key={index}>
                                                                <div className='grid grid-cols-1 gap-4 w-full'>
                                                                    <div className='flex gap-2 items-center'>
                                                                        <div className='font-bold text-[13px]'>
                                                                            Objective {index+1}:
                                                                        </div>
                                                                        <div className='text-[12px]'>
                                                                            {item.title}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='flex gap-2 items-center w-full mt-1'>
                                                                    <div className='font-bold text-[12px]'>
                                                                    Team Lead Grade:
                                                                    </div>
                                                                    <div className='flex items-center text-[13px] text-hr-secondary-1 font-extrabold gap-1'>
                                                                        <FormInput 
                                                                            name='team_lead_grade'
                                                                            type='number'
                                                                            value={item.team_lead_score}
                                                                            className='!h-[25px] !w-[80px] bg-white'
                                                                            onChange={(e) => {
                                                                                if (e.target.value <= item.weight) {
                                                                                    let newArray = JSON.parse(JSON.stringify(performanceData));
                                                                                    newArray.balanced_scorecard.quadrants.learning_growth.data[index].team_lead_score = e.target.value
                                                                                    setPerformanceData(newArray);
                                                                                } else {
                                                                                    let newArray = JSON.parse(JSON.stringify(performanceData));
                                                                                    newArray.balanced_scorecard.quadrants.learning_growth.data[index].team_lead_score = 0
                                                                                    setPerformanceData(newArray);
                                                                                    dispatch(setAlert(true, 'info', 'Grade cannot be greater than total weight')); 
                                                                                } 
                                                                            }}
                                                                        />/{item.weight}
                                                                    </div>
                                                                </div>
                                                                <div className='flex flex-col gap-2'>
                                                                    <div className='flex w-[100%]'>
                                                                        <FormTextArea
                                                                            label={'Team Lead Comment - (Optional)'}
                                                                            type='text'
                                                                            name='description'
                                                                            className={'md:min-w-[400px] text-[12px]'}
                                                                            readOnly={performanceData?.review_status === 1? true : false}
                                                                            placeholder={'Enter evaluation comment'}
                                                                            value={item.team_lead_comment}
                                                                            onChange={(e) => {
                                                                                let newArray = JSON.parse(JSON.stringify(performanceData));
                                                                                newArray.balanced_scorecard.quadrants.learning_growth.data[index].team_lead_comment = e.target.value
                                                                                setPerformanceData(newArray);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            ))}
                                                        </div>
                                                        :
                                                        <div className='p-2'>
                                                            <div className='flex flex-row w-full text-[13px] justify-center items-center h-[60px] rounded-md border bg-highlight text-black mt-8'>
                                                            No Learning and Growth Objective
                                                            </div>
                                                        </div>
                                                        }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='flex w-full justify-end'>
                                                    <Button
                                                        text={'Submit Balanced Scorcard result'}
                                                        type='button'
                                                        theme='primary'
                                                        className='flex gap-2 h-[35px] w-fit px-3'
                                                        loading={gradeBalancedScorecardLoadng}
                                                        textClass={'!text-[12px]'}
                                                        onClick={() => {gradeBalancedScorecardFn()}}
                                                    />
                                                </div>
                                            </div>
                                        </Collapse>
                                    </div>
                                    :
                                    <>
                                    <div
                                        className={classNames('flex flex-col -mt-2', {
                                            hidden: !showBalancedScorecards,
                                        })}
                                    >
                                        <Collapse isOpen={showBalancedScorecards}>
                                            <div className='flex flex-col p-3 border rounded-md shadow-sm gap-3'>
                                                <div className='grid md:grid-cols-1 grid-cols-1 gap-4 w-full -mb-2'>
                                                    <div className='flex gap-2 col-span-1 items-center'>
                                                        <div className='font-extrabold text-[15px]'>
                                                            Balanced Scorecard Title:
                                                        </div>
                                                        <div className='text-[13px]'>
                                                            {performanceData?.balanced_scorecard.title}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'grid md:grid-cols-2 grid-cols-1 w-full gap-3'}>
                                                    <div className='w-full border rounded overflow-x-hidden shadow-sm'>
                                                        <div className='flex gap-2 bg-hr-primary-1 px-2 h-[50px] items-center justify-start font-extrabold text-white'>
                                                            <img src={FInancialGrowthSVG} alt='' height={'20px'} width={'20px'} />
                                                            <span className='text-white'>Financial</span> 
                                                            <span className='text-white text-[13px]'>({performanceData?.balanced_scorecard.total_finance_score}/{performanceData?.balanced_scorecard.finance_weight}%)</span>
                                                        </div>
                                                        <div className='w-full h-[350px] overflow-x-hidden p-2'>
                                                            {performanceData?.balanced_scorecard?.quadrants?.finance?.data.length > 0 ?
                                                            <div className=' flex flex-col gap-2'> 
                                                                {performanceData?.balanced_scorecard?.quadrants?.finance.data.map((item, index) => (
                                                                <div className='flex flex-col p-2 gap-1 border rounded-md shadow-sm bg-secondary-2-extralight duration-500' key={index}>
                                                                    <div className='grid grid-cols-1 gap-2 w-full'>
                                                                        <div className='flex gap-2 col-span-1 items-center'>
                                                                            <div className='font-bold text-[13px]'>
                                                                                Objective {index+1}:
                                                                            </div>
                                                                            <div className='text-[12px]'>
                                                                                {item.title}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='grid grid-cols-2 w-full mt-1 p-2 rounded border bg-white'>
                                                                        <div className='flex gap-2 items-center'>
                                                                            <div className='font-bold text-[12px]'>
                                                                                Team Lead Grade:
                                                                            </div>
                                                                            <div className='text-[11px] text-hr-secondary-1 font-extrabold'>
                                                                                {item.team_lead_score}/{item.weight}
                                                                            </div>
                                                                        </div>
                                                                        <div className='flex gap-2 items-center'>
                                                                            <div className='font-bold text-[12px]'>
                                                                                Employee Grade:
                                                                            </div>
                                                                            <div className='text-[11px] text-hr-secondary-1 font-extrabold'>
                                                                            {item.employee_score}/{item.weight}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex flex-col gap-2 mt-2'>
                                                                        <div className='flex flex-col p-2 border rounded w-full shadow-sm bg-white'>
                                                                            <span className='text-color-gray text-[12px]'>
                                                                                Employee Comments:
                                                                            </span>
                                                                            <div className='flex text-black text-[11px] h-fit'>
                                                                                {item.employee_comment}
                                                                            </div>
                                                                        </div>
                                                                        <div className='flex flex-col p-2 border rounded w-full shadow-sm bg-white'>
                                                                            <span className='text-color-gray text-[12px]'>
                                                                                Team Lead Comments:
                                                                            </span>
                                                                            <div className='flex text-black text-[11px] h-fit'>
                                                                                {item.team_lead_comment}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                ))}
                                                            </div>
                                                            :
                                                            <div className='p-2'>
                                                                <div className='flex flex-row w-full text-[13px] justify-center items-center h-[60px] rounded-md border bg-highlight text-black mt-8'>
                                                                No Finance Objective
                                                                </div>
                                                            </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='w-full border rounded overflow-x-hidden shadow-sm'>
                                                        <div className='flex gap-2 bg-spend-primary-1 px-2 h-[50px] items-center justify-start font-extrabold text-white'>
                                                            <img src={CustomerGrowthSVG} alt='' height={'20px'} width={'20px'} />
                                                            <span className='text-white'>Customers</span> 
                                                            <span className='text-white text-[13px]'>({performanceData?.balanced_scorecard.total_customer_score}/{performanceData?.balanced_scorecard.customer_weight}%)</span>
                                                        </div>
                                                        <div className='w-full h-[350px] overflow-x-hidden p-2'>
                                                        {performanceData?.balanced_scorecard?.quadrants?.customer.data?.length > 0 ?
                                                        <div className=' flex flex-col gap-2'> 
                                                            {performanceData?.balanced_scorecard?.quadrants?.customer.data.map((item, index) => (
                                                            <div className='flex flex-col p-2 gap-1 border rounded-md shadow-sm bg-secondary-2-extralight duration-500' key={index}>
                                                                <div className='grid grid-cols-1 gap-4 w-full'>
                                                                    <div className='flex gap-2 col-span-1 items-center'>
                                                                        <div className='font-bold text-[13px]'>
                                                                            Objective {index+1}:
                                                                        </div>
                                                                        <div className='text-[12px]'>
                                                                            {item.title}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='grid grid-cols-2 w-full mt-1 p-2 rounded border bg-white'>
                                                                    <div className='flex gap-2 items-center'>
                                                                        <div className='font-bold text-[12px]'>
                                                                            Team Lead Grade:
                                                                        </div>
                                                                        <div className='text-[11px] text-hr-secondary-1 font-extrabold'>
                                                                            {item.team_lead_score}/{item.weight}
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex gap-2 items-center'>
                                                                        <div className='font-bold text-[12px]'>
                                                                            Employee Grade:
                                                                        </div>
                                                                        <div className='text-[11px] text-hr-secondary-1 font-extrabold'>
                                                                        {item.employee_score}/{item.weight}
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </div>
                                                                <div className='flex flex-col gap-2 mt-2'>
                                                                    <div className='flex flex-col p-2 border rounded w-full shadow-sm bg-white'>
                                                                        <span className='text-color-gray text-[12px]'>
                                                                            Employee Comments:
                                                                        </span>
                                                                        <div className='flex text-black text-[11px] h-fit'>
                                                                            {item.employee_comment}
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex flex-col p-2 border rounded w-full shadow-sm bg-white'>
                                                                        <span className='text-color-gray text-[12px]'>
                                                                            Team Lead Comments:
                                                                        </span>
                                                                        <div className='flex text-black text-[11px] h-fit'>
                                                                            {item.team_lead_comment}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            ))}
                                                        </div>
                                                        :
                                                        <div className='p-2'>
                                                            <div className='flex flex-row w-full text-[13px] justify-center items-center h-[60px] rounded-md border bg-highlight text-black mt-8'>
                                                            No Customer Objective
                                                            </div>
                                                        </div>
                                                        }
                                                        </div>
                                                    </div>
                                                    <div className='w-full border rounded overflow-x-hidden shadow-sm'>
                                                        <div className='flex gap-2 bg-secondary-2 px-2 h-[50px] items-center justify-start font-extrabold text-white'>
                                                        <img src={BusinessProcessesSVG} alt='' height={'20px'} width={'20px'} />
                                                        <span className='text-white'>Business Processes</span> 
                                                        <span className='text-white text-[13px]'>({performanceData?.balanced_scorecard.total_business_process_score}/{performanceData?.balanced_scorecard.business_process_weight}%)</span>
                                                        </div>
                                                        <div className='w-full h-[350px] overflow-x-hidden p-2'>
                                                        {performanceData?.balanced_scorecard?.quadrants?.business_process?.data.length > 0 ?
                                                        <div className=' flex flex-col gap-2'> 
                                                            {performanceData?.balanced_scorecard?.quadrants?.business_process?.data.map((item, index) => (
                                                            <div className='flex flex-col p-2 gap-1 border rounded-md shadow-sm bg-secondary-2-extralight duration-500' key={index}>
                                                                <div className='grid gap-2 w-full'>
                                                                    <div className='flex gap-2 items-center w-full'>
                                                                        <div className='font-bold text-[13px]'>
                                                                            Objective {index+1}:
                                                                        </div>
                                                                        <div className='text-[12px]'>
                                                                            {item.title}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='grid grid-cols-2 w-full mt-1 p-2 rounded border bg-white'>
                                                                    <div className='flex gap-2 items-center'>
                                                                        <div className='font-bold text-[12px]'>
                                                                            Team Lead Grade:
                                                                        </div>
                                                                        <div className='text-[11px] text-hr-secondary-1 font-extrabold'>
                                                                            {item.team_lead_score}/{item.weight}
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex gap-2 items-center'>
                                                                        <div className='font-bold text-[12px]'>
                                                                            Employee Grade:
                                                                        </div>
                                                                        <div className='text-[11px] text-hr-secondary-1 font-extrabold'>
                                                                        {item.employee_score}/{item.weight}
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </div>
                                                                <div className='flex flex-col gap-2 mt-2'>
                                                                    <div className='flex flex-col p-2 border rounded w-full shadow-sm bg-white'>
                                                                        <span className='text-color-gray text-[12px]'>
                                                                            Employee Comments:
                                                                        </span>
                                                                        <div className='flex text-black text-[11px] h-fit'>
                                                                            {item.employee_comment}
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex flex-col p-2 border rounded w-full shadow-sm bg-white'>
                                                                        <span className='text-color-gray text-[12px]'>
                                                                            Team Lead Comments:
                                                                        </span>
                                                                        <div className='flex text-black text-[11px] h-fit'>
                                                                            {item.team_lead_comment}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            ))}
                                                        </div>
                                                        :
                                                        <div className='p-2'>
                                                            <div className='flex flex-row w-full text-[13px] justify-center items-center h-[60px] rounded-md border bg-highlight text-black mt-8'>
                                                            No Business Processes Objective
                                                            </div>
                                                        </div>
                                                        }
                                                        </div>
                                                    </div>
                                                    <div className='w-full border rounded overflow-x-hidden shadow-sm'>
                                                        <div className='flex gap-2 bg-error px-2 h-[50px] items-center justify-start font-extrabold text-white'>
                                                            <img src={LeaningGrowthSVG} alt='' height={'20px'} width={'20px'} />
                                                            <span className='text-white'>Learning and Growth</span> 
                                                            <span className='text-white text-[13px]'>({performanceData?.balanced_scorecard.total_learning_growth_score}/{performanceData?.balanced_scorecard.learning_growth_weight}%)</span>
                                                        </div>
                                                        <div className='w-full h-[350px] overflow-x-hidden p-2'>
                                                        {performanceData?.balanced_scorecard?.quadrants?.learning_growth?.data.length > 0 ?
                                                        <div className=' flex flex-col gap-2'> 
                                                            {performanceData?.balanced_scorecard?.quadrants?.learning_growth?.data.map((item, index) => (
                                                            <div className='flex flex-col p-2 gap-1 border rounded-md shadow-sm bg-secondary-2-extralight duration-500' key={index}>
                                                                <div className='grid grid-cols-1 gap-4 w-full'>
                                                                    <div className='flex gap-2 items-center'>
                                                                        <div className='font-bold text-[13px]'>
                                                                            Objective {index+1}:
                                                                        </div>
                                                                        <div className='text-[12px]'>
                                                                            {item.title}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='grid grid-cols-2 w-full mt-1 p-2 rounded border bg-white'>
                                                                    <div className='flex gap-2 items-center'>
                                                                        <div className='font-bold text-[12px]'>
                                                                            Team Lead Grade:
                                                                        </div>
                                                                        <div className='text-[11px] text-hr-secondary-1 font-extrabold'>
                                                                            {item.team_lead_score}/{item.weight}
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex gap-2 items-center'>
                                                                        <div className='font-bold text-[12px]'>
                                                                            Employee Grade:
                                                                        </div>
                                                                        <div className='text-[11px] text-hr-secondary-1 font-extrabold'>
                                                                        {item.employee_score}/{item.weight}
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </div>
                                                                <div className='flex flex-col gap-2 mt-2'>
                                                                    <div className='flex flex-col p-2 border rounded w-full shadow-sm bg-white'>
                                                                        <span className='text-color-gray text-[12px]'>
                                                                            Employee Comments:
                                                                        </span>
                                                                        <div className='flex text-black text-[11px] h-fit'>
                                                                            {item.employee_comment}
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex flex-col p-2 border rounded w-full shadow-sm bg-white'>
                                                                        <span className='text-color-gray text-[12px]'>
                                                                            Team Lead Comments:
                                                                        </span>
                                                                        <div className='flex text-black text-[11px] h-fit'>
                                                                            {item.team_lead_comment}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            ))}
                                                        </div>
                                                        :
                                                        <div className='p-2'>
                                                            <div className='flex flex-row w-full text-[13px] justify-center items-center h-[60px] rounded-md border bg-highlight text-black mt-8'>
                                                            No Learning and Growth Objective
                                                            </div>
                                                        </div>
                                                        }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Collapse>
                                    </div>
                                    
                                    </>
                                    }
                                </>
                                :
                                null}
                            </div>
                            }
                            <div className='flex flex-col p-4 py-2 border rounded w-full shadow-sm mt-2 bg-gray-100'>
                                <span className='text-gray-400 text-[13px]'>
                                    Training Requirement:
                                </span>
                                <div className='flex text-black text-[14px] h-fit'>
                                    {trainingRequirement? trainingRequirement: 'Requirement Not Set'}
                                </div>
                            </div>
                            <div className='flex flex-col p-4 py-2 border rounded w-full shadow-sm bg-gray-100'>
                                <span className='text-gray-400 text-[13px]'>
                                    HR Comments:
                                </span>
                                <div className='flex text-black text-[14px] h-fit'>
                                    {reviewerComment? reviewerComment: 'No Comment Yet'}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                }
            </div>
        </DashboardLayout>
    );
};

export default TeamLeadViewPerformanceEvaluation;
