import DOMPurify from 'dompurify';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import EmptyTableSVG from '../../assets/svg/empty-table.svg';
import Logo from '../../components/logo';
import SingleloadExitLayout from '../../components/layouts/employee-exit';
import FormTextArea from '../../components/form-textarea';
import { Initials } from '../../utils/functions';
import { StatusText } from '../../components/fragments/status-text';
import { ExitEmployeeStatusConfig } from '../../utils/constants';
import Button from '../../components/button';
import { useEmployeeMarkExitComplete, useFetchExit, useSaveAnswer } from '../../redux/employee/hook/exit';
import { setAlert } from '../../redux/components/components-slice';
import { store } from '../../redux';
import LoadingBar from '../../components/loader-bar';

function ExitQuestions() {

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const {data: loadExit, isLoading: exitLoading} = useFetchExit();

  const dispatch = useDispatch();

  const [result, setResult] = useState([]);

  const [exitData, setExitData] = useState()

  const { mutateAsync: saveAnswer, isLoading: saveAnswerloading } = useSaveAnswer();
  const { mutateAsync: markExit, isLoading: markExitloading } = useEmployeeMarkExitComplete();
  const history = useHistory()

  useEffect(() => {
    if (loadExit) {
      setExitData(loadExit)
      if (loadExit.answers && loadExit.answers.length > 0) {
        history.push('/employee-exit/stages')
      } else if (loadExit.questions && loadExit.questions.length > 0) {
        let newResults = []
        loadExit.questions.forEach((item) => {
          let newResult = {
            question_id: item.id,
            question: item.question,
            answer: '',
            question_type: item.question_type,
            options: item.options
          }
          newResults.push(newResult);
        })
        setResult(newResults)
      } else {
        history.push('/employee-exit/stages')
      }
    }
  }, [loadExit,])

  const markloadExit = async() => {
    let payload = {
      employee_id: exitData?.employee_id,
      token: token,
      exit_id: exitData?.id,
      employee_status: exitData?.employee_status == 1 ? 0: 1
    }
    await markExit(payload).then((result) => {
      let newArray =
        JSON.parse(JSON.stringify(exitData));
      
      newArray.answers = result
      store.dispatch(setExitData(newArray));
      history.push('/dashboard');
    });
  }

  const submitForm = async () => {
    if (result && result.length > 0) {
      let missedQuestions = []
      console.log(result)
      result.forEach((item, index) => {
        if (item.answer === '') {
          missedQuestions.push(index+1)
        }
      })
      if (missedQuestions.length < 1) {
        let payload = {
            employee_id: loadExit?.employee_id,
            exit_id: loadExit?.id,
            answers: JSON.stringify(result)
        }
        await saveAnswer(payload).then(() => {
          if (result.status == 'success') {
            if (result?.data?.workflow) {
              history.push('/employee-exit/stages');
            } else {
              store.dispatch(setAlert(true, 'success', 'No workflow added. Exit completed.'));
            }
          }
        });
      } else {
        dispatch(setAlert(true, 'info', 'You have to answer all questions. No answers found for question (' + missedQuestions.join(", ") + ')'));
      }
    }
  };

  return (
    <SingleloadExitLayout step='1' company_name={exitData?.company_name} logo={exitData?.logo}>
      <div className='flex flex-col gap-4 w-full pt-2 !min-h-screen overflow-auto p-4'>
        <div className='flex flex-row items-center justify-start mt-3'>
          <Button
            text='Back To Dashboard'
            type='submit'
            className='!h-[35px] px-3 !bg-hr-primary-1 w-fit'
            textClass='!text-[12px] !text-white'
            onClick={() => history.push('/dashboard')}
          />
        </div>
        {exitLoading?
        <div className='mt-5'>
          <LoadingBar loading={exitLoading} />
        </div>
        : exitData && exitData.status == 0?
        <div>
          {exitData && exitData.status == 0?
          <div className='flex flex-col gap-4'>
            <div className='flex flex-row items-center box-shadow md:h-[104px] w-full border border-gray-300 rounded'>
              <div className='flex md:flex-row flex-col md:px-8 p-4 justify-between md:items-center w-full gap-3 '>
                <div className='flex flex-row items-center'>
                  <div className='flex font-bold justify-center items-center h-[58px] w-[58px] rounded-[58px] border-[3px] header-3 border-secondary-2 bg-green-200'>
                    {Initials(exitData?.first_name, exitData?.last_name)}
                  </div>
                  <div className='flex flex-col pl-4 h-[48px] content-center justify-center'>
                    <span className='p1-bold'>
                      {exitData?.first_name} {exitData?.last_name}
                    </span>
                    <span className='p4 text-color-gray'>{exitData?.email}</span>
                  </div>
                </div>
                {exitData?.employee_status == 1?
                <div className='flex flex-col gap-1 items-center'>
                  <StatusText
                    statusConfig={ExitEmployeeStatusConfig}
                    code={exitData?.employee_status}
                    className={'py-2 rounded p1-bold px-3 p2-bold text-[15px]'}
                  />
                  <span className='p4 text-color-gray'>{exitData?.status == 1? 'Reviewed By Admin': 'Not Reviewed By Admin'}</span>
                </div>
                :
                <div className='flex flex-row items-center justify-end'>
                  <Button
                    text='Mark Exit Complete'
                    type='submit'
                    className='!max-h-[35px] px-3 !bg-hr-primary-1'
                    textClass='!text-[12px] !text-white'
                    onClick={() => markloadExit()}
                    loading={saveAnswerloading}
                  />
                </div>
                }
              </div>
            </div>
            <div className=' flex flex-col text-justify'>
              <span className='header-4'>Instruction:</span>
              <div className='text-[13px] text-gray-500 text-justify'>Complete the exit questions as your company has requested.</div>
            </div>
            {result && exitData.answers.length < 1
            ?
            <div className='flex flex-col items-center justify-center border p-4 border-gray-400 rounded bg-white gap-2'>
              {result?.map((item, index) => (
                  <div className='flex flex-col w-[100%] border p-2 rounded bg-highlight' key={index}>
                    <div className='flex flex-col mb-2'>
                      <span className='header-4'>Question {index+1}</span>
                      <span className='p4-medium'>{item.question}</span>
                    </div>
                    {item.question_type === 'multiple_choice'?
                      <div className='flex flex-col !min-w-[100%]'>
                        {/* <span className='header-5 mb-2 underline '>Options:</span> */}
                        {item?.options?.map((optionItem, optionIndex) => (
                          <div
                            className={optionItem?.selected? 'flex flex-row items-center mb-2 p-2 bg-hr-primary-1 cursor-pointer duration-500 border-1 rounded-md':'flex flex-row items-center bg-white cursor-pointer duration-500 border-1 rounded mb-2 p-2 hover:bg-gray-300'}
                            onClick={() => {
                              let newArray = JSON.parse(JSON.stringify(result));
                              newArray[index].options.forEach((itop) => {itop.selected = false})
                              newArray[index].options[optionIndex].selected = true;
                              newArray[index].answer = newArray[index].options[optionIndex].option_index
                              console.log(newArray[index].options[optionIndex].option_index)
                              setResult(newArray);
                            }}>
                            <div className={optionItem.selected?'header-5 pr-2 !min-w-[20%] !text-white duration-500':'header-5 pr-2 !min-w-[20%] duration-500'}>Option {optionIndex+1}:</div>
                            <div className={optionItem.selected?'header-5 pr-2 !min-w-[20%] !text-white duration-500':'header-5 pr-2 !min-w-[20%] duration-500'}>{optionItem.option}</div>
                          </div>
                        ))}
                      </div>
                      :
                      <div>
                        <FormTextArea
                          label={`Answer`}
                          placeholder={'Enter answer here'}
                          defaultValue={item.answer}
                          onInput={(e) => {
                            let newArray =
                              JSON.parse(JSON.stringify(result));
                            newArray[index].answer = e.target.value
                            setResult(newArray);
                          }}
                          name={`answer_${index+1}`}
                          type='text'
                        />
                      </div>
                    }
                  </div>
              ))}
              <Button
                text='Submit'
                type='button'
                className={'w-[130px] h-[42px] !bg-hr-primary-1'}
                textClass='!text-[12px]'
                loading={saveAnswerloading}
                // disabled={!firstName || !lastName || !mobile || !email || !educationLevel || !yearsOfExperience || expired}
                onClick={() => {
                  submitForm()
                }}
              />
            </div>
            :
            null
            }
          </div>
          : !loadExit?
          <div className='flex flex-col items-center justify-center w-[100%] -mt-4 !min-h-[200px]'>
            <img src={EmptyTableSVG} alt='no_data_fetched' width={'80%'} height={'200px'}/>
            <span className='header-4 mt-3'>You Are not verified</span>
          </div>
          :
          <div className='flex flex-col items-center justify-center w-[100%] -mt-4 !min-h-[200px]'>
            <img src={EmptyTableSVG} alt='no_data_fetched' width={'80%'} height={'200px'}/>
            <span className='header-4 mt-3'>Onboarding has already been reviewed. You can no longer update.</span>
          </div>
          }
        </div>
        :
        <div className='flex flex-col items-center justify-center w-[100%] -mt-4 !min-h-[200px]'>
          <img src={EmptyTableSVG} alt='no_data_fetched' width={'80%'} height={'200px'}/>
          <span className='header-4 mt-3'>No Exit Data Fetched</span>
        </div>
        }
        <div className={'md:hidden flex items-center justify-center text-color-black gap-2 mt-3'}>
          Powered By: <a href='https://www.ropay.africa' target={'_blank'} rel="noreferrer">{<Logo width='80px' height='40px' blue />}</a>
        </div>
        
      </div>
    
    </SingleloadExitLayout>
  );
}

export default ExitQuestions;
