import React from "react";

export const GoogleIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6892:11143)">
      <path
        d="M4.3882 8.1375C4.77811 6.95787 5.53059 5.93151 6.5383 5.20483C7.54601 4.47815 8.75748 4.08828 9.99987 4.09083C11.4082 4.09083 12.6815 4.59083 13.6815 5.40917L16.5915 2.5C14.8182 0.954167 12.5457 0 9.99987 0C6.0582 0 2.66487 2.24833 1.0332 5.54167L4.3882 8.1375Z"
        fill="#EA4335"
      />
      <path
        d="M13.3671 15.0105C12.4588 15.5963 11.3054 15.9088 10.0004 15.9088C8.76298 15.9114 7.55609 15.5246 6.55061 14.8033C5.54514 14.082 4.79204 13.0627 4.39792 11.8896L1.03125 14.4455C1.85729 16.1174 3.13564 17.5241 4.7211 18.5059C6.30656 19.4877 8.13559 20.0052 10.0004 19.9996C12.4446 19.9996 14.7796 19.1305 16.5288 17.4996L13.3679 15.0105H13.3671Z"
        fill="#34A853"
      />
      <path
        d="M16.5283 17.5C18.3575 15.7933 19.545 13.2533 19.545 9.99997C19.545 9.40831 19.4542 8.77247 19.3183 8.18164H10V12.0458H15.3633C15.0992 13.345 14.3883 14.3508 13.3675 15.0108L16.5283 17.5Z"
        fill="#4A90E2"
      />
      <path
        d="M4.3976 11.8903C4.19373 11.2811 4.09016 10.6428 4.09093 10.0003C4.09093 9.34866 4.1951 8.72283 4.38843 8.13783L1.03343 5.54199C0.347079 6.92756 -0.00673329 8.45409 9.70454e-05 10.0003C9.70454e-05 11.6003 0.37093 13.1087 1.03093 14.4462L4.3976 11.8903Z"
        fill="#FBBC05"
      />
    </g>
    <defs>
      <clipPath id="clip0_6892:11143">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
