import React from "react";

export const MoreSquare = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.83313 14.434L1.83313 7.56544C1.83313 4.02619 4.02671 1.83352 7.5678 1.83352L14.4428 1.83352C17.9738 1.83352 20.1665 4.02619 20.1665 7.56544L20.1665 14.4432C20.1665 17.9742 17.9738 20.1669 14.44 20.1659C14.0532 20.1659 13.7388 19.8515 13.7388 19.4647C13.7388 19.0769 14.0532 18.7634 14.44 18.7634C17.2212 18.7634 18.7612 17.2317 18.7612 14.434L18.7612 7.56544C18.7612 4.77602 17.2295 3.23602 14.44 3.23602L7.56505 3.23602C4.77563 3.23602 3.23563 4.76685 3.23563 7.56544L3.23563 14.4349C3.23563 17.2244 4.77563 18.7644 7.56505 18.7644L9.6853 18.7644L9.7018 18.7644C10.085 18.7644 10.3948 19.0742 10.3948 19.4574L10.3948 19.4656L10.3948 19.4674C10.3948 19.8543 10.0804 20.1678 9.69355 20.1669L7.56505 20.1669C4.02671 20.1669 1.83313 17.9577 1.83313 14.434ZM9.90347 15.1062C9.90347 14.5003 10.3957 14.008 10.9998 14.008C11.6048 14.008 12.0971 14.5003 12.0971 15.1062C12.0971 15.7112 11.6048 16.2044 10.9998 16.2044C10.3957 16.2044 9.90347 15.7112 9.90347 15.1062ZM9.90347 11C9.90347 10.3941 10.3957 9.90183 10.9998 9.90183C11.6048 9.90183 12.0971 10.3941 12.0971 11C12.0971 11.605 11.6048 12.0982 10.9998 12.0982C10.3957 12.0982 9.90347 11.605 9.90347 11ZM10.9998 5.79608C10.3957 5.79608 9.90347 6.28833 9.90347 6.89425C9.90347 7.49925 10.3957 7.99242 10.9998 7.99242C11.6048 7.99242 12.0971 7.49925 12.0971 6.89425C12.0971 6.28833 11.6048 5.79608 10.9998 5.79608Z"
      fill="#333333"
    />
  </svg>
);
