import React from "react";

export const Notification = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Iconly/Broken/Notification">
      <g id="Notification">
        <path
          id="Notification_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.8257 11.3093C20.2317 11.9204 20.4646 12.6285 20.5 13.3594L20.4734 13.5442C20.504 14.5445 20.1701 15.5223 19.533 16.2983C18.7193 17.1763 17.6069 17.7247 16.41 17.8381C13.4617 18.1617 10.4863 18.1617 7.53799 17.8381C6.35854 17.717 5.26557 17.1687 4.46827 16.2983C3.82062 15.5334 3.47675 14.5603 3.50122 13.5618V13.4386C3.55 12.6858 3.80121 11.9596 4.22873 11.3357L4.2997 11.2477C4.74432 10.7473 5.05006 10.1409 5.1869 9.4879V8.73119V7.67532C5.31271 7.35744 5.6376 7.16252 5.97989 7.19956C6.32218 7.23659 6.59705 7.4964 6.65079 7.8337V8.71359V9.64628C6.65424 9.67845 6.65424 9.7109 6.65079 9.74307C6.46606 10.6508 6.04382 11.4944 5.42645 12.1892C5.1887 12.5648 5.05412 12.9955 5.03608 13.4386V13.6322C5.01638 14.2554 5.22433 14.8647 5.62163 15.348C6.17247 15.9123 6.90907 16.2619 7.69768 16.3335C10.552 16.6415 13.4315 16.6415 16.2858 16.3335C17.096 16.2597 17.8506 15.8934 18.4062 15.304C18.784 14.8322 18.9819 14.2433 18.9651 13.641V13.4386C18.9469 12.994 18.8157 12.5612 18.5836 12.1804C17.9406 11.4935 17.4935 10.6492 17.2883 9.73427C17.2849 9.7021 17.2849 9.66966 17.2883 9.63748V8.696V7.8161C17.031 5.15882 14.3606 3.51342 12.0361 3.51342C11.0479 3.51116 10.0759 3.76277 9.2148 4.24373C8.97637 4.38588 8.67884 4.38806 8.43831 4.24943C8.19779 4.1108 8.05241 3.85333 8.05891 3.5775C8.06541 3.30168 8.22276 3.05122 8.46955 2.92389C9.53654 2.32607 10.7397 2.00808 11.9651 2C15.0348 2 18.4772 4.16454 18.8675 7.64012V8.6784V9.45271C19.0008 10.1069 19.307 10.7142 19.7548 11.2125C19.7813 11.2426 19.805 11.275 19.8257 11.3093ZM12.2515 20.4308C12.7729 20.3531 13.2415 20.0723 13.5532 19.6507H13.5621C13.8316 19.3275 14.3142 19.2822 14.64 19.5495C14.9659 19.8168 15.0116 20.2954 14.7421 20.6186C14.0777 21.4802 13.0499 21.9899 11.9563 22C10.8658 21.9872 9.84176 21.4778 9.17931 20.6186C8.90982 20.2954 8.9555 19.8168 9.28134 19.5495C9.60718 19.2822 10.0898 19.3275 10.3593 19.6507C10.4776 19.8076 10.6181 19.9469 10.7763 20.0642C11.1991 20.3765 11.7302 20.5084 12.2515 20.4308Z"
          fill="#130F26"
        />
      </g>
    </g>
  </svg>
);
