import { Axios } from '../axios';

const getDepartments = async (payload) => {
  const { data } = await Axios.get(
    `/mobile/departments`,
  );
  return data?.data;
};

const getDepartment = async (payload) => {
  const { data } = await Axios.get('/mobile/departments/' + payload.id, payload);
  return data?.data;
};

const getDepartmentEmployees = async (payload) => {
  const { data } = await Axios.get('/mobile/departments/' + payload.id, payload);
  return data?.data;
};

const getDepartmentTeams = async (payload) => {
  const { data } = await Axios.get('/mobile/department/teams/' + payload.id);
  return data?.data;
};

export const departments = {
  getDepartments,
  getDepartment,
  getDepartmentEmployees,
  getDepartmentTeams,
};
