import { companies } from '../../api';
import { store } from '..';
import { setAlert, setIsLoading } from '../components/components-slice';
import { updateUser } from '../user/hook';
import { useMutation, useQuery } from '@tanstack/react-query';
import { setCompany } from './reducer';
import { queryClient } from '../..';
import { setToken, setUser } from '../user/user-slice';

export function useGenerateOtp() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return companies.generateCompanyOtp(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        // console.log(response)
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useConfirmOtp() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return companies.confirmCompanyOtp(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setUser(response.data));
        store.dispatch(setCompany(response.data.company));
        store.dispatch(setAlert(true, 'success', 'Company Added Successfully'));
        window.location.reload();
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useAddCompany() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return companies.addCompany(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setUser(response.data));
        store.dispatch(setCompany(response.data.company));
        store.dispatch(setAlert(true, 'success', 'Company Added Successfully'));
        window.location.reload();
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetUserCompanies() {
  return useQuery(['user-companies'], companies.getUserCompanies);
}

export function useSwitchCurrentCompany() {
  return useMutation(
    (payload) => {
      return companies.switchCompany(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        updateUser({ reload: true });
        store.dispatch(setAlert(true, 'success', response.message));
      },
    },
    {
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}
