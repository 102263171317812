import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, path }) => {
  const { user } = useSelector((state) => state.userSlice);
  // const { section } = useSelector((state) => state.sectionSlice);

  // const location = useLocation();

  const history = useHistory();

  if (!user) {
    return <Redirect to='/' />;
  }

  return <Route component={Component} path={path} history={history} />;
};

export default ProtectedRoute;
