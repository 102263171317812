import React, { useState } from 'react';
import { Initials } from '../../utils/functions';
import { useSelector } from 'react-redux';
import ChatHome from '../../assets/svg/chat/chat-home.svg';
import RecentActivity from '../../assets/svg/chat/activity.svg';
import PinnedChats from '../../assets/svg/chat/pin-chat.svg';
import Chats from '../../assets/svg/chat/messages.svg';
import Channels from '../../assets/svg/chat/channels.svg';
import Groups from '../../assets/svg/chat/group.svg';
import DropDownSVG from '../../assets/svg/chevron-down.svg';
import { Collapse } from 'reactstrap';

const RochatMenuView = () => {
    const [pinnedChatsCollapse, setPinnedChatsCollapse] = useState(false);
    const [chatsCollapse, setChatsCollapse] = useState(false);
    const [groupCollapse, setGroupCollapse] = useState(false);
    const [channelCollapse, setChannelCollapse] = useState(false);

    const toggleCollapse = (type) => {
        if (type === 'pinned') {
            setPinnedChatsCollapse(!pinnedChatsCollapse);
            setChatsCollapse(false);
            setChannelCollapse(false);
            setGroupCollapse(false);
        } else if (type === 'chats') {
            setChatsCollapse(!chatsCollapse);
            setPinnedChatsCollapse(false);
            setChannelCollapse(false);
            setGroupCollapse(false);
        } else if (type === 'channels') {
            setChannelCollapse(!channelCollapse);
        } else if (type === 'groups') {
            setGroupCollapse(!groupCollapse);
            setPinnedChatsCollapse(false);
            setChannelCollapse(false);
            setChatsCollapse(false);
        }
        
    };
    const { user } = useSelector((state) => state.userSlice);
    const [pinnedChats, setPinnedChats] = useState([
        {
            name: 'Adedokun Agunbiade',
            message: 'Where are you',
            read_status: 1
        }
    ])

    const [chats, setChats] = useState([
        {
            name: 'Adedokun Agunbiade',
            message: 'Where are you',
            read_status: 1
        }
    ])

    const [groups, setGroups] = useState([
        {

        }
    ])

    const [channels, setChannels] = useState([
        {

        }
    ])

    return (
        <div className='md:w-[20%] w-full p-2 flex flex-col gap-2 items-center'>
            <div className='flex flex-col gap-1 items-center'>
                <div className='flex font-bold justify-center items-center h-[60px] w-[60px] rounded-[60px] header-4 bg-green-200'>
                    {Initials(user?.first_name, user?.last_name)}
                </div>
                <div className='text-[14px] font-semibold text-secondary-2'>
                    {user.first_name} {user.last_name}
                </div>
                <div className='text-[12px] font-semibold'>
                    Chief Executive Officer
                </div>
            </div>
            <div className='w-full h-[40px] bg-highlight rounded-3xl'>

            </div>
            <div className='flex flex-col items-start w-full mt-2'>
                <div className='flex gap-2 h-[40px] items-center w-full hover:bg-highlight hover:px-2 duration-300 cursor-pointer'>
                    <img src={ChatHome} alt='' width={'24px'}/>
                    <span className='text-[14px] text-secondary-2 font-extrabold'>Home</span>
                </div>
                <div className='flex gap-2 h-[40px] items-center w-full hover:bg-highlight hover:px-2 duration-300 cursor-pointer'>
                    <img src={RecentActivity} alt='' width={'24px'}/>
                    <span className='text-[14px] text-secondary-2 font-extrabold'>Activities</span>
                </div>
            </div>
            <hr className='divider mt-2 mb-2' />
            <div className='flex flex-col items-stretch w-full '>
                <div className='flex flex-col w-full'>
                    <div className='flex flex-col bg-white'>
                        <div onClick ={() => {
                                toggleCollapse('pinned')
                            }} 
                            className='h-[50px] flex justify-between w-full cursor-pointer items-center hover:bg-highlight hover:px-2 duration-300 rounded-md'
                        >
                            <div className='flex gap-2 items-center justify-start'>
                                <img src={PinnedChats} alt='' width={'20px'} />
                                <span className={'text-[14px] duration-300 font-semibold text-secondary-2'}>
                                    Pinned Chats
                                </span>
                            </div>
                            <img src={DropDownSVG} alt='' width={'14px'} />
                        </div>
                        <Collapse isOpen={pinnedChatsCollapse}>
                        <div className='flex flex-col'>
                            {pinnedChats.length + 1 && pinnedChats.map((item, index) => (
                                <>
                                    {item.name}
                                </>
                            ))}
                        </div>
                        </Collapse>
                    </div>
                </div>
                <div className='flex flex-col w-full'>
                    <div className='flex flex-col bg-white'>
                        <div onClick ={() => {
                                toggleCollapse('chats')
                            }} 
                            className='h-[50px] flex justify-between w-full cursor-pointer items-center hover:bg-highlight hover:px-2 duration-300 rounded-md'
                        >
                            <div className='flex gap-2 items-center justify-start'>
                                <img src={Chats} alt='' width={'20px'} />
                                <span className={'text-[14px] duration-300 font-semibold text-secondary-2'}>
                                    Chats
                                </span>
                            </div>
                            <img src={DropDownSVG} alt='' width={'14px'} />
                        </div>
                        <Collapse isOpen={chatsCollapse}>
                        <div className='flex flex-col'>
                            {chats.length + 1 && chats.map((item, index) => (
                                <>
                                    {item.name}
                                </>
                            ))}
                        </div>
                        </Collapse>
                    </div>
                </div>
                <div className='flex flex-col w-full'>
                    <div className='flex flex-col bg-white'>
                        <div onClick ={() => {
                                toggleCollapse('channels')
                            }} 
                            className='h-[50px] flex justify-between w-full cursor-pointer items-center hover:bg-highlight hover:px-2 duration-300 rounded-md'
                        >
                            <div className='flex gap-2 items-center justify-start'>
                                <img src={Channels} alt='' width={'20px'} />
                                <span className={'text-[14px] duration-300 font-semibold text-secondary-2'}>
                                    Channels
                                </span>
                            </div>
                            <img src={DropDownSVG} alt='' width={'14px'} />
                        </div>
                        <Collapse isOpen={channelCollapse}>
                        <div className='flex flex-col'>
                            {channels.length + 1 && channels.map((item, index) => (
                                <>
                                    {item.name}
                                </>
                            ))}
                        </div>
                        </Collapse>
                    </div>
                </div>
                <div className='flex flex-col w-full'>
                    <div className='flex flex-col bg-white'>
                        <div onClick ={() => {
                                toggleCollapse('groups')
                            }} 
                            className='h-[50px] flex justify-between w-full cursor-pointer items-center hover:bg-highlight hover:px-2 duration-300 rounded-md'
                        >
                            <div className='flex gap-2 items-center justify-start'>
                                <img src={Groups} alt='' width={'20px'} />
                                <span className={'text-[14px] duration-300 font-semibold text-secondary-2'}>
                                    Groups
                                </span>
                            </div>
                            <img src={DropDownSVG} alt='' width={'14px'} />
                        </div>
                        <Collapse isOpen={groupCollapse}>
                        <div className='flex flex-col'>
                            {chats.length + 1 && chats.map((item, index) => (
                                <>
                                    {item.name}
                                </>
                            ))}
                        </div>
                        </Collapse>
                    </div>
                </div>
            </div>
            
        </div>
    );
};

export default RochatMenuView;