import { createSlice } from '@reduxjs/toolkit';

// Slice
const slice = createSlice({
  name: 'misc',
  initialState: {
    transiting: false,
  },
  reducers: {
    setTransiting: (state, { payload }) => {
      state.transiting = payload;
    },
  },
});

// Actions
export const { setTransiting } = slice.actions;

export default slice.reducer;
