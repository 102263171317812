import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Button from "../../components/button";
import AuthLayout from "../../components/layouts/auth";
import TickSVG from '../../assets/svg/tick.svg';
import ErrorSVG from '../../assets/svg/error_tick.svg';
import { useVerifyEmail } from "../../redux/user/hook";
import LoadingBar from "../../components/loader-bar";
import { useSelector } from "react-redux";

const VerifyEmail = () => {
    useEffect(() => {
        window.scroll(0, 0);
    }, []);
    const history = useHistory();

    const { section } = useSelector((state) => state.sectionSlice);

    const { token } = useParams();

    const { mutateAsync: verifyEmail, isLoading: loading } = useVerifyEmail();

    const [verifyStatus, setVerifyStatus] = useState()

    useEffect(() => {
        async function verifyMyEmail () {
            await verifyEmail({verify_token: token}).then((result)=> {
                setVerifyStatus(result.status)
            })
        }
        verifyMyEmail();
    }, [])

    return (
        <AuthLayout>
            <React.Fragment>
                {loading?
                <div className='flex flex-col w-full text-center justify-center pb-[25px] pt-[100px]'>
                    <LoadingBar loading={loading} />
                </div>
                :
                <form className="form">
                    { verifyStatus === 'success'?
                    <div className='flex flex-col w-full text-center justify-center pb-[25px]'>
                        <div className='w-[100%] flex justify-center pb-[15px]'>
                            <div
                            className={`flex items-center justify-center w-[110px] h-[110px] rounded-[130px] bg-[#44AF69]`}
                            >
                            <img
                                src={TickSVG}
                                alt='tick'
                                style={{ width: '60px', height: '60px' }}
                            />
                            </div>
                        </div>
                        <span className={'header-4-regular md:header-2 text-secondary-2 pb-1'}>
                            Email Verified
                        </span>
                        <span className='p4 md:header-4-regular text-color-gray'>
                        Your Email has been verified, Please log back in to continue
                        </span>
                    </div>
                    :
                    <div className='flex flex-col w-full text-center justify-center pb-[25px]'>
                        
                        <div className='w-[100%] flex justify-center pb-[15px]'>
                            <div
                            className={`flex items-center justify-center w-[110px] h-[110px] rounded-[130px] bg-[#D34829]`}
                            >
                            <img
                                src={ErrorSVG}
                                alt='tick'
                                style={{ width: '60px', height: '60px' }}
                            />
                            </div>
                        </div>
                        <span className='header-4-regular md:header-2 text-secondary-2 pb-1'>
                            Email Not Verified
                        </span>
                        <span className='p4 md:header-4-regular text-color-gray'>
                            Log Back In To Resend Your Verification Email
                        </span>
                    </div>
                    }
                    <Button
                        text="Login"
                        type="submit"
                        onClick={() => history.push(`/`)}
                    />
                </form>
                }
            </React.Fragment>
        </AuthLayout>
    );
};

export default VerifyEmail;
