import React, { useEffect } from 'react';
import Button from '../../components/button';
import DashboardLayout from '../../components/layouts/dashboard';
import RochatMenuView from '../../components/ro-chat.js/menu';
import ChatView from '../../components/ro-chat.js/chat';
import RecentChatActivityView from '../../components/ro-chat.js/recent-activities';
import ChatProfileView from '../../components/ro-chat.js/chat-profile';

const RochatPage = () => {

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    return (
        <DashboardLayout title='Ro-chat'>
        <div className='flex flex-col bg-white -m-4'>
            <div className='flex w-full min-h-[600px]'>
                <RochatMenuView />
                <ChatView />
                {/* <RecentChatActivityView /> */}
                <ChatProfileView />
                
            </div>
        </div>
        </DashboardLayout>
    );
};

export default RochatPage;
