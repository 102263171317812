import DOMPurify from 'dompurify';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../assets/svg/close.svg';
import { formValidation } from '../../../utils/functions';
import Button from '../../button';
import FormInput from '../../form-input';
import { usePasswordUpdate } from '../../../redux/user/hook';
import Modal from '../modal';
import { useSelector } from 'react-redux';

function ChangePassword({ isOpen, closeModal }) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const { user } = useSelector((state) => state.userSlice);

  const { mutateAsync: updateUserPass, isLoading: updateLoading } =
    usePasswordUpdate();

  const [Password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const submitForm = async (data) => {
    const old_password = DOMPurify.sanitize(data?.old_password);
    const password = DOMPurify.sanitize(data?.new_password);
    const password_confirmation = DOMPurify.sanitize(
      data?.confirm_new_password,
    );

    let payload = {
      email: user?.email,
      old_password,
      password,
      password_confirmation,
    };
    await updateUserPass(payload).then(() => {
      closeModal()
    })
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[700px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <form className='form pb-3' onSubmit={handleSubmit(submitForm)}>
          <div className='pl-8 pr-8 pt-8'>
            <div className='flex flex-row justify-between'>
              <span className='header-3'>Change Password</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='' />
              </div>
            </div>
          </div>
          <hr className='divider' />
          <div className='flex flex-col mx-14'>
            <div className='flex flex-col md:flex-col w-full justify-between'>
              <FormInput
                label='Old Password'
                name='old_password'
                type='password'
                placeholder={'Enter old password'}
                readOnly={updateLoading}
                inputRef={register(formValidation('text', true))}
                error={errors.old_password}
                errorMessage={
                  errors.old_password && errors.old_password.message
                }
              />
              <FormInput
                label='New Password'
                name='new_password'
                type='password'
                placeholder={'Enter new password'}
                readOnly={updateLoading}
                inputRef={register(formValidation('password', true))}
                onInput={(e) => setPassword(e.target.value)}
                error={errors.password}
                errorMessage={errors.password && errors.password.message}
                infos={[
                  'Password should contain minimum of 8 characters, 1 caps letter and 1 number',
                ]}
              />
              <FormInput
                label='Confirm New Password'
                name='confirm_new_password'
                type='password'
                placeholder={'Confirm new password'}
                readOnly={updateLoading}
                inputRef={register(formValidation('password', true))}
                onInput={(e) => setConfirmPassword(e.target.value)}
                error={confirmPassword !== Password && confirmPassword.trim()}
                errorMessage={'Password does not match'}
              />
              <div className='w-full mt-[20px]'>
                <Button
                  text='UPDATE PASSWORD'
                  type='submit'
                  disabled={!isValid}
                  loading={updateLoading}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default ChangePassword;
