import DOMPurify from 'dompurify';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../assets/svg/close.svg';
import {
  useAddSalaryAdvance,
} from '../../../redux/employee/hook/salary-advance';
import { monthOptions, yearOptions } from '../../../utils/constants/date';
import { formValidation } from '../../../utils/functions';
import Button from '../../button';
import FormInput from '../../form-input';
import FormSelect from '../../form-select';
import FormTextArea from '../../form-textarea';
import Modal from '../modal';

function AddSalaryAdvance({
  isOpen,
  closeModal,
  title,
  buttonText,
}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const { mutateAsync: addSalaryAdvance, isLoading: addSalaryAdvanceloading } = useAddSalaryAdvance();
  const [month, setMonth] = useState();
  const [year, setYear] = useState();

  const submitForm = async (data) => {
    const amount = DOMPurify.sanitize(data?.amount);
    const purpose = DOMPurify.sanitize(data?.purpose);
    const duration = DOMPurify.sanitize(data?.duration);

    let payload = {
      duration,
      amount,
      purpose,
      repayment_start: year + month,
    };
    await addSalaryAdvance(payload).then(() => {
      closeModal();
    });
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[700px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto '>
        <form className='form pb-4' onSubmit={handleSubmit(submitForm)}>
          <div className='pl-4 pr-4 pt-4'>
            <div className='flex flex-row justify-between'>
              <span className='header-3'>{title}</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='' />
              </div>
            </div>
          </div>
          <hr className='divider' />
          <div className='flex flex-col mx-8'>
            <div>
              <div className='flex flex-row md:flex-col w-full justify-between'>
                <div className='w-full md:w-1/2 pr-2'>
                  <FormSelect
                    value={month}
                    options={monthOptions}
                    onChange={(selected) => {
                      setMonth(selected);
                    }}
                    label='Repayment start month'
                  />
                </div>

                <div className='w-full md:w-1/2 pl-2'>
                  <FormSelect
                    value={year}
                    options={yearOptions}
                    onChange={(selected) => {
                      setYear(selected);
                    }}
                    label='Repayment start year'
                  />
                </div>
              </div>
              <FormTextArea
                label='Purpose'
                name='purpose'
                type='text'
                placeholder='Enter salary advance purpose'
                inputRef={register(formValidation('text', true))}
                readOnly={addSalaryAdvanceloading}
                error={errors.purpose}
                errorMessage={errors.purpose && errors.purpose.message}
              />
              <div className='flex flex-row md:flex-col w-full justify-between'>
                <div className='w-full md:w-1/2 pr-2'>
                  <FormInput
                    label='Loan Duration (Months)'
                    placeholder='Enter loan duration'
                    name='duration'
                    type='number'
                    inputRef={register(formValidation('number', true))}
                    readOnly={addSalaryAdvanceloading}
                    error={errors.duration}
                    errorMessage={errors.duration && errors.duration.message}
                  />
                </div>

                <div className='w-full md:w-1/2 pl-2'>
                  <FormInput
                    label='Loan/Advance Amount'
                    placeholder='Enter Amount'
                    name='amount'
                    type='number'
                    inputRef={register(formValidation('number', true))}
                    readOnly={addSalaryAdvanceloading }
                    error={errors.amount}
                    errorMessage={errors.amount && errors.amount.message}
                  />
                </div>
              </div>
              
            </div>
            <div className='w-full mt-[20px]'>
              <Button
                text={buttonText}
                type='submit'
                loading={addSalaryAdvanceloading}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddSalaryAdvance;
