import React from 'react';
import DataTable from '../../../components/datatable';
import { useState } from 'react';
import { PAGINATION_DEFAULT, payrollHistoryStatusConfig } from '../../../utils/constants';
import { useCheckBulkTransactionStatus, useCheckTransactionStatus, useGetBranchWalletHistory, useRetryTransaction } from '../../../redux/human-resources/hook/branches';
import { useParams } from 'react-router-dom';
import Button from '../../button';

const BranchTransactionsTab = () => {

  const {id} = useParams()

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: '',
    start_date: '',
    end_date: '',
    type: '',
  });

  const {mutateAsync: checkTransactionStatus} = useCheckTransactionStatus()
  const {mutateAsync: retryTransaction} = useRetryTransaction()

  const { mutateAsync: refreshTransactionStatus, isLoading: refreshLoading } =
    useCheckBulkTransactionStatus();

  const { data: walletHistory } = useGetBranchWalletHistory(
    id,
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search,
    pagination?.start_date,
    pagination?.end_date,
    pagination?.type,
  );


  const tableConfig = {
    headers: ['Date', 'Name', 'Description', 'Type', 'Fees', 'Source', 'Inflow', 'Outflow', 'Status' ],
    keys: ['created', 'name', 'info', 'type', 'fees', 'source', 'credit', 'debit', 'status'],
    mobileHeader: {
      left: {
        title: 'Date Created',
        key: 'created',
      },
      right: {
        title: 'Amount',
        key: 'amount',
      },
    },
  };

  const dateConfig = {
    startDate: "0000-00-00",
    endDate: "0000-00-00"
  };

  const actionConfig = [
    {
      name: 'Check Status',
      color: 'green',
      action: async (id) => {
        let index = walletHistory?.data.findIndex((item) => item.id === id);
        await checkTransactionStatus(walletHistory?.data[index].id);
      },
    },
    {
      name: 'Retry',
      color: 'red',
      action: (id) => {
        let index = walletHistory?.data.findIndex((item) => item.id === id);
        retryTransaction({transaction_id : walletHistory?.data[index].id, branch_id : walletHistory?.data[index].source_id})
      },
    },
  ];

  const typeConfig = [
    {
        label: 'Deposit',
        color: 'green',
        value: 'wallet',
    },
    {
        label: 'Disbursements',
        color: 'orange',
        value: 'disburse',
    },
  ];

  return (
    <>
      {/* {unauthorized?
        <UnauthorizedPage />: */}
        <>
        {/* {showFundWallet && companyAccountDetails ? (
          <FundWalletModal
              isOpen={showFundWallet}
              source={'wallet'}
              accountDetails={companyAccountDetails}
              closeModal={() => setShowFundWallet(false)}
            />
        ) :null} */}
        <div className='flex flex-col -mt-4' id ='wallet-management'>
          <div className='flex flex-col bg-white rounded shadow-sm'>
            <div className='flex flex-col md:flex-row md:justify-between items-center justify-center px-4 gap-[10px] my-2 mt-3'>
              <div className='p3-bold header-4'>
                Transaction History
              </div>
              <div className='flex flex-col md:flex-row items-start gap-[10px] md:items-center'>
                <div className='md:w-fit w-[100%]' id='second-step'>
                  <Button
                    text='Refresh Transactions'
                    type='button'
                    theme='primary'
                    className='flex gap-2 h-[35px] w-fit p-2 '
                    textClass={'!text-[13px]'}
                    leftIcon={'refresh'}
                    loading={refreshLoading}
                    onClick={() => {
                      refreshTransactionStatus();
                    }}
                  />
                </div>
              </div>
            </div>
            <hr className='divider mt-2 mb-2' />
            <DataTable
              data={walletHistory}
              tableConfig={tableConfig}
              noCheck={true}
              actionConfig={actionConfig}
              filterByStatus={true}
              filterByType={true}
              typeConfig={typeConfig}
              computedActions={true}
              filterByDate={dateConfig}
              statusConfig={payrollHistoryStatusConfig}
              updatePagination={(data) => setPagination(data)}
            />
            </div>
          </div>
        </>
      {/* } */}
    </>
  );
};

export default BranchTransactionsTab;
