import React, { useEffect } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { store } from '../redux';
import { setAlert } from '../redux/components/components-slice';

const Alert = ({
  message=null,
  type=null,
}) => {
  const notify = () => toast(message, {
    type: type
  });
  useEffect(() => {
    if (message && type) {
      notify()
    }
    return () => {
      store.dispatch(
        setAlert(
          true,
          '',
          '',
        ),
      );
    }
  }, [message, type]);

  return (
    <ToastContainer 
      style={{ fontSize: '12px', select: 'none', borderRadius: '30px' }}
      autoClose={5000}
      newestOnTop={true}
      closeOnClick={false}
      position="top-right"
      pauseOnHover={true}
      theme='light'
      type={type}
    />
  );
};

export default Alert;
