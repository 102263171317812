import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import FormInput from '../../components/form-input';
import Button from '../../components/button';
import { useResetPassword } from '../../redux/user/hook';
import { formValidation } from '../../utils/functions';
import AuthLayout from '../../components/layouts/auth';
import { useSelector } from 'react-redux';

const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const { section } = useSelector((state) => state.sectionSlice);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const { mutateAsync: resetPassword, isLoading: resetLoading } = useResetPassword();
  const history = useHistory();
  const { token } = useParams();

  const submitForm = async (data) => {
    const confirm_password = DOMPurify.sanitize(data?.confirm_password);
    const password = DOMPurify.sanitize(data?.password);

    const requestData = {
      'token':token,
      'password_confirmation':confirm_password,
      'password':password,
    };

    await resetPassword(requestData).then((result) => {
      history.push(`/`);
    });
  };

  return (
    <AuthLayout>
      <React.Fragment>
        <form className='form' onSubmit={handleSubmit(submitForm)}>
          <div className='w-full text-center flex flex-col pb-[25px]'>
            <span className={'header-3-regular md:header-2 text-secondary-2 pb-1'}>
              Welcome Back
            </span>
            <span className='header-4-regular'>
              Enter Your New Password
            </span>
          </div>
          <FormInput
            label='New Password'
            name='password'
            type='password'
            placeholder={'Enter your new password'}
            inputRef={register(formValidation('password', true))}
            readOnly={resetLoading}
            error={errors.password}
            errorMessage={errors.password && errors.password.message}
          />

          <FormInput
            label='Confirm Password'
            name='confirm_password'
            type='password'
            placeholder={'Confirm your password'}
            inputRef={register(formValidation('password', true))}
            readOnly={resetLoading}
            error={errors.confirm_password}
            errorMessage={errors.confirm_password && errors.confirm_password.message}
          />
          <Button
            text='Reset'
            type='submit'
            loading={resetLoading}
            disabled={!isValid}
          />
        </form>
      </React.Fragment>
    </AuthLayout>
  );
};

export default ResetPassword;
