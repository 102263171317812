import { Axios } from '../axios';

const getTrainingSummary = async () => {
  const { data } = await Axios.get('/mobile/trainings/summary');
  return data.data;
};

const getEmployeeTrainings = async (payload) => {
    let search = payload.search ? '&search=' + payload.search : '';
    let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  
    const { data } = await Axios.get(
      `/mobile/trainings?limit=${payload.limit}&page=${payload.page}${filter}${search}`,
    );
  
    return data?.data;
};

const getEmployeeTrainingEmployees = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';

  const { data } = await Axios.get(
    `/mobile/trainings/employees/${payload.id}?limit=${payload.limit}&page=${payload.page}${search}`,
  );

  return data?.data;
};

const getEmployeeTraining = async (payload) => {
    const { data } = await Axios.get('/mobile/trainings/' + payload);
    return data?.data;
};

const commentOnTraining = async (payload) => {
  const { data } = await Axios.post(
    '/mobile/trainings/add-remark/'+ payload.id, payload.data,
  );
  return data;
};

const uploadTrainingCertificate = async (payload) => {
  const { data } = await Axios.post(
    '/mobile/trainings/upload-certificate', payload,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 0
    }
  );
  return data;
};



export const employeeTrainings = {
    getEmployeeTrainings,
    getEmployeeTrainingEmployees,
    getEmployeeTraining,


    commentOnTraining,
    uploadTrainingCertificate
  };