import React from 'react';
import Logo from '../logo';
import HRAuthLogo from "../../assets/svg/login-4.svg"
import OtherAuthLogo from "../../assets/svg/other_auth_image.svg"
import pppLogo from "../../assets/img/ppp-white.png"
import { useSelector } from 'react-redux';

const AuthLayout = ({ children }) => {
  const { section } = useSelector((state) => state.sectionSlice);
  return (
    <div className='flex flex-row' >
      <div className='hidden md:flex md:w-[35%] position-fixed'>
        <div
          className={'hidden md:flex h-screen inset-0 w-full radial-gradient justify-center items-center max-h-screen'}
        >
          <div className='flex justify-center align-center flex-col w-[80%] max-w-[400px]'>
            <div className='mb-4'>
              <Logo white />
            </div>
            <div>
              <img 
                src={OtherAuthLogo}
                alt='Payroll/Spend'
              />
            </div>
            <div className='flex flew-row justify-center align-center mt-4'>
              <img
                src={pppLogo}
                alt='payroll, people, payments'
                style={{ width: '65%', height: '25px' }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Page Components */}
      <div
        className='md:w-[100%] w-[100%] flex items-center 
        justify-center
        min-h-screen
        md:ml-[35%]
        pb-4
        pt-4'
      >
        <div className='w-[78%] max-w-[480px]'>
          <div className='flex flex-col'>
            <div className='md:hidden flex justify-center'>
              <Logo width={'170px'} height={'76px'} />
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
