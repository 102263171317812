import React, { useEffect } from 'react';
import DataTable from '../../components/datatable';
import { useState } from 'react';
import { expenseClaimApplicationStatusConfig, PAGINATION_DEFAULT } from '../../utils/constants';
import { useGetEmployeeReimbursements } from '../../redux/employee/hook/reimbursements';
import AddReImbursement from '../../components/modal/employees/add-reimbursement';
import Button from '../../components/button';
import DashboardLayout from '../../components/layouts/dashboard';

const ReimbursementPage = () => {

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: '',
  });

  const { data: expenseClaims } = useGetEmployeeReimbursements(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search,
  );

  const [showAddReImbursement, setShowAddReImbursement] = useState(false);
  const [reimbData, setReimbData] = useState();
  const [title, setTitle] = useState(null);
  const [buttonText, setButtonText] = useState(null);

  const tableConfig = {
    headers: [
      'Employee Name',
      'Mobile',
      'Purpose',
      'Amount',
      'Status'
    ],
    keys: [
      'employeeName',
      'mobile',
      'expenses_description',
      'expenses_amount',
      'status'
    ],
    mobileHeader: {
      left: {
        title: 'Employee Name',
        key: 'employeeName',
      },
      right: {
        title: 'Status',
        key: 'status',
      },
    },
  };


  const actionConfig = [
    {
      name: 'View',
      color: 'green',
      action: (id) => {
        let index = expenseClaims.data.findIndex((item) => item.id === id);
        setButtonText('Update')
        setTitle('Update Reimbursement')
        setReimbData(expenseClaims.data[index])
        setShowAddReImbursement(true)
      },
    },
  ];

  return (
    <DashboardLayout title='My Reimbursements'>
      <div className='flex flex-col bg-white shadow-md rounded-md'>
        {showAddReImbursement?
        <AddReImbursement
          isOpen={showAddReImbursement}
          closeModal={() => {
            setShowAddReImbursement(false)
          }}
          reimbData={reimbData}
          title={title}
          buttonText={buttonText}
        />
        :
        null
        }
        <div className='flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3'>
          <div className='p3-bold header-4'>
            My Expense Claims
          </div>
          <div className='flex flex-col md:flex-row items-start gap-[10px] md:items-center'>
            <div className='md:w-fit w-[100%]'>
              <Button
              text='Apply for Expenses Claim'
              type='submit'
              theme='primary'
              className='flex gap-2 h-[35px] w-fit p-2 '
              textClass={'!text-[13px]'}
              leftIcon={'add'}
              onClick={() => {
                setButtonText('Apply')
                setTitle('Apply For Expenses Claim')
                setReimbData('')
                setShowAddReImbursement(true)
              }}
              />
            </div>
          </div>
        </div>
        <hr className='divider mt-2 mb-2' />
        <DataTable
          data={expenseClaims}
          tableConfig={tableConfig}
          actionConfig={actionConfig}
          updatePagination={(data) => setPagination(data)}
          searchData={true}
          statusConfig={expenseClaimApplicationStatusConfig}
          // filterByStatus={true}
          computedActions={true}
          noCheck={true}
        />
      </div>
    </DashboardLayout>
  );
};

export default ReimbursementPage;
