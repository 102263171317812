import React from "react";

const Loader = ({ color }) => (
  <div className="loader_container">
    {color === "blue" ? (
      <img
        src="https://res.cloudinary.com/emekamykael45/image/upload/v1620338359/InventSoft/loader_blue.svg"
        className="loader_img"
        alt="Loader"
      />
    ) : (
      <img
        src="https://res.cloudinary.com/emekamykael45/image/upload/v1620338376/InventSoft/loader_white.svg"
        className="loader_img"
        alt="Loader"
      />
    )}
  </div>
);

export default Loader;
