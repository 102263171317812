import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../components/button";
import AuthLayout from "../../components/layouts/auth";
import TickSVG from '../../assets/svg/tick.svg';
import { useSelector } from "react-redux";

const CheckEmailPassword = () => {
  const history = useHistory();

  const { section } = useSelector((state) => state.sectionSlice);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <AuthLayout>
      <React.Fragment>
        <form className="form">
          <div className='flex flex-col w-full text-center justify-center pb-[25px]'>
            
            <div className='w-[100%] flex justify-center pb-[15px]'>
              <div
              className={`flex items-center justify-center w-[110px] h-[110px] rounded-[130px] bg-[#44AF69]`}
              >
                <img
                  src={TickSVG}
                  alt='tick'
                  style={{ width: '60px', height: '60px' }}
                />
              </div>
            </div>
            <span className={'header-4-regular md:header-2 text-secondary-2 pb-1'}>
              Email Sent
            </span>
            <span className='p4 md:header-4-regular text-color-gray'>
              An email has been sent to you, please use the link in your email to reset your password.
            </span>
          </div>
          <Button
            text="Login"
            type="submit"
            onClick={() => history.push(`/`)}
          />
        </form>
      </React.Fragment>
    </AuthLayout>
  );
};

export default CheckEmailPassword;
