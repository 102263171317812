import React from 'react';
import { Initials } from '../../utils/functions';
import { useSelector } from 'react-redux';

const ChatProfileView = () => {

    const { user } = useSelector((state) => state.userSlice);

    return (
        <div className='md:w-[20%] w-full p-2 flex flex-col gap-3 items-center mt-2'>
            <div className='w-full h-[42px] bg-highlight rounded-xl'>
            </div>
            <div className='flex flex-col items-center w-full h-[206px] bg-highlight rounded-2xl p-3 gap-2'>
                <div className='flex font-bold justify-center items-center h-[100px] w-[100px] rounded-[100px] header-2 bg-green-200'>
                    {Initials(user?.first_name, user?.last_name)}
                </div>
                <div className='flex flex-col text-[13px] items-center'>
                    <div className='font-extrabold text-[14px]'>
                        Festus Aderibigbe
                    </div>
                    <div className='font-normal'>
                        Digital Marketer
                    </div>
                </div>
                
            </div>
            <div className='flex flex-col items-center w-full h-[206px] bg-highlight rounded-2xl p-3 gap-2'>
                <div className='font-normal text-[11px]'>
                    All Media
                </div>
                <div className='grid grid-cols-3 text-[13px] items-center'>
                    
                </div>
            </div>
        </div>
    );
};

export default ChatProfileView;