import { Axios } from '../axios';

const addLeave = async (payload) => {
  const { data } = await Axios.post('/mobile/leaves', payload);
  return data;
};

const getLeaves = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  let source = payload.source ? '&source=' + payload.source : "&source=''";
  let source_id = payload.source_id ? '&source_id=' + payload.source_id : null;
  const { data } = await Axios.get(
    `/mobile/leaves?limit=${payload.limit}&page=${payload.page}${filter}${search}${source_id}${source}`,
  );
  return data?.data;
};

const addLeaveType = async (payload) => {
  const { data } = await Axios.post('/mobile/leave-types', payload);
  return data;
};

const getLeaveTypes = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  const { data } = await Axios.get(
    `/mobile/leave/types?limit=${payload.limit}&page=${payload.page}${search}`,
  );
  return data?.data;
};

const approveLeave = async (payload) => {
  const { data } = await Axios.post(`/mobile/leave/approve`, payload);
  return data;
};
const declineLeave = async (payload) => {
  const { data } = await Axios.post(`/mobile/leave/decline`, payload);
  return data;
};

export const leaves = {
  addLeave,
  getLeaves,
  approveLeave,
  declineLeave,
  addLeaveType,
  getLeaveTypes,
};
