import React, { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  employeeStatusConfig,
  PAGINATION_DEFAULT,
} from '../../../utils/constants';
import DataTable from '../../datatable';
import { useGetDepartmentEmployees } from '../../../redux/human-resources/hook/departments';

const DepartmentOverviewTab = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: '',
  });
  const { id } = useParams();

  const { data: department_employees } = useGetDepartmentEmployees(
    id,
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search,
  );

  const tableConfig = {
    headers: ['Date Added', 'Employee Name', 'Email', 'Mobile'],
    keys: ['created', 'employeeName', 'employee_email', 'employee_mobile'],
    mobileHeader: {
      left: {
        title: 'Employee Name',
        key: 'employeeName',
      },
      right: {
        title: 'Mobile',
        key: 'employee_mobile',
      },
    },
  };

  return (
    <div className='flex flex-col bg-white'>
      <div className='flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3'>
        <div className='p3-bold header-4'>
          Employee List
        </div>
      </div>
      <hr className='divider mt-2' />
      <DataTable
        data={department_employees}
        tableConfig={tableConfig}
        statusConfig={employeeStatusConfig}
        updatePagination={(data) => setPagination(data)}
        searchData={true}
        noCheck={true}
      />
    </div>
  );
};

export default DepartmentOverviewTab;
