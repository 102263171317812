import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { store } from '../..';
import { departments } from '../../../api/hr/department-management';
import { setIsLoading } from '../../components/components-slice';

export function useGetDepartments() {
    return useQuery(
      ['departments', {}],
      () => {
        store.dispatch(setIsLoading(true));
        return departments.getDepartments();
      },
      {
        select: (data) => {
          data.forEach((department) => {
            department.created = moment(department.created).format('MMMM D, YYYY');
            department.computedActions = [0,1,2]
            department.department_manager = department.manager_setting.manager
            if (department && department.description && department.description.length >= 40) {
              department.description = department.description.substring(0, 40) + '....';
            }
          });
  
          return data;
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
      },
    );
}

export function useGetOnboardingDepartments() {
  return useQuery(
    ['onboarding-departments'],
    () => {
      store.dispatch(setIsLoading(true));
      return departments.getDepartments();
    },
    {
      select: (data) => {
        let newData = [];
        newData.push({ value: '', label: 'No Department Selected' });
        data.forEach((item) => {
          newData.push({ value: item.id, label: item.name });
        });
        return newData;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
    
  );
}

export function useGetDepartment(id) {
    return useQuery(
        ['department', {id}],
        () => {
            return departments.getDepartment({id});
        },
        {
            onSettled: (data, error, variables, context) => {
                store.dispatch(setIsLoading(false));
            },
        },
    );
}

export function useGetDepartmentEmployees(id, limit, page, statusFilter, search) {
    return useQuery(
      ['department-employees', { id, limit, page, statusFilter, search }],
      () => {
        store.dispatch(setIsLoading(true));
        return departments.getDepartmentEmployees({
          id,
          limit,
          page,
          statusFilter,
          search,
        });
      },
      {
        
        enabled: id !== null,
        select: (data) => {
          data.employees.name = data.name;
          data.employees.id = data.id;
          if (data.employees.data) {
            data.employees.data.forEach((employee) => {
              employee.created = moment(employee.created).format('MMMM D, YYYY')
              employee.computedActions = [0];
              employee.employeeName = `${employee.employee_first_name} ${employee.employee_last_name}`;
            });
          }
          return data?.employees;
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        
      },
    );
}

export function useGetDepartmentTeams(id, limit, page, search) {
    return useQuery(
      ['department-teams', { id, limit, page, search }],
      () => {
        store.dispatch(setIsLoading(true));
        return departments.getDepartmentTeams({
          id,
          limit,
          page,
          search,
        });
      },
      {
        
        enabled: id !== null,
        select: (data) => {
          data.data.forEach((department_teams) => {
            department_teams.created = moment(department_teams.created).format('MMMM D, YYYY')
            department_teams.computedActions = [0];
          });
          return data;
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        
      },
    );
}