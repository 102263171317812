import { useMutation, useQuery } from "@tanstack/react-query";
import { store } from "../..";
import { queryClient } from "../../..";
import { attendances } from "../../../api/hr/attendance-management";
import { setAlert, setIsLoading } from "../../components/components-slice";
import moment from "moment";
import { updateUser } from "../../user/hook";

export function useGetAttendanceSummary() {
  return useQuery(["attendance-summary"], () => {
    return attendances.getAttendanceSummary();
  });
}

export function useGetAttendances(
  limit,
  page,
  statusFilter,
  search,
  start_date,
  end_date
) {
  return useQuery(
    [
      "attendances",
      { limit, page, statusFilter, search, start_date, end_date },
    ],
    () => {
      store.dispatch(setIsLoading(true));
      return attendances.getAttendances({
        limit,
        page,
        statusFilter,
        search,
        start_date,
        end_date,
      });
    },
    {
      select: (data) => {
        data?.data.forEach((attendance) => {
          attendance.employeeName = `${attendance.first_name} ${attendance.last_name}`;
          attendance.attendance_date = moment(
            attendance.attendance_date
          ).format("MMMM D, YYYY");
          attendance.computedActions = [0, 1, 2];
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    }
  );
}

export function useAddAttendance() {
  return useMutation(
    (payload) => {
      return attendances.addAttendance(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("attendances");
        queryClient.invalidateQueries("attendance-summary");
        store.dispatch(setAlert(true, "success", "Clockin successful"));
        updateUser({ reload: true });
      },
    }
  );
}

export function useClockOut() {
  return useMutation(
    (payload) => {
      return attendances.clockOut(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        updateUser({ reload: false });
        queryClient.invalidateQueries("attendances");
        queryClient.invalidateQueries("attendance-summary");
        store.dispatch(setAlert(true, "success", "Clockout successful"));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useGetAttendance(id) {
  return useQuery(
    ["attendance", { id }],
    () => {
      store.dispatch(setIsLoading(true));
      return attendances.getAttendance(id);
    },
    {
      enabled: id !== null,
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, "success", response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useGetAttendanceByEmployee(
  employee_id,
  limit,
  page,
  statusFilter,
  search,
  start_date,
  end_date
) {
  return useQuery(
    [
      "attendance-by-employee",
      { employee_id, limit, page, statusFilter, search, start_date, end_date },
    ],
    () => {
      store.dispatch(setIsLoading(true));
      return attendances.getAttendanceByEmployee({
        employee_id,
        limit,
        page,
        statusFilter,
        search,
        start_date,
        end_date,
      });
    },
    {
      enabled: employee_id !== null ? true : false,
      select(data) {
        data.data.forEach((attendances) => {
          attendances.employeeName =
            attendances.first_name + " " + attendances.last_name;
          attendances.computedActions = [0, 1];
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    }
  );
}

export function useGetAttendanceSchedules(start_date, end_date) {
  return useQuery(
    ["attendance-schedules", { start_date, end_date }],
    () => {
      store.dispatch(setIsLoading(true));
      return attendances.getAttendanceSchedules({
        start_date,
        end_date,
      });
    },

    {
      select(data) {
        data.forEach((schedule) => {
          schedule.start = moment(schedule.start).toDate();
          schedule.end = moment(schedule.end).toDate();
          if (schedule.title == "Present") {
            schedule.color = "#1bb21b";
          } else if (schedule.title == "Off Day") {
            schedule.color = "#00d2ff";
          } else if (schedule.title == "Work day") {
            schedule.color = "#d2f8d2";
          } else {
            schedule.color = "#FF0000";
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    }
  );
}

export function useGetAttendanceSchedule(id) {
  return useQuery(
    ["attendance-schedule", { id }],
    () => {
      store.dispatch(setIsLoading(true));
      return attendances.getAttendanceSchedule(id);
    },
    {
      enabled: id !== null,
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, "success", response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}
