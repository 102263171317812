import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { formValidation } from '../../../utils/functions';
import Button from '../../../components/button';
import FormInput from '../../../components/form-input';
import FormSelect from '../../../components/form-select';
import FormTextArea from '../../../components/form-textarea';
import FormSelectEmployee from '../../../components/employee/form-select-employee';
import {
  useAddPerformanceEvaluation,
  useGetPerformanceOnboardingWorkflows,
} from '../../../redux/human-resources/hook/performance';
import AddPerformanceWorkflow from '../../../components/modal/hr-section/performance/add-workflow';
import LoadingBar from '../../../components/loader-bar';
import PerformanceEvaluationLayout from '../../../components/layouts/performance-evaluation';
import { useHistory } from 'react-router-dom';

function BasicEvaluation() {
    const {
        register,
        handleSubmit,
        formState: { isValid },
        errors,
    } = useForm({
        mode: 'onChange',
    });

    const [workflowId, setWorkflowId] = useState();

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [reviewerComment, setReviewerComment] = useState();
    const history = useHistory()
    const [trainingRequirement, setTrainingRequirement] = useState('')

    // const [workflow, setWorkflow] = useState();
    const [showAddWorkflow, setShowAddWorkflow] = useState();

    const [showConfirmation, setShowConfirmation] = useState(false);

    const { data: workflowOptions, isLoading: performanceIsLoading } =
    useGetPerformanceOnboardingWorkflows(1000, 1, '');

    useEffect(() => {
        if (!workflowOptions && !performanceIsLoading) {
            setShowAddWorkflow(true);
        }
    }, [workflowOptions, performanceIsLoading]);

    const {mutateAsync: addPerformanceEvaluation, isLoading: addPerformanceEvaluationLoading} = useAddPerformanceEvaluation();

    const [subWorkflow, setSubWorkflow] = useState([]);

    const [employee, setEmployee] = useState();
    const [employeeName, setEmployeeName] = useState();

    const changeCriteria = (value) => {
        let index = workflowOptions.findIndex((item) => item.value == value);
        if (index !== -1) {
          setWorkflowId(workflowOptions[index].id);
          let subData = [];
          if (
            workflowOptions[index].criteria &&
            workflowOptions[index].criteria.length > 0
          ) {
            workflowOptions[index].criteria.forEach((item) => {
              let newCriteria = {
                name: item.name,
                max_grade: item.max_grade,
                grade: '',
              };
              subData.push(newCriteria);
            });
            setSubWorkflow(subData);
          }
        }
    };
    
    const submitForm = async () => {
        let payload = {
            employee_id: employee,
            workflow_id: workflowId,
            evaluation_start: startDate,
            evaluation_end: endDate,
            reviewer_comment: reviewerComment,
            training_requirement: trainingRequirement,
            result: subWorkflow,
        };
        addPerformanceEvaluation(payload).then(() => {
            setShowConfirmation(true);
        });
    };

    const confirmationNext = () => {
        history.push('/performance');
    };

  return (
    <PerformanceEvaluationLayout step={2} title={'Add Evaluation Information'}>
      <div className='flex flex-col justify-center h-full min-h-[480px]'>
        <>
        {showConfirmation?
        <ConfirmationModal
            isOpen={showConfirmation}
            closeModal={() => setShowConfirmation(false)}
            btnFunction={confirmationNext}
            title={'You did it, ' + user?.first_name}
            subtitle={'Performance Evalaution successfully added'}
        />
        :
        null}
        {showAddWorkflow ? (
            <AddPerformanceWorkflow
            isOpen={showAddWorkflow}
            closeModal={() => setShowAddWorkflow(false)}
            groupData={null}
            title='Add Performance Workflow'
            buttonText='SAVE'
            />
        ) : null}
        <div className='flex flex-col overflow-auto'>
        <form className='form'>
            <div className='flex flex-row justify-between'>
                <span className='header-3'>Evaluate Performance (Basic)</span>
            </div>

            <LoadingBar loading={performanceIsLoading} />

            <hr className='divider' />

            {!showAddWorkflow && !performanceIsLoading ? (
            <div className='flex flex-col -mt-2'>
                <div>
                <FormSelectEmployee
                    label={'Employee'}
                    name='employee'
                    placeholder='Select Employee'
                    value={employee}
                    empName={employeeName}
                    setEmployee={setEmployee}
                />
                <span className='text-color-gray'>Evaluation Period</span>
                <div className='flex flex-row md:flex-col w-full justify-between'>
                    <div className='w-full md:w-1/2'>
                    <FormInput
                        name='start_date'
                        type='date'
                        value={startDate}
                        onInput={(e) => {
                        setStartDate(e.target.value);
                        }}
                        inputRef={register(formValidation('date', true))}
                        error={errors.start_date}
                        errorMessage={
                        errors.start_date && errors.start_date.message
                        }
                    />
                    </div>
                    <div className='w-full md:w-1/2 pl-2'>
                    <FormInput
                        name='end_date'
                        type='date'
                        value={endDate}
                        onInput={(e) => {
                        setEndDate(e.target.value);
                        }}
                        inputRef={register(formValidation('date', true))}
                        error={errors.end_date}
                        errorMessage={
                        errors.end_date && errors.end_date.message
                        }
                    />
                    </div>
                </div>
                <hr className='divider mb-2 mt-2' />
                {workflowOptions ? (
                    <div className='w-full '>
                    <FormSelect
                        value={workflowId}
                        options={workflowOptions}
                        onChange={(selected) => {
                        changeCriteria(selected);
                        setWorkflowId(selected);
                        }}
                        label='Select Performance Workflow'
                    />
                    </div>
                ) : 
                <div
                className='flex w-full text-hr-secondary-1 italic cursor-pointer font-bold text-[14px] hover:underline'
                onClick={() => {
                    setShowAddWorkflow(true)
                }}>
                    Add Performance Workflow
                </div>
                }

                <hr className='divider mb-2 mt-2' />
                <div className='w-full flex flex-row justify-center text-[#44AF69] mb-3'>
                    Evaluation Criteria
                </div>
                {subWorkflow && subWorkflow.length > 0 ? (
                    <div className='flex flex-col w-full text-[13px] '>
                    {subWorkflow.map((item, index) => (
                        <div
                        className='flex flex-row w-full items-center gap-3 justify-between -mt-2'
                        key={index}
                        >
                        <div className='flex flex-col w-[60%]'>
                            <span className='text-[12px] text-gray-400'>
                            Criteria
                            </span>
                            <FormInput
                            placeholder='Review Name'
                            name={index + '_title'}
                            type='text'
                            value={item.name}
                            readOnly
                            className={'text-[13px] max-h-[30px]'}
                            inputRef={register(formValidation('date', true))}
                            error={errors.end_date}
                            errorMessage={
                                errors.end_date && errors.end_date.message
                            }
                            />
                        </div>
                        <div className='flex flex-col w-[20%]'>
                            <span className='text-[14px] text-gray-400'>
                            Max. Grade
                            </span>
                            <FormInput
                            placeholder='Max. Grade'
                            name={index + '_max_grade'}
                            type='number'
                            readOnly
                            value={item.max_grade}
                            inputRef={register(
                                formValidation('number', true),
                            )}
                            error={errors.max_grade}
                            className={'text-[13px] max-h-[30px]'}
                            errorMessage={
                                errors.max_grade && errors.max_grade.message
                            }
                            />
                        </div>
                        <div className='w-[20%]'>
                            <span className='text-[14px] text-gray-400'>
                            Grade
                            </span>
                            <FormInput
                            placeholder='Grade'
                            name={index + '_grade'}
                            type='number'
                            className={'text-[13px] max-h-[30px]'}
                            value={item.grade}
                            onInput={(e) => {
                                if (e.target.value > item.max_grade) {
                                let newArray = JSON.parse(
                                    JSON.stringify(subWorkflow),
                                );

                                newArray[index].grade = '';
                                setSubWorkflow(newArray);
                                } else {
                                let newArray = JSON.parse(
                                    JSON.stringify(subWorkflow),
                                );

                                newArray[index].grade = e.target.value;
                                setSubWorkflow(newArray);
                                }
                            }}
                            inputRef={register(
                                formValidation('number', true, 0),
                            )}
                            />
                        </div>
                        </div>
                    ))}
                    </div>
                ) : (
                    <span className='w-full italic'>No workflow selected</span>
                )}
                <hr className='divider mt-2' />
                <div className='text-[#44AF69] '>Reviewer Comment</div>
                <FormTextArea
                    name='review_comment'
                    placeholder={'Enter Evaluation Comment'}
                    type='text'
                    value={reviewerComment}
                    onInput={(e) => {
                    setReviewerComment(e.target.value);
                    }}
                    error={errors.review_comment}
                    errorMessage={
                    errors.review_comment && errors.review_comment.message
                    }
                />
                <FormTextArea
                    label={'Training Requirement'}
                    type='text'
                    name='training_requirement'
                    placeholder={'Enter Training requirement'}
                    value={trainingRequirement}
                    onInput={(e) =>
                        setTrainingRequirement(e.target.value)
                    }
                />

                {/* {evaluationType !== 'add' ? (
                    <>
                    <div className='text-[#44AF69] '>Employee Comment</div>
                    <pre className='border-1 p-3 rounded !max-h-[400px] overflow-auto'>
                        {employeeComment
                        ? employeeComment
                        : 'No comment posted yet'}
                    </pre>
                    </>
                ) : null} */}
                </div>
                <div className='w-full mt-[20px] pb-4 '>
                    <Button
                        loading={addPerformanceEvaluationLoading}
                        onClick={() => submitForm()}
                        text={'SUBMIT'}
                        type='button'
                    />
                    <div
                        className='flex justify-center w-full cursor-pointer'
                        onClick={() => history.goBack()}
                        >
                        <span className='p2-medium text-color-black mt-3'>Back</span>
                    </div>
                </div>
            </div>
            ) : null}
        </form>
        </div>
        </>
      </div>
    </PerformanceEvaluationLayout>
  );
}

export default BasicEvaluation;
