import React from 'react';
import CloseSVG from '../../../assets/svg/close.svg';
import FormInput from '../../form-input';
import Modal from '../modal';
import { leaveStatusConfig } from '../../../utils/constants';
import { StatusText } from '../../fragments/status-text';

function ViewLeave({ isOpen, closeModal, leaveData }) {
    return (
        <Modal
            scrollable={true}
            className='!max-w-[600px] !rounded-none'
            isOpen={isOpen}
            centered={true}
        >
            <div className='flex flex-col overflow-auto'>
                <div className='pl-4 pr-4 pt-4'>
                    <div className='flex flex-row justify-between'>
                    <span className='header-3'>View Leave</span>
                    <div className='cursor-pointer' onClick={() => closeModal()}>
                        <img src={CloseSVG} alt='' />
                    </div>
                    </div>
                </div>
                <hr className='divider' />
                <div className='flex flex-col mx-8 mb-4'>
                    <div className='flex flex-row md:flex-col w-full justify-between'>
                    <div className='w-full md:pr-2 md:w-1/2'>
                        <FormInput
                        label='Leave Type'
                        name='start_date'
                        type='text'
                        value={leaveData.leave_type}
                        readOnly={true}
                        />
                    </div>
                    <div className='w-full md:pl-2 md:w-1/2'>
                        <FormInput
                            label='Number Of Days'
                            name='start_date'
                            type='text'
                            value={leaveData.number_of_days}
                            readOnly={true}
                        />
                    </div>
                    </div>
                    <div className='flex flex-row md:flex-col w-full justify-between'>
                    <div className='w-full md:pr-2 md:w-1/2'>
                        <FormInput
                        label='Start Date'
                        type='date'
                        value={leaveData.start_date}
                        readOnly={true}
                        />
                    </div>
                    <div className='w-full md:pl-2 md:w-1/2'>
                        <FormInput
                        label='End Date'
                        type='date'
                        value={leaveData.end_date}
                        readOnly={true}
                        />
                    </div>
                    </div>
                    <FormInput
                    label='Purpose'
                    type='text'
                    value={leaveData.purpose}
                    readOnly={true}
                    />
                    <div className='flex flex-row justify-between gap-2 items-center p-2 border rounded-md bg-secondary-2-extralight mt-2'>
                        <div className='flex flex-col header-4 text-left'>
                        <span className='text-[11px] '>Final Status</span>
                        <StatusText
                            code={leaveData?.final_status}
                            statusConfig={leaveStatusConfig}
                        />
                        </div>
                        <div className='flex flex-col header-4 text-left'>
                        <span className='text-[11px] '>Team Status</span>
                        <StatusText
                            code={leaveData?.team_status}
                            statusConfig={leaveStatusConfig}
                        />
                        </div>
                        <div className='flex flex-col header-4 text-left'>
                        <span className='text-[11px] '>Department Status</span>
                        <StatusText
                            code={leaveData?.department_status}
                            statusConfig={leaveStatusConfig}
                        />
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
export default ViewLeave;
