import React, { useEffect } from 'react';
import { useState } from 'react';
import Button from '../button';
import { useForm } from 'react-hook-form';
import { useClockOut } from '../../redux/human-resources/hook/attendance';
import AddAttendance from '../modal/hr-section/attendance/add-attendance';
import { StatusText } from '../fragments/status-text';
import { attendancePunctualityStatusConfig, attendanceStatusConfig } from '../../utils/constants';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { store } from '../../redux';
import { setUser } from '../../redux/user/user-slice';

const AttendanceSummary = () => {
  const {
    register,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const [ attendanceSummary, setAttendanceSummary ] = useState(null);
  const { user } = useSelector((state) => state.userSlice);

  const [showAddAttendance, setshowAddAttendance] = useState(false);

  const [clockedIn, setClockedIn] = useState(true)

  useEffect(() => {
    if (user) {
      if (user.company_id && user.company) {
        console.log(user.company)
        let company = user.company
        setAttendanceSummary(company.attendance)
        if (company?.attendance?.clockout_date || !company?.attendance) {
          
          setClockedIn(false)
        }
      }
    }
  },[user])
  const { mutateAsync: clockOut, isLoading: clockOutloading } = useClockOut();

  const [long, setLong] = useState();
  const [lat, setLat] = useState();

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function(position) {
      setLat(position.coords.latitude)
      setLong(position.coords.longitude)
    });
  }, [])

  const clockInOut = async () => {
    if (clockedIn) {
      let payload = {
        id : attendanceSummary.id,
        data: {
          long: long ? long.toString() : 'Not Set',
          lat: lat ? lat.toString() : 'Not Set',
        }
      }
      await clockOut(payload).then((result) => {
        let newArray = JSON.parse(JSON.stringify(user))
        newArray.company.attendance = result
        store.dispatch(setUser(newArray))
      })
    } else {
      setshowAddAttendance(true)
    }
  }

  return (
    <React.Fragment>
      <div className='flex flex-col bg-white w-full shadow-md rounded-md h-full min-h-fit'>
        {showAddAttendance ?
          <AddAttendance
            isOpen={showAddAttendance}
            closeModal={() => setshowAddAttendance(false)}
            title='Employee Clock In'
            buttonText='CLOCK IN'
          />
        : null}
        <div className='flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] mt-3'>
          <div className='header-4'>
            Current Attendance Information
          </div>
          <div className='flex flex-col md:flex-row items-start gap-[10px] md:items-center'>
            <div className='md:w-fit w-[100%]'>
              <Button
                text={!clockedIn?'Clock In':'Clock Out'}
                type='submit'
                theme='primary'
                loading={clockOutloading}
                className='flex gap-2 h-[35px] w-full md:w-[100px] p-2 '
                textClass={'!text-[13px]'}
                onClick={() => {
                  clockInOut()
                }}
              />
            </div>
          </div>
        </div>
        <hr className='divider' />
        <div className='flex flex-col mx-8 mb-4'>
        {attendanceSummary ?
          <div className='flex flex-col w-full h-fit'>
            <div className='flex md:flex-row flex-col justify-between md:items-center items-start gap-4'>
              <div className='flex flex-col md:w-1/2 w-full justify-start'>
                <div className='flex flex-col h4-regular text-color-gray gap-2 items-start justify-center'>
                  Employee Name
                  <span className='flex text-[13px] text-color-black-2 font-bold items-center -mt-2'>
                    {attendanceSummary?.first_name} {attendanceSummary?.last_name} <span className='italic text-[13px] text-hr-primary-1'>
                    &nbsp;({attendanceSummary?.mobile})
                    </span>
                  </span>
                </div>
              </div>
              <div className='flex flex-row gap-3 md:w-1/2 w-full justify-end'>
                <div className='flex flex-col gap-2'>
                  <div className='flex flex-row items-center'>
                    <StatusText
                      statusConfig={attendanceStatusConfig}
                      code={attendanceSummary?.status}
                      className={
                        'py-2 rounded px-3 p2-bold text-[13px] font-semibold'
                      }
                    />
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div className='flex flex-row items-center'>
                    <StatusText
                      statusConfig={attendancePunctualityStatusConfig}
                      code={attendanceSummary?.punctuality_status}
                      className={
                        'py-2 rounded p1-bold px-3 p2-bold text-[13px] font-semibold'
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='flex flex-col mt-1'>
              <hr className='divider' />
              <div className='grid md:grid-cols-2  grid-cols-1 gap-4 w-full'>
                <div className='flex flex-col'>
                  <span className='text-[13px] text-color-gray'>Attendance Date</span>
                  <span className='mt-1 text-color-black font-bold'>
                    {moment(attendanceSummary?.attendance_date).format("MMMM DD, YYYY")}
                  </span>
                </div>
                <div className='flex flex-col'>
                  <span className='text-[13px] text-color-gray'>
                    ClockIn Location
                  </span>
                  <span className='mt-1 text-color-black font-bold'>
                    {attendanceSummary?.clockin_location}
                  </span>
                </div>
                <div className='flex flex-col'>
                  <span className='text-[13px] text-color-gray'>
                    Clockout Location
                  </span>
                  <span className='mt-1 text-color-black font-bold'>
                    {attendanceSummary?.clockout_location
                      ? attendanceSummary?.clockout_location
                      : 'You have not clocked out yet'}
                  </span>
                </div>
              </div>
              <hr className='divider' />
              <div className='grid md:grid-cols-2  grid-cols-1 gap-4 w-full'>
                <div className='flex flex-col'>
                  <span className='text-[13px] text-color-gray'>
                    Expected Clockin
                  </span>
                  <span className='mt-1 text-color-black font-bold'>
                    {attendanceSummary?.expected_clockin}
                  </span>
                </div>
                <div className='flex flex-col'>
                  <span className='text-[13px] text-color-gray'>
                    Expected Clockout
                  </span>
                  <span className='mt-1 text-color-black font-bold'>
                    {attendanceSummary?.expected_clockout}
                  </span>
                </div>
                <div className='flex flex-col'>
                  <span className='text-[13px] text-color-gray'>
                    Clockin Time
                  </span>
                  <span className='mt-1 text-hr-primary-1 font-bold'>
                    {attendanceSummary?.clockin_time}
                  </span>
                </div>
                <div className='flex flex-col'>
                  <span className='text-[13px] text-color-gray'>
                    Clockout Time
                  </span>
                  <span className='mt-1 text-red-400 font-bold'>
                    {attendanceSummary?.clockout_time
                      ? attendanceSummary?.clockout_time
                      : 'Not clocked Out'}
                  </span>
                </div>
              </div>
            </div>
          </div>
        :
        <div className='flex h-[100px] w-full border rounded-md justify-center items-center text-primary-2 font-bold mt-2'>
          No attendance data fetched
        </div>
        }
        </div>
      </div>
    </React.Fragment>
  );
};

export default AttendanceSummary;
