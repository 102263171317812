import { Axios } from '../axios';

const addAttendance = async (payload) => {
    const { data } = await Axios.post('/mobile/attendance/clock-in', payload);
    return data?.data;
};

const getAttendances = async (payload) => {
    let start_date = payload.start_date != -1 ? '&start_date=' + payload.start_date : '';
    let end_date = payload.end_date != -1 ? '&end_date=' + payload.end_date : '';
    let search = payload.search ? '&search=' + payload.search : '';
    let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
    const { data } = await Axios.get( `/mobile/attendances?limit=${payload.limit}&page=${payload.page}${filter}${search}${start_date}${end_date}`,);
    return data?.data;
};

const getAttendanceSummary = async () => {
    const { data } = await Axios.get('/mobile/attendance/summary');
    return data?.data;
};

const clockOut = async (payload) => {
    const { data } = await Axios.post('/mobile/attendance/clock-out/' + payload.id, payload.data);
    return data?.data;
};

const getAttendanceByEmployee = async (payload) => {
    let start_date = payload.start_date != -1 ? '&start_date=' + payload.start_date : '';
    let end_date = payload.end_date != -1 ? '&end_date=' + payload.end_date : '';
    let search = payload.search ? '&search=' + payload.search : '';
    const { data } = await Axios.get(`/mobile/attendances/by-employee/${payload.employee_id}?limit=${payload.limit}&page=${payload.page}${search}${start_date}${end_date}`,);
    return data?.data;
};
  
const getAttendance = async (payload) => {
    const { data } = await Axios.get('/mobile/attendance/' + payload);
    return data.data;
};

const getAttendanceSchedules = async (payload) => {
    let start_date = payload.start_date != -1 ? 'start_date=' + payload.start_date : '';
    let end_date = payload.end_date != -1 ? '&end_date=' + payload.end_date : '';
    const { data } = await Axios.get( `/mobile/attendance/work-schedule?${start_date}${end_date}`, 
    {
      timeout: 0
    });
    return data?.data;
};

const getAttendanceScheduleBySource = async (payload) => {
    let start_date = payload.start_date != -1 ? '&start_date=' + payload.start_date : '';
    let end_date = payload.end_date != -1 ? '&end_date=' + payload.end_date : '';
    let search = payload.search ? '&search=' + payload.search : '';
    const { data } = await Axios.post(`/mobile/attendance-schedules/by-source?limit=${payload.limit}&page=${payload.page}${search}&source_id=${payload.source_id}&source=${payload.source}${start_date}${end_date}`,);
    return data?.data;
};

const getAttendanceSchedule = async (payload) => {
    const { data } = await Axios.get('/mobile/attendance/work-schedule' + payload);
    return data.data;
};

export const attendances = {
    addAttendance,
    getAttendances,
    getAttendanceByEmployee,
    getAttendanceSchedules,
    getAttendanceScheduleBySource,
    getAttendanceSummary,
    clockOut,
    getAttendance,
    getAttendanceSchedule,
};
