import { Axios } from './axios';

const getUserCompanies = async () => {
  const { data } = await Axios.get('/mobile/companies');
  return data?.data;
};

const switchCompany = async (payload) => {
  const { data } = await Axios.get(
    `/mobile/companies/switch-company/${payload}`,
  );
  return data;
};

const generateCompanyOtp = async (payload) => {
  const { data } = await Axios.post('/mobile/companies/generate-otp', payload);
  return data;
};

const confirmCompanyOtp = async (payload) => {
  const { data } = await Axios.post('/mobile/companies/confirm-otp', payload);
  return data;
};

const addCompany = async (payload) => {
  const { data } = await Axios.post('/mobile/companies', payload);
  return data;
};

export const companies = {
  getUserCompanies,
  switchCompany,
  generateCompanyOtp,
  confirmCompanyOtp,
  addCompany,
};
