import DOMPurify from 'dompurify';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../components/button';
import FormTextArea from '../../components/form-textarea';
import { setAlert } from '../../redux/components/components-slice';
import { useSaveAnswer } from '../../redux/employee/hook/onboarding';
import { store } from '../../redux';
import { setSection } from '../../redux/section/reducer';

function OnboardingQuestionAnswers({questions, answers, onboarding, token, subId, setOnboardingData, currentIndex}) {
  
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const { mutateAsync: saveAnswer, isLoading: saveAnswerloading } = useSaveAnswer();

  const [result, setResult] = useState([]);

  const dispatch = useDispatch();

  const [employeeId, setEmployeeId] = useState();
  const [onboardingId, setOnboardingId] = useState();

  useEffect(() => {
    if (onboarding) {
        setEmployeeId(onboarding.employee_id)
        setOnboardingId(onboarding.id)
    }
  }, [onboarding, subId])

  useEffect(() => {
    if (questions && questions.length>0) {
      let newResults = []
      questions.forEach((item) => {
        let newResult = {
          question_id: item.id,
          question: item.question,
          answer: '',
          question_type: item.question_type,
          options: item.options

        }
        newResults.push(newResult);
      })
      setResult(newResults)
    }

  }, [questions])

  const submitForm = async () => {
    if (result && result.length > 0) {
        let missedQuestions = []
        result.forEach((item, index) => {
            if (item.answer == '') {
                missedQuestions.push(index+1)
            }
        })
        if (missedQuestions.length < 1) {
            let payload = {
                employee_id: employeeId,
                token: token,
                onboarding_id: onboardingId,
                subworkflow_id: subId,
                answers: JSON.stringify(result)
            }
            await saveAnswer(payload).then((response) => {
                let newArray = JSON.parse(JSON.stringify(onboarding));

                newArray.workflow.sub_workflows[currentIndex-1].answers = response.data.answers
                setOnboardingData(newArray)
            });
        } else {
            dispatch(setAlert(true, 'info', 'You have to answer all questions. No answers found for question (' + missedQuestions.join(", ") + ')'));
        }
        
    }
  };

  return (
    <div className='flex flex-col justify-center w-full md:pt-2 !min-h-fit overflow-auto'>
        {answers.length > 0 && questions.length > 0
        ?
        <div className='flex flex-col items-center justify-center w-[100%] !min-h-[200px]'>
            <span className='header-4 mt-3'>You have already submitted your answers</span>
        </div>
        :
        <div>
            {questions && questions.length>0?
            <div className='flex flex-col items-center justify-center !min-h-[200px] !min-w-full p-4 pt-1 !max-h-screen'>
                <div className='flex flex-col min-h-fit overflow-auto mb-[10px] min-w-full gap-3'>
                <div>
                    {result?.map((item, index) => (
                        <div className='flex flex-col border-r-4 border-gray-300 border-r-primary-3 rounded-[8px] pt-2 pr-4 pl-4 border-[1px] gap-2' key={index}>
                            <div className='flex flex-row items-center gap-2'>
                                <span className='header-4 text-color-black'>Question {index+1}: </span>
                                <span className='p4-medium'>{item.question}</span>
                            </div>
                            {item.question_type === 'multiple_choice' ?
                            <div className='flex flex-col !min-w-[100%]'>
                            <span className='header-5 mb-2 underline '>Options:</span>
                            {item.options.map((optionItem, optionIndex) => (
                                <div 
                                className={optionItem.selected?'flex flex-row items-center mb-2 p-2 bg-hr-primary-1  cursor-pointer duration-500 border-1 rounded':'flex flex-row items-center cursor-pointer duration-500 border-1 rounded mb-2 p-2 hover:bg-gray-200 '}
                                onClick={() => {
                                    let newArray = 
                                        JSON.parse(JSON.stringify(result));
                                    
                                    newArray[index].options.forEach((item) => {
                                        item.selected = false;
                                    })
                                    newArray[index].options[optionIndex].selected = true;
                                    newArray[index].answer = newArray[index].options[optionIndex].index
                                    setResult(newArray);
                                }}>
                                <div className={optionItem.selected?'header-5 pr-2 !min-w-[20%] !text-white duration-500':'header-5 pr-2 !min-w-[20%] duration-500'}>Option {optionIndex+1}:</div>
                                <div className={optionItem.selected?'header-5 pr-2 !min-w-[20%] !text-white duration-500':'header-5 pr-2 !min-w-[20%] duration-500'}>{optionItem.option}</div>
                                </div>
                            ))}
                            </div>
                            :
                            <div>
                            <FormTextArea
                                label={`Answer`}
                                placeholder={'Enter your answer here'}
                                defaultValue={item.answer}
                                onInput={(e) => {
                                let newArray = 
                                    JSON.parse(JSON.stringify(result));
                                    
                                newArray[index].answer = e.target.value
                                setResult(newArray);
                                }}
                                name={`answer_${index+1}`}
                                type='text'
                            />
                            </div>
                            } 
                        </div>
                    ))}
                </div>
                <Button
                    text='Submit Answers'
                    type='button'
                    onClick={() => {
                        submitForm()
                    }}
                    loading={saveAnswerloading}
                    className=' flex !h-[35px] px-3 w-fit !bg-hr-primary-1'
                    textClass='!text-[12px]'
                />
                </div>
            </div>
            :
            <div className='flex flex-col items-center justify-center w-[100%] -mt-4 !max-h-[200px] !min-h-[200px]'>
                <span className='header-4 mt-3'>No Action Required</span>
            </div>
            }
        </div>
        }
        
    </div>
  );
}

export default OnboardingQuestionAnswers;
