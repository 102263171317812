import React, { useState } from 'react';
import CloseSVG from '../../../assets/svg/close.svg';
import Button from '../../button';
import { useEnable2Fa } from '../../../redux/user/hook';
import Modal from '../modal';
import FormSelect from '../../form-select';
import Activate2Fa from './activate-2fa';

function EnableTwoFactorAuthentication({ isOpen, closeModal }) {

  const { mutateAsync: enableTwoFa, isLoading: enableTwoFaLoading } =
    useEnable2Fa();

  const [authenticatorType, setAuthenticatorType] = useState('email');

  const [showActivate2Fa, setShowActivate2Fa] = useState(null);
  const [activationData, setActivationData] = useState();

  const authenticatorTypeOptions = [
    { label: 'Email', value: 'email' },
    { label: 'Authenticator App', value: 'authenticator' },
  ];

  const submitForm = async () => {

    let payload = {
      two_fa_type: authenticatorType,
    };
    await enableTwoFa(payload).then((response) => {
      if (response.status == 'success') {
        setActivationData(response)
        setShowActivate2Fa(true)
      }
    })
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[500px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
        {showActivate2Fa ?
          <Activate2Fa
            isOpen={showActivate2Fa}
            type={authenticatorType}
            oldModalClose={closeModal}
            closeModal={() => setShowActivate2Fa(false)}
            activationData={activationData}
          />
        : null}
        <div className='flex flex-col overflow-auto'>
            <form className='form pb-3' >
            <div className='pl-4 pr-4 pt-4'>
                <div className='flex flex-row justify-between'>
                <span className='header-3'>Enable 2FA</span>
                <div className='cursor-pointer' onClick={() => closeModal()}>
                    <img src={CloseSVG} alt='' />
                </div>
                </div>
            </div>
            <hr className='divider' />
            <div className='flex flex-col mx-8'>
                <div className='flex flex-col md:flex-col w-full justify-between'>
                    <FormSelect
                    label={'Authentication Type'}
                    value={authenticatorType}
                    options={authenticatorTypeOptions}
                    onChange={(selected) => {
                        setAuthenticatorType(selected);
                    }}
                    />
                    <div className='w-full mt-[20px]'>
                        <Button
                        text='CONTINUE'
                        type='button'
                        onClick={() => submitForm()}
                        loading={enableTwoFaLoading}
                        />
                    </div>
                </div>
            </div>
            </form>
        </div>
    </Modal>
  );
}

export default EnableTwoFactorAuthentication;
