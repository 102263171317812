import { Axios } from '../axios';

//Employee Action
const fetchOnboarding = async () => {
  const { data } = await Axios.get('/mobile/employee-onboarding');
  return data?.data;
};

const employeeMarkStageComplete = async (payload) => {
  const { data } = await Axios.post(
    '/mobile/employee-onboarding/subworkflow/status',payload
  );
  return data?.data;
};

const employeeMarkOnboardingComplete = async (payload) => {
  const { data } = await Axios.post(
    '/mobile/employee-onboarding/status', payload,
  );
  return data?.data;
};

const uploadRequestedFile = async (payload) => {
  const { data } = await Axios.post('/mobile/employee-onboarding/upload-requested-file', payload,
  {
    headers: { 'Content-Type': 'multipart/form-data' },
    timeout: 0
  });
  return data;
};

const employeeSaveAnswer = async (payload) => {
  const { data } = await Axios.post('/mobile/employee-onboarding/answer', payload);
  return data;
};

export const employeeOnboardings = {
  fetchOnboarding,
  employeeMarkStageComplete,
  employeeMarkOnboardingComplete,
  uploadRequestedFile,
  employeeSaveAnswer,
};