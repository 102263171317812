import DOMPurify from 'dompurify';
import React, { useState } from 'react';
import { useEffect } from 'react';
import Button from '../../components/button';
import { useFetchHMODependants, useGetBeneficiary, useStoreDependant} from '../../redux/hmo/hook';
import LoadingBar from '../../components/loader-bar';
import FormSelect from '../../components/form-select';
import EmployeeHmoLayout from '../../components/layouts/employee-hmo';
import EmptyTableSVG from '../../assets/svg/empty-table.svg';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import FormInput from '../../components/form-input';

function AddDependants() {
  
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const history = useHistory()

  const { data: hmo_beneficiary, isLoading: beneficiaryLoading } = useGetBeneficiary();
  const { data: hmo_dependants, isLoading: dependantsLoading } = useFetchHMODependants();

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [mobile, setMobile] = useState();
  const [address, setAddress] = useState();
  const [type, setType] = useState('Spouse');

  const typeOptions = [
    {
      label: 'Spouse',
      value: 'Spouse'
    },
    {
      label: 'Child',
      value: 'Child'
    }
  ]

  const [showAddDependant, setShowAddDependant] = useState(false)

  const skip = async () => {
    history.push('/hmo/select-hmo');
  }

  const { mutateAsync: storeDependant, isLoading: storeDependantLoading } = useStoreDependant();

  const submitForm = async () => {
    let payload = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      mobile: mobile,
      address: address,
      type: type
    }
    await storeDependant(payload).then(() => {
      history.push('/hmo/select-hmo')
    })
  }

  return (
    <EmployeeHmoLayout step='1'>
        <div className='flex flex-col justify-center w-full md:pt-5 min-h-fit overflow-y-auto gap-4 items-center'>
            <div className='flex md:min-h-fit flex-col w-full md:px-0'>
                <form className='form min-h-fit'>
                    <div className='w-full text-center flex flex-col pb-[25px] pt-2'>
                    <span className='text-[25px] font-bold text-secondary-2'>
                      Add Dependants
                    </span>
                    <span className='header-5-regular text-secondary-2'>
                      Add dependants to your HMO plan
                    </span>
                    </div>
                    <hr className='divider -mt-2' />
                    <div className='flex flex-col px-4'>
                      <div
                        className='flex justify-end w-full  mb-2'
                        onClick={() => skip()}
                      >
                        <span className='text-[15px] font-bold text-secondary-2 cursor-pointer hover:underline mb-2'>
                          Skip this step
                        </span>
                      </div>
                      <div className='flex flex-col w-full p-3 border rounded-md shadow-md'>
                        {dependantsLoading || beneficiaryLoading?
                        <div className='flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]'>
                          <LoadingBar loading={dependantsLoading || beneficiaryLoading} />
                        </div>
                        :
                        <>
                        {showAddDependant?
                        <>
                          <div className='text-center font-semibold'>Add Dependant</div>
                          <form className='form min-h-fit border p-3 rounded-md shadow-sm' >
                            <div className='flex flex-col md:flex-row w-full justify-between'>
                              <div className='w-full md:w-1/2 md:pr-2'>
                                <FormInput
                                  label='First Name'
                                  name='first_name'
                                  type='text'
                                  value={firstName}
                                  onInput={(e) => {
                                    setFirstName(e.target.value);
                                  }}
                                  error={errors.first_name}
                                  errorMessage={errors.first_name && errors.first_name.message}
                                />
                              </div>
                              <div className='w-full md:w-1/2 md:pl-2'>
                                <FormInput
                                  label='Last Name'
                                  name='last_name'
                                  type='text'
                                  value={lastName}
                                  onInput={(e) => {
                                    setLastName(e.target.value);
                                  }}
                                  error={errors.last_name}
                                  errorMessage={errors.last_name && errors.last_name.message}
                                />
                              </div>
                            </div>
                            <div className='flex flex-col md:flex-row w-full justify-between'>
                              <div className='w-full md:w-2/3 md:pr-2'>
                                <FormInput
                                  label='Email'
                                  name='email'
                                  type='email'
                                  value={email}
                                  onInput={(e) => {
                                    setEmail(e.target.value);
                                  }}
                                  error={errors.email}
                                  errorMessage={errors.email && errors.email.message}
                                />
                              </div>
                              <div className='w-full md:w-1/3 md:pl-2'>
                                <FormInput
                                  label='Mobile'
                                  name='mobile'
                                  type='text'
                                  value={mobile}
                                  onInput={(e) => {
                                    setMobile(e.target.value);
                                  }}
                                  error={errors.mobile}
                                  errorMessage={errors.mobile && errors.mobile.message}
                                />
                              </div>
                            </div>
                            <div className='flex flex-col md:flex-row w-full justify-between'>
                              <div className='w-full md:w-2/3 md:pr-2'>
                                <FormInput
                                  label='Address'
                                  name='address'
                                  type='text'
                                  value={address}
                                  onInput={(e) => {
                                    setAddress(e.target.value);
                                  }}
                                  error={errors.address}
                                  errorMessage={errors.address && errors.address.message}
                                />
                              </div>
                              <div className='w-full md:w-1/3 md:pl-2'>
                                <FormSelect
                                  value={type}
                                  options={typeOptions}
                                  onChange={(selected) => {
                                    setType(selected);
                                  }}
                                  label='Dependant Type'
                                />
                              </div>
                            </div>
                            <div className='flex gap-3 md:w-full justify-end mt-2'>
                              <Button
                                text='Cancel'
                                type='button'
                                theme='secondary'
                                className='flex gap-2 h-[32px] w-fit p-2 '
                                textClass={'!text-[13px]'}
                                onClick={() => {
                                  setShowAddDependant(false)
                                }}
                              />
                              <Button
                                text='Save Dependant'
                                type='button'
                                theme='primary'
                                className='flex gap-2 h-[32px] w-fit p-2 '
                                textClass={'!text-[13px]'}
                                loading={storeDependantLoading}
                                onClick={() => {
                                  submitForm()
                                }}
                              />
                            </div>
                          </form>
                        </>
                        :
                        <>
                          {hmo_dependants && hmo_beneficiary?
                          <>
                            <span className='header-4 p-2 text-white rounded-[8px] bg-secondary-2 max-w-fit'>Dependants Summary</span>
                            <div className='flex flex-col gap-3 mt-3'>
                              
                              <>
                              <div className='text-center font-semibold'>{hmo_dependants?.length}/{hmo_beneficiary?.max_dependants} Dependants Added</div>
                              <div className='flex flex-col gap-3'>
                                {hmo_dependants?.map((item, index) => (
                                <div className='flex flex-col p-3 border shadow-md rounded-md gap-1' key={index}>
                                  <div className='text-[15px] font-extrabold'>{item.first_name} {item.last_name} <span className='text-[13px] text-color-gray'>({item.type})</span></div>
                                  <div className='flex justify-between text-color-gray'>
                                    <span className='text-color-gray text-[14px]'>{item.mobile}</span>
                                    <span className='text-color-gray text-[14px]'>{item.email}</span>
                                  </div>
                                </div>
                                ))}
                              </div>
                              </>

                              {hmo_beneficiary?.max_dependants > 0 && hmo_dependants?.length <= hmo_beneficiary?.max_dependants?
                              <div className='flex gap-2 md:w-full justify-end'>
                                <Button
                                  text='Add dependant'
                                  type='button'
                                  theme='primary'
                                  className='flex gap-2 h-[32px] w-fit p-2 '
                                  textClass={'!text-[13px]'}
                                  leftIcon={'add'}
                                  onClick={() => {
                                    setShowAddDependant(true)
                                  }}
                                />
                              </div>
                              :
                              null
                              }
                            </div>
                          </>
                          :
                          <div className='flex flex-col items-center justify-center w-[100%] gap-2'>
                            {!hmo_dependants && hmo_beneficiary?.max_dependants > 0?
                            <div className='flex md:w-full justify-end'>
                              <Button
                                text='Add dependant'
                                type='button'
                                theme='primary'
                                className='flex gap-2 h-[32px] w-fit p-2'
                                textClass={'!text-[13px]'}
                                leftIcon={'add'}
                                onClick={() => {
                                  setShowAddDependant(true)
                                }}
                              />
                            </div>
                            :hmo_beneficiary?.max_dependants > 0 && hmo_dependants?.length <= hmo_beneficiary?.max_dependants?
                            <div className='flex md:w-full justify-end'>
                              <Button
                                text='Add dependant'
                                type='button'
                                theme='primary'
                                className='flex gap-2 h-[32px] w-fit p-2 '
                                textClass={'!text-[13px]'}
                                leftIcon={'add'}
                                onClick={() => {
                                  setShowAddDependant(true)
                                }}
                              />
                            </div>
                            :
                            null
                            }
                            <img src={EmptyTableSVG} alt='No Data Fetched' width={'70%'} height={'100px'}/>
                            <span className='header-4 p-2 text-white rounded-[8px] bg-secondary-2 max-w-fit'>No HMO dependants fetched</span>
                          </div>
                          } 
                        </>
                        }
                        </>
                        }
                      </div>
                      <div
                        className='flex justify-end w-full  mb-2 mt-3'
                        onClick={() => history.push('/dashboard')}
                      >
                        <span className='text-[15px] font-bold text-secondary-2 cursor-pointer hover:underline mb-2'>
                          Back to Dashboard
                        </span>
                      </div>
                    </div>
                </form>
            </div>
        </div>
    </EmployeeHmoLayout>
  );
}

export default AddDependants;