import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../components/layouts/dashboard';
import { useParams } from 'react-router-dom';
import TabView from '../../components/tabview';
import { useGetBranch } from '../../redux/human-resources/hook/branches';
import { Initials } from '../../utils/functions';
import BranchOverviewTab from '../../components/branch/view/branch-profile';
import BranchTeamTab from '../../components/branch/view/branch-teams';
import BranchDepartmentTab from '../../components/branch/view/branch-departments';
import { branchPayrollTab } from '../../components/branch/view/branch-payroll';
import BranchTransactionsTab from '../../components/branch/view/branch-transaction-history';
import { useDispatch, useSelector } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';
import CopySVG from '../../assets/svg/copy.svg';
import { setAlert } from '../../redux/components/components-slice';

const ViewBranchPage = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const {user} = useSelector((state) => state.userSlice);
  
  const [activeTab, setActiveTab] = useState(1);
  const { id } = useParams();
  const { data: branch } = useGetBranch(id);

  const dispatch = useDispatch()

  useEffect(() => {
    if (branch) {
      let manager_setting = branch.manager_setting
      let data = [
        {
          title: 'Employees',
          Content: BranchOverviewTab,
        },
      ]
      if (user && user.employee_id === manager_setting.manager_id) {
        let newData = [
          {
            title: 'Teams',
            Content: BranchTeamTab,
          },
          {
            title: 'Departments',
            Content: BranchDepartmentTab,
          },
        ]
        newData.forEach(item => {
          data.push(item)
        });
        if (manager_setting.can_read_payroll === 1) {
          data.push(
            {
              title: 'Payroll Summary',
              Content: branchPayrollTab,
            },
          )
        }
      }
      if (manager_setting.can_read_transaction_history === 1) {
        data.push(
          {
            title: 'Transaction History',
            Content: BranchTransactionsTab,
          },
        )
      }
      setBranchTabs(data)
    }
  }, [branch])
  
  const [BranchTabs, setBranchTabs] = useState([
    {
      title: 'Employees',
      Content: BranchOverviewTab,
    },
    
  ]);

  return (
    <DashboardLayout title='View Branch'>
      <div className='flex flex-col'>
        <div className='flex flex-row items-center box-shadow h-[104px]'>
          <div className='flex flex-row px-[25px] justify-between w-full'>
            <div className='flex flex-row items-center'>
              <div className='flex font-bold justify-center items-center h-[58px] w-[58px] rounded-[58px] border-[3px] header-3 border-secondary-2 bg-[#FFC0CB]'>
                {branch?.name ? Initials(branch?.name): null}
              </div>
              <div className='flex flex-col pl-4 items-start justify-center'>
                <span className='header-4'>{branch?.name}</span>
                <span className='p4'>{branch?.description}</span>
              </div>
            </div>
            {branch?.manager_setting?.manager_id === user?.employee_id?
            <div className='flex flex-col items-end justify-center md:w-1/2 w-full'>
              <div  className='p-2 px-4 border bg-gray-100 rounded-md' >
                <span className='text-[14px] mb-1 text-secondary-2'>
                  Wallet Balance - {branch?.balance?`₦${branch.balance.toLocaleString()}`: 0.00}
                </span>
                
                <CopyToClipboard
                  text={branch?.account_number}
                  onCopy={() => {
                    dispatch(setAlert(true, 'success', 'Copied successfully'));
                  }}
                >
                  <button className='text-[13px] flex flex-row items-center justify-end w-full duration-200 hover:scale-110 hover:font-bold hover:pr-3 mt-2'>
                    <div className='md:w-[100%] w-full flex flex-row gap-1 text-secondary-2 justify-end'>
                      <span className='header-4 text-secondary-2'>VFD -&nbsp;</span>
                      <span className='header-4 text-secondary-2'>{branch?.account_number}</span>
                    </div>
                    <img src={CopySVG} width={15} className='ml-2 hover:scale-125 duration-300' alt='copy' />
                  </button>
                </CopyToClipboard>
              </div>
            </div>
            :
            null
            }
            
          </div>
        </div>

        <div className='mt-8'>
          <TabView
            Tabs={BranchTabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ViewBranchPage;
