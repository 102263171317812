import DOMPurify from 'dompurify';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../assets/svg/close.svg';
import { monthOptions, yearOptions } from '../../../utils/constants/date';
import { formValidation } from '../../../utils/functions';
import Button from '../../button';
import FormInput from '../../form-input';
import FormSelect from '../../form-select';
import FormTextArea from '../../form-textarea';
import AdvancedPayrollBreakdown from './advanced-payroll-breakdown';
import Modal from '../modal';

function RunAdvancedPayroll({
  isOpen,
  closeModal,
  source = 'default',
  sourceData,
}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const [month, setMonth] = useState('06');
  const [year, setYear] = useState('2023-');
  // const [setting, setSetting] = useState('group');
  const [showAdvancedPayroll, setShowAdvancedPayroll] = useState(false);
  const [payloadData, setPayloadData] = useState(null);

  const submitForm = async (data) => {
    const desc = DOMPurify.sanitize(data?.desc);
    const payment_date = DOMPurify.sanitize(data?.payment_date);

    let payload = {
      desc,
      month: year + month,
      setting: 'group',
      payment_date,
    };
    payload.branch_id = parseInt(sourceData);
    onAdvancedPayrollSuccess(payload);
  };

  function onAdvancedPayrollSuccess(payload) {
    setPayloadData(payload);
    setShowAdvancedPayroll(true);
  }

  return (
    <>
      {showAdvancedPayroll ? (
        <AdvancedPayrollBreakdown
          isOpen={showAdvancedPayroll}
          source={source}
          sourceData={sourceData}
          payloadData={payloadData}
          closeModal={() => setShowAdvancedPayroll(false)}
        />
      ) : null}
      <Modal
        scrollable={true}
        className='!max-w-[700px] !rounded-none'
        isOpen={isOpen}
        centered={true}
      >
        <div className='flex flex-col overflow-auto'>
          <form className='form pb-4' onSubmit={handleSubmit(submitForm)}>
            <div className='pl-8 pr-8 pt-8'>
              <div className='flex flex-row justify-between'>
                <span className='header-3'>Run Advanced Payroll</span>
                <div className='cursor-pointer' onClick={() => closeModal()}>
                  <img src={CloseSVG} alt='' />
                </div>
              </div>
            </div>
            <hr className='divider' />
            <div className='flex flex-col mx-14'>
              <div>
                <div className='flex flex-col md:flex-row w-full justify-between'>
                  <div className='w-full md:w-1/2 md:pr-2'>
                    <FormSelect
                      defaultValue={month}
                      options={monthOptions}
                      onChange={(selected) => {
                        setMonth(selected);
                      }}
                      label='Payroll Month'
                    />
                  </div>

                  <div className='w-full md:w-1/2 md:pl-2'>
                    <FormSelect
                      defaultValue={year}
                      options={yearOptions}
                      onChange={(selected) => {
                        setYear(selected);
                      }}
                      label='Year'
                    />
                  </div>
                </div>
                <div className='flex flex-col md:flex-row w-full justify-between'>
                  <div className='w-full '>
                    <FormInput
                      label='Select Payment Date'
                      name='payment_date'
                      type='date'
                      inputRef={register(formValidation('text', true))}
                      error={errors.payment_date}
                      errorMessage={
                        errors.payment_date && errors.payment_date.message
                      }
                    />
                  </div>
                </div>
                <FormTextArea
                  label='Descripton'
                  placeholder={'Enter payroll description'}
                  name='desc'
                  type='text'
                  inputRef={register(formValidation('text', true))}
                  error={errors.desc}
                  errorMessage={errors.desc && errors.desc.message}
                />
              </div>

              <div className='w-full mt-[20px]'>
                <Button text='CONTINUE' type='submit' disabled={!isValid} />
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default RunAdvancedPayroll;
