import { useSelector } from 'react-redux';
import { AttendanceRoutes} from './attendance';
import { DashboardRoutes } from './dashboard';
import { HMORoutes } from './hmo';
import { DepartmentsRoutes } from './departments';
import { BranchesRoutes } from './branches';
import { LeaveRoutes } from './leaves';
import { PayslipRoutes } from './payslip';
import { PerformanceRoutes } from './performance';
import { TeamRoutes } from './teams';
import { SalaryAdvanceRoutes } from './salary-advance';
import { CalendarRoutes } from './calendar';
import { ReimbursementRoutes } from './reimbursement';
import { EmployeeRegistrationRoutes } from './employee-registration';
import { EmployeeOnboardingRoutes } from './employee-onboarding';
import { EmployeeExitRoutes } from './employee-exit';
import { ProfileRoutes } from './profile';
import { TrainingRoutes } from './training';
import { RochatRoutes } from './ro-chat';
import { OvertimeRoutes } from './overtime';

export const AdminRoutes = () => {
  const { user } = useSelector((state) => state.userSlice);

  if (user) {
    return [
      ...CalendarRoutes,
      ...DashboardRoutes,
      ...AttendanceRoutes,
      ...BranchesRoutes,
      ...HMORoutes,
      ...DepartmentsRoutes,
      ...BranchesRoutes,
      ...LeaveRoutes,
      ...PayslipRoutes,
      ...PerformanceRoutes,
      ...TeamRoutes,
      ...SalaryAdvanceRoutes,
      ...OvertimeRoutes,
      ...ReimbursementRoutes,
      ...EmployeeRegistrationRoutes,
      ...EmployeeOnboardingRoutes,
      ...EmployeeExitRoutes,
      ...ProfileRoutes,
      ...TrainingRoutes,
      ...RochatRoutes
    ];
  }
};
