import React, { useEffect } from 'react';
import DataTable from '../datatable';
import { useState } from 'react';
import { PAGINATION_DEFAULT } from '../../utils/constants';
import AddPerformanceOKR from '../modal/hr-section/performance/add-okr';
import { useGetPerformanceOKRS } from '../../redux/human-resources/hook/performance';
import FormSelect from '../form-select';
import { useSelector } from 'react-redux';

const PerformanceOKRTab = () => {

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const { user } = useSelector((state) => state.userSlice);

  const [source, setSource] = useState('employee');
  const [sourceId, setSourceId] = useState(user?.employee_id);
  const [sourceName, setSourceName] = useState(null);

  const [teams, setTeams] = useState([{ label: 'Select Team', value: null }]);
  const [departments, setDepartments] = useState([{ label: 'Select Department', value: null }]);
  const [branches, setBranches] = useState([{ label: 'Select Branch', value: null }]);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: '',
  });

  const { data: okrs } = useGetPerformanceOKRS(
    source,
    sourceId,
    pagination?.limit,
    pagination?.page,
    pagination?.search,
  );

  const [sourceOptions, setSourceOptions] = useState([
    { label: 'My OKRs', value: 'employee' },
  ]);

  useEffect(() => {
    if (source === 'employee' && user) {
      setSourceId(user.employee_id)
      let company = user.company
      let data = [{ label: 'My OKRs', value: 'employee' },]
      if (company.is_team_lead) {
        data.push({ label: 'My Team OKRs', value: 'team' })
      }
      if (company.is_department_lead) {
        data.push({ label: 'My Department OKRs', value: 'department' })
      }
      if ((company.is_branch_lead)) {
        data.push({ label: 'My Branch OKRs', value: 'branch' })
      }
      setSourceOptions(data)
    }
  }, [source, user])

  useEffect(() => {

    if (user) {
      let company = user.company
      if (company.teams && company.teams.length > 0) {
        let teamData = [{ label: 'Select Team', value: null }]
        company.teams.forEach(item => {
          teamData.push({ label: item.name, value: item.id })
        });
        setTeams(teamData)
      }
      if (company.departments && company.departments.length > 0) {
        let departmentData = [{ label: 'Select Department', value: null }]
        company.departments.forEach(item => {
          departmentData.push({ label: item.name, value: item.id })
        });
        setDepartments(departmentData)
      }
      if (company.branches && company.branches.length > 0) {
        let branchData = [{ label: 'Select Branch', value: null }]
        company.branches.forEach(item => {
          branchData.push({ label: item.name, value: item.id })
        });
        setBranches(branchData)
      }
    }
  }, [user])

  const [okrData, setOkrData] = useState(null);
  const [title, setTitle] = useState(null);
  const [buttonText, setButtonText] = useState(null);
  const [showAddOkr, setShowAddOkr] = useState(null);

  const tableConfig = {
    headers: [
        'Title',
        'Source',
        'Source Name',
        'Period Covered',
    ],
    keys: [
        'okr_title',
        'source',
        //source_id
        'source_name',
        'period',
    ],
    mobileHeader: {
      left: {
          title: 'Title',
          key: 'okr_title',
      },
      right: {
          title: 'Period Covered',
          key: 'period',
      },
        
    },
  };

  const actionConfig = [
    {
      name: 'View',
      color: 'green',
      action: (id) => {
        let index = okrs.data.findIndex((item) => item.id === id);
        setTitle('Update Objectives and Key results')
        setButtonText('Update')
        setOkrData(okrs.data[index])
        setShowAddOkr(true)
      },
    },
  ];

  return (
    <div className='flex flex-col gap-4 -mt-6'>
        {showAddOkr ? (
          <AddPerformanceOKR
            isOpen={showAddOkr}
            closeModal={() => setShowAddOkr(false)}
            okrData={okrData}
            title={title}
            buttonText={buttonText}
          />
        ) : null}
        <div className='flex flex-col'>
          <div className=' bg-white border border-gray-300 text-[12px] p-3 rounded text-gray-600 mt-2'>
            <span className='text-[13px] font-semibold'>Objectives and Key Results (OKR)</span> is a collaborative goal-setting methodology used by teams and individuals to set challenging, 
            ambitious goals with measurable results. OKRs are how you track progress, create alignment, and encourage engagement around measurable goals.
          </div>
        </div>
        <div className='flex flex-col bg-white rounded-md shadow-sm'>
            <div className='flex flex-col md:flex-row justify-between items-center px-4 gap-[10px]'>
              <div className='p3-bold header-4 md:w-1/2'>
                My Objectives and Key Results (OKRs)
              </div>
              <div className='flex flex-row md:flex-col w-full justify-end gap-4 md:w-1/2'>
                <div className='w-full md:w-1/2 pr-2'>
                  <FormSelect
                    value={source}
                    options={sourceOptions}
                    onChange={(selected) => {
                      setSource(selected);
                      if (selected === 'employee') {
                        setSourceId(user?.employee_id)
                      } else {
                        setSourceId(null);
                      }
                    }}
                  />
                </div>
                {source === 'employee' ? (
                  null
                ) : source === 'team' && teams && teams.length > 1 ? (
                  <div className='w-full md:w-1/2 pl-2'>
                    <FormSelect
                      value={sourceId}
                      options={teams}
                      onChange={(selected) => {
                        if (selected === 'Select Team') {
                          setSourceId(null);
                        } else {
                          setSourceId(selected);
                        }
                      }}
                    />
                  </div>
                ) : source === 'department' && departments && departments.length > 1 ? (
                  <div className='w-full md:w-1/2 pl-2'>
                    <FormSelect
                      value={sourceId}
                      options={departments}
                      onChange={(selected) => {
                        if (selected === 'Select Department') {
                          setSourceId(null);
                        } else {
                          setSourceId(selected);
                        }
                      }}
                    />
                  </div>
                ) :  source === 'branch' && branches && branches.length > 1 ? 
                (
                  <div className='w-full md:w-1/2 pl-2'>
                    <FormSelect
                      value={sourceId}
                      options={branches}
                      onChange={(selected) => {
                        if (selected === 'Select Branch') {
                          setSourceId(null);
                        } else {
                          setSourceId(selected);
                        }
                      }}
                    />
                  </div>
                )
                :
                null}
              </div>
            </div>
            <hr className='divider -mt-1 mb-2' />
            <DataTable
                data={okrs}
                tableConfig={tableConfig}
                updatePagination={(data) => setPagination(data)}
                actionConfig={actionConfig}
                computedActions={true}
                noCheck={true}
            />
        </div>
    </div>
    
  );
};

export default PerformanceOKRTab;
