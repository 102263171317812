import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import TickSVG from '../assets/svg/tick.svg';

function ButtonSelect({ isActive, title, subtitle, className, ...rest }) {
  return (
    <button
      className={classNames(
        'flex flex-col h-[120px] justify-center px-4 rounded-md shadow-md duration-300 bg-secondary-2',
        className,
      )}
      {...rest}
    >
      <div className='flex flex-row w-full'>
        <div className='flex flex-col text-start w-5/6'>
          <span
            className={classNames('p4-bold', {
              'text-color-white': isActive,
            })}
          >
            {title}
          </span>
          <span
            className={classNames('p4-regular', {
              'text-color-white': isActive,
            })}
          >
            {subtitle}
          </span>
        </div>
        <div
          className={classNames(
            'w-1/6 flex flex-row justify-end items-center md:mr-3',
            {
              hidden: !isActive,
            },
          )}
        >
          <img src={TickSVG} alt='' width={'30px'} />
        </div>
      </div>
    </button>
  );
}

export default ButtonSelect;
