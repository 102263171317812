import { createSlice } from '@reduxjs/toolkit';

// Slice
const slice = createSlice({
  name: 'payroll',
  initialState: {
    advanced: null,
  },
  reducers: {
    setAdvancedPayroll: (state, { payload }) => {
      state.advanced = payload;
    },
  },
});

// Actions
export const { setAdvancedPayroll } = slice.actions;

export default slice.reducer;
