const { Axios } = require('./axios');

const getBanks = async (payload) => {
  const { data } = await Axios.get('/banks');
  return data;
};

const resolveBankAccount = async (payload) => {
  const { data } = await Axios.post('/resolve-account', payload,
  {
    timeout: 0
  });
  return data;
};

const getPFAs = async () => {
  const { data } = await Axios.get('/pfas');
  return data.data;
};

export const bank = {
  getBanks,
  resolveBankAccount,
  getPFAs
};
