import { useMutation, useQuery } from '@tanstack/react-query';
import { store } from '../..';
import { queryClient } from '../../..';
import { setAlert, setIsLoading } from '../../components/components-slice';
import moment from 'moment';
import { employeeTrainings } from '../../../api/employees/training';

export function useGetTrainingSummary() {
    return useQuery(
      ['training-summary'],
      () => {
        return employeeTrainings.getTrainingSummary();
      }
    );
}
export function useGetemployeeTrainings(limit, page, statusFilter, search) {
    return useQuery(
      ['employee-trainings', { limit, page, statusFilter, search }],
      () => {
        store.dispatch(setIsLoading(true));
        return employeeTrainings.getEmployeeTrainings({
          limit,
          page,
          statusFilter, 
          search,
        });
      },
      {
        select(data) {
          data.data.forEach((training) => {
              
            training.created = moment(training.created).format('MMMM D, YYYY');
            training.period = `${moment(training.training_start).format('MMMM D, YYYY')} to ${moment(training.training_end).format('MMMM D, YYYY')}`;
            training.computedActions = [0, 1, 2, 3];
          });
  
          return data;
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
      },
    );
}

export function useUploadTrainingCertificate() {
    return useMutation(
        (payload) => {
          store.dispatch(setIsLoading(true));
          return employeeTrainings.uploadTrainingCertificate(payload);
        },
        {
          onSuccess: (response, variables, context) => {
            queryClient.invalidateQueries('employee-trainings');
            store.dispatch(setAlert(true, 'success', 'Training certificate uploaded successfully'));
          },
          onSettled: (data, error, variables, context) => {
            store.dispatch(setIsLoading(false));
          },
        },
    );
}

export function useCommentOnTraining() {
  return useMutation(
      (payload) => {
        store.dispatch(setIsLoading(true));
        return employeeTrainings.commentOnTraining(payload);
      },
      {
        onSuccess: (response, variables, context) => {
          queryClient.invalidateQueries('employee-trainings');
          store.dispatch(setAlert(true, 'success', 'Training comment added successfully'));
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
      },
  );
}
