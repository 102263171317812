import React from "react";

export const Close = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Iconly/Broken/Close Square">
      <g id="Close Square">
        <path
          id="Close Square_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.2442 11.578C21.6612 11.578 22.0002 11.239 22.0002 10.822V8.253C22.0002 4.392 19.5902 2 15.7532 2H8.25318C4.39218 2 2.00018 4.392 2.00018 8.256V15.756C2.00018 19.608 4.39218 22 8.25318 22H15.7562C19.6082 22 22.0002 19.608 21.9972 15.753C21.9972 15.331 21.6542 14.988 21.2322 14.988C20.8092 14.988 20.4672 15.331 20.4672 15.753C20.4672 18.787 18.7962 20.467 15.7532 20.467H8.25318C5.21018 20.467 3.53018 18.787 3.53018 15.753V8.253C3.53018 5.21 5.21018 3.53 8.25618 3.53H15.7562C18.8002 3.53 20.4702 5.2 20.4702 8.253V10.795V10.797C20.4712 11.219 20.8132 11.56 21.2352 11.56V11.578H21.2442ZM9.81498 13.1454L9.13098 13.8294C8.82598 14.1194 8.80898 14.6004 9.09298 14.9114L9.11498 14.9274C9.40698 15.2194 9.87698 15.2274 10.178 14.9454L10.859 14.2644C11.168 13.9764 11.185 13.4924 10.897 13.1824C10.608 12.8744 10.124 12.8564 9.81498 13.1454ZM15.1428 14.8033C14.8488 15.0963 14.3738 15.1023 14.0728 14.8153L14.0348 14.7783L9.25478 9.9993C8.96278 9.6893 8.95678 9.2073 9.24178 8.8913C9.53378 8.5923 10.0118 8.5863 10.3108 8.8783C10.3138 8.8803 10.3158 8.8823 10.3188 8.88529L10.3188 8.8853L12.1838 10.7513L13.8808 9.0533C14.1858 8.7623 14.6668 8.7653 14.9688 9.0603C15.0228 9.1143 15.0688 9.1753 15.1038 9.2433C15.2768 9.5443 15.2268 9.9223 14.9818 10.1683L13.2918 11.8573L15.1298 13.6963C15.4398 13.9893 15.4518 14.4783 15.1578 14.7873C15.1555 14.7907 15.1526 14.7935 15.1496 14.7963C15.1473 14.7985 15.1449 14.8007 15.1428 14.8033Z"
          fill="#130F26"
        />
      </g>
    </g>
  </svg>
);
