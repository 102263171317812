import DOMPurify from 'dompurify';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import CloseSVG from '../../../../assets/svg/close.svg';
import {
  attendancePunctualityStatusConfig,
  attendanceStatusConfig,
} from '../../../../utils/constants';
import Button from '../../../button';
import { StatusText } from '../../../fragments/status-text';
import Modal from '../../modal';

function ViewAttendance({
  isOpen,
  closeModal,
  refresh,
  attendance,
  // title,
  // buttonText,
}) {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const history = useHistory();

  return (
    <Modal
      scrollable={true}
      className='!min-w-[70%] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col pb-4 overflow-auto'>
        <div className='pl-8 pr-8 pt-8'>
          <div className='flex flex-row justify-between'>
            <span className='header-3'>Employee Attendance Information</span>
            <div className='cursor-pointer' onClick={() => closeModal()}>
              <img src={CloseSVG} alt='' />
            </div>
          </div>
        </div>
        <hr className='divider' />
        <div className='flex flex-col mx-14'>
          <div className='flex flex-col w-full h-fit'>
            <div className='flex md:flex-row flex-col justify-between md:items-center items-start gap-4'>
              <div className='flex flex-col'>
                <span className='h4-regular text-color-gray'>
                  Employee Name:
                </span>
                <span className='h4-regular text-color-black-2 mt-1'>
                  {attendance?.first_name} {attendance?.last_name}
                </span>
                <span className='italic text-[13px] text-hr-primary-1'>
                  {attendance?.mobile}
                </span>
              </div>
              <div className='flex flex-row gap-3'>
                <div className='flex flex-col gap-2'>
                  <div className='flex flex-row items-center'>
                    <StatusText
                      statusConfig={attendanceStatusConfig}
                      code={attendance?.status}
                      className={
                        'py-2 rounded p1-bold px-3 p2-bold text-[15px]'
                      }
                    />
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div className='flex flex-row items-center'>
                    <StatusText
                      statusConfig={attendancePunctualityStatusConfig}
                      code={attendance?.punctuality_status}
                      className={
                        'py-2 rounded p1-bold px-3 p2-bold text-[15px]'
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='flex flex-col'>
              <div className='flex flex-col mt-1'>
                <hr className='divider' />
                <div className='grid md:grid-cols-2  grid-cols-1 gap-4 w-full'>
                  <div className='flex flex-col'>
                    <span className='h4-regular text-color-gray'>Date</span>
                    <span className='mt-1 text-color-black'>
                      {attendance?.attendance_date}
                    </span>
                  </div>
                  {/* <div className='flex flex-col'>
                  <span className='h4-regular text-color-gray'>Location</span>
                  <span className='mt-1 text-color-black'>
                  {attendance?.location}
                  </span>
              </div> */}
                  <div className='flex flex-col'>
                    <span className='h4-regular text-color-gray'>
                      ClockIn Location
                    </span>
                    <span className='mt-1 text-color-black'>
                      {attendance?.clockin_location}
                    </span>
                  </div>
                  <div className='flex flex-col'>
                    <span className='h4-regular text-color-gray'>
                      Clockout Location
                    </span>
                    <span className='mt-1 text-color-black'>
                      {attendance?.clockout_location
                        ? attendance?.clockout_location
                        : 'Not clocked Out'}
                    </span>
                  </div>
                </div>
                <hr className='divider' />
                <div className='grid md:grid-cols-2  grid-cols-1 gap-4 w-full'>
                  <div className='flex flex-col'>
                    <span className='h4-regular text-color-gray'>
                      Expected Clockin
                    </span>
                    <span className='mt-1 text-color-black'>
                      {attendance?.expected_clockin}
                    </span>
                  </div>
                  <div className='flex flex-col'>
                    <span className='h4-regular text-color-gray'>
                      Expected Clockout
                    </span>
                    <span className='mt-1 text-color-black'>
                      {attendance?.expected_clockout}
                    </span>
                  </div>
                  <div className='flex flex-col'>
                    <span className='h4-regular text-color-gray'>
                      Clockin Time
                    </span>
                    <span className='mt-1 text-hr-primary-1'>
                      {attendance?.clockin_time}
                    </span>
                  </div>
                  <div className='flex flex-col'>
                    <span className='h4-regular text-color-gray'>
                      Clockout Time
                    </span>
                    <span className='mt-1 text-red-400'>
                      {attendance?.clockout_time
                        ? attendance?.clockout_time
                        : 'Not clocked Out'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ViewAttendance;
