import moment from "moment";
import Logo from '../components/logo';

// Local storage operations
export const useLocalStorage = {
  set: (key, data) => {
    let stringifiedData = JSON.stringify(data);
    localStorage.setItem(key, stringifiedData);
  },

  get: (key) => {
    const data = JSON.parse(localStorage.getItem(key));

    if (!data) {
      return null;
    }
    return data;
  },

  remove: (key) => {
    localStorage.removeItem(key);
  },

  clear: () => {
    localStorage.clear();
  },
};

export const getRequestError = (error) => {
  const { response } = error;
  if (
    response &&
    (response?.data?.status?.code === 115 || response?.status === 401)
  ) {
    return response?.data?.status?.desc;
  } else if (response && response?.data?.status === 400) {
    logout();
  } else if (response && response?.data?.errors) {
    if (typeof response?.data?.errors === 'object') {
      return Object.values(response?.data?.errors)?.[0];
    } else {
      return response?.data?.errors;
    }
  } else if (response && response?.data?.message) {
    return response?.data?.message;
  } else if (response && response?.data?.error) {
    return response?.data?.error;
  }
  return 'There might be a problem with your internet connection. Please check and try again.';
};

export const logout = () => {
  useLocalStorage.remove('app_user');
  window.scrollTo(0, 0);
  window.location.reload();
};

export const formatDateInWords = (date) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const current_date = new Date(date);
  const month_value = current_date.getMonth();
  const day_value = current_date.getDate();
  const year = current_date.getUTCFullYear();
  let hour = current_date.getHours();
  let mins = current_date.getMinutes();

  if (hour < 10) hour = `0${hour}`;
  if (mins < 10) mins = `0${mins}`;

  return date
    ? `${day_value} ${months[month_value]}, ${year} | ${hour}:${mins}`
    : '';
};

export const formatInputDate = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [year, month, day].join('-');
};

export const Initials = (first = null, second = null) => {
  if (!first && !second) {
    return '';
  } else if (first && !second) {
    return first[0];
  } else if (first && second) {
    return first[0] + second[0];
  }
};

export const formatAmount = (amount, decimals) => {
  return amount
    ? `N` +
        Number(amount)
          .toFixed(decimals || 2)
          .replace(/./g, function (c, i, a) {
            return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c;
          })
    : `N--`;
};

export const formatNumber = (n, decimals= 2) => {
  return (
    n && parseFloat(n).toLocaleString()
  );
};

export const firstLetter = (letter) => {
  var str = letter;
  var res = str.substring(0, 1);
  return res;
};

export const statusText = (status) => {
  switch (status) {
    case 1:
      return 'Active';
    case 2:
      return 'Inactive';
    case 3:
      return 'Disabled';
    default:
      return 'Unknown';
  }
};

export const statusStyling = (status) => {
  switch (status) {
    case 'active':
      return 'success';
    case 'successful':
      return 'success';
    case 'processing':
      return 'success';
    case 'completed':
      return 'secondary';
    case 'pending':
      return 'warning';
    case 'inactive':
      return 'tertiary';
    case 'unsuccessful':
      return 'error';
    default:
      return 'success';
  }
};

export const sumOfObjectKeys = (array, key) => {
  return array.reduce((a, b) => a + (b[key] || 0), 0);
};

export const formValidation = (type, required, min, max) => {
  if (type === 'email') {
    return {
      required: required ? 'Email address is required' : false,
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'Invalid email address',
      },
    };
  } else if (type === 'text') {
    return {
      required: required ? 'This field is required' : false,
    };
  } else if (type === 'non-empty') {
    return {
      required: required ? 'This field cannot be empty' : false,
    };
  } else if (type === 'number') {
    return {
      required: required ? 'This field is required' : false,
      min: min
        ? {
            value: min,
            message: `Minimum value is ${min}`,
          }
        : null,
      max: max
        ? {
            value: max,
            message: `Maximum value is ${max}`,
          }
        : null,
    };
  } else if (type === 'password') {
    return {
      required: required ? 'This field is required' : false,
      minLength: {
        value: 8,
        message: 'Must be atleast 8 characters',
      },
    };
  } else if (type === 'file') {
    return {
      required: required ? 'This field is required' : false,
    };
  } else if (type === 'select') {
    return {
      required: required ? 'This field is required' : false,
    };
  } else if (type === 'radio') {
    return {
      required: required ? 'This field is required' : false,
    };
  } else if (type === 'checkbox') {
    return {
      required: required ? 'This field is required' : false,
    };
  } else if (type === 'date') {
    return {
      required: required ? 'This field is required' : false,
    };
  }
};

export const payrollJSX = (company, payslip, imageBlob=null) => {
  return (
    <div className='flex flex-col gap-3 min-h-fit max-w-full bg-white p-4'>
        <div className='flex  items-center justify-between bg-color-white p-3 pt-0 text-secondary-2 rounded-md'>
            <div className='w-1/2 flex flex-col items-start gap-0'>
                <div className='flex gap-2 items-center'>
                  <div className='text-[20px] flex font-bold justify-center items-center h-[55px] w-[60px] header-3 relative rounded-xl bg-white'>
                    {company?.logo ? (
                      <img
                          src={imageBlob}
                          alt='company_logo'
                          className='object-fit min-w-[60px] min-h-[45px] max-w-[60px] max-h-[45px] '
                      />
                    ) : (
                        <span className='text-primary-1'>{Initials(company?.company_name)}</span>
                    )}
                  </div>
                  <span className='text-secondary-2 text-[18px] pb-4 font-semibold'>{company?.company_name}</span>
                </div>
                <div className='flex flex-col justify-start gap-2 font-normal -mt-2'>
                  <div className='w-full flex justify-start text-[14px] text-secondary-2'>
                      {company?.address}, {company?.city}, {company?.state}, {company?.country}.
                  </div>
                  <div className='w-full flex justify-start text-[13px] font-semibold text-secondary-2'>
                      {company?.email} | {company?.mobile}
                  </div>
                </div>
            </div>
            <div className='w-1/2 flex justify-end text-2xl text-primary-1 pt-0'>
                <div className='w-full flex flex-col items-start'>
                    <div className='flex flex-col  justify-end items-end font-normal w-full gap-0'>
                      <span className='text-[18px] w-2/3 text-right font-semibold text-secondary-2'>Payslip Information</span> 
                      <div className='flex justify-end px-2 items-center h-[42px] gap-2 w-full text-[14px] text-secondary-2'>
                        <span className='text-[14px] w-2/3 text-right font-semibold text-hr-primary-1'>Pay Month:</span> 
                        <div className='w-1/2 text-right text-secondary-2 text-[13px]'>
                          {moment(payslip.month).format('MMMM, YYYY')}
                        </div>
                      </div>
                      <div className='flex justify-end px-2 items-center h-[42px] gap-2 w-full text-[14px] text-secondary-2 -mt-4'>
                        <span className='text-[14px] w-2/3 text-right font-semibold text-secondary-2'>Payment Date:</span> 
                        <div className='w-1/2 text-right text-secondary-2 text-[14px]'>
                          {moment(payslip.payment_date).format('MMMM DD, YYYY')}
                        </div>
                      </div>
                      {payslip.staff_number ?
                      <div className='flex justify-end px-2 items-center h-[42px] gap-2 w-full text-[14px] text-secondary-2 -mt-4'>
                        <span className='text-[14px] w-2/3 text-right font-semibold text-hr-secondary-2'>Staff Number:</span> 
                        <div className='w-1/2 text-right text-secondary-2 text-[14px]'>
                          {payslip.staff_number}
                        </div>
                      </div>
                      :
                      null
                      }
                    </div>
                </div>
            </div>
        </div>
        <div className='flex items-center justify-between bg-secondary-2 p-3 text-color-white rounded-md pb-4 -mt-2'>
          <div className='w-1/2 flex flex-col items-start gap-2 font-normal'>
            <span className='text-[18px] w-full text-left font-semibold text-color-white'>{payslip.employee_first_name} {payslip.employee_last_name}</span> 
            <div className='flex flex-col justify-start gap-2 font-normal'>
              <div className='w-full flex justify-start text-[13px] font-normal text-color-white'>
                {payslip.employee_email} | {payslip.employee_phone}
              </div>
            </div>
          </div>
          <div className='w-1/2 flex flex-col justify-end gap-2 font-normal'>
            <span className='text-[18px] w-full text-right font-semibold text-color-white'>{payslip.bank_name}</span> 
            <div className='w-full flex justify-end text-[13px] font-normal text-color-white'>
              {payslip.account_number} | {payslip.account_name}
            </div>
          </div>
        </div>
        <div className='flex flex-col font-normal'>
            <div className='flex pb-3 bg-white border-b-2 border-b-secondary-2 border-t-2 border-t-secondary-2 h-[50px]'>
                <div className='flex w-2/6 items-center justify-center px-3'>
                    <div className='text-secondary-2 !text-[14px] font-extrabold'>Earnings</div>
                </div>
                <div className='flex w-2/6 items-center justify-center px-3'>
                    <div className='text-secondary-2 !text-[14px] font-normal'>Type</div>
                </div>
                <div className='flex w-2/6 items-center justify-center px-3'>
                    <div className='text-secondary-2 !text-[14px] font-normal text-center'>Amount</div>
                </div>
            </div>
            {payslip.basic_salary && payslip.basic_salary > 0 ?
            <div className='flex bg-white h-[40px]'>
                <div className='flex w-2/6 items-center justify-center px-3'>
                    <div className='text-secondary-2 !text-[12px] font-extrabold'></div>
                </div>
                <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
                    <div className='text-secondary-2 !text-[12px] font-normal'>Basic Salary</div>
                </div>
                <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
                    <div className='text-secondary-2 !text-[12px] font-bold text-center pr-4'>{formatNumber(payslip.basic_salary)}</div>
                </div>
            </div>
            :
            null}
            
            {payslip.housing_allowance && parseFloat(payslip.housing_allowance) > 0 ?
            <div className='flex bg-white h-[40px]'>
                <div className='flex w-2/6 items-center justify-center px-3'>
                    <div className='text-secondary-2 !text-[12px] font-extrabold'></div>
                </div>
                <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
                    <div className='text-secondary-2 !text-[12px] font-normal'>Housing Allowance</div>
                </div>
                <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
                    <div className='text-secondary-2 !text-[12px] font-bold text-center pr-4'>{formatNumber(payslip.housing_allowance)}</div>
                </div>
            </div>
            :
            null}
            
            {payslip.transport_allowance && parseFloat(payslip.transport_allowance) > 0 ?
            <div className='flex bg-white h-[40px]'>
                <div className='flex w-2/6 items-center justify-center px-3'>
                    <div className='text-secondary-2 !text-[12px] font-extrabold'></div>
                </div>
                <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
                    <div className='text-secondary-2 !text-[12px] font-normal'>Transport Allowance</div>
                </div>
                <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
                  <div className='text-secondary-2 !text-[12px] font-bold text-center pr-4'>{formatNumber(parseFloat(payslip.transport_allowance))}</div>
                </div>
            </div>
            :
            null}
            
            {payslip.leave_allowance && parseFloat(payslip.leave_allowance) > 0 ?
            <div className='flex bg-white h-[40px]'>
              <div className='flex w-2/6 items-center justify-center px-3'>
                  <div className='text-secondary-2 !text-[12px] font-extrabold'></div>
              </div>
              <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
                  <div className='text-secondary-2 !text-[12px] font-normal'>Leave Allowance</div>
              </div>
              <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
                  <div className='text-secondary-2 !text-[12px] font-bold text-center pr-4'>{formatNumber(payslip.leave_allowance)}</div>
              </div>
            </div>
            :
            null}
            {payslip.lunch_allowance && parseFloat(payslip.lunch_allowance) > 0 ?
            <div className='flex bg-white h-[40px]'>
              <div className='flex w-2/6 items-center justify-center px-3'>
                <div className='text-secondary-2 !text-[12px] font-extrabold'></div>
              </div>
              <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
                <div className='text-secondary-2 !text-[12px] font-normal'>Lunch Allowance</div>
              </div>
              <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
                <div className='text-secondary-2 !text-[12px] font-bold text-center pr-4'>{formatNumber(payslip.lunch_allowance)}</div>
              </div>
            </div>
            :
            null}

            {payslip.utility_allowance && parseFloat(payslip.utility_allowance) > 0 ?
            <div className='flex bg-white h-[40px]'>
              <div className='flex w-2/6 items-center justify-center px-3'>
                  <div className='text-secondary-2 !text-[12px] font-extrabold'></div>
              </div>
              <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
                  <div className='text-secondary-2 !text-[12px] font-normal'>Utility Allowance</div>
              </div>
              <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
                  <div className='text-secondary-2 !text-[12px] font-bold text-center pr-4'>{formatNumber(payslip.utility_allowance)}</div>
              </div>
            </div>
            :
            null}
            
            {payslip.benefit_in_kind && payslip.benefit_in_kind > 0 ?
            <div className='flex bg-white h-[40px]'>
              <div className='flex w-2/6 items-center justify-center px-3'>
                <div className='text-secondary-2 !text-[12px] font-extrabold'></div>
              </div>
              <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
                <div className='text-secondary-2 !text-[12px] font-normal'>Benefit In Kind</div>
              </div>
              <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
                <div className='text-secondary-2 !text-[12px] font-bold text-center pr-4'>{formatNumber(payslip.benefit_in_kind)}</div>
              </div>
            </div>
            :
            null}
            
            
            {payslip.additional_allowances && JSON.parse(payslip.additional_allowances).length > 0 && JSON.parse(payslip.additional_allowances).map((item) => (
              <div className='flex bg-white h-[40px]'>
                <div className='flex w-2/6 items-center justify-center px-3'>
                  <div className='text-secondary-2 !text-[12px] font-extrabold'></div>
                </div>
                <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
                  <div className='text-secondary-2 !text-[12px] font-normal'>{item.title}</div>
                </div>
                <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
                  <div className='text-secondary-2 !text-[12px] font-bold text-center pr-4'>{formatNumber(item.amount)}</div>
                </div>
              </div>
            ))}

            {payslip.other_allowance && parseFloat(payslip.other_allowance) > 0 ?
            <div className='flex bg-white h-[40px]'>
              <div className='flex w-2/6 items-center justify-center px-3'>
                <div className='text-secondary-2 !text-[12px] font-extrabold'></div>
              </div>
              <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
                <div className='text-secondary-2 !text-[12px] font-normal'>Other Allowance</div>
              </div>
              <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
                <div className='text-secondary-2 !text-[12px] font-bold text-center pr-4'>{formatNumber(payslip.other_allowance)}</div>
              </div>
            </div>
            :
            null}
            
            {payslip.overtime && parseFloat(payslip.overtime) > 0 ?
            <div className='flex bg-white h-[40px]'>
              <div className='flex w-2/6 items-center justify-center px-3'>
                <div className='text-secondary-2 !text-[12px] font-extrabold'></div>
              </div>
              <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
                <div className='text-secondary-2 !text-[12px] font-normal'>Overtime</div>
              </div>
              <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
                <div className='text-secondary-2 !text-[12px] font-bold text-center pr-4'>{formatNumber(payslip.overtime)}</div>
              </div>
            </div>
            :
            null}
            {payslip.bonus ?
            <>
              {JSON.parse(payslip.bonus_breakdown) && JSON.parse(payslip.bonus_breakdown).length > 0 ?
              <>
                {JSON.parse(payslip.bonus_breakdown).map((item, index) => (
                  <div className='flex bg-white h-[40px]'>
                    <div className='flex w-2/6 items-center justify-center px-3'>
                      <div className='text-secondary-2 !text-[12px] font-extrabold'></div>
                    </div>
                    <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
                      <div className='text-secondary-2 !text-[12px] font-normal'>{item.description}</div>
                    </div>
                    <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
                      <div className='text-secondary-2 !text-[12px] font-bold text-center pr-4'>{formatNumber(item.amount)}</div>
                    </div>
                  </div>
                ))}
              </>
              : parseFloat(payslip.bonus) > 0 ?
              <div className='flex bg-white h-[40px]'>
                <div className='flex w-2/6 items-center justify-center px-3'>
                  <div className='text-secondary-2 !text-[12px] font-extrabold'></div>
                </div>
                <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
                  <div className='text-secondary-2 !text-[12px] font-normal'>Bonus</div>
                </div>
                <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
                  <div className='text-secondary-2 !text-[12px] font-bold text-center pr-4'>{formatNumber(payslip.bonus)}</div>
                </div>
              </div>
              :
              null
              }
            </>
            :
            null
            }
            <div className='flex bg-white h-[50px]'>
              <div className='flex w-2/6 items-center justify-center px-3 bg-highlight pb-2'>
                <div className='text-secondary-2 !text-[15px] font-normal'></div>
              </div>
              <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
                <div className='text-secondary-2 !text-[15px] font-semibold'>Gross Pay</div>
              </div>
              <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
                <div className='text-secondary-2 !text-[15px] text-center font-extrabold pr-4'>{formatNumber(payslip.gross_pay)}</div>
              </div>
            </div>
            <div className='flex pb-3 bg-white border-b-2 border-b-secondary-2 border-t-2 border-t-secondary-2 h-[50px]'>
                <div className='flex w-2/6 items-center justify-center px-3'>
                    <div className='text-secondary-2 !text-[14px] font-extrabold'>Deductions</div>
                </div>
                <div className='flex w-2/6 items-center justify-center px-3'>
                    <div className='text-secondary-2 !text-[14px] font-normal'>Type</div>
                </div>
                <div className='flex w-2/6 items-center justify-center px-3'>
                    <div className='text-secondary-2 !text-[14px] font-normal text-center'>Amount</div>
                </div>
            </div>
            
            <div className='flex bg-white h-[40px]'>
              <div className='flex w-2/6 items-center justify-center px-3'>
                <div className='text-secondary-2 !text-[12px] font-extrabold'></div>
              </div>
              <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
                <div className='text-secondary-2 !text-[12px] font-normal'>PAYE Tax</div>
              </div>
              <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
                <div className='text-secondary-2 !text-[12px] font-bold text-center pr-4'>{formatNumber(payslip.tax)}</div>
              </div>
            </div>
            {payslip.pension && parseFloat(payslip.pension) > 0 ?
            <div className='flex bg-white h-[40px]'>
                <div className='flex w-2/6 items-center justify-center px-3'>
                    <div className='text-secondary-2 !text-[12px] font-extrabold'></div>
                </div>
                <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
                    <div className='text-secondary-2 !text-[12px] font-normal'>Pension</div>
                </div>
                <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
                  <div className='text-secondary-2 !text-[12px] font-bold text-center pr-4'>{formatNumber(payslip.pension)}</div>
                </div>
            </div>
            :
            null}
            {payslip.other_statutory_allowance && parseFloat(payslip.other_statutory_allowance) > 0 ?
            <div className='flex bg-white h-[40px]'>
              <div className='flex w-2/6 items-center justify-center px-3'>
                <div className='text-secondary-2 !text-[12px] font-extrabold'></div>
              </div>
              <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
                <div className='text-secondary-2 !text-[12px] font-normal'>Other Statutory Deductions</div>
              </div>
              <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
                <div className='text-secondary-2 !text-[12px] font-bold text-center pr-4'>{formatNumber(parseFloat(payslip.other_statutory_allowance))}</div>
              </div>
            </div>
            :
            null}
            {payslip.salary_advance && parseFloat(payslip.salary_advance) > 0 ?
            <div className='flex bg-white h-[40px]'>
              <div className='flex w-2/6 items-center justify-center px-3'>
                <div className='text-secondary-2 !text-[12px] font-extrabold'></div>
              </div>
              <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
                <div className='text-secondary-2 !text-[12px] font-normal'>Advances/Loans</div>
              </div>
              <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
                <div className='text-secondary-2 !text-[12px] font-bold text-center pr-4'>{formatNumber(payslip.salary_advance)}</div>
              </div>
            </div>
            :
            null}
            {payslip.other_deductions && parseFloat(payslip.other_deductions) > 0 ?
            <>
              {JSON.parse(payslip.other_deductions_breakdown) && JSON.parse(payslip.other_deductions_breakdown).length> 0 ?
              <>
                {JSON.parse(payslip.other_deductions_breakdown).map((item, index) => (
                  <div className='flex bg-white h-[40px]'>
                    <div className='flex w-2/6 items-center justify-center px-3'>
                      <div className='text-secondary-2 !text-[12px] font-extrabold'></div>
                    </div>
                    <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
                      <div className='text-secondary-2 !text-[12px] font-normal'>{item.description}</div>
                    </div>
                    <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
                      <div className='text-secondary-2 !text-[12px] font-bold text-center pr-4'>{formatNumber(item.amount)}</div>
                    </div>
                  </div>
                ))}
              </>
              :payslip.other_deductions && parseFloat(payslip.other_deductions) > 0 ?
              <div className='flex bg-white h-[40px]'>
                <div className='flex w-2/6 items-center justify-center px-3'>
                  <div className='text-secondary-2 !text-[12px] font-extrabold'></div>
                </div>
                <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
                  <div className='text-secondary-2 !text-[12px] font-normal'>Other Salary Deductions</div>
                </div>
                <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
                  <div className='text-secondary-2 !text-[12px] font-bold text-center pr-4'>{formatNumber(payslip.other_deductions)}</div>
                </div>
              </div>
              :
              null
              }
            </>
            :
            null
            }
            
            <div className='flex bg-white h-[40px]'>
              <div className='flex w-2/6 items-center justify-center px-3 bg-highlight pb-2'>
                <div className='text-secondary-2 !text-[14px] font-normal'></div>
              </div>
              <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
                <div className='text-secondary-2 !text-[14px] font-semibold'>Total Deductions</div>
              </div>
              <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
                <div className='text-secondary-2 !text-[14px] text-center font-extrabold pr-4'>{formatNumber(payslip.tax + payslip.pension + parseFloat(payslip.other_statutory_allowance) + payslip.salary_advance + payslip.other_deductions)}</div>
              </div>
            </div>
            {payslip.reimburse && parseFloat(payslip.reimburse) > 0 ?
            <>
              <div className='flex pb-3 bg-white border-b-2 border-b-secondary-2 border-t-2 border-t-secondary-2 h-[50px]'>
                <div className='flex w-2/6 items-center justify-center px-3'>
                    <div className='text-secondary-2 !text-[14px] font-extrabold'>Reimbursements</div>
                </div>
                <div className='flex w-2/6 items-center justify-center px-3'>
                    <div className='text-secondary-2 !text-[14px] font-normal'>Type</div>
                </div>
                <div className='flex w-2/6 items-center justify-center px-3'>
                    <div className='text-secondary-2 !text-[14px] font-normal text-center'>Amount</div>
                </div>
              </div>
              {JSON.parse(payslip.reimbursement_breakdown) && JSON.parse(payslip.reimbursement_breakdown).length> 0 ?
              <>
                
                {JSON.parse(payslip.reimbursement_breakdown).map((item, index) => (
                  <div className='flex bg-white h-[40px]'>
                    <div className='flex w-2/6 items-center justify-center px-3'>
                      <div className='text-secondary-2 !text-[12px] font-extrabold'></div>
                    </div>
                    <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
                      <div className='text-secondary-2 !text-[12px] font-normal'>{item.description}</div>
                    </div>
                    <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
                      <div className='text-secondary-2 !text-[12px] font-bold text-center pr-4'>{formatNumber(item.amount)}</div>
                    </div>
                  </div>
                ))}
              </>
              :payslip.reimburse && parseFloat(payslip.reimburse) > 0 ?
              <div className='flex bg-white h-[40px]'>
                <div className='flex w-2/6 items-center justify-center px-3'>
                  <div className='text-secondary-2 !text-[12px] font-extrabold'></div>
                </div>
                <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
                  <div className='text-secondary-2 !text-[12px] font-normal'>Reimbursements</div>
                </div>
                <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
                  <div className='text-secondary-2 !text-[12px] font-bold text-center pr-4'>{formatNumber(payslip.reimburse)}</div>
                </div>
              </div>
              :
              null
              }
            </>
            :
            null
            }
            <div className='flex bg-white h-[50px]'>
              <div className='flex w-2/6 items-center justify-center px-3 bg-gray-200 pb-2'>
                <div className='text-secondary-2 !text-[16px] font-normal'></div>
              </div>
              <div className='flex w-2/6 items-center justify-start px-3 bg-gray-200 pb-3'>
                <div className='text-secondary-2 !text-[18px] font-semibold'>Net Pay</div>
              </div>
              <div className='flex w-2/6 items-center justify-end px-3 bg-gray-200 pb-3'>
                <div className='text-secondary-2 !text-[18px] text-center font-extrabold pr-4'>{formatNumber(payslip.net_pay)}</div>
              </div>
            </div>
        </div>
        <a  href="https://www.ropay.africa" target="_blank" className='flex w-full flex-col justify-center items-center p-2 gap-2 text-black'>
          <span>
            Powered By:
          </span>
          <Logo/>
        </a>
    </div>
  )
}

export const toDataUrl = async function (url, callback) {
  //Convert to base64
  return new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
          var reader = new FileReader();
          reader.onloadend = function () {
          resolve(reader.result);
          };
          reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = () => {
          reject({
          status: this.status,
          statusText: xhr.statusText,
          });
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
  });
};
