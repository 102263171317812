import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import Button from "../../components/button";
import FormInput from "../../components/form-input";
import FormSelect from "../../components/form-select";
import EmployeeRegistrationLayout from "../../components/layouts/employee-registration";
import LoadingBar from "../../components/loader-bar";
import { useGetPFA } from "../../redux/bank/hook";
import { formValidation } from "../../utils/functions";
import Logo from "../../components/logo";
import { setEmployee } from "../../redux/employee/reducer";
import { store } from "../../redux";

function CompleteEmployeeInformationPage() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [loading, setLoading] = useState(false);
  const { data: PFAs } = useGetPFA();
  const [payload, setPayload] = useState({});
  const [rsaPin, setRsaPin] = useState("");
  const [taxId, setTaxId] = useState("");
  const [pfa_name, setPFAName] = useState("");

  const { employee } = useSelector((state) => state.employeeSlice);
  const { company } = useSelector((state) => state.companySlice);

  const location = useLocation();

  useEffect(() => {
    if (location) {
      setPayload(location.state);
    }
  }, [location]);

  const history = useHistory();

  const {
    register,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (employee) {
      setRsaPin(employee.rsa_pin);
      setTaxId(employee.tax_number);
      setPFAName(employee.pfa_name);
    }
  }, [employee]);

  const submitForm = async () => {
    setLoading(true);

    let newData = {
      ...employee,
      pfa_name: pfa_name,
      rsa_pin: rsaPin,
      tax_number: taxId,
    };
    store.dispatch(setEmployee(newData));
    setLoading(false);

    history.push("/registration/salary-payment-information", newData);
  };

  return (
    <EmployeeRegistrationLayout
      step="2"
      company_name={company?.company_name}
      logo={company?.logo}
    >
      <div className="flex flex-col justify-center w-full md:pt-5 h-fit gap-4">
        <div className="flex md:min-h-full flex-col w-full max-w-[800px] px-5 md:px-0">
          <form className="form ">
            <div className="w-full text-center flex flex-col pb-[25px] md:pt-[20px] pt-2">
              <span className="header-2-regular text-secondary-2">
                Employment Information
              </span>
              <span className="header-4-regularh text-secondary-2">
                View and update your employment details
              </span>
            </div>
            <hr className="divider -mt-2" />
            <div className="md:hidden flex flex-col md:w-1/2 w-full md:pr-2">
              <span className="header-3 text-color-gray">Company Name</span>
              <span className="header-4 mt-1 text-color-black font-bold">
                {employee?.company_name}
              </span>
              <hr className="divider" />
            </div>
            {payload ? (
              <div className="flex flex-col">
                <div className="p-2 rounded-md border bg-secondary-2-extralight">
                  <div className="header-4 mb-2 text-color-gray">
                    Pre-filled employment information
                  </div>
                  <div className="flex flex-row w-full justify-between">
                    <div className="flex flex-col md:w-1/2 w-full md:pr-2">
                      <span className="header-5 text-color-gray">
                        First Name
                      </span>
                      <span className="text-[13px] mt-1 text-color-black font-medium">
                        {employee?.first_name}
                      </span>
                    </div>
                    <div className="flex flex-col md:w-1/2 w-full md:pr-2">
                      <span className="text-[13px] header-5 text-color-gray">
                        Last name
                      </span>
                      <span className="text-[13px] mt-1 text-color-black font-medium">
                        {employee?.last_name}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-row w-full justify-between mt-3 gap-3">
                    <div className="flex flex-col md:w-1/2 w-full md:pr-2">
                      <span className="header-5 text-color-gray">Email</span>
                      <span className="text-[13px] mt-1 text-color-black font-semibold">
                        {employee?.email}
                      </span>
                    </div>
                    <div className="flex flex-col md:w-1/2 w-full md:pr-2">
                      <span className="header-5 text-color-gray">
                        Phone number
                      </span>
                      <span className="text-[13px] mt-1 text-color-black font-bold">
                        {employee?.mobile}
                      </span>
                    </div>
                  </div>
                  <hr className="divider" />
                  <div className="flex flex-col md:w-1/2 w-full md:pr-2">
                    <span className="header-5 text-color-gray">
                      Date Of Employment
                    </span>
                    <span className="mt-1 text-color-black font-bold text-[13px]">
                      {moment(employee?.date_joined).format("MMMM DD, YYYY")}
                    </span>
                  </div>
                  <div className="flex flex-row w-full justify-between mt-2 gap-2">
                    <div className="flex flex-col w-1/2 md:pr-2">
                      <span className="header-5 text-color-gray">
                        Job Position
                      </span>
                      <span className="mt-1 text-color-black font-bold text-[13px]">
                        {employee?.job_position
                          ? employee?.job_position
                          : "Not Set"}
                      </span>
                    </div>
                    <div className="flex flex-col w-1/2 md:pl-2">
                      <span className="header-5 text-color-gray">
                        Staff Number
                      </span>
                      <span className="mt-1 text-color-black font-bold text-[13px]">
                        {employee?.staff_number
                          ? employee?.staff_number
                          : "Not Set"}
                      </span>
                    </div>
                  </div>
                </div>
                <hr className="divider" />
                {PFAs ? (
                  <FormSelect
                    value={pfa_name}
                    options={PFAs}
                    onChange={(selected) => {
                      setPFAName(selected);
                    }}
                    label="Pension Fund Administrator (PFA) - (Optional)"
                    extraClass={"mb-3"}
                  />
                ) : (
                  <LoadingBar loading={true} />
                )}
                <div className="flex flex-col md:flex-row w-full justify-between gap-2">
                  <div className="w-full md:w-1/2">
                    <FormInput
                      label="Pension RSA PIN - (Optional)"
                      name="rsa"
                      type="text"
                      value={rsaPin}
                      placeholder={"Enter RSA PIN"}
                      onInput={(e) => setRsaPin(e.target.value)}
                      inputRef={register(formValidation("text", false))}
                      readOnly={loading}
                      error={errors.tax}
                      errorMessage={errors.rsa && errors.rsa.message}
                    />
                  </div>
                  <div className="w-full md:w-1/2 pl-2">
                    <FormInput
                      label="Tax number - (Optional)"
                      name="tax"
                      type="text"
                      value={taxId}
                      placeholder={"Enter tax Number"}
                      onInput={(e) => setTaxId(e.target.value)}
                      inputRef={register(formValidation("text", false))}
                      readOnly={loading}
                      error={errors.tax}
                      errorMessage={errors.tax && errors.tax.message}
                    />
                  </div>
                </div>
                <div className="flex justify-end items-center w-full mt-[20px] gap-4">
                  <div
                    className="flex justify-end cursor-pointer hover:underline"
                    onClick={() => history.goBack()}
                  >
                    <span className="p2-medium text-color-black">Go back</span>
                  </div>
                  <Button
                    text="Save and Continue"
                    type="submit"
                    className={"!bg-secondary-2 w-[200px] h-[42px]"}
                    loading={loading}
                    onClick={() => submitForm()}
                  />
                </div>
              </div>
            ) : null}
          </form>
        </div>
        <div
          className={
            "md:hidden flex items-center justify-center text-color-black gap-2"
          }
        >
          Powered By:{" "}
          <a href="https://www.ropay.africa" target={"_blank"} rel="noreferrer">
            <Logo width="80px" height="40px" blue />
          </a>
        </div>
      </div>
    </EmployeeRegistrationLayout>
  );
}

export default CompleteEmployeeInformationPage;
