import React from "react";

export const ChevronCircle = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.8865 10.481C21.9625 10.979 22.0005 11.49 22.0005 12C22.0005 17.514 17.5145 22 12.0005 22C6.48649 22 2.00049 17.514 2.00049 12C2.00049 6.486 6.48649 2 12.0005 2C15.1285 2 18.0175 3.423 19.9275 5.902C20.1725 6.22 20.1125 6.678 19.7955 6.922C19.4775 7.168 19.0215 7.107 18.7745 6.79C17.1435 4.669 14.6735 3.454 12.0005 3.454C7.28849 3.454 3.45449 7.287 3.45449 12C3.45449 16.712 7.28849 20.545 12.0005 20.545C16.7125 20.545 20.5465 16.712 20.5465 12C20.5465 11.563 20.5135 11.126 20.4485 10.701C20.3885 10.305 20.6605 9.934 21.0575 9.873C21.4565 9.807 21.8255 10.084 21.8865 10.481ZM9.20629 11.9992C9.20629 11.8002 9.28529 11.6092 9.42729 11.4682L12.9133 7.9972C13.2073 7.7042 13.6823 7.7052 13.9743 7.9992C14.2663 8.2932 14.2663 8.7672 13.9723 9.0592L11.0193 11.9992L13.9723 14.9392C14.2663 15.2312 14.2663 15.7062 13.9743 16.0002C13.6823 16.2932 13.2073 16.2942 12.9133 16.0022L9.42729 12.5302C9.28529 12.3902 9.20629 12.1992 9.20629 11.9992Z"
      fill="#130F26"
    />
  </svg>
);
