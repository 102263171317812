import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";
import Button from "../../components/button";
import FormDatalist from "../../components/form-datalist";
import FormInput from "../../components/form-input";
import EmployeeRegistrationLayout from "../../components/layouts/employee-registration";
import { formValidation } from "../../utils/functions";
import { useGetBanks, useResolveBankAccount } from "../../redux/bank/hook";
import { useHistory } from "react-router-dom";
import Logo from "../../components/logo";
import { useSelector } from "react-redux";
import { store } from "../../redux";
import { setEmployee } from "../../redux/employee/reducer";
import moment from "moment";

function CompleteSalaryInformationPage() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [bankname, setBankName] = useState("");
  const [account_number, setAccountNumber] = useState("");
  const [account_name, setAccountName] = useState("");
  const { mutateAsync: resolveAccount, isLoading: resolveAccountLoading } =
    useResolveBankAccount();
  const [loading, setLoading] = useState(false);

  const { employee } = useSelector((state) => state.employeeSlice);
  const { company } = useSelector((state) => state.companySlice);

  const location = useLocation();

  const [payload, setPayload] = useState({});

  const history = useHistory();

  const { data: banks } = useGetBanks();

  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (employee) {
      setBankName(employee.bank);
      setAccountNumber(employee.account_number);
      setAccountName(employee.account_name);
    }
  }, [employee]);

  useEffect(() => {
    if (account_number?.length >= 10 && bankname !== "") {
      let payload = {
        bankname,
        account_number,
      };
      setAccountName("");
      resolveAccount(payload)
        .then((response) => {
          setAccountName(response.data.accountname);
        })
        .catch(() => {
          setAccountName("Not Resolved");
        });
    }
  }, [account_number, bankname]);

  const submitForm = async () => {
    setLoading(true);
    let newData = {
      ...employee,
      bank: bankname,
      account_name: account_name,
      account_number: account_number,
    };
    store.dispatch(setEmployee(newData));
    setLoading(false);
    history.push("/registration/upload-picture");
  };

  return (
    <EmployeeRegistrationLayout
      step="3"
      company_name={company?.company_name}
      logo={company?.logo}
    >
      <div className="flex flex-col justify-center w-full md:pt-5 h-fit gap-4">
        <div className="flex flex-col w-full max-w-[800px] px-5 md:px-0">
          <form className="form " onSubmit={() => submitForm()}>
            <div className="w-full text-center flex flex-col pb-[25px] pt-2">
              <span className="header-2-regular text-secondary-2">
                Bank Information
              </span>
              <span className="header-4-regular text-secondary-2">
                Enter your bank account information
              </span>
            </div>
            <hr className="divider -mt-2" />
            <div className="md:hidden flex flex-col md:w-1/2 w-full md:pr-2">
              <span className="header-3 text-color-gray">Company Name</span>
              <span className="header-4 mt-1 text-color-black font-bold">
                {employee?.company_name}
              </span>
              <hr className="divider" />
            </div>
            <div className="p-2 rounded-md border bg-secondary-2-extralight">
              <div className="header-4 mb-2 text-color-gray">
                Pre-filled information
              </div>
              <div className="flex flex-row w-full justify-between">
                <div className="flex flex-col md:w-1/2 w-full md:pr-2">
                  <span className="header-5 text-color-gray">First Name</span>
                  <span className="text-[13px] mt-1 text-color-black font-medium">
                    {employee?.first_name}
                  </span>
                </div>
                <div className="flex flex-col md:w-1/2 w-full md:pr-2">
                  <span className="text-[13px] header-5 text-color-gray">
                    Last name
                  </span>
                  <span className="text-[13px] mt-1 text-color-black font-medium">
                    {employee?.last_name}
                  </span>
                </div>
              </div>
              <div className="flex flex-row w-full justify-between mt-3 gap-3">
                <div className="flex flex-col md:w-1/2 w-full md:pr-2">
                  <span className="header-5 text-color-gray">Email</span>
                  <span className="text-[13px] mt-1 text-color-black font-medium">
                    {employee?.email}
                  </span>
                </div>
                <div className="flex flex-col md:w-1/2 w-full md:pr-2">
                  <span className="header-5 text-color-gray">Phone number</span>
                  <span className="text-[13px] mt-1 text-color-black font-medium">
                    {employee?.mobile}
                  </span>
                </div>
              </div>
            </div>
            <hr className="divider" />
            <div className="flex flex-col">
              <FormDatalist
                label="Bank Name"
                placeholder="Type here to select bank"
                data={banks ? banks : []}
                value={bankname}
                onSelect={(value) => setBankName(value)}
              />
              <div className="flex md:flex-row flex-col w-full justify-between md:gap-3 gap-2">
                <div className="w-full md:w-1/3">
                  <FormInput
                    label="Account Number"
                    name="account_number"
                    type="text"
                    value={account_number}
                    inputRef={register(formValidation("text", true))}
                    error={errors.account_number}
                    errorMessage={
                      errors.account_number && errors.account_number.message
                    }
                    onInput={(e) => setAccountNumber(e.target.value)}
                  />
                </div>
                <div className="w-full md:w-2/3 ">
                  <FormInput
                    label="Account Name (Auto-Generated)"
                    name="account_name"
                    type="text"
                    placeholder={
                      resolveAccountLoading
                        ? "Fetching Account Name..."
                        : "Auto Generated"
                    }
                    readOnly
                    inputRef={register(formValidation("text", true))}
                    value={account_name}
                    error={errors.account_name}
                    errorMessage={
                      errors.account_name && errors.account_name.message
                    }
                  />
                </div>
              </div>
              <div className="flex justify-end items-center w-full mt-[20px] gap-4">
                <div
                  className="flex justify-end cursor-pointer hover:underline"
                  onClick={() => history.goBack()}
                >
                  <span className="p2-medium text-color-black">Go back</span>
                </div>
                <Button
                  text="Save and Continue"
                  className={"!bg-secondary-2 w-[200px] h-[42px]"}
                  type="submit"
                  loading={loading}
                  onClick={() => submitForm()}
                />
              </div>
            </div>
          </form>
        </div>
        <div
          className={
            "md:hidden flex items-center justify-center text-color-black gap-2"
          }
        >
          Powered By:{" "}
          <a href="https://www.ropay.africa" target={"_blank"} rel="noreferrer">
            <Logo width="80px" height="40px" blue />
          </a>
        </div>
      </div>
    </EmployeeRegistrationLayout>
  );
}

export default CompleteSalaryInformationPage;
