import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../components/layouts/dashboard';
import { useHistory } from 'react-router-dom';
import DepartmentSVG from '../../assets/svg/department-widget-icon.svg';
import { useSelector } from 'react-redux';
import { Initials } from '../../utils/functions';
import classNames from 'classnames';
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import TableActionIconSVG from '../../assets/svg/table-action-icon.svg';
import EmptyTableSVG from '../../assets/svg/empty-table.svg';
import { useGetDepartments } from '../../redux/human-resources/hook/departments';
import LoadingBar from '../../components/loader-bar';

const DepartmentPage = () => {

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

    
  const { user } = useSelector((state) => state.userSlice);

  const { data: departments, isLoading  } = useGetDepartments();

  const history = useHistory();

  const actionConfig = [
    {
      name: 'View',
      color: 'green',
      action: (id) => {
        let index = departments.findIndex((item) => item.id === id);
        history.push('/department/view/' + departments[index].id);
      }
    },
  ];

  const ActionItem = ({ item, id, sentData }) => {
    return (
      <div
        className='py-2 hover:bg-secondary-2-extralight px-3 pb-1 cursor-pointer'
        onClick={() => {
          item.action(id);
        }}
      >
        <span
          className={classNames(
            {
              'text-color-green-medium': item.color === 'green',
            },
            {
              'text-color-orange': item.color === 'orange',
            },
            {
              'text-color-error': item.color === 'red',
            },
          )}
        >
          {item.name}
        </span>
      </div>
    );
  };
  
  const Action = ({ data }) => {
    return (
      <>
        {data && data.computedActions? (
          <div className='flex justify-end'>
            <div className='!max-w-[45px]'>
              <UncontrolledDropdown direction='auto'>
                <DropdownToggle className='bg-transparent border-0 w-full'>
                  <img
                    src={TableActionIconSVG}
                    alt=''
                    className='!min-w-[35px]'
                  />
                </DropdownToggle>

                <DropdownMenu
                  container='body'
                  className='!w-[150px] !p-0 !text-[14px]'
                  style={{ zIndex: 1090 }}
                  width='50px'
                >
                  <div className='flex flex-col'>
                    {actionConfig?.map((item, index) => (
                      <>
                        {data.computedActions ? (
                          <>
                            {data.computedActions ? (
                              <>
                                {data.computedActions.includes(index) ? (
                                  <ActionItem item={item} id={data.id} />
                                ) : null}
                              </>
                            ) : null}
                          </>
                        ) : (
                          <ActionItem item={item} id={data.id} />
                        )}
                      </>
                    ))}
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
        ) : null}
      </>
    );
  };

  return (
    <DashboardLayout title='departments Management'>
      <div className='flex flex-col -mt-4'>
        <div className='flex flex-col bg-white mt-[10px] shadow-md rounded-md'>
          <div className='flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3'>
            <div className='p3-bold header-4'>
              Departments
            </div>
          </div>
          <hr className='divider mt-2 mb-2' />
          {departments?
          <div className='grid md:grid-cols-3 grid-cols-1 gap-4 w-full p-4 pt-2 duration-500'>
            {departments?.map((data, index) => (
              <div className='flex flex-row flex-wrap-reverse justify-between rounded-md mx-2 p-3 w-full shadow-md hover:shadow-2xl border hover:bg-green-50 duration-500 hover:scale-110 mt-1'>
                <div className=' flex flex-col !min-w-full gap-2 !min-h-[120px]'>
                  <div className='flex flex-row items-center justify-between w-full -mr-8'>
                    <div>
                      <img src={DepartmentSVG} alt='team_icon' />
                    </div>
                    <Action data={data} />
                  </div>
                  <div className='flex flex-col justify-between p-2 bg-highlight rounded-md'>
                    <div className='header-4 text-left'>{data?.name}</div>
                    <span className='text-[13px]'>{data?.description}</span>
                  </div>
                  <div className='flex flex-row items-start justify-between min-h-[70px]'>
                    <div className='flex flex-col'>
                      <span className='header-6 md:header-5 text-gray-400'>
                        Employees
                      </span>
                      {data?.employees && data?.employee_count > 0 ? (
                        <>
                          {data?.employee_count <= 5 ? (
                            <div className='flex flex-row items-center justify-between w-full mt-2'>
                              {data?.employees.map((item, index) => (
                                <div
                                  className={classNames(
                                    'text-[12px] md:text-[18px] flex font-bold justify-center items-center h-[30px] md:h-[45px] w-[30px] md:w-[45px] rounded-[45px] header-3 border-secondary-2 bg-green-200 relative shadow-md',
                                    {
                                      '-ml-3 md:-ml-4': index !== 0,
                                    },
                                  )}
                                >
                                  {item?.profile_photo_url ? (
                                    <div>
                                      <img
                                        src={item?.profile_photo_url}
                                        alt={`employee_${index + 1}`}
                                        className='object-contain !max-w-[35px] !max-h-[35px] rounded-[35px]'
                                      />
                                    </div>
                                  ) : (
                                    <>{Initials(item?.first_name, item?.last_name)}</>
                                  )}
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className='flex flex-row items-center justify-between w-full  mt-2 '>
                              {data?.employees.slice(0, 5).map((item, index) => (
                                <div
                                  className={classNames(
                                    'text-[12px] md:text-[18px] flex font-bold justify-center items-center h-[30px] md:h-[45px] w-[30px] md:w-[45px] rounded-[45px] border-[3px] header-3 border-secondary-2 bg-green-200 relative shadow-md',
                                    {
                                      '-ml-3 md:-ml-4': index !== 0,
                                    },
                                  )}
                                >
                                  {item?.profile_photo_url ? (
                                    <div>
                                      <img
                                        src={item?.profile_photo_url}
                                        alt={`employee_${index + 1}`}
                                        className='object-contain  !max-w-[35px] !max-h-[35px] rounded-[35px]'
                                      />
                                    </div>
                                  ) : (
                                    <>{Initials(item?.first_name, item?.last_name)}</>
                                  )}
                                </div>
                              ))}
                              <span className='font-semibold text-[15px] ml-1'>
                                + {data?.employee_count - 5}
                              </span>
                            </div>
                          )}
                        </>
                      ) : (
                        <span className='text-[15px]'>
                          {data?.employee_count} Employees
                        </span>
                      )}
                    </div>
                    <div className='flex flex-col justify-center items-end'>
                      {data.manager_setting.manager_id &&
                        data.employees[
                          data.employees.findIndex(
                            (item) => item.id === data.manager_setting.manager_id,
                          )
                        ] && (
                          <>
                            <div className='flex justify-center flex-col items-center'>
                              <span className='header-6 md:header-5 text-gray-400'>
                                Team Lead
                              </span>
                              <div className='text-[12px] md:text-[18px] flex font-bold justify-center items-center h-[30px] md:h-[45px] w-[30px] md:w-[45px] rounded-[45px] border-[3px] header-3 border-secondary-2 bg-green-200 relative mt-2 hover:scale-150 hover:m-2 duration-500'>
                                {data.employees[
                                  data.employees.findIndex(
                                    (item) =>
                                      item.id === data.manager_setting.manager_id,
                                  )
                                ]?.profile_photo_url ? (
                                  <div>
                                    <img
                                      src={
                                        data.employees[
                                          data.employees.findIndex(
                                            (item) =>
                                              item.id ===
                                              data.manager_setting.manager_id,
                                          )
                                        ]?.profile_photo_url
                                      }
                                      alt={`lead_pix`}
                                      className='object-contain !max-w-[35px] !max-h-[35px] rounded-[35px]'
                                    />
                                  </div>
                                ) : (
                                  <>
                                    {Initials(
                                      data.employees[
                                        data.employees.findIndex(
                                          (item) =>
                                            item.id === data.manager_setting.manager_id,
                                        )
                                      ]?.first_name,
                                      data.employees[
                                        data.employees.findIndex(
                                          (item) =>
                                            item.id === data.manager_setting.manager_id,
                                        )
                                      ]?.last_name,
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          :
          <>
            {isLoading?
            <LoadingBar loading={isLoading} />
            :
            <div className='flex items-center justify-center min-w-full mt-4'>
              <img
                src={EmptyTableSVG}
                alt='No Data Fetched'
                width={'40%'}
                height={'400px'}
              />
            </div>
            }
          </>
          }
        </div>
      </div>
    </DashboardLayout>
  );
};

export default DepartmentPage;
