import React from 'react';
import ProtectedRoute from './protected';
import CompleteProfileInformationPage from '../pages/employee-registration/complete-profile-infomation';
import CompleteEmployeeInformationPage from '../pages/employee-registration/complete-employee-infomation';
import CompleteSalaryInformationPage from '../pages/employee-registration/complete-salary-infomation';
import UploadProfilePicture from '../pages/employee-registration/upload-profile-picture';
import VerifySingleEmployeeInformationPage from '../pages/employee-registration/verify-and-submit-infomation';

export const EmployeeRegistrationRoutes = [
  <ProtectedRoute
    exact
    path='/registration/profile'
    component={CompleteProfileInformationPage}
  />,
  <ProtectedRoute
    exact
    path='/registration/employment-information'
    component={CompleteEmployeeInformationPage}
  />,
  <ProtectedRoute
    exact
    path='/registration/salary-payment-information'
    component={CompleteSalaryInformationPage}
  />,
  <ProtectedRoute
    exact
    path='/registration/upload-picture'
    component={UploadProfilePicture}
  />,
  <ProtectedRoute
    exact
    path='/registration/verify'
    component={VerifySingleEmployeeInformationPage}
  />
]
