import React from "react";

export const QuestionMark = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.90353 12.6001H8.10352V14.4001H9.90353V12.6001Z" fill="black" />
    <path
      d="M8.99998 0C4.02945 0 0 4.02945 0 8.99998C0 13.9705 4.02945 18 8.99998 18C13.9705 18 18 13.9705 18 8.99998C18 4.02945 13.9705 0 8.99998 0ZM8.99998 16.371C4.9291 16.371 1.62898 13.0709 1.62898 8.99998C1.62898 4.9291 4.9291 1.62898 8.99998 1.62898C13.0688 1.63392 16.366 4.93112 16.371 8.99998C16.371 13.0709 13.0709 16.371 8.99998 16.371Z"
      fill="black"
    />
    <path
      d="M12.4975 6.34505C12.0346 4.41147 10.0918 3.21934 8.1582 3.6823C6.53259 4.07152 5.38876 5.52851 5.39652 7.20006H7.19654C7.28104 6.20596 8.15542 5.46856 9.14953 5.55306C10.1436 5.63756 10.881 6.51194 10.7965 7.50605C10.6127 8.40059 9.81018 9.03193 8.89754 9.00003C8.45516 9.00003 8.09652 9.35867 8.09652 9.80105V11.7H9.89654V10.674C11.8 10.1832 12.9579 8.25618 12.4975 6.34505Z"
      fill="black"
    />
  </svg>
);
