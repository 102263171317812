import React, { useEffect, useState } from 'react';
import Button from '../../../components/button';
import ButtonSelect from '../../../components/button-select';
import { useHistory } from 'react-router-dom';
import PerformanceEvaluationLayout from '../../../components/layouts/performance-evaluation';

function SelectEvaluationOptions() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [evaluationOptions, setEvaluationOptions] = useState('basic');
  const history = useHistory();

  const submit = () => {
    if (evaluationOptions === 'basic') {
        history.push(
          '/performance/evaluate/basic',
        );
      } else if (evaluationOptions === 'advanced') {
        history.push(
          '/performance/evaluate/advanced',
        );
      }
  };

  return (
    <PerformanceEvaluationLayout step={1} title={'Select Evaluation Type'}>
      <div className='flex flex-col justify-center h-full min-h-[480px]'>
        <ButtonSelect
          title={'Basic performance evaluation'}
          subtitle={'Evaluate performance using basic performance workflow'}
          isActive={evaluationOptions === 'basic'}
          onClick={() => setEvaluationOptions('basic')}
        />
        <ButtonSelect
          title={'Advanced performance evaluation'}
          subtitle={'Evaluate performance using advance performance criteria like OKR, SMART Goals and predefined Organisational metrics.'}
          isActive={evaluationOptions === 'advanced'}
          onClick={() => setEvaluationOptions('advanced')}
          className='mt-8'
        />

        <div className='w-full mt-[20px]'>
          <Button 
            text='Next' 
            type='submit' 
            onClick={() => submit()} 
          />
        </div>
        <div
          className='flex justify-center w-full cursor-pointer'
          onClick={() => history.push('/performance')}
        >
          <span className='p2-medium text-color-black mt-3'>
            Back to Dashboard
          </span>
        </div>
      </div>
    </PerformanceEvaluationLayout>
  );
}

export default SelectEvaluationOptions;
