import React from 'react';
import ProtectedRoute from './protected';
import OnboardingVerified from '../pages/employee-onboarding/onboarding';

export const EmployeeOnboardingRoutes = [
  <ProtectedRoute
    exact
    path='/onboarding'
    component={OnboardingVerified}
  />
];


