import React, { useState, useEffect } from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Collapse,
} from 'reactstrap';
import classnames from 'classnames';
import DropDownSVG from '../assets/svg/chevron-down.svg';

const TabView = ({ Tabs, activeTab, setActiveTab }) => {
  const [collapse, setCollapse] = useState(false);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  useEffect(() => {
    if(activeTab > Tabs.length) {
      setActiveTab(1)
    }
  }, [])

  const toggleCollapse = () => {
    setCollapse(!collapse);
  };

  function switchTab(index) {
    setActiveTab(index);
    toggleCollapse();
  }

  return (
    <React.Fragment>
      {/* Desktop Tab */}
      <div
        className='hidden md:flex tab-view-container select-none'
      >
        <Nav
          tabs
          className='tab-view !rounded-none tab-box-shadow !flex-nowrap w-full p-0 overflow-y-hidden'
        >
          {activeTab < Tabs.length + 1 && Tabs.map((item, index) => (
            <NavItem className='flex flex-row justify-center items-center !max-w-[220px] !h-[100%] p-0' key={index}>
              <NavLink
                className={classnames(
                  'flex flex-col justify-start items-start tab-view-item !w-full !rounded-none  p-0',
                  {
                    'duration-500 border-b-2': activeTab === index + 1,
                  },
                )}
                onClick={() => {
                  toggle(index + 1);
                }}
              >
                <div
                  className={classnames(
                    'flex flex-row justify-center items-start tab-view-item !rounded-none min-w-[135px] min-h-[100%]',
                    {
                    'text-secondary-2 text-[14px] duration-600 border-b-[4px] pb-[14px] pt-[18px] border-secondary-2 cursor-default': activeTab === index + 1,
                    'text-[12px] text-color-gray hover:text-gray-500 duration-500 pb-[14px] pt-[18px] hover:border-b-[4px] hover:text-[14px] hover:border-gray-300': activeTab !== index + 1,
                  })}
                >
                  {item.title}
                </div>
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </div>

      <div className='flex md:hidden flex-col bg-white border border-color-off-white px-4'>
        <div onClick={toggleCollapse} className='h-[60px] flex justify-center'>
          <button className='flex justify-between flex-row items-center w-full '>
            <span className={'text-secondary-2 text-[15px] duration-500'}>
              {Tabs[activeTab - 1]?.title}
            </span>
            <img src={DropDownSVG} alt='' width={'12px'} />
          </button>
        </div>

        <Collapse isOpen={collapse}>
          <div className='flex flex-col'>
            {activeTab < Tabs.length + 1 && Tabs.map((tab, index) => (
              <>
                {index + 1 !== activeTab ? (
                  <button
                    className='h-[50px] text-start'
                    onClick={() => switchTab(index + 1)}
                    key={index}
                  >
                    <span className='p4-bold text-color-gray'>
                      {tab.title}
                    </span>
                  </button>
                ) : null}
              </>
            ))}
          </div>
        </Collapse>
      </div>

      <TabContent activeTab={activeTab} className='mt-[35px]'>
        {activeTab < Tabs.length + 1 && Tabs.map(({ title, Content }, index) => (
          <TabPane tabId={index + 1} key={index}>
            <>
              {activeTab - 1 === index ? (
                <>{Content ? <Content /> : null}</>
              ) : null}
            </>
          </TabPane>
        ))}
      </TabContent>
    </React.Fragment>
  );
};

export default TabView;
