import { Axios } from '../axios';

const getBranches = async (payload) => {
  const { data } = await Axios.get(
    `/mobile/branches`,
  );
  return data?.data;
};

const getBranch = async (payload) => {
  const { data } = await Axios.get('/mobile/branches/' + payload);
  return data?.data;
};

const getBranchEmployees = async (payload) => {
  const { data } = await Axios.get(`/mobile/branches/${payload.id}`, payload);
  return data?.data;
};

const getBranchTeams = async (payload) => {
  const { data } = await Axios.get('/mobile/branch/teams/' + payload.id, payload);
  return data?.data;
};

const getBranchDepartments = async (payload) => {
  const { data } = await Axios.get('/mobile/branch/departments/' + payload.id, payload);
  return data?.data;
};

const getBranchWalletHistory = async (payload) => {
  let start_date = payload.start_date ? '&start_date=' + payload.start_date : '';
  let end_date = payload.end_date ? '&end_date=' + payload.end_date : '';
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  let type = payload.type ? '&type=' + payload.type : '';

  const { data } = await Axios.get(`/mobile/wallet/history/by-branch/${payload.id}?limit=${payload.limit}&page=${payload.page}${start_date}${end_date}${search}${filter}${type}`);
  return data?.data;
};

const retryTransaction = async (payload) => {
  const { data } = await Axios.get(`/mobile/branch/transaction/retry-transaction/${payload.transaction_id}/${payload.branch_id}`);
  return data?.data;
};

const refreshTransactionStatus = async () => {
  const { data } = await Axios.get('/mobile/branch/transactions/bulk/status',
  {
    timeout: 0
  });
  return data;
};

const checkTransactionStatus = async (payload) => {
  const { data } = await Axios.get(`/mobile/branch/transactions/status/${payload}`);
  return data?.data;
};

export const branches = {
  getBranches,
  getBranch,
  getBranchEmployees,
  getBranchTeams,
  getBranchDepartments,
  getBranchWalletHistory,
  retryTransaction,
  refreshTransactionStatus,
  checkTransactionStatus
};
