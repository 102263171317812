import { Axios } from '../axios';

const getHMOBeneficiary = async () => {
  const { data } = await Axios.get('/mobile/hmo');
  return data?.data;
};

const getHMOs = async () => {
  const { data } = await Axios.get(`/mobile/hmo/hmos`);
  return data?.data;
};

const getHMOPlans = async (payload) => {

  const { data } = await Axios.get(
    `/mobile/hmo/hmos/${payload}/hmo_plans`,
  );
  return data?.data;
};

const getHMOPlan = async (payload) => {
  const { data } = await Axios.get('/mobile/hmo/hmo_plans/' + payload,
  {
    timeout: 0
  });
  return data?.data;
};

const storeDetails = async (payload) => {
  const { data } = await Axios.post('/mobile/hmo', payload);
  return data;
};

const storeShort = async (payload) => {
  const { data } = await Axios.post('/mobile/hmo/short', payload);
  return data;
};
const fetchDependants = async (payload) => {
  const { data } = await Axios.get(
    `/mobile/hmo/dependants`,
  );
  return data?.data;
};

const fetchDependant = async (payload) => {
  const { data } = await Axios.get('/mobile/hmo/dependants/' + payload);
  return data?.data;
};

const storeDependant = async (payload) => {
  const { data } = await Axios.post('/mobile/hmo/dependants', payload);
  return data;
};

const getHMOSettings = async () => {
  const { data } = await Axios.get('/mobile/hmo/settings');
  return data?.data;
};

export const hmo = {
  getHMOBeneficiary,
  getHMOs,
  getHMOPlans,
  getHMOPlan,
  storeDetails,
  storeShort,
  fetchDependants,
  fetchDependant,
  storeDependant,
  getHMOSettings
};