import React, { useEffect, useState } from 'react';
import CloseSVG from '../../../../assets/svg/close.svg';
import FormInput from '../../../form-input';
import FormSelectBranch from '../../../employee/form-select-branch';
import FormSelectDepartment from '../../../employee/form-select-department';
import FormSelectEmployee from '../../../employee/form-select-employee';
import FormSelectTeam from '../../../employee/form-select-team';
import Modal from '../../modal';
import FormSelect from '../../../form-select';

function AddPerformanceOKR({
  isOpen,
  closeModal,
  okrData,
}) {

  const [source, setSource] = useState('employee');
  const [sourceId, setSourceId] = useState(null);
  const [sourceName, setSourceName] = useState(null);

  const sourceOptions = [
    { label: 'Employee', value: 'employee' },
    { label: 'Team', value: 'team' },
    { label: 'Department', value: 'department' },
    { label: 'Branch', value: 'branch' },
  ];

  const [okrObjectives, setOkrObjectives] = useState([]);

  const [okrName, setOkrName] = useState(null);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [okrId, setOkrId] = useState(null);

  useEffect(() => {
    if (okrData) {

      setOkrId(okrData.id);
      setStartDate(okrData?.start_date)
      setEndDate(okrData?.end_date)
      setOkrName(okrData.okr_title);
      setOkrObjectives(okrData.objectives);
      setSourceName(okrData.source_name)
      setSourceId(okrData.source_id)
      setSource(okrData.source)
      setOkrType('edit');

      let objs = []
      okrData.objectives.forEach((obj) => {
        let krs = []
        obj.key_results?.forEach((keyItem) => {
          if (keyItem.key_result && keyItem.weight && keyItem.weight > 0 && keyItem.start_date && keyItem.end_date) {
            let newKrs = {
                key_result: keyItem.key_result,
                weight: keyItem.weight,
                start_date: keyItem.start_date,
                end_date: keyItem.end_date,
            }
            krs.push(newKrs)
          }
        })
        let newObj = {
          objective: obj.objective,
          error: null,
          max_grade: obj.max_grade,
          start_date: obj.start_date,
          end_date: obj.end_date,
          key_results: krs
        }
        objs.push(newObj)
      })
      setOkrObjectives(objs);
    } else {
      setOkrType('add');
    }
  }, [okrData]);

  const [okrType, setOkrType] = useState('add');

  
  return (
    <Modal
      scrollable={true}
      className='!md:max-w-[600px] w-full !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <form className='form pb-6'>
          <div className='pl-6 pr-6 pt-4'>
            <div className='flex flex-row justify-between'>
              <span className='header-3'>View Objective and Key results</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='' />
              </div>
            </div>
          </div>
          <hr className='divider mb-2 mt-2' />
          <div className='flex flex-col max-h-fit mx-8'>
            <div className='flex flex-row md:flex-col w-full justify-between'>
              <div className='w-full md:w-1/3 pr-2'>
                <FormSelect
                  readOnly={true}
                  defaultValue={source}
                  options={sourceOptions}
                  label='OKR For:'
                />
              </div>
              <div className='w-full md:w-2/3 pl-2'>
                {!source || source === 'company' ? null : source ===
                  'employee' ? (
                  <FormSelectEmployee
                    label={'Employee'}
                    name='employee'
                    placeholder='Select Employee'
                    value={sourceId}
                    readOnly={true}
                    empName={sourceName}
                  />
                ) : source === 'team' ? (
                  <FormSelectTeam
                    label={'Team'}
                    name='team'
                    placeholder='Select Team'
                    value={sourceId}
                    readOnly={true}
                    teamName={sourceName}
                  />
                ) : source === 'department' ? (
                  <FormSelectDepartment
                    label={'Department'}
                    name='department'
                    placeholder='Select Department'
                    value={sourceId}
                    readOnly={true}
                    departmentName={sourceName}
                  />
                ) : (
                  <FormSelectBranch
                    label={'Branch'}
                    name='branch'
                    placeholder='Select Branch'
                    value={sourceId}
                    readOnly={true}
                    branchName={sourceName}
                  />
                )}
              </div>
            </div>
            <span className='text-[13px] font-normal text-color-gray -mt-2 '>OKR Title</span>
            <div className='w-full p-2 border rounded-md text-[13px] mb-2'>
              {okrName}
            </div>
            <span className='text-color-gray -mb-2 text-[13px]'>Period Covered</span>
            <div className='flex flex-row md:flex-col w-full justify-between'>
                <div className='w-full md:w-1/2'>
                    <FormInput
                    name='start_date'
                    type='date'
                    value={startDate}
                    readOnly
                    className='!h-[30px] text-[13px] w-[100%] '
                    />
                </div>
                <div className='w-full md:w-1/2 pl-2'>
                    <FormInput
                      name='end_date'
                      type='date'
                      value={endDate}
                      readOnly
                      className='!h-[30px] text-[13px] w-[100%] '
                    />
                </div>
            </div>
            <div className='flex flex-col mt-2'>
              <span className='header-4'>Objectives</span>
              <span className='p3 text-[12px] text-gray-400'>
                Your expected Key Objectives and associated key results.
              </span>
              <hr className='divider mt-2' />
            </div>
            <div className='flex flex-col gap-3'>
              {okrObjectives &&
              okrObjectives?.map((item, index) => (
                <div className='flex flex-col hover:shadow-2xl shadow-green-500 p-3 pt-2 rounded-xl border-1 bg-gray-50 overflow-clip'>
                  <div className='flex justify-between'>
                      <div className='text-[14px] italic text-[#44AF69] font-bold'>
                          Objective {index + 1}
                      </div>
                    </div>
                  <div
                    className='flex flex-row items-center justify-between first-line:-mt-3 pr-2 text-[13px]'
                    key={index}
                  >
                    <div className='w-[100%]'>
                      <div className='flex flex-col w-full'>
                        <span className='text-[13px] leading-[23px] font-normal text-color-gray'>Objective</span>
                        <div className='w-full p-2 border rounded-md text-[13px] mb-2'>
                          {item.objective}
                        </div>
                      </div>
                      <div className='text-[13px] italic text-gray-400 -mt-0 -mb-2'>
                        Timeline
                      </div>
                      <div className='flex flex-row md:flex-col justify-between'>
                        <div className='w-full md:w-1/2 md:pr-2'>
                          <FormInput
                            name='start_date'
                            type='date'
                            value={item.start_date}
                            readOnly
                            className='!h-[30px] text-[13px] w-[100%] !bg-gray-100'
                          />
                        </div>
                        <div className='w-full md:w-1/2 md:pl-2'>
                          <FormInput
                            name='end_date'
                            type='date'
                            value={item.end_date}
                            readOnly
                            className='!h-[30px] text-[13px] w-[100%] !bg-gray-100'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className='divider mt-1 mb-1' />
                  <div className='flex justify-end w-full text-[14px] text-red-600 font-bold italic'>{item.error? item.error: null}</div>
                  <div className='text-[15px] italic text-gray-400 font-semibold'>
                    Key results
                  </div>
                  <div className='p-2 border rounded-md bg-secondary-2-extralight shadow-sm'>
                    <div className='flex flex-col'>
                      {item.key_results.length > 0 && item.key_results?.map((subItem, subIndex) => (
                      <>
                        <div className='flex justify-between'>
                          <div className='text-[13px] italic text-gray-400 font-semibold'>Key Result {subIndex+1}</div>
                        </div>
                        <div className='flex flex-col w-full'>
                          <span className='text-[13px] leading-[23px] font-normal text-color-gray'>Expected Key Result: <span className='font-semibold text-[12px]'>Weight({subItem.weight})</span></span>
                          <div className='w-full p-2 border rounded-md text-[13px] mb-2 bg-white text-justify'>
                            {subItem.key_result}
                          </div>
                        </div>
                        <div className='text-[13px] italic text-gray-400 -mb-2'>
                          Timeline
                        </div>
                        <div className='flex flex-row md:flex-col w-full justify-between'>
                          <div className='w-full md:w-1/2'>
                            <FormInput
                              name='start_date'
                              type='date'
                              value={subItem.start_date}
                              readOnly
                              className='!h-[30px] text-[13px] w-[100%] !bg-gray-100'
                            />
                          </div>
                          <div className='w-full md:w-1/2 pl-2'>
                            <FormInput
                              name='end_date'
                              type='date'
                              value={subItem.end_date}
                              readOnly
                              className='!h-[30px] text-[13px] w-[100%] !bg-gray-100'
                            />
                          </div>
                          
                        </div>
                        <hr className='divider mt-1 mb-1' />
                      </>
                      ))} 
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddPerformanceOKR;
