import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../components/layouts/dashboard';
import { useParams } from 'react-router-dom';
import TabView from '../../components/tabview';
import { Initials } from '../../utils/functions';
import DepartmentOverviewTab from '../../components/employee/view-department/department-info';
import DepartmentTeamTab from '../../components/employee/view-department/department-teams';
import { useGetDepartment } from '../../redux/human-resources/hook/departments';
import { useSelector } from 'react-redux';

const ViewDepartmentPage = () => {

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const { user } = useSelector((state) => state.userSlice);
  
  const [activeTab, setActiveTab] = useState(1);
  const { id } = useParams();
  const { data: department } = useGetDepartment(id);
  
  const DepartmentTabs = [
    {
      title: 'Employees',
      Content: DepartmentOverviewTab,
    },
    {
      title: 'Teams',
      Content: DepartmentTeamTab,
    },
  ];

  return (
    <DashboardLayout title='View Departments'>
      <div className='flex flex-col'>
        <div className='flex flex-row items-center box-shadow h-[104px]'>
          <div className='flex flex-row px-[55px] justify-between  w-full'>
            <div className='flex flex-row items-center'>
              <div className='flex font-bold justify-center items-center h-[58px] w-[58px] rounded-[58px] border-[3px] header-3 border-secondary-2 bg-[#FFC0CB]'>
                {department?.name ? Initials(department?.name): null}
              </div>
              <div className='flex flex-col pl-4 items-start justify-center'>
                <span className='header-4'>{department?.name}</span>
                <span className='p4'>{department?.description}</span>
              </div>
            </div>
          </div>
        </div>

        <div className='mt-8'>
          <TabView
            Tabs={DepartmentTabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ViewDepartmentPage;
