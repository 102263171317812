import DOMPurify from 'dompurify';
import React, { useState } from 'react';
import { useEffect } from 'react';
import Button from '../../components/button';
import { useGetBeneficiary, useGetHMOPlan, useGetHMOs, useGetOnboardingHMOPlans, useStoreDetails, useStoreDetailsShort } from '../../redux/hmo/hook';
import LoadingBar from '../../components/loader-bar';
import FormSelect from '../../components/form-select';
import EmployeeHmoLayout from '../../components/layouts/employee-hmo';
import { store } from '../../redux';
import { setAlert } from '../../redux/components/components-slice';
import AsyncSelect from 'react-select/async';
import { useHistory } from 'react-router-dom';

function SelectHMO() {
  
    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    const { data: hmo_beneficiary, isLoading: beneficiaryLoading } = useGetBeneficiary();
    const [hmoName, setHmoName] = useState('')
    const [hmoId, setHmoId] = useState(null)
    const [hmoPlan, setHmoPlan] = useState('')
    const [planId, setPlanId] = useState(null)
    const [hospitals, setHospitals] = useState([])
    const [defaultOptions, setDefaultOptions] = useState([])

    const [selectedHospitals, setSelectedHospitals] = useState([])

    const history = useHistory()

    //Comparer Function    
    function GetSortOrder(prop) {    
        return function(a, b) {    
            if (a[prop] > b[prop]) {    
                return 1;    
            } else if (a[prop] < b[prop]) {    
                return -1;    
            }    
            return 0;    
        }    
    }  

    const loadOptions = async (inputValue) => {
        if (hospitals && hospitals.length > 0) {
            let data = [];
            let newData = hospitals?.filter( item => item?.hospital_name?.toLowerCase().includes(inputValue.toLowerCase()));
            newData.forEach(item => {
                data.push({
                    label: item.hospital_name + ' | ' + item.state + ' | ' + item.city + ' | ' + item.address  ,
                    value: item.id,
                });
            }) 
            return data.sort(GetSortOrder("label"));
        } else {
            return [];
        }
    };

    useEffect(() => {
        if (hmo_beneficiary) {
            if (hmo_beneficiary.status == 2 || hmo_beneficiary.status == 0) {
                setHmoName(hmo_beneficiary.hmo_name)
                setHmoId(hmo_beneficiary.hmo_id)
                setHmoPlan(hmo_beneficiary.hmo_plan)
                setPlanId(hmo_beneficiary.hmo_plan_id)
            } else {
                history.push('/dashboard')
            }
        }
    }, [hmo_beneficiary])

    const { data: hmos } = useGetHMOs();

    const { data: hmoPlans, isLoading: plansLoading } = useGetOnboardingHMOPlans(hmoId);

    const { data: hmoPlanInfo, isLoading: planInfoLoading } = useGetHMOPlan(planId);

    const [hmoData, setHmoData] = useState([
        { value: '', label: 'No HMO Selected', hmo_name: '' }
    ])

    const [hmoPlanData, setHmoPlanData] = useState([
        { value: '1', label: 'No Plan Selected' }
    ])
    useEffect(() => {
        if (hmos) {
            setHmoData(hmos)
        }
    }, [hmos])

    useEffect(() => {
        if (hmoPlans) {
            setHmoPlanData(hmoPlans)
        }
    }, [hmoPlans])

    useEffect(() => {
        if (hmoPlanInfo) {
            if (hmoPlanInfo.hospitals && hmoPlanInfo.hospitals.length > 0) {
                let data = [];
                let newData = hmoPlanInfo.hospitals;
                newData.forEach(item => {
                    data.push({
                        label: item.hospital_name + ' | ' + item.state + ' | ' + item.city + ' | ' + item.address  ,
                        value: item.id,
                    });
                }) 
                setDefaultOptions(data.sort(GetSortOrder("label")))
                setHospitals(hmoPlanInfo.hospitals)
            }
        }
    }, [hmoPlanInfo])
    

    const { mutateAsync: storeDetails, isLoading: storeDetailsLoading } = useStoreDetails();
    const { mutateAsync: storeDetailsShort, isLoading: storeDetailsShortLoading } = useStoreDetailsShort();
        
    const submitForm = async () => {
        let hospitals = "";
        if (selectedHospitals.length < 1) {
            dispatch(setAlert(true, 'info', 'Select at least one hospital'));
            return;
        }
        let hospitalArrays = [];

        selectedHospitals.forEach((item) => {
            hospitalArrays.push(item.value);
        });

        hospitals = hospitalArrays.join();

        if (hmo_beneficiary?.status == 0){
            let payload = {
                hospitals
            }
            await storeDetailsShort(payload).then(() => {
                history.push('/hmo')
            })
        } else if (hmo_beneficiary?.status == 2) {
            let payload = {
                hmo_name: hmoName,
                hmo_id: hmoId,
                hmo_plan: hmoPlan,
                hmo_plan_id: planId,
                hospitals
            }
            await storeDetails(payload).then(() => {
                history.push('/hmo')
            })
        } else {
            store.dispatch(setAlert(true, 'success', 'No HMO beneficiary found'))
        }
    }

    return (
        <EmployeeHmoLayout step='2'>
            <div className='flex flex-col justify-center w-full md:pt-5 min-h-fit overflow-y-auto gap-4'>
                <div className='flex md:min-h-screen flex-col w-full md:px-0'>
                    <form className='form min-h-fit'>
                        <div className='w-full text-center flex flex-col pb-[25px] pt-3'>
                        <span className='text-[20px] font-bold text-secondary-2'>
                            Select HMO, Plan and Hospitals
                        </span>
                        <span className='header-5-regular text-secondary-2'>
                            Update and edit your HMO settings
                        </span>
                        </div>
                        <hr className='divider -mt-2' />
                        <div className='flex flex-col pl-4 pr-4 py-3 h-full min-h-fit w-full'>
                            <div className='flex flex-col md:flex-row'>
                                <div className='flex w-full min-w-[100%] px-4 md:px-0 flex-col'>
                                {beneficiaryLoading?
                                <div className='flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]'>
                                    <LoadingBar loading={beneficiaryLoading} />
                                </div>
                                :
                                <div className='flex flex-col w-full gap-4'>
                                    {hmo_beneficiary?.status == 2?
                                    <div className='flex flex-col gap-1 h-[100px] rounded-md !bg-secondary-2 p-4'>
                                        <span className='text-[20px] font-bold !text-white'>HMO Budget:</span>
                                        <span className='text-[16px] font-normal !text-white'>{hmo_beneficiary?.hmo_budget}</span>
                                    </div>
                                    :
                                    null
                                    }
                                    <div className='flex flex-col p-4 border rounded shadow-sm w-full'>
                                        <div className='flex md:flex-row flex-col w-full justify-between gap-2'>
                                            <div className='md:w-1/2 w-full md:pr-2'>
                                            <FormSelect
                                                value={hmoId}
                                                options={hmoData}
                                                readOnly={hmo_beneficiary?.status == 0? true : false}
                                                onChange={(selected) => {
                                                    if (hmo_beneficiary?.status == 2) {
                                                        setHmoId(selected);
                                                    } 
                                                }}
                                                label={hmo_beneficiary?.status == 0? 'Select HMO - (ReadOnly)' : 'Select HMO - (Editable) '}
                                            />
                                            </div>
                                            {hmoPlans?
                                            <div className='md:w-1/2 w-full md:pl-2'>
                                                <FormSelect
                                                    value={planId}
                                                    options={hmoPlanData}
                                                    readOnly={hmo_beneficiary?.status == 0? true : false}
                                                    onChange={(selected) => {
                                                        if (hmo_beneficiary?.status == 2) {
                                                            setPlanId(selected);
                                                        }
                                                    }}
                                                    label={hmo_beneficiary?.status == 0? 'Select HMO Plan - (ReadOnly)' : 'Select HMO Plan - (Editable) '}
                                                />
                                            </div>
                                            : plansLoading && hmoId?
                                            <div className='flex flex-col w-full text-center align-center justify-center'>
                                                <LoadingBar loading={plansLoading && hmoId} />
                                            </div>
                                            :
                                            <div className='flex flex-col md:w-1/2 w-full text-center align-center justify-center p-3'>
                                                No plan fetched
                                            </div>
                                            }
                                            
                                        </div>
                                        <div className='flex flex-col p-2 border rounded mb-2 w-full'>
                                            {hmoPlanInfo?
                                            <>
                                                <div className='text-gray-500 text-[15px]'>
                                                    Plan Name
                                                </div>
                                                <div className='text-[13px] font-extrabold'>
                                                    {hmoPlanInfo?.name}
                                                </div>
                                                <hr className='divider' />
                                                <div className='font-bold text-[16px] mb-2'>
                                                    Plan Coverage
                                                </div>
                                                <div className='grid md:grid-cols-3 grid-cols-3 gap-4 w-full pt-2 duration-500'>
                                                    <div className='flex flex-col'>
                                                        <div className='text-gray-500 text-[14px]'>
                                                            Plan Type
                                                        </div>
                                                        <div className='text-[13px]'>
                                                            {hmoPlanInfo?.Plan_type}
                                                        </div> 
                                                    </div>
                                                    <div className='flex flex-col'>
                                                        <div className='text-gray-500 text-[14px]'>
                                                            An Pn Childbirth
                                                        </div>
                                                        <div className='text-[13px]'>
                                                            {hmoPlanInfo?.an_pn_childbirth}
                                                        </div>
                                                        
                                                    </div>
                                                    <div className='flex flex-col'>
                                                        <div className='text-gray-500 text-[14px]'>
                                                            Dental
                                                        </div>
                                                        <div className='text-[13px]'>
                                                            {hmoPlanInfo?.dental}
                                                        </div>
                                                        
                                                    </div>
                                                    <div className='flex flex-col'>
                                                        <div className='text-gray-500 text-[14px]'>
                                                            Drugs
                                                        </div>
                                                        <div className='text-[13px]'>
                                                            {hmoPlanInfo?.drugs}
                                                        </div>
                                                        
                                                    </div>
                                                    <div className='flex flex-col'>
                                                        <div className='text-gray-500 text-[14px]'>
                                                            Emergency
                                                        </div>
                                                        <div className='text-[13px]'>
                                                            {hmoPlanInfo?.emergency}
                                                        </div>
                                                    </div>
                                                    <div className='flex flex-col'>
                                                        <div className='text-gray-500 text-[14px]'>
                                                            Gym
                                                        </div>
                                                        <div className='text-[13px]'>
                                                            {hmoPlanInfo?.gym}
                                                        </div>
                                                    </div>
                                                    <div className='flex flex-col'>
                                                        <div className='text-gray-500 text-[14px]'>
                                                            Gynecology
                                                        </div>
                                                        <div className='text-[13px]'>
                                                            {hmoPlanInfo?.gynecology}
                                                        </div>
                                                    </div>
                                                    <div className='flex flex-col'>
                                                        <div className='text-gray-500 text-[14px]'>
                                                            Optical care
                                                        </div>
                                                        <div className='text-[13px]'>
                                                            {hmoPlanInfo?.optical_care}
                                                        </div>
                                                    </div>
                                                    <div className='flex flex-col'>
                                                        <div className='text-gray-500 text-[14px]'>
                                                            Surgery Coverage
                                                        </div>
                                                        <div className='text-[13px]'>
                                                            {hmoPlanInfo?.surgery_coverage.toLocaleString('en-NG', {
                                                                style: 'currency',
                                                                currency: 'NGN',
                                                            })}
                                                        </div>
                                                    </div>
                                                    <div className='flex flex-col'>
                                                        <div className='text-gray-500 text-[14px]'>
                                                            Ward Type
                                                        </div>
                                                        <div className='text-[13px]'>
                                                            {hmoPlanInfo?.ward_type}
                                                        </div>
                                                    </div>
                                                    <div className='flex flex-col justify-center'>
                                                        <div className='text-gray-500 text-[14px] hover:underline hover:italic'>
                                                            <a
                                                                href={hmoPlanInfo?.benefit_detail_link}
                                                                target='_blank'
                                                                rel='noreferrer'
                                                            >
                                                                View Full Benefits
                                                            </a>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                            { planInfoLoading && planId?
                                            <div className='flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]'>
                                                <LoadingBar loading={planInfoLoading} />
                                            </div>
                                            :
                                            <div className='flex p-2 justify-center items-center w-full'>
                                                No HMO Plan selected
                                            </div>
                                            }
                                            </>

                                            }
                                        </div>
                                        {hmoPlanInfo?
                                        <div>
                                            <label className='text-[15px] leading-[23px] font-normal text-color-gray mb-1'>
                                                Select Employees
                                            </label>
                                            <AsyncSelect
                                                isMulti
                                                cacheOptions
                                                defaultOptions={defaultOptions}
                                                readOnly={selectedHospitals.length > 1? true : false}
                                                placeholder={'Type hospital name to select'}
                                                loadOptions={loadOptions}
                                                onChange={(options) => {
                                                    setSelectedHospitals(options)
                                                }}
                                                classNamePrefix='mySelect'
                                            />
                                        </div>
                                        :
                                        null}
                                    </div>
                                    <div className='flex justify-between w-full items-center'>
                                        <div
                                            className='flex justify-end'
                                            onClick={() => history.push('/dashboard')}
                                        >
                                            <span className='text-[15px] font-bold text-secondary-2 cursor-pointer hover:underline'>
                                                Back to Dashboard
                                            </span>
                                        </div>
                                        <Button
                                        text={'SAVE AND COMPLETE'}
                                        className='flex gap-2 h-[35px] w-fit p-2 '
                                        textClass={'!text-[13px]'}
                                        type='button'
                                        onClick={() => {
                                            submitForm()
                                        }}
                                        loading={storeDetailsLoading || storeDetailsShortLoading}
                                        />
                                    </div>
                                </div>
                                }    
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </EmployeeHmoLayout>
    );
}

export default SelectHMO;
