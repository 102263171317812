import React from "react";

export const Empty = () => (
  <svg
    width="512"
    height="512"
    viewBox="0 0 512 512"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M426.941 280.047L498.58 208.407H365.107L318.82 254.693L329.447 265.321L371.331 223.436H462.296L413.199 272.532H211.019L146.893 208.407H13.4202L85.0601 280.047L13.4202 351.687H243.082L296.579 298.188V496.971H103.202V368.219H88.1722V512H423.828V351.687H498.58L426.941 280.047ZM140.669 223.436L189.764 272.532H98.8L49.7031 223.436H140.669ZM236.857 336.657H49.7031L98.799 287.562H285.952L236.857 336.657ZM408.798 496.971H311.609V287.562H408.798V496.971ZM423.828 298.189L462.297 336.657H423.828V298.189Z"
        fill="black"
      />
      <path
        d="M177.98 221.801L238.257 196.812C251.042 203.753 265.408 207.405 280.047 207.405C328.389 207.405 367.718 168.076 367.718 119.734C367.718 71.3914 328.389 32.0626 280.047 32.0626C231.705 32.0626 192.376 71.3914 192.376 119.734C192.376 134.372 196.028 148.738 202.968 161.523L177.98 221.801ZM217.76 157.121C210.986 145.863 207.405 132.935 207.405 119.734C207.405 79.6786 239.992 47.092 280.047 47.092C320.102 47.092 352.689 79.6786 352.689 119.734C352.689 159.789 320.102 192.376 280.047 192.376C266.846 192.376 253.918 188.795 242.66 182.021L239.411 180.065L205.771 194.011L219.717 160.371L217.76 157.121Z"
        fill="black"
      />
      <path
        d="M159.812 47.092C172.796 47.092 183.358 36.5293 183.358 23.546C183.358 10.5626 172.796 0 159.812 0C146.829 0 136.266 10.5626 136.266 23.546C136.266 36.5293 146.829 47.092 159.812 47.092ZM159.812 15.0294C164.508 15.0294 168.329 18.8498 168.329 23.546C168.329 28.2422 164.508 32.0626 159.812 32.0626C155.116 32.0626 151.296 28.2422 151.296 23.546C151.296 18.8498 155.116 15.0294 159.812 15.0294Z"
        fill="black"
      />
      <path
        d="M408.798 143.781C408.798 156.764 419.361 167.327 432.344 167.327C445.328 167.327 455.89 156.764 455.89 143.781C455.89 130.797 445.328 120.235 432.344 120.235C419.361 120.235 408.798 130.797 408.798 143.781ZM432.344 135.264C437.041 135.264 440.861 139.085 440.861 143.781C440.861 148.477 437.041 152.297 432.344 152.297C427.648 152.297 423.828 148.477 423.828 143.781C423.828 139.085 427.648 135.264 432.344 135.264Z"
        fill="black"
      />
      <path
        d="M104.204 119.734H119.233V103.202H135.765V88.1722H119.233V71.6399H104.204V88.1722H87.6713V103.202H104.204V119.734Z"
        fill="black"
      />
      <path
        d="M392.767 71.64H407.796V55.1077H424.329V40.0783H407.796V23.546H392.767V40.0783H376.235V55.1077H392.767V71.64Z"
        fill="black"
      />
      <path
        d="M159.812 456.892H175.843V471.922H159.812V456.892Z"
        fill="black"
      />
      <path
        d="M191.875 456.892H207.906V471.922H191.875V456.892Z"
        fill="black"
      />
      <path
        d="M223.937 456.892H239.969V471.922H223.937V456.892Z"
        fill="black"
      />
      <path
        d="M341.471 445.674L352.689 434.456V472.423H367.718V434.456L378.936 445.674L389.565 435.046L360.204 405.686L330.842 435.046L341.471 445.674Z"
        fill="black"
      />
      <path
        d="M279.368 125.629C271.03 125.629 263.161 128.394 257.197 133.423C253.544 136.503 253.079 141.963 256.161 145.616C259.241 149.271 264.701 149.735 268.355 146.654C271.159 144.29 275.17 142.936 279.369 142.936H279.426C283.646 142.949 287.672 144.328 290.475 146.72C292.105 148.111 294.102 148.791 296.088 148.791C298.533 148.791 300.963 147.76 302.674 145.755C305.777 142.12 305.345 136.657 301.711 133.554C295.752 128.468 287.855 125.652 279.476 125.627C279.44 125.629 279.403 125.629 279.368 125.629V125.629Z"
        fill="black"
      />
      <path
        d="M244.754 100.001H244.674C239.89 99.9306 236.035 103.817 236 108.595C235.968 113.374 239.855 117.275 244.634 117.309H244.696C249.446 117.309 253.315 113.473 253.348 108.715C253.382 103.936 249.535 100.034 244.754 100.001Z"
        fill="black"
      />
      <path
        d="M312.322 117.131C312.352 117.137 312.383 117.14 312.413 117.146C312.663 117.195 312.915 117.235 313.172 117.261C313.255 117.27 313.339 117.27 313.422 117.275C313.615 117.289 313.807 117.308 314.005 117.309H314.066C314.364 117.309 314.658 117.294 314.948 117.264C319.318 116.827 322.729 113.139 322.729 108.654C322.729 104.228 319.405 100.585 315.118 100.069C315.079 100.064 315.041 100.055 315.002 100.052C314.804 100.031 314.602 100.025 314.4 100.017C314.308 100.014 314.218 100.002 314.125 100.001H314.08C314.078 100.001 314.076 100.001 314.075 100.001H314.044H313.993C313.99 100.001 313.986 100.001 313.983 100.001C309.232 100.001 305.404 103.836 305.37 108.595C305.342 112.798 308.346 116.32 312.322 117.131V117.131Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="512" height="512" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
