const { Axios } = require('./axios');

const getAdmins = async () => {
  const { data } = await Axios.get('/admins');
  return data;
};

export const admin = {
 getAdmins
};
