import React, { useEffect } from 'react';
import { Modal as DefaultModal } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { updateModalStatus } from '../../redux/components/components-slice';

export default function Modal({ isOpen, children, className, ...rest }) {
  const dispatch = useDispatch();

  // When Modal is closed
  useEffect(() => {
    if (isOpen) {
      dispatch(updateModalStatus(true));
    }

    return () => dispatch(updateModalStatus(false));
  }, [isOpen]);

  return (
    <DefaultModal
      isOpen={isOpen}
      className={`${className} !border-none`}
      modalClassName=' !border-none'
      {...rest}
    >
      {children}
    </DefaultModal>
  );
}
