import React from 'react';
import { TourProvider } from '@reactour/tour';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import OnboardingCard from '../onboarding-card';
import { onboardingSteps } from '../../redux/onboarding/constant';

export default function OnboardingProvider({ children }) {
  const [steps, setSteps] = useState();
  const { currentOnboarding, currentStep } = useSelector(
    (state) => state.onboardingSlice,
  );

  useEffect(() => {
    //set onboarding steps
    if (currentOnboarding) {
      let index = onboardingSteps.findIndex(
        (item) => (item.title = currentOnboarding),
      );

      if (index !== -1) {
        setSteps(onboardingSteps[index].steps);
      }
    }
  }, [currentOnboarding]);

  return (
    <>
      {steps && (
        <TourProvider steps={steps} ContentComponent={OnboardingCard}>
          {children}
        </TourProvider>
      )}
    </>
  );
}
