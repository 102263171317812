import React, { useState } from 'react';
import CloseSVG from '../../assets/svg/close.svg';
import TickSVG from '../../assets/svg/tick.svg';
import Button from '../button';
import Modal from './modal';

function ConfirmationModal({
  isOpen,
  closeModal,
  refresh,
  title,
  subtitle,
  btnFunction,
}) {
  return (
    <Modal
      scrollable={true}
      className='!max-w-[500px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='p-4 mb-10 overflow-auto'>
        <div className='flex flex-col overflow-auto justify-center'>
          <div className='flex flex-col md:mx-14 items-center'>
            <div
              className={`flex items-center justify-center w-[110px] h-[110px] rounded-[130px] bg-[#44AF69]`}
            >
              <img
                src={TickSVG}
                alt='tick'
                style={{ width: '60px', height: '60px' }}
              />
            </div>
            <span className='my-4 header-3'>{title}</span>
            <span className='my-2 p3 text-center'>{subtitle}</span>
            <div className='w-full mt-[20px]'>
              <Button
                text='OKAY!'
                type='submit'
                onClick={() => btnFunction()}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmationModal;
